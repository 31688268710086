import { LanguageProvider } from "@shared/components/language-provider";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import "../components/modal-geral.scss";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useFilterContext } from "../../../../protected-routes";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";

// #region TODO - list comapania

export function ModalGeral({ title, userData, setTitleModal }) {

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  //Dados Generales
  const [usuario, setUsuario] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.id);
  const [tipoSelected, setTipoSelected] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.tipo?.descripcion);
  const [optionesTipoUsuarios, setOptionesTipoUsuarios] = useState([]);
  const [nombre, setNombre] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.nombre);
  const [apelido, setApelido] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.apellido);
  const [email, setEmail] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.email);
  const [telefono, setTelefono] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.telefono);
  const [celular, setCelular] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.apellido);
  const [idiomaSelected, setIdiomaSelected] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.idioma?.descripcion);
  const [listIdiomas, setListIdiomas] = useState([]);
  const [companiaSelected, setCompaniaSelected] = useState("");
  const [listCompanias, setListCompanias] = useState([]);
  const [clave, setClave] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.password);
  const [confirmacion, setConfirmacion] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.repeatPassword);
  const [nivelSeguridade, setNivelSeguridade] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.nivel);
  const [internet, setInternet] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.nivelInternet);
  const [usuarioRed, setUsuarioRed] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.usuarioDeRed);
  const [discoverer, setDiscoverer] = useState(title === "Mantener Usuarios - Alta" ? false : userData?.usuarioDeOracle);
  const [arcLog, setArcLog] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.pathLog);
  const [cuil, setCuil] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.cuil);
  const [ultMod, setUltMod] = useState(title === "Mantener Usuarios - Alta" ? "" : userData?.usuarioUltimaModificacion?.id);
  const [fecUltMod, setFecUltMod] = useState(title === "Mantener Usuarios - Alta" ? "" : new Intl.DateTimeFormat("pt-BR").format(new Date(userData?.fechaUltimaModificacion.date))); 

  const [contador, setContador] = useState(0);
  const [idRemoved, setIdRemoved] = useState(null);

  const [rolesRolesSelected, setRolesRolesSelected] = useState("");
  const [roleSelectedObject, setRoleSelectedObject] = useState(null);

  const [roleSelectedInTabla, setRoleSelectedInTabla] = useState(null);
  const [rolesPaisSelected, setRolesPaisSelected] = useState(userData?.pais?.descripcion);
  const [rolesPaisSelectedObject, setRolesPaisSelectedObject] = useState(userData?.pais);

  const [listRolesRoles, setListRolesRoles] = useState([]);
  const [listRolesPais, setListRolesPais] = useState([]);
  const [listagregarRole, setListAgregarRole] = useState([]);

  const [ubicacionSelected, setUbicacionSelected] = useState(""); 
  const [ubicanioAreaSelected, setUbicanioAreaSelected] = useState("");
  const [listUbicaciones, setListUbicaciones] = useState([]);
  const [listUbicacionAreas, setListUbicacionAreas] = useState([]);

  const [segCheckedAdmUser, setSegCheckedAdmUser] = useState(title === "Mantener Usuarios - Alta" ? false : userData?.administrador);
  const [segCheckedRespProycto, setSegCheckedRespProycto] = useState(title === "Mantener Usuarios - Alta" ? false : userData?.administraProyectos);
  const [segCheckedAqs, setSegCheckedAqs] = useState(title === "Mantener Usuarios - Alta" ? false : userData?.administraAqs);
  const [segCheckedDanios, setSegCheckedDanios] = useState(title === "Mantener Usuarios - Alta" ? false : userData?.administraDanos );
  const [segCheckedActivo, setSegCheckedActivo] = useState(title === "Mantener Usuarios - Alta" ? true : userData?.activo );

  const [listAgencias, setListAgencias] = useState([]);
  const [auxListAgencias, setAuxListAgencias] = useState([]);

  useEffect(() => {

    findIdiomas();
    findyCompaniaCelular();
    findTipoUsuarioService();
    findPaises();
    findAgencias();
    findUbicacion();
    findAreasSistemas();
    findAllRoles();
    
  }, [userData?.id, title]); //apenas se mudar de usuario ou pais

  const footerContent = (
    <div>
      <Button
        label="Aceptar"
        icon="pi pi-check"
        onClick={() => aceptar()}
        autoFocus
      />

      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => {
          setAuxListAgencias(JSON.parse(JSON.stringify(listAgencias)));
          setTitleModal(null);
        }}
        className="p-button-text"
      />
    </div>
  );

  function validation() {
    
    if(usuario.trim() === "" || nombre.trim() === "" || apelido.trim() === "" || tipoSelected.trim() === "" || idiomaSelected.trim() === "" || clave.trim() === "" || confirmacion.trim() === "" || nivelSeguridade.trim() === "" || internet.trim() === "" ){
      return true
    }else{
      return false
    }
  }


  // #region adic. modal de confirmação.
  function aceptar() {
    if (validation()) return alert("Faltan conpletar datos Usuario");

    const finalListAgencias = listAgencias.map(({id, ...rest}) => rest)

    const body = {
      dadosGenerales: "falta",
      seguridade: {
        segCheckedAdmUser,
        segCheckedRespProycto,
        segCheckedAqs,
        segCheckedDanios,
        segCheckedActivo
      },
      agencias: finalListAgencias,
      ubicanion: {
        ubicacionSelected,
        ubicanioAreaSelected
      },
      roles: {
        listagregarRole
      },
    }

  }

  async function findTipoUsuarioService() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/tipoUsuarioService", {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.id)
          }
        });
      if (status === 200) {
        setOptionesTipoUsuarios(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findIdiomas() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findIdiomas");
      if (status === 200) {
        setListIdiomas(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findyCompaniaCelular() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findyCompaniaCelular", {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.id)
          }
        });
      if (status === 200) {
        setListCompanias(data);
        if(title === "Mantener Usuarios - Modificar") {
          let result = data.find(v => {return v?.id === userData?.companiaCelular?.id}) || "";
          setCompaniaSelected(result)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findAllRoles() {

    if(title === "Mantener Usuarios - Modificar") {
      findRolesByUser();
    }

    try {
      const { data, status } = await sipcoAxiosService.get(
        "/mantenerUsuario/findAllRoles"
      );

      if (status === 200) {
        setListRolesRoles(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findRolesByUser() {

    try {
      const { status, data } = await sipcoAxiosService.get(
         "/mantenerUsuario/rolesByUser",
        {
          params: {
            codUsuario: userData?.id
          },
        }
      );
      if (status === 200) {
        setContador(data.length);
        setListAgregarRole(data.map((item, index) => ({ ...item, index })
      
      ));
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findPaises() {
    try {
      const { data, status } = await sipcoAxiosService.get(
        "/mantenerUsuario/findBySistema",
        {
          params: {
            codSistema: "02",
          },
        }
      );

      if (status === 200) {
        setListRolesPais(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findUbicacion() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/lugarFisicoService",
        {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.id),
          },
        }
      );
      if (status === 200) {
        setListUbicaciones(data);
        if(title === "Mantener Usuarios - Modificar") {
          let result = data.find(v => {return v?.id === userData?.lugarFisico?.id}) || "";
          setUbicacionSelected(result)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findAreasSistemas() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/areaSistemasService",
        {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.id),
          },
        }
      );
      if (status === 200) {
        setListUbicacionAreas(data);
        if(title === "Mantener Usuarios - Modificar") {
          let result = data.find(v => {return v?.id === userData?.area?.id}) || "";
          setUbicanioAreaSelected(result)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function aggregateInTabla(): void {

    const newRole = {...roleSelectedObject, pais: rolesPaisSelectedObject, index: contador }
    
    setListAgregarRole([
      newRole, ...listagregarRole
    ]);

    setContador(contador + 1);
  }

  function disaggregate() {

    if (!roleSelectedInTabla) return alert("selecionar item")
    setListAgregarRole(listagregarRole.filter(({ index }) => index !== idRemoved));
  }

  async function findAgencias() {
    const param = title === "Mantener Usuarios - Alta" ? "findAllAgencias" : "agenciasByUser"

    try {
      const { status, data } = await sipcoAxiosService.get(
         `/mantenerUsuario/${param}`,
        {
          params: {
            codUsuario: userData?.id
          },
        }
      );
      if (status === 200) {
        data.forEach((value, index) => {
          value.id = index;
        });
        console.log(data)
        setListAgencias(data);
        setAuxListAgencias(JSON.parse(JSON.stringify(data)));
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={"modal-geral"}>
      <Dialog
        header={title}
        visible={title}
        maximizable
        style={{ width: "70vw" }}
        footer={footerContent}
        onHide={() => {
          if (!title) return;
          setTitleModal(false);
        }}
      >
        <Panel>
          <TabView>
            {/* DATOS GENERALES */}
            <TabPanel header={"Datos Generales"}>
              <div className="organizeHorizontally">
                <div className={"sipco-option-modal"}>
                  <label className="orgHori">
                    <LanguageProvider id={"TODO"} alt="Usuario" /> 
                  <span className="centralizar">*</span> 
                  </label>
                  <AutoComplete
                    value={usuario}
                    onChange={(e) => {
                    if (e.value.descripcion === "") return setUsuario(null);
                      setUsuario(e.target.value);
                    }}
                  />
                </div>
                <div className="sipco-option-modal">
                  <label className="orgHori">
                    <LanguageProvider id={"TODO"} alt="Tipo" />
                  <span className="centralizar">*</span>
                  </label>
                  <Dropdown
                    value={tipoSelected}
                    placeholder={tipoSelected}
                    options={optionesTipoUsuarios}
                    className="w-full md:w-14rem"
                    onChange={(e) => setTipoSelected(e.value.descripcion)}
                    optionLabel="descripcion"
                    loading={optionesTipoUsuarios.length > 0 ? false : true}
                  />
                </div>
              </div>

              <div className="organizeHorizontally">
              <div className={"sipco-option-modal"}>
                  <label className="orgHori">
                    <LanguageProvider id={"TODO"} alt="Nombre" />
                  <span className="centralizar">*</span>
                  </label>
                  <AutoComplete
                    value={nombre}
                    onChange={(e) => {
                     if (e.value.descripcion === "") return setNombre(null);
                      setNombre(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="organizeHorizontally">
              <div className={"sipco-option-modal"}>
                  <label className="orgHori">
                    <LanguageProvider id={"TODO"} alt="Apelido" />
                  <span className="centralizar">*</span>
                  </label>
                  <AutoComplete
                    value={apelido}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setApelido(null);
                      setApelido(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="organizeHorizontally">
              <div className={"sipco-option-modal"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Email" />
                  </label>
                  <AutoComplete
                    value={email}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setEmail(null);
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>
              
              
              <div className="organizeHorizontally">
                <div className={"sipco-option-modal"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Telefóno" />
                  </label>
                  <AutoComplete
                    value={telefono}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setTelefono(null);
                      setTelefono(e.target.value);
                    }}
                  />
                </div>
                <div className={"sipco-option-modal"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Celular" />
                  </label>
                  <AutoComplete
                    value={celular}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setCelular(null);
                      setCelular(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="organizeHorizontally">
              <div className="sipco-option-modal">
                  <label className="orgHori">
                    <LanguageProvider id={"TODO"} alt="Idioma" />
                  <span className="centralizar">*</span>
                  </label>
                  <Dropdown
                    value={idiomaSelected}
                    placeholder={idiomaSelected}
                    options={listIdiomas}
                    className="w-full md:w-14rem"
                    onChange={(e) => setIdiomaSelected(e.value.descripcion)}
                    optionLabel="descripcion"
                    loading={listIdiomas.length > 0 ? false : true}
                  />
                </div>
                <div className="sipco-option-modal">
                  <label>
                    <LanguageProvider id={"TODO"} alt="Compañía" />
                  </label>
                  <Dropdown
                    value={companiaSelected}
                    placeholder={companiaSelected}
                    options={listCompanias}
                    className="w-full md:w-13rem"
                    onChange={(e) => setCompaniaSelected(e.value.descripcion)}
                    optionLabel="descripcion"
                    loading={listCompanias.length > 0 ? false : true}
                    
                  />
                </div>
              </div>
              <div className="organizeHorizontally">
                <div className={"sipco-option-modal"}>
                  <label className="orgHori">
                    <LanguageProvider id={"TODO"} alt="Clave" />
                  <span className="centralizar">*</span>
                  </label>
                  <AutoComplete
                    value={clave}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setClave(null);
                      setClave(e.target.value);
                    }}
                  />
                </div>
                <div className={"sipco-option-modal"}>
                  <label className="orgHori">
                    <LanguageProvider id={"TODO"} alt="Confirmación" />
                  <span className="centralizar">*</span>
                  </label>
                  <AutoComplete
                    value={confirmacion}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setConfirmacion(null);
                      setConfirmacion(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="organizeHorizontally">
                <div className={"sipco-option-modal"}>
                  <label className="orgHori">
                    <LanguageProvider id={"TODO"} alt="Nivel de Seguridad" />
                  <span className="centralizar">*</span>
                  </label>
                  <AutoComplete
                    value={nivelSeguridade}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setNivelSeguridade(null);
                      setNivelSeguridade(e.target.value);
                    }}
                  />
                </div>
                <div className={"sipco-option-modal"}>
                  <label className="orgHori">
                    <LanguageProvider id={"TODO"} alt="Internet" />
                  <span className="centralizar">*</span>
                  </label >
                  <AutoComplete
                    value={internet}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setInternet(null);
                      setInternet(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="organizeHorizontally">
                <div className={"sipco-option-modal"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Usuario de Red" />
                  </label>
                  <AutoComplete
                    value={usuarioRed}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setUsuarioRed(null);
                      setUsuarioRed(e.target.value);
                    }}
                  />
                </div>
                <div className={"sipco-option-modal"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Discoverer" />
                  </label>
                  <Checkbox
                    checked={discoverer}
                    onClick={() => {
                      setDiscoverer(!discoverer);
                    }}
                  ></Checkbox>
                </div>
              </div>
              <div className="organizeHorizontally">
                <div className={"sipco-option-modal"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Archivo de log" />
                  </label>
                  <AutoComplete
                    value={arcLog}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setArcLog(null);
                      setArcLog(e.target.value);
                    }}
                  />
                </div>
                <div className={"sipco-option-modal"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="CUIL" />
                  </label>
                  <AutoComplete
                    value={cuil}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setCuil(null);
                      setCuil(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="organizeHorizontally">
                <div className={"sipco-option-modal"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Últ. Modificación" />
                  </label>
                  <AutoComplete
                    value={ultMod}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setUltMod(null);
                      setUltMod(e.target.value);
                    }}
                  />
                </div>
                <div className={"sipco-option-modal"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Fec. Últ. Modificación" />
                  </label>
                  <AutoComplete
                    value={fecUltMod}
                    onChange={(e) => {
                      if (e.value.descripcion === "") return setFecUltMod(null);
                      setFecUltMod(e.target.value);
                    }}
                  />
                </div>
              </div>
            </TabPanel>

            {/* SEGURIDADE */}
            <TabPanel header={"Seguridade"}>
              <div className="limit-Width">
                <div className={"org-option"}>
                  <label>
                    <LanguageProvider
                      id={"TODO"}
                      alt="Administrador de Usuarios"
                    />
                  </label>
                  <Checkbox
                    checked={segCheckedAdmUser}
                    onClick={() => {
                      setSegCheckedAdmUser(!segCheckedAdmUser);
                    }}
                  ></Checkbox>
                </div>

                <div className={"org-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Resp. Proyecto" />
                  </label>
                  <Checkbox
                    checked={segCheckedRespProycto}
                    onClick={() => {
                      setSegCheckedRespProycto(!segCheckedRespProycto);
                    }}
                  ></Checkbox>
                </div>

                <div className={"org-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Aqs" />
                  </label>
                  <Checkbox
                    checked={segCheckedAqs}
                    onClick={() => {
                      setSegCheckedAqs(!segCheckedAqs);
                    }}
                  ></Checkbox>
                </div>

                <div className={"org-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Daños" />
                  </label>
                  <Checkbox
                    checked={segCheckedDanios}
                    onClick={() => {
                      setSegCheckedDanios(!segCheckedDanios);
                    }}
                  ></Checkbox>
                </div>
                <div className={"org-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Activo" />
                  </label>
                  <Checkbox
                    checked={segCheckedActivo}
                    onClick={() => {
                      setSegCheckedActivo(!segCheckedActivo);
                    }}
                  ></Checkbox>
                </div>
              </div>
            </TabPanel>

            {/* AGENCIAS */}
            <TabPanel header={"Agencias"}>
              <div className="m-0" style={{ padding: "1rem", width: "100%" }}>
                <DataTable
                  tableStyle={{ width: "100%" }}
                  value={auxListAgencias}
                  cellSelection
                  selectionMode="single"
                  loading={auxListAgencias.length === 0}
                  dataKey="id"
                >
                  <Column
                    field="asignado"
                    header={<LanguageProvider id={"TODO"} alt="Asignado" />}
                    body={(rowData) => (
                      <Checkbox
                        checked={auxListAgencias[rowData?.id].asignado}
                        onClick={() => {
                          const updatedListAgencias = [...auxListAgencias];
                          updatedListAgencias[rowData?.id].asignado =
                            !updatedListAgencias[rowData?.id].asignado;
                          setAuxListAgencias(updatedListAgencias);
                        }}
                      />
                    )}
                  ></Column>
                  <Column
                    field="agencia.descripcion"
                    header={<LanguageProvider id={"TODO"} alt="Agencia" />}
                  ></Column>
                  <Column
                    field="cargo"
                    body={(rowData) => (
                      <Checkbox
                        checked={auxListAgencias[rowData?.id].cargo}
                        onClick={() => {
                          const updatedListAgencias = [...auxListAgencias];
                          updatedListAgencias[rowData?.id].cargo =
                            !updatedListAgencias[rowData?.id].cargo;
                          setAuxListAgencias(updatedListAgencias);
                        }}
                      />
                    )}
                    header={<LanguageProvider id={"TODO"} alt="Cargo" />}
                  ></Column>
                  <Column
                    field="responsableAgencia"
                    body={(rowData) => (
                      <Checkbox
                        checked={auxListAgencias[rowData?.id].responsableAgencia}
                        onClick={() => {
                          const updatedListAgencias = [...auxListAgencias];
                          updatedListAgencias[rowData?.id].responsableAgencia =
                            !updatedListAgencias[rowData?.id].responsableAgencia;
                          setAuxListAgencias(updatedListAgencias);
                        }}
                      />
                    )}
                    header={
                      <LanguageProvider id={"TODO"} alt="Resp. Agencia" />
                    }
                  ></Column>
                  <Column
                    field="responsable" //Correto? por query permanece a duvida, verificar pelo mxml
                    body={(rowData) => (
                      <Checkbox
                        checked={auxListAgencias[rowData?.id].responsable}
                        onClick={() => {
                          const updatedListAgencias = [...auxListAgencias];
                          updatedListAgencias[rowData?.id].responsable =
                            !updatedListAgencias[rowData?.id].responsable;
                          setAuxListAgencias(updatedListAgencias);
                        }}
                      />
                    )}
                    header={
                      <LanguageProvider id={"TODO"} alt="Resp. Cotización" />
                    }
                  ></Column>
                  <Column
                    field="direccion"
                    header={<LanguageProvider id={"TODO"} alt="Dirección" />}
                    body={(rowData) => (
                      <Checkbox
                        checked={auxListAgencias[rowData?.id].direccion}
                        onClick={() => {
                          const updatedListAgencias = [...auxListAgencias];
                          updatedListAgencias[rowData?.id].direccion =
                            !updatedListAgencias[rowData?.id].direccion;
                          setAuxListAgencias(updatedListAgencias);
                        }}
                      />
                    )}
                  ></Column>
                </DataTable>
              </div>
            </TabPanel>

            {/* UBICACION */}
            <TabPanel header={"Ubicacion"}>
              <div className="organizeHorizontally-col">
                <div className={"sipco-option-modal"}>
                  <label >
                    <LanguageProvider id={"TODO"} alt="Ubicacion" />
                  </label>
                  <Dropdown
                    className="w-full md:w-14rem"
                    value={ubicacionSelected}
                    placeholder={ubicacionSelected}
                    options={listUbicaciones}
                    onChange={(e) => setUbicacionSelected(e.value?.descripcion)}
                    optionLabel="descripcion"
                    loading={listUbicaciones.length > 0 ? false : true}
                    filter
                  />
                </div>
                <div className={"sipco-option-modal"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Area" />
                  </label>
                  <Dropdown
                    className="w-full md:w-14rem"
                    value={ubicanioAreaSelected}
                    options={listUbicacionAreas}
                    placeholder={ubicanioAreaSelected}
                    onChange={(e) =>
                      setUbicanioAreaSelected(e.value?.descripcion)
                    }
                    optionLabel="descripcion"
                    loading={listUbicacionAreas.length > 0 ? false : true}
                    filter
                  />
                </div>
              </div>
            </TabPanel>

            {/* ROLES */}
            <TabPanel header={"Roles"}>
              <div className={"organizeHorizontally-col"}>
                <div className={"org-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="Roles" />
                  </label>
                  <Dropdown
                    value={rolesRolesSelected}
                    placeholder={rolesRolesSelected}
                    options={listRolesRoles}
                    className="w-full md:w-14rem"
                    onChange={(e) => 
                      {setRoleSelectedObject(e.value);
                       setRolesRolesSelected(e.value.descripcion)}}
                    optionLabel="descripcion"
                    loading={listRolesRoles.length > 0 ? false : true}
                    filter
                  />
                </div>

                <div className={"org-option"}>
                  <label>
                    <LanguageProvider id={"TODO"} alt="País" />
                  </label>
                  <Dropdown
                    value={rolesPaisSelected}
                    options={listRolesPais}
                    onChange={(e) => {
                      setRolesPaisSelectedObject(e.value)
                      setRolesPaisSelected(e.value.descripcion)}
                    }
                    optionLabel="descripcion"
                    placeholder={rolesPaisSelected}
                    className="w-full md:w-14rem"
                    loading={listRolesPais.length > 0 ? false : true}
                  />
                  <Button onClick={aggregateInTabla}>
                    <LanguageProvider id={"TODO"} alt="Agregar" />
                  </Button>
                  <Button onClick={disaggregate}>
                    <LanguageProvider id={"TODO"} alt="Quitar" />
                  </Button>
                </div>
                <div className="m-0" style={{ padding: "1rem", width: "100%" }}>
                  <DataTable
                    tableStyle={{ width: "100%" }}
                    value={listagregarRole}
                    selectionMode="single"
                    selection={roleSelectedInTabla}
                    metaKeySelection={false}
                    onSelectionChange={(e) => {
                      setRoleSelectedInTabla(e.value);
                      setIdRemoved(e.value?.index);
                    }}
                    dataKey={"index"}
                  >
                    <Column
                      field="descripcion"
                      header={<LanguageProvider id={"TODO"} alt="Rol" />}
                    ></Column>
                    <Column
                      field="pais.descripcion"
                      header={<LanguageProvider id={"TODO"} alt="País" />}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </TabPanel>
          </TabView>
        </Panel>
      </Dialog>
    </div>
  );
}

export default ModalGeral;

