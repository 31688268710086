import "./ModalReingresoDeStock.scss";

import React, {useEffect, useRef, useState} from 'react';
import { Dialog } from 'primereact/dialog';
import {LanguageProvider} from "@shared/components/language-provider";
import {Dropdown} from "primereact/dropdown";
import {useFilterContext} from "../../../protected-routes";
import {useSipcoAxiosService} from "@services/axios/sipco-axios-service";
import {InputText} from "primereact/inputtext";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";

interface ModalReingresoStockProps {
    visible: boolean;
    onHide: () => void;
    taller: any;
}

const ModalReingresoDeStock: React.FC<ModalReingresoStockProps> = ({ visible, onHide , taller}) => {
   console.log("ModalReingresoDeStock");
    const filterNames = [];
    const filterLabelId = [54, 2020, 2183, 2185, 2184, 2139, 1253, 2751];
    const dt = useRef(null);
    const [selection, setSelection] = useState(null);

    const onRowSelect = (event) => {
        setSelection(event.data);
    };

    const handleClose = () => {
        onHide();
    };
    for (const element of filterLabelId) {
        filterNames.push({
            id: element,
            label: LanguageProvider({
                id: element,
                alt: "Error Filter Labels",
            }),
        });
    }
    const filterColumnsId = [54, 2020, 31, 3374, 6256, 2898,1253,1974,12779];
    const columnsNames = [];
    for (const element of filterColumnsId) {
        columnsNames.push({
            id: element,
            label: LanguageProvider({
                id: element,
                alt: "Error Columns Labels",
            }),
        });
    }
    const { values: securityFilters } = useFilterContext();
    const sipcoAxiosService = useSipcoAxiosService();

    const [filter, setFilter] = useState({
        cliente: null,
        rubro: null,
        cuentaTaller: null,
        pieza: null,
        grupoTaller: null,
        modelo: null,
        cantidad: null,
        motivoAjuste: null,
    });
    const [loadingReport, setLoadingReport] = useState(false);
    const [isBlocked, setIsBlocked] = useState(false);

    const [ cliente, setCliente ] = useState(null);
    const [ loadingCliente, setLoadingCliente,  ] = useState(false);

    const [ rubro, setRubro ] = useState(null);
    const [ loadingRubro, setLoadingRubro,  ] = useState(false);

    const [ cuentaTaller, setCuentaTaller ] = useState(null);
    const [ loadingCuentaTaller, setLoadingCuentaTaller,  ] = useState(false);

    const [ pieza, setPieza ] = useState(null);
    const [ loadingPieza, setLoadingPieza,  ] = useState(false);

    const [ grupoTaller, setGrupoTaller ] = useState(null);
    const [ loadingGrupoTaller, setLoadingGrupoTaller,  ] = useState(false);

    const [ modelo, setModelo] = useState(null);
    const [ loadingModelo, setLoadingModelo,  ] = useState(false);

    const [reporte, setReporte] = useState(null);
    async function loadCliente() {
        setLoadingCliente(true);
        try {
            const { status, data } = await sipcoAxiosService.post(
                "/reingreso-de-stock/cliente-repuestos",
                {
                    pais: securityFilters.securityValues.country,
                    cuenta: securityFilters.securityValues.account
                }
            );
            if (status === 200) {
                data.unshift({ id: "", descripcion: "TODOS" });
                setCliente(data);
                return data;
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingCliente(false);
        }
    }

    async function loadRubro() {
        setLoadingRubro(true);
        try {
            const { status, data } = await sipcoAxiosService.post(
                "/reingreso-de-stock/rubro",
                {
                    pais: securityFilters.securityValues.country
                }
            );
            if (status === 200) {
                data.unshift({ id: "", descripcion: "TODOS" });
                setRubro(data);
                return data;

            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingRubro(false);
        }
    }

    async function loadCuentaTaller() {
        setLoadingCuentaTaller(true);
        try {
            const { status, data } = await sipcoAxiosService.post(
                "/reingreso-de-stock/cuenta-taller",
                {
                    pais: securityFilters.securityValues.country,
                    cuenta: securityFilters.securityValues.account

                }
            );
            if (status === 200) {
                data.unshift({ id: "", descripcion: "TODOS" });
                setCuentaTaller(data);
                return data;

            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingCuentaTaller(false);
        }
    }
    async function loadGrupoTaller() {
        setLoadingGrupoTaller(true);
        try {
            const { status, data } = await sipcoAxiosService.post(
                "/reingreso-de-stock/grupo-taller",
                {

                }
            );
            if (status === 200) {
                data.unshift({ id: "", descripcion: "TODOS" });
                setGrupoTaller(data);
                return data;

            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingGrupoTaller(false);
        }
    }
    async function loadModelo() {
        setLoadingModelo(true);
        try {
            const { status, data } = await sipcoAxiosService.post(
                "/reingreso-de-stock/modelo-taller-filtrado",
                {
                    pais: securityFilters.securityValues.country,
                    grupoTaller: filter.grupoTaller

                }
            );
            if (status === 200) {
                data.unshift({ id: "", descripcion: "TODOS" });
                setModelo(data);
                return data;

            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoadingModelo(false);
        }
    }

    async function loadReport() {
        setLoadingModelo(true);
        if (isBlocked) return;
        setIsBlocked(true);
        try {
            const { status, data } = await sipcoAxiosService.post(
                "/reingreso-de-stock/catalogo-repuesto",
                {
                    pais: securityFilters.securityValues.country,
                    cuenta: securityFilters.securityValues.account,
                    cliente: filter.cliente,
                    rubro: filter.rubro,
                    cuentaTaller: filter.cuentaTaller,
                    pieza: filter.pieza,
                    grupoTaller: filter.grupoTaller,
                    modeloTaller: filter.modelo
                }
            );
            if (status === 200) {
                data.unshift({ id: "", descripcion: "TODOS" });
                setReporte(data);
                return data;

            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsBlocked(false);
            setLoadingModelo(false);
        }
    }

    useEffect(() => {
        async function initialize() {
            try {

                    const clientes = await loadCliente();
                    const rubros = await loadRubro();
                    const cuentaTaller = await loadCuentaTaller();
                    const grupoTaller = await loadGrupoTaller();
                    setFilter({
                        ...filter,
                        cliente: clientes[0],
                        rubro: rubros[0],
                        cuentaTaller: cuentaTaller[0],
                        grupoTaller: grupoTaller[0]
                    });

            } catch (error) {
                console.error(error);
            }
        }
        initialize();
    }, [securityFilters.securityValues]);


    useEffect(() => {
        async function initializeModelo() {
            try {
                const modelos = await loadModelo();

                setFilter({
                    ...filter,
                    modelo: modelos[0],
                });

            } catch (error) {
                console.error(error);
            }
        }
        initializeModelo();
    }, [filter.grupoTaller]);

    useEffect(() => {
        async function initializeModelo() {
            try {
                if (filter.pieza.length >= 2){
                    loadReport()
            }
            } catch (error) {
                console.error(error);
            }
        }
        initializeModelo();
    }, [filter.pieza]);

    async function processar() {
        try {
            const { status, data } = await sipcoAxiosService.post(
                "/reingreso-de-stock/save-stock",
                {
                    pais: securityFilters.securityValues.country,
                    cuenta: securityFilters.securityValues.account,
                    subcuenta: securityFilters.securityValues.subaccount,
                    clienteRepuesto: selection.cliente,
                   taller: taller,
                   catalogoRepuesto: selection,
                    cantidad: filter.cantidad,
                }
            );
            if (status === 200) {

                return data;
            }
        } catch (error) {
            console.error(error);
        } finally {
        }
    }













    return (
        <Dialog
            visible={visible}
            onHide={onHide}
            style={{width: '75vw'}}
            header="Reingreso de Stock - Alta"
            modal
        >
            <div className={"modal-content"} style={{display: "flex", flexDirection: "row", width: "100%"}}>
                <div className="sipco-option">
                    <label>
                        <LanguageProvider id={filterNames[0].label} alt="Cliente"/>
                    </label>
                    <Dropdown
                        value={filter.cliente}
                        options={cliente}
                        onChange={(e) => setFilter({...filter, cliente: e.value})}
                        loading={loadingCliente}
                        optionLabel="descripcion"
                        filter
                        showClear
                    />
                </div>
                <div className="sipco-option">
                    <label>
                        <LanguageProvider id={filterNames[1].label} alt="Rubro"/>
                    </label>
                    <Dropdown
                        value={filter.rubro}
                        options={rubro}
                        onChange={(e) => setFilter({...filter, rubro: e.value})}
                        loading={loadingRubro}
                        optionLabel="descripcion"
                        filter
                        showClear
                    />
                </div>
                <div className="sipco-option">
                    <label>
                        <LanguageProvider id={filterNames[2].label} alt="Cuenta Taller"/>
                    </label>
                    <Dropdown
                        value={filter.cuentaTaller}
                        options={cuentaTaller}
                        onChange={(e) => setFilter({...filter, cuentaTaller: e.value})}
                        loading={loadingCuentaTaller}
                        optionLabel="descripcion"
                        filter
                        showClear
                    />
                </div>
                <div className="sipco-option">
                    <label>
                        <LanguageProvider id={filterNames[3].label} alt="Pieza"/>
                    </label>
                    <InputText
                        value={filter.pieza}
                        onChange={(e) => setFilter({...filter, pieza: e.target.value})}
                    />
                </div>
                <div className="sipco-option">
                    <label>
                        <LanguageProvider id={filterNames[4].label} alt="Grupo Taller"/>
                    </label>
                    <Dropdown
                        value={filter.grupoTaller}
                        options={grupoTaller}
                        onChange={(e) => setFilter({...filter, grupoTaller: e.value})}
                        loading={loadingGrupoTaller}
                        optionLabel="descripcion"
                        filter
                        showClear
                    />
                </div>
                <div className="sipco-option">
                    <label>
                        <LanguageProvider id={filterNames[5].label} alt="Modelo"/>
                    </label>
                    <Dropdown
                        value={filter.modelo}
                        options={modelo}
                        onChange={(e) => setFilter({...filter, modelo: e.value})}
                        loading={loadingModelo}
                        optionLabel="descripcion"
                        filter
                        showClear
                    />
                </div>
            </div>
            <DataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                value={reporte}
                paginator
                scrollHeight="flex"
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                loading={loadingReport}
                filterDisplay="menu"
                selectionMode="single"
                onRowSelect={onRowSelect}
                selection={selection}
            >
                <Column field="cliente.id" header={columnsNames[0].label} sortable filter/>
                <Column field="id" header={columnsNames[3].label} sortable filter/>
                <Column field="descripcion" header={columnsNames[1].label} sortable filter/>
                <Column field="costoPeso" header={columnsNames[2].label} sortable filter/>
                <Column field="fechaCotizacion.date" header={columnsNames[4].label} sortable filter/>
                <Column field="peso" header={columnsNames[5].label} sortable filter/>
                <Column field="cantidad" header={columnsNames[6].label} sortable filter/>
                <Column field="costoPeso" header={columnsNames[7].label} sortable filter/>
                <Column field="iva" header={columnsNames[8].label} sortable filter/>
            </DataTable>
            <div className="modal-footer">
                <div className="sipco-option-number">
                    <label>
                        <LanguageProvider id={filterNames[6].label} alt="Cantidad"/>
                    </label>
                    <InputText
                        value={filter.cantidad}
                        onChange={(e) => setFilter({...filter, cantidad: e.target.value})}
                    />
                </div>
                <div className="sipco-option">
                    <label>
                        <LanguageProvider id={filterNames[7].label} alt="Motivo Ajuste"/>
                    </label>
                    <InputText
                        value={filter.motivoAjuste}
                        onChange={(e) => setFilter({...filter, motivoAjuste: e.target.value})}
                    />
                </div>
            </div>
            <div className="modal-bottons">
                <Button label="Aceptar" icon="pi pi-check" onClick={processar}/>
                <Button label="Cancelar" icon="pi pi-times" onClick={handleClose}/>
            </div>
        </Dialog>
    );
};

export default ModalReingresoDeStock;