import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import useAuth from "@shared/AuthContext";
import { useInactivoColor } from "../../../../hooks/useInactivoColor";
import { ScreenCodeValue } from "@shared/ScrennCode";

export function DisponibilidadImportacionBrForm(props) {
  const { SCREEN_CODE } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  ScreenCodeValue(SCREEN_CODE);
  const toast = useRef<Toast>(null);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);

  //#region --Methods and Functions
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const { user } = useAuth();

  const inactivoColorT = useInactivoColor();

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeEntradaPatioDestino = (e) => {
    setModel({ ...model, entradaPatioDestino: new Date(e.value).getTime() });
  };

  const handleChangeSaidoPatioOrigem = (e) => {
    setModel({ ...model, saidoPatioOrigem: new Date(e.value).getTime() });
  };

  const selectAll = () => {
    const allRegisters = dt.current.props.value;
    setSelectedReportData(allRegisters);
  };

  const deselectAll = () => {
    setSelectedReportData([]);
  };

  async function validarProcesar() {
    try {
      if (selectedReportData <= 0) {
        showError("753", "Deve selecionar ao menos um registro do quadro");
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  //#endregion

  //#region  --Filters
  const [filter, setFilter] = useState({
    patio: null,
    rua: null,
    coluna: null,
    nivel: null,

    patioDestino: null,
    ruaDestino: null,
    colunaDestino: null,
    nivelDestino: null,

    vin: null,
    marca: null,
    modo: null,
  });

  const [patio, setPatio] = useState([]);
  const [loadingPatio, setLoadingPatio] = useState(false);
  async function loadPatio() {
    setLoadingPatio(true);
    try {
      if (securityFilters?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/playas",
          {
            usuario: {
              id: user?.userName || null,
              idioma: { id: user?.idioma?.codIdioma || null },
              nivel: user?.nivel ?? 0,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
          }
        );

        if (status === 200) {
          const patioData = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setPatio(patioData);
          return patioData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPatio(false);
    }
  }

  const [rua, setRua] = useState([]);
  const [loadingRua, setLoadingRua] = useState(false);
  async function loadRua(patioId) {
    setLoadingRua(true);
    try {
      if (patioId) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/calle",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: patioId,
            },
          }
        );

        if (status === 200) {
          setRua(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRua(false);
    }
  }

  const [coluna, setColuna] = useState([]);
  const [loadingColuna, setLoadingColuna] = useState(false);
  async function loadColuna(rua) {
    setLoadingColuna(true);
    try {
      if (rua) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/columna",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.patio.id,
            },
            calle: rua,
          }
        );

        if (status === 200) {
          setColuna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColuna(false);
    }
  }

  const [nivel, setNivel] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);
  async function loadNivel(rua, coluna) {
    setLoadingNivel(true);
    try {
      if (rua && coluna) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/nivel",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.patio.id || null,
            },
            calle: rua,
            columna: coluna,
          }
        );

        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
  }

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters?.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/vin",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            vin: {
              id: vin,
            },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError(
              "1184",
              "Para os critérios selecionados a peça não existe"
            );
          }

          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [marca, setMarca] = useState([]);
  const [loadingMarca, setLoadingMarca] = useState(false);
  async function loadMarca() {
    setLoadingMarca(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/marca",
          {
            pais: { id: securityFilters?.securityValues?.country?.id || null },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
          }
        );

        if (status === 200) {
          const marca = data.map((x) => ({
            ...x,
            label: `${x.descripcionFull}`,
            activo: x.activo ?? true,
          }));

          marca.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcionFull: "TODOS",
            activo: true,
          });

          setMarca(marca);
          return marca;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
  }

  const [modo, setModo] = useState([]);
  const [loadingModo, setLoadingModo] = useState(false);
  async function loadModelo(marcaId) {
    setLoadingModo(true);
    try {
      if (
        marcaId &&
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/modelo",
          {
            pais: { id: securityFilters?.securityValues?.country?.id || null },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            marca: { id: marcaId },
          }
        );

        if (status === 200) {
          const modo = data.map((x) => ({
            ...x,
            label: `${x.descripcionFull}`,
            activo: x.activo ?? true,
          }));

          if (modo?.length > 0) {
            modo.unshift({
              label: "TODOS",
              id: 0,
              descripcionFull: "TODOS",
              activo: true,
            });
          }

          setModo(modo);
          return modo;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModo(false);
    }
    setModo([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const patios = await loadPatio();
          if (patios.length > 0) {
            setFilter((prevFilter) => ({ ...prevFilter, patio: patios[0] }));
            await loadRua(patios[0].id);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  // Carregar ruas ao mudar o pátio
  useEffect(() => {
    async function updateRua() {
      if (filter?.patio) {
        try {
          const ruas = await loadRua(filter.patio.id);
          setFilter((prevFilter) => ({
            ...prevFilter,
            rua: ruas?.length > 0 ? ruas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateRua();
  }, [filter?.patio]);

  // Carregar colunas ao mudar a rua
  useEffect(() => {
    async function updateColuna() {
      if (filter?.rua) {
        try {
          const colunas = await loadColuna(filter.rua);
          setFilter((prevFilter) => ({
            ...prevFilter,
            coluna: colunas?.length > 0 ? colunas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColuna();
  }, [filter?.rua]);

  // Carregar níveis ao mudar a coluna
  useEffect(() => {
    async function updateNivel() {
      if (filter?.rua && filter?.coluna) {
        try {
          const niveis = await loadNivel(filter.rua, filter.coluna);
          setFilter((prevFilter) => ({
            ...prevFilter,
            nivel: niveis?.length > 0 ? niveis[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateNivel();
  }, [filter?.coluna]);

  useEffect(() => {
    async function initialize() {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        try {
          const marcas = await loadMarca();
          setFilter((prevFilter) => ({
            ...prevFilter,
            marca: marcas[0],
          }));

          await loadModelo(marcas[0]?.id);
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  async function handleMarcaChange(value) {
    try {
      setFilter((prevFilter) => ({
        ...prevFilter,
        marca: value,
        modo: null,
      }));

      const modelos = await loadModelo(value.id);
      setFilter((prevFilter) => ({
        ...prevFilter,
        modo: modelos?.length > 0 ? modelos[0] : null,
      }));
    } catch (error) {
      console.error(error);
    }
  }
  //#endregion

  //#region --Models
  const [model, setModel] = useState({
    entradaPatioDestino: new Date().getTime(),
    instalRadio: null,
    predoivery: null,
    saidoPatioOrigem: new Date().getTime(),
    fornecedorDanho: null,
    gravouCristal: null,
    esTransferencia: false,
    transportista: null,
    tractor: null,
    bateaTransportin: null,
    chofer: null,
  });

  const [instalRadio, setInstalRadio] = useState([]);
  const [loadingInstalRadio, setLoadingInstalRadio] = useState(false);
  async function loadInstalRadio() {
    setLoadingInstalRadio(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/instalador-radios",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.patio.id || null,
            },
            tipoDeProveedor: {
              id: 2,
            },
          }
        );

        if (status === 200) {
          setInstalRadio(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingInstalRadio(false);
    }
  }

  const [predoivery, setPredoivery] = useState([]);
  const [loadingPredoivery, setLoadingPredoivery] = useState(false);
  async function loadPredoivery() {
    setLoadingPredoivery(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/proveedor-predelivery",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            tipoDeProveedor: {
              id: 8,
            },
          }
        );

        if (status === 200) {
          setPredoivery(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPredoivery(false);
    }
  }

  const [fornecedorDanho, setFornecedorDanho] = useState([]);
  const [loadingFornecedorDanho, setLoadingFornecedorDanho] = useState(false);
  async function loadFornecedorDanho() {
    setLoadingFornecedorDanho(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/proveedor-danios",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.patio.id || null,
            },
            tipoDeProveedor: {
              id: 27,
            },
          }
        );

        if (status === 200) {
          setFornecedorDanho(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFornecedorDanho(false);
    }
  }

  const [gravouCristal, setGravouCristal] = useState([]);
  const [loadingGravouCristal, setLoadingGravouCristal] = useState(false);
  async function loadGravouCristal() {
    setLoadingGravouCristal(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/proveedor-cristales",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            tipoDeProveedor: {
              id: 7,
            },
          }
        );

        if (status === 200) {
          setGravouCristal(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGravouCristal(false);
    }
  }

  //#endregion

  async function loadReportData() {
    try {
      setLoadingReportData(true);

      const dto = {
        vin: { id: filter?.vin?.id ?? "" },
        subcuenta: { id: filter?.patio.id || null },
        subcuentaDestino: {
          id: filter?.patioDestino || null,
        },
        marca: { id: filter?.marca.id || null },
        modelo: { id: filter?.modo?.id ?? 0 },
        pais: { id: securityFilters?.securityValues?.country?.id || null },
        cliente: { id: securityFilters?.securityValues?.client?.id || null },
        cuenta: { id: securityFilters?.securityValues?.account?.id || null },
        nivel: filter?.nivel || null,
        columna: filter?.coluna || null,
        calle: filter?.rua || null,
        nivelDestino: filter?.nivelDestino || null,
        columnaDestino: filter?.colunaDestino || null,
        calleDestino: filter?.ruaDestino || null,
        // hojaRuta: { id: 0 },
        esTransferencia: model?.esTransferencia,
        // cristales: "",
        listaProcesar: [
          {
            vin: { id: filter?.vin?.id ?? "" },
            subcuenta: { id: filter?.patio.id || null },
            subcuentaDestino: {
              id: filter?.patioDestino || null,
            },
            marca: { id: filter?.marca.id || null },
            modelo: { id: filter?.modo?.id ?? 0 },
            pais: { id: securityFilters?.securityValues?.country?.id || null },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            nivel: filter?.nivel || null,
            columna: filter?.coluna || null,
            calle: filter?.rua || null,
            nivelDestino: filter?.nivelDestino || null,
            columnaDestino: filter?.colunaDestino || null,
            calleDestino: filter?.ruaDestino || null,
            // hojaRuta: { id: 0 },
            esTransferencia: model?.esTransferencia,
            // cristales: "",
            fechaIngreso: model?.entradaPatioDestino || null,
            fechaEgreso: model?.saidoPatioOrigem || null,
            // instaladorRadios: { id: model?.instalRadio || null },
            // proveedorDanios: { id: model?.fornecedorDanho || null },
            // predelivery: { id: model?.predoivery || null },
            // graboCristales: { id: model?.gravouCristal || null },
            transportista: { id: model?.transportista || null },
            tractor: { id: model?.tractor || null },
            bateaTransportin: { id: model?.bateaTransportin || null },
            chofer: { id: model?.chofer || null },
            // cantUnidades: 1,
          },
        ],
        fechaIngreso: model?.entradaPatioDestino,
        fechaEgreso: model?.saidoPatioOrigem,
        // instaladorRadios: { id: model?.instalRadio || null },
        // proveedorDanios: { id: model?.fornecedorDanho || null },
        // predelivery: { id: model?.predoivery || null },
        // graboCristales: { id: model?.gravouCristal || null },
        transportista: { id: model?.transportista || null },
        tractor: { id: model?.tractor || null },
        bateaTransportin: { id: model?.bateaTransportin || null },
        chofer: { id: model?.chofer || null },
        // cantUnidades: 1,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/disponibilidad-importacion/fill-grid-disponibilidad-importacion",
        { dto: dto }
      );

      if (status === 200) {
        setReporte(data);
      }

      const instaladorRadios = await loadInstalRadio();
      const predoiverys = await loadPredoivery();
      const fornecedorDanhos = await loadFornecedorDanho();
      const gravouCristais = await loadGravouCristal();
      setModel({
        ...model,
        instalRadio: instaladorRadios[0] || null,
        predoivery: predoiverys[0] || null,
        fornecedorDanho: fornecedorDanhos[0] || null,
        gravouCristal: gravouCristais[0] || null,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  return (
    <div className="disponibilidad-importacion">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"449"} alt="Pátios" />
                </label>
                <Dropdown
                  value={filter?.patio}
                  options={patio}
                  onChange={(e) => setFilter({ ...filter, patio: e.value })}
                  optionLabel="label"
                  loading={loadingPatio}
                  filter
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Rua" />
                </label>
                <Dropdown
                  value={filter?.rua}
                  options={rua}
                  onChange={(e) => setFilter({ ...filter, rua: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingRua}
                  filter
                />
              </div>

              <div className="sipco-option-row">
                <div className="sipco-option">
                  <Dropdown
                    value={filter?.coluna}
                    options={coluna}
                    onChange={(e) => setFilter({ ...filter, coluna: e.value })}
                    optionLabel="descripcionFull"
                    loading={loadingColuna}
                    filter
                  />
                </div>

                <div className="sipco-option">
                  <Dropdown
                    value={filter?.nivel}
                    options={nivel}
                    onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                    optionLabel="descripcionFull"
                    loading={loadingNivel}
                    filter
                  />
                </div>
              </div>
            </div>

            <div className="sipco-option-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Acesse o Vin" />
                </label>
                <AutoComplete
                  value={filter?.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="label"
                  dropdown
                  forceSelection
                  maxLength={17}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"489"} alt="Marcas" />
                </label>
                <Dropdown
                  value={filter?.marca}
                  options={marca}
                  onChange={(e) => handleMarcaChange(e.value)}
                  optionLabel="label"
                  itemTemplate={inactivoColorT}
                  loading={loadingMarca}
                  filter
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"408"} alt="Modos" />
                </label>
                <Dropdown
                  value={filter?.modo}
                  options={modo}
                  onChange={(e) => setFilter({ ...filter, modo: e.value })}
                  optionLabel="label"
                  itemTemplate={inactivoColorT}
                  loading={loadingModo}
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => (
          <div className={props.className} onClick={props.onTogglerClick}>
            <span className={props.titleClassName}>
              <LanguageProvider
                id={"1085"}
                alt="Disponibilidade de Importação"
              />
            </span>
          </div>
        )}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="multiple"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          {/* /deve colocar status/ */}
          <Column field="" header="" sortable filter />
          <Column
            field="vin.id"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <Column
            field="vin.marca.descripcion"
            header={<LanguageProvider id="23888" alt="Marca" />}
            sortable
            filter
          />
          <Column
            field="vin.modelo.descripcion"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <Column
            field="vin.color.descripcion"
            header={<LanguageProvider id="2140" alt="Cor" />}
            sortable
            filter
          />
          <Column
            field="vin.numeroMotor"
            header={<LanguageProvider id="13335" alt="Motor" />}
            sortable
            filter
          />
        </DataTable>
      </Panel>

      <div className="chips">
        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"864"} alt="Registros Selecionados:" />
          </label>
          <Chip
            label={
              selectedReportData && selectedReportData.length > 0
                ? String(selectedReportData.length)
                : "0"
            }
          />
        </div>

        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"841"} alt="Total de Registros:" />
          </label>
          <Chip
            label={
              reporte && Array.isArray(reporte) ? String(reporte.length) : "0"
            }
          />
        </div>
      </div>

      <div className="models">
        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1089"} alt="entrada a Pátio Destino" />
            </label>
            <Calendar
              value={new Date(model?.entradaPatioDestino)}
              onChange={handleChangeEntradaPatioDestino}
              showButtonBar
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1332"} alt="Instalador Rádios" />
            </label>
            <Dropdown
              value={model?.instalRadio}
              options={instalRadio}
              onChange={(e) => setModel({ ...model, instalRadio: e.value })}
              optionLabel="descripcionFull"
              loading={loadingInstalRadio}
              filter
            />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1416"} alt="Predoivery" />
            </label>
            <Dropdown
              value={model?.predoivery}
              options={predoivery}
              onChange={(e) => setModel({ ...model, predoivery: e.value })}
              optionLabel="descripcionFull"
              loading={loadingPredoivery}
              filter
            />
          </div>
        </div>

        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1090"} alt="Saído de Pátio Origem" />
            </label>
            <Calendar
              value={new Date(model?.saidoPatioOrigem)}
              onChange={handleChangeSaidoPatioOrigem}
              showButtonBar
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"16844"} alt="Fornecedor danhos" />
            </label>
            <Dropdown
              value={model.fornecedorDanho}
              options={fornecedorDanho}
              onChange={(e) => setModel({ ...model, fornecedorDanho: e.value })}
              optionLabel="descripcionFull"
              loading={loadingFornecedorDanho}
              filter
            />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1415"} alt="Gravou Cristais" />
            </label>
            <Dropdown
              value={model.gravouCristal}
              options={gravouCristal}
              onChange={(e) => setModel({ ...model, gravouCristal: e.value })}
              optionLabel="descripcionFull"
              loading={loadingGravouCristal}
              filter
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({ id: "748", alt: "Selecionar tudo" })}
          icon="pi pi-plus"
          onClick={selectAll}
        />
        <Button
          label={LanguageProvider({ id: "749", alt: "Deselecionar tudo" })}
          icon="pi pi-minus"
          onClick={deselectAll}
        />
        <Button
          label={LanguageProvider({
            id: "34",
            alt: "Processar ou guardar Mudanças",
          })}
          icon="pi pi-save"
          onClick={validarProcesar}
        />
      </div>
    </div>
  );
}
export default DisponibilidadImportacionBrForm;
