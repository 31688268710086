import "./stock.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { Panel } from "primereact/panel";
import { RadioButton } from "primereact/radiobutton";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const TODOS_OBJ = {
  label: "TODOS",
  id: "TODOS",
  descripcion: "TODOS",
};

export function Stock() {
  const SCREEN_CODE = "con0027_stock";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const filterLabelId = [
    1241, 1242, 21417, 11443, 21473, 396, 1149, 4397, 449, 10774, 2496, 369,
  ];
  const filterNames = [];
  const filterColumnsId = [
    19927, 54, 4791, 1491, 990, 991, 992, 10774, 2496, 396, 6590, 408, 491,
    13335, 10021, 31, 6590, 18202, 9387, 369, 932, 2941, 492, 13798, 12103,
    21430, 10979, 11307, 10758, 4724, 11443, 16932, 21473, 14456,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 467, 468, 449, 990, 991, 992, 10774, 2496, 369,
    396, 408, 16808, 16809, 491, 10021, 748, 749, 2, 1182, 16531, 10531, 1241,
    1242, 1149, 4397, 4791, 1491, 6590, 13335, 31, 13798, 12103, 10979, 11307,
    9387, 10758, 4724, 932, 2941, 492, 11443, 21417, 21418, 21473, 992, 6781,
    13358, 3774, 21422, 16932, 21906,
    //21412,
    19927, 14456, 21430, 18202, 291,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const [filter, setFilter] = useState({
    stock: false,
    mostrarRazonSocialUltimaNF: false,
    estadoVehiculo: TODOS_OBJ,
    estadoRemito: [],
    conDanos: null,
    conBloqueo: null,
    playas: [],
    calles: [],
    columnas: [],
    niveles: [],
    mercado: [],
    origens: [],
    destinos: [],
    marcas: [],
    modelos: [],
    versiones: [],
    colores: [],
    familias: [],
    categorias: [],
  });

  const [loading, setLoading] = useState(false);

  const [estadoVehiculo, setEstadoVehiculo] = useState([]);
  const [vehiculosConDanios, setVehiculosConDanios] = useState([]);
  const [vehiculosConBloqueo, setVehiculosConBloqueo] = useState([]);
  const [playas, setPlayas] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [origens, setOrigens] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [calles, setCalles] = useState([]);
  const [columnas, setColumnas] = useState([]);
  const [niveles, setNiveles] = useState([]);

  const [loadingEstadosVeiculos, setLoadingEstadosVeiculos] = useState(false);
  const [loadingPlayas, setLoadingPlayas] = useState(true);
  const [loadingMercados, setLoadingMercados] = useState(true);
  const [loadingOrigens, setLoadingOrigens] = useState(true);
  const [loadingDestinos, setLoadingDestinos] = useState(true);
  const [loadingMarcas, setLoadingMarcas] = useState(true);
  const [loadingCalles, setLoadingCalles] = useState(false);
  const [loadingColumnas, setLoadingColumnas] = useState(false);
  const [loadingNiveles, setLoadingNiveles] = useState(false);

  async function loadEstadoVehiculo() {
    setLoadingEstadosVeiculos(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/estado-vehiculo-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );

        if (status === 200) {
          data.unshift({
            id: 0,
            descripcion: "TODOS",
          });
          setEstadoVehiculo(data);
          return estadoVehiculo;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadosVeiculos(false);
    }
    return [];
  }
  async function loadVehiculosConDanios() {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/codigo-playa-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          setVehiculosConDanios(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadVehiculosConBloqueo() {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/codigo-playa-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          setVehiculosConBloqueo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  async function loadPlaya() {
    setLoadingPlayas(true);
    try {
      if (securityFilters.securityValues.account) {
        const { status, data } = await sipcoAxiosService.get(
          "/playas-options",
          {
            params: {
              codCuenta: securityFilters.securityValues.account.id,
              descripcion: "",
            },
          }
        );

        if (status === 200) {
          setPlayas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlayas(false);
    }
    return [];
  }
  async function loadMercado() {
    setLoadingMercados(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/mercados-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              descripcion: "",
            },
          }
        );
        if (status === 200) {
          setMercados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
    return [];
  }
  async function loadOrigen() {
    setLoadingOrigens(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.get(
          "/origen-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              descripcion: "",
            },
          }
        );

        if (status === 200) {
          setOrigens(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigens(false);
    }
    return [];
  }
  async function loadDestinos() {
    setLoadingDestinos(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/combo/destinos",
          securityFilters.securityValues.country
        );

        if (status === 200) {
          setDestinos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinos(false);
    }
  }
  async function loadMarcas() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client?.id
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/stock-marcas-options",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );

        if (status === 200) {
          const marcas = data.map((x: any) => {
            return { ...x, label: `${x.label} - (${x.id})` };
          });
          setMarcas(marcas);
          return marcas;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
    return [];
  }
  async function loadCalles() {
    setLoadingCalles(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        filter?.playas.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get("/combo/calles", {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCuenta: securityFilters.securityValues.account.id,
            codSubcuenta: filter.playas[0].codSubcuenta,
          },
        });

        if (status === 200) {
          setCalles(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalles(false);
    }
    return [];
  }
  async function loadColumnas() {
    setLoadingColumnas(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        filter?.calles.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/columnas",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: filter.playas[0].codSubcuenta,
              calle: filter.calles[0].id,
            },
          }
        );

        if (status === 200) {
          setColumnas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumnas(false);
    }
    return [];
  }
  async function loadNiveles() {
    setLoadingNiveles(true);
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.account &&
        filter?.columnas.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/columnas",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCuenta: securityFilters.securityValues.account.id,
              codSubcuenta: filter.playas[0].codSubcuenta,
              calle: filter.calles[0].id,
              columna: filter.columnas[0].id,
            },
          }
        );

        if (status === 200) {
          setNiveles(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNiveles(false);
    }
    return [];
  }

  const [modelos, setModelos] = useState([]);
  const [loadingModelos, setLoadingModelos] = useState(false);
  async function loadModelos() {
    setLoadingModelos(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        filter?.marcas?.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get("/combo/modelos", {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
            codMarca: filter.marcas[0].id,
          },
        });
        if (status === 200) {
          setModelos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }

  const [versiones, setVersiones] = useState([]);
  const [loadingVersiones, setLoadingVersiones] = useState(false);
  async function loadVersiones() {
    setLoadingVersiones(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        filter?.marcas?.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/versiones",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codMarca: filter.marcas[0].id,
            },
          }
        );
        if (status === 200) {
          setVersiones(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVersiones(false);
    }
  }

  const [colores, setColores] = useState([]);
  const [loadingColores, setLoadingColores] = useState(false);
  async function loadColores() {
    setLoadingColores(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        filter?.marcas?.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get("/combo/color", {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
            codMarca: filter.marcas[0].id,
          },
        });
        if (status === 200) {
          setColores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColores(false);
    }
  }

  const [familia, setFamilia] = useState([]);
  const [loadingFamilias, setLoadingFamilias] = useState(false);
  async function loadFamilias() {
    setLoadingFamilias(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        filter?.marcas?.length === 1
      ) {
        const { status, data } = await sipcoAxiosService.get("/combo/familia", {
          params: {
            codPais: securityFilters.securityValues.country.id,
            codCliente: securityFilters.securityValues.client.id,
            codMarca: filter.marcas[0].id,
          },
        });
        if (status === 200) {
          setFamilia(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingFamilias(false);
    }
  }

  const [categorias, setCategorias] = useState([]);
  const [loadingCategorias, setLoadingCategorias] = useState(false);
  async function loadCategorias() {
    setLoadingCategorias(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.get(
          "/combo/categoria",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
            },
          }
        );
        if (status === 200) {
          setCategorias(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCategorias(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const estadosVeiculos = await loadEstadoVehiculo();
          const estadosVeiculosDanos = await loadVehiculosConDanios();
          const estadosVeiculosBoqueios = await loadVehiculosConBloqueo();
          const playas = await loadPlaya();
          const mercados = await loadMercado();
          const origens = await loadOrigen();
          const destinos = await loadDestinos();
          const marcas = await loadMarcas();

          setFilter({
            ...filter,
            estadoVehiculo: estadosVeiculos[0],
            conDanos: estadosVeiculosDanos[0],
            conBloqueo: estadosVeiculosBoqueios[0],
            playas: playas,
            mercado: mercados,
            origens: origens,
            destinos: destinos,
            marcas: marcas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);
  useEffect(() => {
    async function handlePlayaChange() {
      if (filter?.playas?.length === 1) {
        try {
          const calles = await loadCalles();
          setFilter({
            ...filter,
            calles: calles,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handlePlayaChange();
  }, [filter.playas]);
  useEffect(() => {
    async function handleCallesChange() {
      if (filter?.calles?.length === 1) {
        try {
          const columnas = await loadColumnas();
          setFilter({
            ...filter,
            columnas: columnas,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleCallesChange();
  }, [filter.calles]);
  useEffect(() => {
    async function handleColumnasChange() {
      if (filter?.columnas?.length === 1) {
        try {
          const niveles = await loadNiveles();
          setFilter({
            ...filter,
            niveles: niveles,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleColumnasChange();
  }, [filter.columnas]);
  useEffect(() => {
    async function handleMarcaChange() {
      if (filter?.marcas?.length === 1) {
        try {
          const modelos = await loadModelos();
          const versiones = await loadVersiones();
          const colores = await loadColores();
          const familias = await loadFamilias();
          const categorias = await loadCategorias();
          setFilter({
            ...filter,
            modelos: modelos,
            versiones: versiones,
            colores: colores,
            familias: familias,
            categorias: categorias,
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    handleMarcaChange();
  }, [filter.marcas]);

  async function loadReportData() {
    try {
      setLoading(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,

        stock: filter.stock ? "Stock con Remitos" : "Stock Actual",
        mostrarRazonSocialUltimaNF: filter.mostrarRazonSocialUltimaNF,
        estadoVehiculo: filter?.estadoVehiculo?.id || null,
        estadoRemito: filter?.estadoRemito?.map((x) => x.codEstadoRemito) || [],
        conDanos: filter?.conDanos?.id || null,
        conBloqueo: filter?.conBloqueo?.id || null,
        playas: filter?.playas?.map((x) => x.codSubcuenta.toString()) || [],
        mercado: filter?.mercado?.map((x) => x.codMercado) || [],
        origens: filter?.origens?.map((x) => x.codOrigen) || [],
        destinos: filter?.destinos?.map((x) => x.id) || [],
        marcas: filter?.marcas?.map((x) => x.id) || [],
        calles: filter?.calles?.map((x) => x.id) || [],
        columnas: filter?.columnas?.map((x) => x.id) || [],
        niveles: filter?.niveles?.map((x) => x.id) || [],
        modelos: filter?.modelos?.map((x) => x.id) || [],
        versiones: filter?.versiones?.map((x) => x.id) || [],
        colores: filter?.colores?.map((x) => x.id) || [],
        familias: filter?.familias?.map((x) => x.id) || [],
        categorias: filter?.categorias?.map((x) => x.id) || [],
      };
      const { status, data } = await sipcoAxiosService.post(
        "/report/stock",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const clientIsTodos =
    securityFilters.securityValues?.client?.descripcion === "TODOS";

  return (
    <div className="stock-report">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="RadioButtonFilter">
                <RadioButton
                  checked={filter.stock}
                  onChange={(e) => {
                    setFilter({ ...filter, stock: true });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={filterNames[0].label} />
                </label>
                <RadioButton
                  checked={!filter.stock}
                  onChange={(e) => {
                    setFilter({ ...filter, stock: false });
                    setReporte([]);
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id={filterNames[1].label} />
                </label>
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[3].label}
                    alt="Estado vehículo"
                  />
                </label>
                <Dropdown
                  value={filter.estadoVehiculo}
                  options={estadoVehiculo}
                  onChange={(e) =>
                    setFilter({ ...filter, estadoVehiculo: e.value })
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loadingEstadosVeiculos}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[6].label}
                    alt="Listar vehiculos con daños (SI/NO/AMBOS)"
                  />
                </label>
                <Dropdown
                  value={filter.conDanos}
                  options={vehiculosConDanios}
                  onChange={(e) => setFilter({ ...filter, conDanos: e.value })}
                  optionLabel="descripcion"
                  filter
                  loading={loading}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={filterNames[7].label}
                    alt="Listar vehiculos con Bloqueo (SI/NO/AMBOS)"
                  />
                </label>
                <Dropdown
                  value={filter.conBloqueo}
                  options={vehiculosConBloqueo}
                  onChange={(e) =>
                    setFilter({ ...filter, conBloqueo: e.value })
                  }
                  optionLabel="descripcion"
                  filter
                  loading={loading}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[8].label} alt="Playas" />
                </label>
                <MultiSelect
                  value={filter.playas}
                  options={playas}
                  onChange={(e) => {
                    setFilter({ ...filter, playas: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="descripcion"
                  loading={loadingPlayas}
                  showClear
                />
              </div>
              {filter?.playas?.length === 1 && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={filterNames[8].label} alt="Calles" />
                  </label>
                  <MultiSelect
                    value={filter.calles}
                    options={calles}
                    onChange={(e) => {
                      setFilter({ ...filter, calles: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingCalles}
                    showClear
                  />
                </div>
              )}
              {filter?.calles?.length === 1 && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider
                      id={filterNames[8].label}
                      alt="Columnas"
                    />
                  </label>
                  <MultiSelect
                    value={filter.columnas}
                    options={columnas}
                    onChange={(e) => {
                      setFilter({ ...filter, columnas: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingColumnas}
                    showClear
                  />
                </div>
              )}
              {filter?.columnas?.length === 1 && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={filterNames[8].label} alt="Niveles" />
                  </label>
                  <MultiSelect
                    value={filter.niveles}
                    options={niveles}
                    onChange={(e) => {
                      setFilter({ ...filter, niveles: e.value });
                    }}
                    display="chip"
                    filter
                    optionLabel="label"
                    loading={loadingNiveles}
                    showClear
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[9].label} alt="Mercados" />
                </label>
                <MultiSelect
                  value={filter.mercado}
                  options={mercados}
                  onChange={(e) => {
                    setFilter({ ...filter, mercado: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="descripcion"
                  loading={loadingMercados}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[10].label} alt="Origen" />
                </label>
                <MultiSelect
                  value={filter.origens}
                  options={origens}
                  onChange={(e) => {
                    setFilter({ ...filter, origens: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="descripcion"
                  loading={loadingOrigens}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={filterNames[11].label} alt="Destinos" />
                </label>
                <MultiSelect
                  value={filter.destinos}
                  options={destinos}
                  onChange={(e) => {
                    setFilter({ ...filter, destinos: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingDestinos}
                  showClear
                />
              </div>
            </div>
            {!clientIsTodos && (
              <div className="form-row">
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={filterNames[5].label} alt="Marca" />
                  </label>
                  <MultiSelect
                    value={filter.marcas}
                    options={marcas}
                    onChange={(e) => {
                      setFilter({ ...filter, marcas: e.value });
                    }}
                    display="chip"
                    filter
                    loading={loadingMarcas}
                    showClear
                  />
                </div>
                {filter?.marcas?.length === 1 && (
                  <>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"2139"} alt="Modelo" />
                      </label>
                      <MultiSelect
                        value={filter.modelos}
                        options={modelos}
                        onChange={(e) => {
                          setFilter({ ...filter, modelos: e.value });
                        }}
                        display="chip"
                        filter
                        loading={loadingModelos}
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"16808"} alt="Versiones" />
                      </label>
                      <MultiSelect
                        value={filter.versiones}
                        options={versiones}
                        onChange={(e) => {
                          setFilter({ ...filter, versiones: e.value });
                        }}
                        display="chip"
                        filter
                        loading={loadingVersiones}
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"491"} alt="Colores" />
                      </label>
                      <MultiSelect
                        value={filter.colores}
                        options={colores}
                        onChange={(e) => {
                          setFilter({ ...filter, colores: e.value });
                        }}
                        display="chip"
                        filter
                        loading={loadingColores}
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"10021"} alt="Familias" />
                      </label>
                      <MultiSelect
                        value={filter.familias}
                        options={familia}
                        onChange={(e) => {
                          setFilter({ ...filter, familias: e.value });
                        }}
                        display="chip"
                        filter
                        loading={loadingFamilias}
                        showClear
                      />
                    </div>
                    <div className={"sipco-option"}>
                      <label>
                        <LanguageProvider id={"16809"} alt="Categorías" />
                      </label>
                      <MultiSelect
                        value={filter.categorias}
                        options={categorias}
                        onChange={(e) => {
                          setFilter({ ...filter, categorias: e.value });
                        }}
                        display="chip"
                        filter
                        loading={loadingCategorias}
                        showClear
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {mensagens[60].label}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Stock"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loading}
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />
          <Column field="13" header={columnsNames[13].label} sortable filter />
          <Column field="14" header={columnsNames[14].label} sortable filter />
          <Column field="15" header={columnsNames[15].label} sortable filter />
          <Column field="16" header={columnsNames[16].label} sortable filter />
          <Column field="17" header={columnsNames[17].label} sortable filter />
          <Column field="18" header={columnsNames[18].label} sortable filter />
          <Column field="19" header={columnsNames[19].label} sortable filter />
          <Column field="20" header={columnsNames[20].label} sortable filter />
          <Column field="21" header={columnsNames[21].label} sortable filter />
          <Column field="22" header={columnsNames[22].label} sortable filter />
          <Column field="23" header={columnsNames[23].label} sortable filter />
          <Column field="24" header={columnsNames[24].label} sortable filter />
          <Column field="25" header={columnsNames[25].label} sortable filter />
          <Column field="26" header={columnsNames[26].label} sortable filter />
          <Column field="27" header={columnsNames[27].label} sortable filter />
          <Column field="28" header={columnsNames[28].label} sortable filter />
          <Column field="29" header={columnsNames[29].label} sortable filter />
          <Column field="30" header={columnsNames[30].label} sortable filter />
          <Column field="31" header={columnsNames[31].label} sortable filter />
          <Column field="32" header={columnsNames[32].label} sortable filter />
          <Column field="33" header={columnsNames[33].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default Stock;
