import "./ManejarErroresRemitos.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { format } from "date-fns";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import useFilterColumnsIds from "../../../hooks/useFilterColumnsIds";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import useAuth from "@shared/AuthContext";
import { HttpStatusCode } from "axios";

const columnsDataTable = [
  { header: 16757, field: "@remito.leyendaRemito.id" },
  { header: 16757, field: "@remito.leyendaRemito.descripcion" },
  { header: 849, field: "@remito.letra" },
  { header: 850, field: "@remito.puntoDeVenta" },
  { header: 3774, field: "@remito.id" },
  { header: 1491, field: "@remito.vin.id" },
  { header: 1492, field: "@remito.fechaEmision" },
  { header: 3344, field: "@remito.estadoRemito.descripcion" },
  { header: 15624, field: "@remito.idPedidoCliente" },
  { header: 2941, field: "@remito.concesionario.id" },
  { header: 4216, field: "@remito.direccionEntrega.id" },
  { header: 3591, field: "@remito.razonSocialImpresion" },
  { header: 1, field: "" },
  { header: 159, field: "@remito.provinciaImpresion" },
  { header: 3186, field: "@remito.localidadImpresion" },
  { header: 1617, field: "@remito.tipoRemito.id" },
];

export function ManejarErroresRemitos() {
  const SCREEN_CODE = "fun0049_manejar_errores_remitos";
  ScreenCodeValue(SCREEN_CODE);
  const { user } = useAuth();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const columnsDataTableNames = useFilterColumnsIds(columnsDataTable);
  const [dataTableValue, setDataTableValue] = useState([]);
  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(null);
  const [filter, setFilter] = useState({
    vin: null,
    tipoRemito: null,
    letra: '',
    puntoDeVenta: '',
    numero: '',
  });

  const data = convertDateObjects(report?.filas);

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/manejar-errores-remitos/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: {
              id: query,
            },
          }
        );
        if (status === HttpStatusCode.Ok) {
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  const [tipoRemito, setTipoRemito] = useState([]);
  const [loadingTipoRemito, setLoadingTipoRemito] = useState(false);

  async function loadTipoRemito() {
    try {
      setLoadingTipoRemito(true);
      if (securityFilters.securityValues?.country) {
        const { data, status } = await sipcoAxiosService.post(
          "/manejar-errores-remitos/tipo-remito",
          {
            pais: securityFilters.securityValues?.country,
          }
        );

        if (status === HttpStatusCode.Ok) {
          setTipoRemito(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoRemito(false);
    }
  }

  const [manejarErroresRemitos, setManejarErroresRemitos] = useState([]);

  async function loadReportData() {
    try {
      setLoading(true);
      if (securityFilters.securityValues?.country && securityFilters.securityValues?.client
        && securityFilters.securityValues?.account && filter.tipoRemito
      ) {
        const { data, status } = await sipcoAxiosService.post(
          "/manejar-errores-remitos/vin-full",
          {
            pais: securityFilters.securityValues?.country,
            cliente: securityFilters.securityValues?.client,
            cuenta: securityFilters.securityValues?.account,
            vin: filter.vin || null,
            tipoRemito: filter.tipoRemito || null,
            letra: filter.letra || null,
            puntoDeVenta: filter.puntoDeVenta || null,
            numero: filter.numero || null
          }
        );

        if (status === HttpStatusCode.Ok) {
          const response = data.map((x : any) => {
            return {
              ...x,
              estado: x?.remito?.estadoRemito?.descripcion,
              vin: x?.remito?.vin?.id,
              fechaEmision: x?.remito?.fechaEmision?.date,
              nroRemito: x?.remito?.id,
              ptoVenta: x?.remito?.puntoDeVenta,
              letra: x?.remito?.letra,
              tipoRemito: x?.remito?.tipoRemito?.id
            };
          });
          console.log(response);
          setManejarErroresRemitos(response);
          setReport(data);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTable = [
    { field: "estado", header: "Estado" },
    { field: "fechaEmision", header: "Fecha Emision" },
    { field: "vin", header: "VIN" },
    { field: "nroRemito", header: "Nro Remito" },
    { field: "ptoVenta", header: "Pto Venta" },
    { field: "letra", header: "Letra" },
    { field: "tipoRemito", header: "Tipo remito" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };


  useEffect(() => {
    async function initialize() {

      try {
        const tipoRemito = await loadTipoRemito();

        setFilter({
          ...filter,
          tipoRemito: tipoRemito[0],
        });
      } catch (error) {
        console.error(error);
      } finally {
      }

    }
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityFilters.securityValues]);

  return (
    <div className="manejar-errores-remitos">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <Dropdown
                  loading={loadingTipoRemito}
                  value={filter.tipoRemito}
                  options={tipoRemito}
                  onChange={(e) =>
                    setFilter({ ...filter, tipoRemito: e.value })
                  }
                  optionLabel="descripcion"
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <AutoComplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
                  field="id"
                  dropdown
                  forceSelection
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="849" alt="Letra" />
                </label>
                <InputText
                // value={filter.ofertaServicio}
                // onChange={(e) =>
                //   setFilter({ ...filter, ofertaServicio: e.target.value })
                // }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13358"} alt="Punto Venta" />
                </label>
                <InputText
                // value={filter.nroHr}
                // onChange={(e) =>
                //   setFilter({ ...filter, nroHr: e.target.value })
                // }
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <InputText
                // value={filter.nroHr}
                // onChange={(e) =>
                //   setFilter({ ...filter, nroHr: e.target.value })
                // }
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={<LanguageProvider id={"3715"} alt="Gastos Extraordinarios" />}
      >
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsDataTableNames}
                  screenName={"GastosExtraPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={manejarErroresRemitos}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
          >
            {columnsNamesTable.map((colum, index) => {
              if (colum.header === "Fecha Emision") {
                
                return (
                  <Column
                    key={index}
                    field={colum.field}
                    body={(e) => dateColumnTemplate(e, colum.field)}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              } else {
                return (
                     <Column
                    key={index}
                    field={colum.field}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              }
            })}
          </DataTable>
        </div>
        <Divider />
        <div className="fieldset-footer">
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "748",
                alt: "Seleccionar todo",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deseleccionar todo",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "16283",
                alt: "Procesar",
              })}
              text
            />
            <Button
              label={LanguageProvider({
                id: "187",
                alt: "Imprimir o generar archivo Excel",
              })}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default ManejarErroresRemitos;
