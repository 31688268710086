import "./LevantarIndisponibilidad.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import useRowStyles from "../../../hooks/useRowStyles";
import { Chip } from "primereact/chip";
import useFormattedDate from "../../../hooks/useFormattedDate";

export function LevantarIndisponibilidad() {
  const SCREEN_CODE = "fun0070_levantar_indisponibilidad";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const selectAll = () => {
    const allRegisters = dt.current.props.value;
    setSelectedReportData(allRegisters);
  };

  const deselectAll = () => {
    setSelectedReportData([]);
  };

  async function validarProcesar() {
    try {
      if (selectedReportData <= 0) {
        showError("12642", "Debe seleccionar al menos un registro");
        return;
      } else {
        processar();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);
  const { formatDate } = useFormattedDate();

  //const rowStyles = useRowStyles([selectedReportData]);

  const [filter, setFilter] = useState({
    vin: null,
  });

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin) {
    try {
      if (securityFilters?.securityValues?.account) {
        const { status, data } = await sipcoAxiosService.post(
          "/levantar-indisponibilidad/vin",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            vin: { id: vin },
          }
        );

        if (status === 200) {
          setVin(data);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVin([]);
    return [];
  }

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/levantar-indisponibilidad/indisponibilidad",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          vin: filter?.vin,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }
  async function processar() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/levantar-indisponibilidad/levantar",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          vins: selectedReportData,
        }
      );

      if (status === 200) {
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }
  return (
    <div className="levantar-indisponibilidad">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"662"} alt="Ingrese el VIN" />
              </label>
              <AutoComplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                field="id"
                dropdown
                forceSelection
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id="1483"
                  alt="Levantar Indisponibles"
                ></LanguageProvider>
              </span>
            </div>
          );
        }}
      >
        <div className="dt-descripcion">
          <LanguageProvider
            id="1527"
            alt="Marque en la Grilla los VIN a procesar"
          ></LanguageProvider>
        </div>

        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          //rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="multiple"
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          selection={selectedReportData}
        >
          <Column
            field="id"
            header={<LanguageProvider id="17088" alt="Vin" />}
            sortable
            filter
          />
          <Column
            field="fechaIndisponible.date"
            header={<LanguageProvider id="16555" alt="Fecha indisponible" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaIndisponible?.date
                ? formatDate(rowData.fechaIndisponible.date)
                : rowData.fechaIndisponible || ""
            }
          />
          <Column
            field="marca.descripcion"
            header={<LanguageProvider id="23888" alt="Marca" />}
            sortable
            filter
          />
          <Column
            field="modelo.descripcionFull"
            header={<LanguageProvider id="23864" alt="Modelo" />}
            sortable
            filter
          />
          <Column
            field="color.descripcion"
            header={<LanguageProvider id="2140" alt="Color" />}
            sortable
            filter
          />
        </DataTable>
      </Panel>

      <div className="chips">
        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"841"} alt="Total de Registros:" />
          </label>
          <Chip
            label={
              reporte && Array.isArray(reporte) ? String(reporte.length) : "0"
            }
          />
        </div>

        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"864"} alt="Registros Selecionados:" />
          </label>
          <Chip
            label={
              selectedReportData && selectedReportData.length > 0
                ? String(selectedReportData.length)
                : "0"
            }
          />
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({ id: "748", alt: "Seleccionar tudo" })}
          icon="pi pi-plus"
          onClick={selectAll}
        />
        <Button
          label={LanguageProvider({ id: "749", alt: "Deseleccionar tudo" })}
          icon="pi pi-minus"
          onClick={deselectAll}
        />
        <Button
          label={LanguageProvider({
            id: "34",
            alt: "Procesar o guardar cambios",
          })}
          icon="pi pi-save"
          onClick={validarProcesar}
        />
      </div>
    </div>
  );
}
