import "./GestionOperaciones.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import {Toast} from "primereact/toast";

export function GestionOperaciones() {
  const SCREEN_CODE = "fun0116_gestion_de_operaciones";
  usePageViews();
  ScreenCodeValue(SCREEN_CODE);

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loadingReport, setLoadingReport] = useState(false);
  const toast = useRef(null);

  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [14370, 467, 14368, 14369, 901, 18308, 18309, 1569];
  const filterNames = [];
  const filterColumnsId = [14369, 1491, 1569, 14368, 13324, 10828, 8380, 3625];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    32, 54, 65, 448, 467, 468, 653, 901, 1182, 1184, 1491, 1569, 1669, 2502,
    4791, 5044, 5583, 1480, 11035, 11833, 11986, 12642, 13324, 14368, 14369,
    14370, 14562, 18308, 18309, 396, 13514, 2139, 18305, 2140, 367, 2892, 6367,
    16580, 3410, 18308, 18307, 749, 748,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();
  const [filter, setFilter] = useState({
    numeroOt: null,
    operacion: null,
    linea: null,
    resultado: null,
    observacion: null,
    ordenTrabajo: null,
  });
  const [numeroOt, setNumeroOt] = useState(null);
  const [loadingNumeroOt, setLoadingNumeroOt] = useState(false);

  const [ordenTrabajo, setOrdenTrabajo] = useState(null);
  const [loadingOrdenTrabajo, setLoadingOrdenTrabajo] = useState(false);

  const [selectedRow, setSelectedRow] = useState([]);

  async function searchNumeroOt(event: any) {
    await loadNumeroOt(event.query);
  }
  async function loadNumeroOt(nmroOt: any) {
    try {
      if (
          securityFilters?.securityValues?.account != null &&
          securityFilters?.securityValues?.country != null &&
          securityFilters?.securityValues?.client != null &&
          securityFilters?.securityValues?.subaccount != null &&
          nmroOt > 7
      ) {
          setLoadingNumeroOt(true);
          const {status, data} = await sipcoAxiosService.post(
              "/gestion-operacion/find-orden3",
              {
                pais: securityFilters.securityValues.country,
                cuenta: securityFilters.securityValues.account,
                cliente: securityFilters.securityValues.client,
                subcuenta: securityFilters.securityValues.subaccount,
                otLike: nmroOt
              }
          );

          if (status === 200) {
            if (data.length === 0) {
              toast.current.show({
                severity: "error",
                postion: "bottom-left",
                summary: <LanguageProvider id={"13"} alt={"Erro"}/>,
                detail: "Não há resultao para esses filtros PAIS, CLIENTE, CONTA E SUBCONTA",
                life: 3000,
              });
            }
            if (data.length > 1) {
              toast.current.show({
                severity: "warning",
                position: "bottom-left",
                summary: "Aviso",
                detail: "Foram encontrados mais de 1 OT. Selecione a que você quer ver",
                life: 3000,
              });
            }
            setNumeroOt(data);
            return data;
          }
        }

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNumeroOt(false);
    }    return [];
  }
  async function loadReport() {
    try {
      setLoadingReport(true);
      const filters = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente : securityFilters.securityValues.client,
        subcuenta: securityFilters.securityValues.subaccount,
        ordenTrabajo: filter.ordenTrabajo

      };
      const { status, data } = await sipcoAxiosService.post(
          "/gestion-operacion/grid",
          filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
          const ordenTrabajo = await loadOrdenTrabajo(filter.numeroOt);
          setFilter({
            ...filter,
            ordenTrabajo: ordenTrabajo ?? null,
          });

      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [filter.numeroOt]);

  useEffect(() => {
    setFilter({
      ...filter,
      operacion:"",
      linea: "",
      resultado:"",
      observacion:"",
    },)
      console.log(selectedRow);
      setFilter({
            ...filter,
        operacion: (selectedRow as any)?.operacion?.descripcion || "",
        linea: (selectedRow as any)?.lineaOperacion?.descripcion || "",
        resultado: (selectedRow as any)?.valor || "",
        observacion:(selectedRow as any)?.operacionRegistrada?.observaciones || "",
          },)
  }, [selectedRow]);
  async function loadOrdenTrabajo(nmroOt: any) {
    try {
      if (
          securityFilters?.securityValues?.account != null &&
          securityFilters?.securityValues?.country != null &&
          securityFilters?.securityValues?.client != null &&
          securityFilters?.securityValues?.subaccount != null
      ) {
        if (nmroOt.length >= 8) {
          setLoadingOrdenTrabajo(true);
          const {status, data} = await sipcoAxiosService.post(
              "/gestion-operacion/find-orden",
              {
                pais: securityFilters.securityValues.country,
                cuenta: securityFilters.securityValues.account,
                cliente: securityFilters.securityValues.client,
                subcuenta: securityFilters.securityValues.subaccount,
                numeroOrden:  nmroOt
              }
          );

          if (status === 200) {
            setOrdenTrabajo(data);
            setFilter({
              ...filter,
              ordenTrabajo: data ?? null,
            });
            loadReport();
            return data;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrdenTrabajo(false);
    }    return [];
  }
  return (
    <div className="consulta-ot">
      <Toast ref={toast} position="top-center" />
      <Filter
        //onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[0].label} alt="Número OT" />
                </label>
                <AutoComplete
                    value={filter.numeroOt}
                    suggestions={numeroOt}
                    completeMethod={searchNumeroOt}
                    onChange={(e) => setFilter({ ...filter, numeroOt: e.value })}
                    //field="id"
                    //dropdown
                    //forceSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="14618" alt="Gestión de Operaciones" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Orden Trabajo"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div className="form">
                <div className="form-row">
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider
                        id={filterNames[0].label}
                        alt="Operación:"
                      />
                    </label>
                    <InputText
                      value={filter.operacion}
                      onChange={(e) =>
                        setFilter({ ...filter, operacion: e.target.value })
                      }
                    />
                  </div>
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider
                        id={filterNames[0].label}
                        alt="Linea:"
                      />
                    </label>
                    <InputText
                      value={filter.linea}
                      onChange={(e) =>
                        setFilter({ ...filter, linea: e.target.value })
                      }
                    />
                  </div>
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider
                        id={filterNames[0].label}
                        alt="Resultado:"
                      />
                    </label>
                    <InputText
                      value={filter.resultado}
                      onChange={(e) =>
                        setFilter({ ...filter, resultado: e.target.value })
                      }
                    />
                  </div>
                  <div className="sipco-option" style={{ marginTop: "1.25em" }}>
                    <Button
                      // className="buttons"
                      label={LanguageProvider({
                        id: "14394",
                        alt: "Registrar Operación",
                      })}
                      text
                    />
                  </div>
                </div>
              </div>

              <div className="form">
                <div className="form-row">
                  <div className="sipco-option" style={{ marginTop: "1.25em" }}>
                    <label>
                      <LanguageProvider
                        id={filterNames[0].label}
                        alt="Observación:"
                      />
                    </label>
                    <InputText
                      value={filter.observacion}
                      onChange={(e) =>
                        setFilter({ ...filter, observacion: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "1.25em" }}>
                <Button
                  // className="buttons"
                  label={LanguageProvider({
                    id: "14391",
                    alt: "Realizar Operación",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReport}
          filterDisplay="menu"
          selectionMode="single"
          selection={selectedRow}
          onSelectionChange={(e) => setSelectedRow(e.value)}

        >
          <Column field="ordenTrabajo.id" header={columnsNames[0].label} sortable filter />
          <Column field="ordenTrabajo.estado.descripcion" header={columnsNames[3].label} sortable filter />
          <Column field="vin.id" header={columnsNames[1].label} sortable filter />
          <Column field="operacion.descripcion" header={columnsNames[2].label} sortable filter />
          <Column field="operacionRegistrada.id" header={columnsNames[4].label} sortable filter />
          <Column field="lineaOperacion.descripcion" header={columnsNames[5].label} sortable filter />
          <Column field="valor" header={columnsNames[6].label} sortable filter />
          <Column field="operacionRegistrada.observaciones" header={columnsNames[7].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default GestionOperaciones;
