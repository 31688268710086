import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { RadioButton } from "primereact/radiobutton";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { format } from "date-fns";
import useAuth from "@shared/AuthContext";
import useFilterColumnsIds from "../../../../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const columnsIdsHeaderCTRC = [
  { header: 1316, field: "serie" },
  { header: 10794, field: "id" },
  { header: 1492, field: "fechaEmision.date" },
  { header: 10883, field: "cfop" },
  { header: 10884, field: "ciudadOrigen.descripcion" },
  { header: 10885, field: "ciudadDestino.descripcion" },
  { header: 10886, field: "concesionarioRe.descripcion" },
  { header: 10887, field: "direccionEntregaRe.direccion" },
  { header: 10888, field: "concesionarioDe.descripcion" },
  { header: 10889, field: "direccionEntregaDe.direccio" },
  { header: 10890, field: "concesionarioCo.descripcion" },
  { header: 10891, field: "direccionEntregaCo.direccio" },
  { header: 10892, field: "fleteaCargo" },
  { header: 10893, field: "proveedor.descripcion" },
  { header: 9215, field: "tipoImpuesto" },
  { header: 2898, field: "peso" },
  { header: 10894, field: "importeSeguro" },
  { header: 10895, field: "porcentajeAlicuota" },
  { header: 10896, field: "importeImpuesto" },
  { header: 10897, field: "importeBaseCalculo" },
  { header: 2724, field: "importeTotal" },
  { header: 3344, field: "estado" },
  { header: 3625, field: "observaciones" },
  { header: 10898, field: "fechaDespacho.date" },
  { header: 10899, field: "funcion" },
  //chave @notaPedidoFlete.id esta comentada no projeto original - Lucas Silvestre - Duett Software - 03-12-2024
  { header: 10900, field: "@notaPedidoFlete.id" },
];
const columnsIdsDetailsCTRC = [
  { header: 1316, field: "serie" },
  { header: 10794, field: "id" },
  { header: 1491, field: "vin.id" },
  { header: 2898, field: "peso" },
  { header: 1316, field: "remito.puntoDeVenta" },
  { header: 10747, field: "remito.id" },
  { header: 32, field: "valor" },
  { header: 10776, field: "naturalezaFiscal.id" },
  { header: 10758, field: "hojaRuta.id" },
  { header: 10909, field: "centroCosto.id" },
  { header: 10910, field: "ordenInterna" },
  { header: 10772, field: "cuentaGasto.descripcion" },
  { header: 11051, field: "funcion" },
  { header: 396, field: "vin.marca.id" },
  { header: 396, field: "vin.marca.descripcion" },
  { header: 3344, field: "estado.descripcion" },
];

const columnsIdsHeaderColeta = [
  { header: 10758, field: "id" },
  { header: 5686, field: "fechaHojaRuta.date" },
  { header: 4285, field: "tractor.descripcion" },
  { header: 10902, field: "batea.descripcion" },
  { header: 4655, field: "chofer.nombre" },
  { header: 10893, field: "administradorTractor.descripcion" },
  { header: 3344, field: "estado.descripcion" },
  { header: 990, field: "calle" },
  { header: 991, field: "columna" },
];

const columnsIdsDetailsColeta = [
  { header: 1491, field: "vin.id" },
  { header: 1316, field: "remito.puntoDeVenta" },
  { header: 326, field: "remito.id" },
  { header: 740, field: "subcuenta.descripcion" },
  { header: 10903, field: "subcuentaOrigen.descripcion" },
  { header: 1569, field: "tipoRegistro" },
  { header: 2941, field: "concesionarioP.descripcion" },
  { header: 10904, field: "direccionEntregaP.direccion" },
  { header: 3344, field: "estado.descripcion" },
  { header: 10905, field: "jumelage" },
];

const TODOS_OBJ = {
  id: "TODOS",
  descripcion: "TODOS",
};

export function TranferenciaPlayaBrForm(props) {
  const { SCREEN_CODE } = props;
  const { user } = useAuth();

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const dt = useRef(null);
  const [filter, setFilter] = useState<any>({
    playaDestino: "",
    calle: "",
    columna: "",
    nivel: "",
    ctrc: "",
  });
  const [fechaDespacho, setFechaDespacho] = useState(new Date());

  const toast = useRef<Toast>(null);
  const [loading, setLoading] = useState(false);
  const [isColetaOrCtrc, setIsColetaOrCtrc] = useState("CTRC");
  const [dataTableHeaderValue, setDataTableHeaderValue] = useState([]);
  const [loadingDataTableHeader, setLoadingDataTableHeader] = useState(false);
  const [loadingDataTableDetails, setLoadingDataTableDetails] = useState(false);
  const [selectedRowsHeader, setSelectedRowsHeader] = useState([]);
  const [selectedRowsDetails, setSelectedRowsDetails] = useState([]);
  const [dataTableDetailsValue, setDataTableDetailsValue] = useState([]);
  const columnsNamesHeaderCTRC = useFilterColumnsIds(columnsIdsHeaderCTRC);
  const columnsNamesDetaisCTRC = useFilterColumnsIds(columnsIdsDetailsCTRC);
  const columnsNamesHeaderColeta = useFilterColumnsIds(columnsIdsHeaderColeta);
  const columnsNamesDetaisColeta = useFilterColumnsIds(columnsIdsDetailsColeta);
  const [columnsNamesHeader, setColumnsNamesHeader] = useState([]);
  const [columnsNamesDetails, setColumnsNamesDetails] = useState([]);

  const [loadingPlayaDestino, setLoadingPlayaDestino] = useState(false);
  const [playaDestinoOptions, setPlayaDestinoOptions] = useState([]);

  async function loadPlayaDestino() {
    try {
      const payload = {
        usuario: {
          ...user,
          idioma: {
            id: user.idioma.codIdioma,
          },
        },
        cliente: {
          id: securityFilters?.securityValues?.client?.id,
        },
        cuenta: {
          id: securityFilters?.securityValues?.account?.id,
        },
        nivel: user.nivel,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/subcuenta",
        payload
      );
      if (status === 200) {
        const updatedElements = data.map((x) => {
          return {
            ...x,
            search: x.descripcion + x.id,
            descripcionFull: `${x.descripcion} - (${x.id})`,
          };
        });
        setPlayaDestinoOptions(updatedElements);
        return updatedElements;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingCalle, setLoadingCalle] = useState(false);
  const [calleOptions, setCalleOptions] = useState([]);
  async function loadCalles(playaDestino) {
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: playaDestino,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/calle",
        payload
      );
      if (status === 200) {
        setCalleOptions(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingColumnas, setLoadingColumnas] = useState(false);
  const [columnasOptions, setColumnasOptions] = useState([]);
  async function loadColumnas(playaDestino, calle) {
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: playaDestino,
        calle: calle,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/columna",
        params
      );
      if (status === 200) {
        setColumnasOptions(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingNiveles, setLoadingNiveles] = useState(false);
  const [nivelesOptions, setNivelesOptions] = useState([]);
  async function loadNiveles(playaDestino, calle, columna) {
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: playaDestino,
        calle: calle,
        columna: columna,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/niveles",
        params
      );
      if (status === 200) {
        setNivelesOptions(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  function checkSecurityFilters() {
    if (
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  const [loadingCtrcs, setLoadingCtrcs] = useState(false);
  const [ctrcsOptions, setCtrcsOptions] = useState([]);
  async function loadCtrc(playaDestino) {
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: securityFilters.securityValues.subaccount,
        playaDestino,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/filtro-ctrc",
        payload
      );
      if (data.length > 0) {
        data.unshift(TODOS_OBJ);
        setCtrcsOptions(data);
        return data;
      } else {
        setCtrcsOptions([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingColeta, setLoadingColeta] = useState(false);
  const [coletasOptions, setColetasOptions] = useState([]);
  async function loadColeta(playaDestino) {
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: securityFilters.securityValues.subaccount,
        playaDestino,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/filtro-coleta",
        payload
      );
      if (status === 200) {
        if (data.length > 0) {
          data.unshift(TODOS_OBJ);
          setColetasOptions(data);
          return data;
        } else {
          setColetasOptions([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadFillGridCtrc() {
    try {
      setLoadingDataTableHeader(true);
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: securityFilters.securityValues.subaccount,
        playaDestino: filter.playaDestino,
      };
      if (filter.ctrc?.id !== "TODOS") {
        payload["cabeceraCtrc"] = filter.ctrc;
      }
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/fill-grid-ctrc",
        payload
      );
      if (status === 200) {
        setDataTableHeaderValue(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTableHeader(false);
    }
  }
  async function loadFillGridColeta() {
    setLoadingDataTableHeader(true);
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: securityFilters.securityValues.subaccount,
        playaDestino: filter.playaDestino,
      };
      if (filter.coleta?.id !== "TODOS") {
        payload["hojaRuta"] = filter.coleta;
      }
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/fill-grid-colecta",
        payload
      );
      if (status === 200) {
        setDataTableHeaderValue(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTableHeader(false);
    }
  }

  function loadReport() {
    if (isColetaOrCtrc === "CTRC") {
      loadFillGridCtrc();
    } else {
      loadFillGridColeta();
    }
  }

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  async function loadFillGridDetalhesCtrc(cabeceraCtrc) {
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: securityFilters.securityValues.subaccount,
        playaDestino: filter.playaDestino,
        cabeceraCtrc,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/fill-grid-detalhes-ctrc",
        payload
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadFillGridDetalhesColeta(hojaRuta) {
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: securityFilters.securityValues.subaccount,
        playaDestino: filter.playaDestino,
        hojaRuta,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/fill-grid-detalhes-coleta",
        payload
      );
      if (status === 200) {
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function selectItems(params: any[]) {
    if (!params) {
      console.log("Nenhuma seleção foi passada.");
      return;
    }

    const removedItems = selectedRowsHeader.filter(
      (item) => !params.includes(item)
    );
    const addedItems = params.filter(
      (item) => !selectedRowsHeader.includes(item)
    );

    // Atualiza o estado dos itens selecionados
    setSelectedRowsHeader(params);

    try {
      setLoadingDataTableDetails(true);
      // Processa os itens adicionados
      if (addedItems.length > 0) {
        const detalhesResults = await Promise.all(
          addedItems.map(async (item) => {
            if (isColetaOrCtrc === "CTRC") {
              return await loadFillGridDetalhesCtrc(item);
            } else {
              return await loadFillGridDetalhesColeta(item);
            }
          })
        );
        const flattenedResults = detalhesResults.flat();
        setDataTableDetailsValue(flattenedResults);
        console.log(
          "Detalhes carregados para itens adicionados:",
          detalhesResults
        );
      }

      // Processa os itens removidos
      if (removedItems.length > 0) {
        console.log("Itens removidos da seleção:", removedItems);
        // Aqui você pode implementar a lógica para lidar com a remoção, como limpar dados associados
      }
    } catch (error) {
      console.error("Erro ao atualizar seleção:", error);
    } finally {
      setLoadingDataTableDetails(false);
    }
  }

  async function validarEnStock() {
    //srvStockPlaya.validarEnStock(filterForm.pais, filterForm.cliente,
    //  filterForm.cuenta, filterForm.subcuenta, gridDetalle.selectedItems);
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: securityFilters.securityValues.subaccount,
        playaDestino: filter.playaDestino,
      };
      let url = "";
      if (isColetaOrCtrc === "CTRC") {
        payload["detallesCtrc"] = selectedRowsDetails;
        url = "validar-en-stock-ctrc";
      } else {
        payload["detallesLineaHojaRuta"] = selectedRowsDetails;
        url = "validar-en-stock-coleta";
      }
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/" + url,
        payload
      );
      if (status === 200) {
        if (data.length === 0) {
          return true;
        } else {
          pintarFilas(data);
          return false;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function validateProcesar() {
    if (selectedRowsHeader.length === 0) {
      toast.current?.show({
        severity: "warn",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="753"
            alt="Deve selecionar ao menos um registro do quadro"
          />
        ),
        life: 3000,
      });
      return false;
    } else if (selectedRowsDetails.length === 0) {
      toast.current?.show({
        severity: "warn",
        summary: "Error",
        detail: (
          <LanguageProvider
            id="10914"
            alt="Deve selecionar um Registro no quadro de detalhe"
          />
        ),
        life: 3000,
      });
      return false;
    }
    return true;
  }

  function pintarFilas(filas) {
    // Converte a lista de VINs fora de estoque em um Set para acesso rápido
    const vinSet = new Set(filas.map((item) => String(item.id)));

    // Atualiza diretamente os itens do dataProvider
    const updatedDataRowsDetails = selectedRowsDetails.map((item) => {
      const vinProvider = String(item.vin.id);
      if (vinSet.has(vinProvider)) {
        return {
          ...item, // Mantém o restante das propriedades intactas
          vin: {
            ...item.vin,
            stockPlaya: null, // Atualiza apenas `stockPlaya`
          },
        };
      }
      return item; // Retorna o item sem alterações, se não estiver no Set
    });
    setDataTableDetailsValue(updatedDataRowsDetails);
    setSelectedRowsDetails([]);
  }

  async function validarTransferenciaBrMasivo() {
    // srvStockPlaya.validarTransferenciaBrMasivo(filterForm.pais, filterForm.cliente, filterForm.cuenta, filterForm.subcuenta,
    // filterForm.playaDestino, filterForm.calle,
    // filterForm.columna, filterForm.nivel, gridDetalle.selectedItems, this.fecha);
    try {
      const payload = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente: securityFilters.securityValues.client,
        subcuenta: securityFilters.securityValues.subaccount,
        playaOrigen: securityFilters.securityValues.subaccount,
        playaDestino: filter.playaDestino,
        calleDestino: filter.calle,
        columnaDestino: filter.columna,
        nivelDestino: filter.nivel,
        fechaDespacho: { date: fechaDespacho },
      };
      let url = "";
      if (isColetaOrCtrc === "CTRC") {
        payload["detallesCtrc"] = selectedRowsDetails;
        url = "validar-transferencia-masivo-br-ctrc";
      } else {
        payload["detallesLineaHojaRuta"] = selectedRowsDetails;
        url = "validar-transferencia-masivo-br-coleta";
      }
      const { status, data } = await sipcoAxiosService.post(
        "/transferencia-playa-br/" + url,
        payload
      );
      if (status === 200) {
        if (typeof data === "object") {
          return true;
        } else {
          toast.current?.show({
            severity: "warn",
            summary: "Error",
            detail: data?.mensaje,
            life: 3000,
          });
          return false;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function procesar() {
    try {
      setLoadingDataTableHeader(true);
      setLoadingDataTableDetails(true);
      const valid = validateProcesar();
      if (valid) {
        const enStock = await validarEnStock();
        if (enStock) {
          const transferir = await validarTransferenciaBrMasivo();
          if (transferir) {
            // srvStockPlaya.procesarTransferenciaEntrePlayaBrMasivo(filterForm.pais, filterForm.cliente, filterForm.cuenta,
            //  filterForm.subcuenta, filterForm.playaDestino,filterForm.calle, filterForm.columna, filterForm.nivel,
            //  gridDetalle.selectedItems, this.fecha);
            try {
              const payload = {
                pais: securityFilters.securityValues.country,
                cuenta: securityFilters.securityValues.account,
                cliente: securityFilters.securityValues.client,
                subcuenta: securityFilters.securityValues.subaccount,
                playaOrigen: securityFilters.securityValues.subaccount,
                playaDestino: filter.playaDestino,
                calleDestino: filter.calle,
                columnaDestino: filter.columna,
                nivelDestino: filter.nivel,
                fechaDespacho: { date: fechaDespacho },
              };
              let url = "";
              if (isColetaOrCtrc === "CTRC") {
                payload["detallesCtrc"] = selectedRowsDetails;
                url = "transferencia-masivo-br-ctrc";
              } else {
                payload["detallesLineaHojaRuta"] = selectedRowsDetails;
                url = "transferencia-masivo-br-coleta";
              }
              const { status, data } = await sipcoAxiosService.post(
                "/transferencia-playa-br/" + url,
                payload
              );
              if (status === 200) {
                loadReport();
              }
            } catch (error) {
              console.error(error);
            }
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataTableHeader(false);
      setLoadingDataTableDetails(false);
    }
  }

  function deselectCtrcHeader() {
    setSelectedRowsHeader([]);
    setSelectedRowsDetails([]);
    setDataTableDetailsValue([]);
  }

  useEffect(() => {
    setDataTableHeaderValue([]);
    setSelectedRowsHeader([]);
    setSelectedRowsDetails([]);
    setDataTableDetailsValue([]);
  }, [securityFilters.securityValues.subaccount, filter.playaDestino]);

  useEffect(() => {
    async function initialize() {
      const check = checkSecurityFilters();
      if (check) {
        setLoadingPlayaDestino(true);
        try {
          const initialPlayaDestino = await loadPlayaDestino();

          setFilter({
            ...filter,
            playaDestino: initialPlayaDestino[0],
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingPlayaDestino(false);
        }
      }
    }
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    securityFilters.securityValues.country,
    securityFilters.securityValues.client,
    securityFilters.securityValues.account,
  ]);
  function setFilterLoading(boolean: boolean) {
    setLoadingCalle(boolean);
    setLoadingColumnas(boolean);
    setLoadingNiveles(boolean);
    setLoadingCtrcs(boolean);
    setLoadingColeta(boolean);
  }
  useEffect(() => {
    async function initialize() {
      const check = checkSecurityFilters();
      if (check) {
        setFilterLoading(true);
        try {
          const initialCalle = await loadCalles(filter.playaDestino);
          const initialColumnas = await loadColumnas(
            filter.playaDestino,
            initialCalle[0]
          );
          const initialNivels = await loadNiveles(
            filter.playaDestino,
            initialCalle[0],
            initialColumnas[0]
          );
          let resultCtrcOrColeta;
          if (isColetaOrCtrc === "CTRC") {
            resultCtrcOrColeta = await loadCtrc(filter.playaDestino);
          } else {
            resultCtrcOrColeta = await loadColeta(filter.playaDestino);
          }

          setFilter({
            ...filter,
            calle: initialCalle[0],
            columna: initialColumnas[0],
            nivel: initialNivels[0],
            [isColetaOrCtrc.toLowerCase()]: resultCtrcOrColeta[0],
          });
        } catch (error) {
          console.error(error);
        } finally {
          setFilterLoading(false);
        }
      }
    }
    initialize();
  }, [filter.playaDestino]);

  useEffect(() => {
    setDataTableHeaderValue([]);
    setDataTableDetailsValue([]);
    setSelectedRowsDetails([]);
    setSelectedRowsHeader([]);
    if (isColetaOrCtrc === "CTRC") {
      setColumnsNamesHeader(columnsNamesHeaderCTRC);
      setColumnsNamesDetails(columnsNamesDetaisCTRC);
    } else {
      setColumnsNamesHeader(columnsNamesHeaderColeta);
      setColumnsNamesDetails(columnsNamesDetaisColeta);
    }

    async function sync() {
      try {
        setLoadingCtrcs(true);
        setLoadingColeta(true);
        let resultCtrcOrColeta;
        if (isColetaOrCtrc === "CTRC") {
          resultCtrcOrColeta = await loadCtrc(filter.playaDestino);
        } else {
          resultCtrcOrColeta = await loadColeta(filter.playaDestino);
        }
        setFilter({
          ...filter,
          [isColetaOrCtrc.toLowerCase()]: resultCtrcOrColeta[0],
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingCtrcs(false);
        setLoadingColeta(false);
      }
    }
    sync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isColetaOrCtrc]);

  return (
    <div className="tranferencia-playa">
      <Toast ref={toast} position="bottom-left" />
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"740"} alt="Pátio Destino" />
                </label>
                <Dropdown
                  loading={loadingPlayaDestino}
                  value={filter.playaDestino}
                  options={playaDestinoOptions}
                  onChange={(e) =>
                    setFilter({ ...filter, playaDestino: e.value })
                  }
                  optionLabel="descripcionFull"
                  filter
                  filterBy="search"
                />
              </div>
              <div className="RadioButtonFilter">
                <label>
                  <LanguageProvider
                    id={"10958"}
                    alt="Transferência por Coleta"
                  />
                </label>
                <RadioButton
                  value="COLETA"
                  onChange={(e) => setIsColetaOrCtrc(e.value)}
                  checked={isColetaOrCtrc === "COLETA"}
                />
              </div>
              <div className="RadioButtonFilter">
                <label>
                  <LanguageProvider id={"10959"} alt="Transferencia por CTRC" />
                </label>
                <RadioButton
                  value="CTRC"
                  onChange={(e) => setIsColetaOrCtrc(e.value)}
                  checked={isColetaOrCtrc === "CTRC"}
                />
              </div>
            </div>
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"741"} alt="Localização de Traslado" />
                </label>
                <Dropdown
                  loading={loadingCalle}
                  value={filter.calle}
                  options={calleOptions}
                  onChange={(e) => setFilter({ ...filter, calle: e.value })}
                />
              </div>
              <div className="sipco-option-no-label">
                <Dropdown
                  loading={loadingColumnas}
                  value={filter.columna}
                  options={columnasOptions}
                  onChange={(e) => setFilter({ ...filter, columna: e.value })}
                />
              </div>
              <div className="sipco-option-no-label">
                <Dropdown
                  loading={loadingNiveles}
                  value={filter.nivel}
                  options={nivelesOptions}
                  onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                />
              </div>
            </div>
            <div className="sipco-options-line">
              {isColetaOrCtrc === "CTRC" ? (
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"10794"} alt="Nro. Coleta" />
                  </label>
                  <Dropdown
                    loading={loadingCtrcs}
                    value={filter.ctrc}
                    options={ctrcsOptions}
                    onChange={(e) => setFilter({ ...filter, ctrc: e.value })}
                    optionLabel="id"
                  />
                </div>
              ) : (
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"10758"} alt="Nro. Coleta" />
                  </label>
                  <Dropdown
                    loading={loadingColeta}
                    value={filter.coleta}
                    options={coletasOptions}
                    onChange={(e) => setFilter({ ...filter, coleta: e.value })}
                    optionLabel="id"
                  />
                </div>
              )}
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Fieldset
        legend={
          <LanguageProvider id={"746"} alt="Transferência entre Pátios" />
        }
      >
        <div className="fieldset-content">
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={dataTableHeaderValue}
                  columns={columnsIdsHeaderCTRC}
                  screenName={"TranferenciaPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={dataTableHeaderValue}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loadingDataTableHeader}
            selectionMode={isColetaOrCtrc === "CTRC" ? "multiple" : "checkbox"}
            selection={selectedRowsHeader}
            onSelectionChange={(e) => selectItems(e.value)}
          >
            {columnsNamesHeader.map((column, index) => {
              if (
                column.field === "fechaEmision.date" ||
                column.field === "fechaDespacho.date" ||
                column.field === "fechaHojaRuta.date"
              ) {
                return (
                  <Column
                    key={index}
                    body={(e) => dateColumnTemplate(e, column.field)}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              } else {
                return (
                  <Column
                    key={index}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              }
            })}
          </DataTable>
          <Divider />
          <div className="filter-options">
            {isColetaOrCtrc === "CTRC" ? (
              <div className="sipco-options-line">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1533"} alt="Data de Transferência" />
                  </label>
                  <Calendar
                    value={fechaDespacho}
                    onChange={(e) => setFechaDespacho(e.value)}
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showTime
                    showIcon
                  />
                </div>
                <div className="RadioButtonFilter">
                  <Button
                    label={LanguageProvider({
                      id: "748",
                      alt: "Selecionar tudo",
                    })}
                    onClick={(e) => selectItems(dataTableHeaderValue)}
                    text
                  />
                  <Button
                    label={LanguageProvider({
                      id: "749",
                      alt: "Deselecionar tudo",
                    })}
                    onClick={deselectCtrcHeader}
                    text
                  />
                </div>
              </div>
            ) : (
              <div className="sipco-options-line">
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id={"1533"} alt="Data de Transferência" />
                  </label>
                  <Calendar
                    value={fechaDespacho}
                    onChange={(e) => setFechaDespacho(e.value)}
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showTime
                    showIcon
                  />
                </div>
              </div>
            )}
          </div>
          <Divider />
          <DataTable
            size={"small"}
            header={
              <div
                className="action"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ExportButtons
                  dt={dt}
                  data={dataTableDetailsValue}
                  columns={columnsNamesDetails}
                  screenName={"TranferenciaPlaya"}
                />
              </div>
            }
            showGridlines
            stripedRows
            ref={dt}
            value={dataTableDetailsValue}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loadingDataTableDetails}
            selectionMode="multiple"
            selection={selectedRowsDetails}
            onSelectionChange={(e) => setSelectedRowsDetails(e.value)}
            rowClassName={(rowData) =>
              rowData.vin?.stockPlaya == null ? "row-bloqueante" : ""
            }
          >
            {columnsNamesDetails.map((column, index) => {
              if (column.field === "fechaEmision.date") {
                return (
                  <Column
                    key={index}
                    body={(e) => dateColumnTemplate(e, column.field)}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              } else {
                return (
                  <Column
                    key={index}
                    field={column.field}
                    header={column.label}
                    sortable
                  />
                );
              }
            })}
          </DataTable>
        </div>
        <Divider />
        <div className="fieldset-footer">
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="chips">
                <div className={"sipco-chip"}>
                  <label>
                    <LanguageProvider id={"10270"} alt="Total Selecionados" />
                  </label>
                  <Chip label="0" />
                </div>
              </div>
              <div className="tags">
                <Tag
                  value={
                    <LanguageProvider
                      id={"22636"}
                      alt={"Operación pendiente bloqueante"}
                    />
                  }
                  style={{ background: "#800040" }}
                />
              </div>
            </div>
          </div>
          {/* buttons */}
          <div>
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Processar ou guardar Mudanças",
              })}
              onClick={procesar}
              text
            />
            <Button
              label={LanguageProvider({
                id: "748",
                alt: "Selecionar tudo",
              })}
              onClick={(e) => setSelectedRowsDetails(dataTableDetailsValue)}
              text
            />
            <Button
              label={LanguageProvider({
                id: "749",
                alt: "Deselecionar tudo",
              })}
              onClick={(e) => setSelectedRowsDetails([])}
              text
            />
          </div>
        </div>
      </Fieldset>
    </div>
  );
}
export default TranferenciaPlayaBrForm;
