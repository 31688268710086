import "./OrdenReparacion.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Dropdown } from "primereact/dropdown";
import { useNavigate } from "react-router-dom";

export function OrdenReparacion() {
  const SCREEN_CODE = "fun0079_orden_reparacion";
  ScreenCodeValue(SCREEN_CODE);
  let navigate = useNavigate();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);


  const columnsNames = [];
  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    checkVinTaller: false,
    taller: null,
    estados: null,
    nroOrden: null,
    vin: null,
    checkFueraTaller: false,
    desde: new Date(),
    hasta: new Date(),
  });

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              vin: query,
              descripcionCliente: "",
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);

  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return { ...x, label: x.descripcion };
          });
          setTaller(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
  }

  const [estados, setEstados] = useState(null);
  const [loadingEstados, setLoadingEstados] = useState(false);

  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const { status, data } = await sipcoAxiosService.get(
        "/orden-reparacion/estados",

        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        data.unshift({ id: 0, descripcionFull: "TODOS", descripcion: "TODOS" });

        const response = data.map((x: any) => {
          return { ...x, label: `${x.descripcion} - (${x.id})` };
        });

        setEstados(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }

  async function loadReportData() {
    // setLoading(true);
    // try {
    //   let transacciones = [];
    //   if (filter.capitalyHBA) {
    //     transacciones.push("GFC06");
    //   }
    //   const { status, data } = await sipcoAxiosService.post(
    //     "/administracion-remitos-concesionario/vin",
    //     {
    //       codPais: securityFilters?.securityValues?.country?.codPais || null,
    //       codCliente: securityFilters?.securityValues?.client?.codCliente || "",
    //       codCuenta:
    //         securityFilters?.securityValues?.account?.codCuenta || null,
    //       codSubcuenta:
    //         securityFilters?.securityValues?.subaccount?.codSubcuenta || null,
    //     }
    //   );
    //   if (status === 200) {
    //     setReporte(data);
    //   }
    // } catch (error) {
    //   console.error(error);
    // } finally {
    //   setLoading(false);
    // }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const taller = await loadTaller();
        const estados = await loadEstados();

        setFilter({ ...filter, taller: taller[0], estados: estados[0] });
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [securityFilters.securityValues]);

  const data = convertDateObjects(reporte?.filas);

  const values = {
    state: {
      pais: securityFilters?.securityValues?.country,
      cuenta: securityFilters?.securityValues?.account,
      subcuenta: securityFilters?.securityValues?.subaccount,
      nroOrden: filter?.nroOrden,
      tallerFilter: filter?.taller?.label
    },
  };

  return (
    <div className="orden-reparacion">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"327"} alt="Taller" />
                </label>
                <Dropdown
                  loading={loadingTaller}
                  options={taller}
                  value={filter.taller}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                  optionLabel="label"
                />
              </div>
            </div>
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estados" />
                </label>
                <Dropdown
                  loading={loadingEstados}
                  value={filter.estados}
                  options={estados}
                  onChange={(e) => setFilter({ ...filter, estados: e.value })}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1491"} alt="VIN" />
                </label>
                <AutoComplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5701"} alt="Nro. Orden" />
                </label>
                <InputText 
                  value={filter.nroOrden}
                  onChange={(e) => setFilter({ ...filter, nroOrden: e.target.value })}
                />
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.checkVinTaller}
                  onChange={(e) => {
                    setFilter({ ...filter, checkVinTaller: e.checked });
                  }}
                  style={{
                    justifyContent: "center",
                    position: "relative",
                  }}
                />
                <label>
                  <LanguageProvider id={"4009"} alt="VIN en taller" />
                </label>
              </div>
              <div className="RadioButtonFilter">
                <Checkbox
                  checked={filter.checkFueraTaller}
                  onChange={(e) => {
                    setFilter({ ...filter, checkFueraTaller: e.checked });
                  }}
                  style={{
                    justifyContent: "center",
                    position: "relative",
                  }}
                />
                <label>
                  <LanguageProvider id={"4010"} alt="VIN fuera de taller" />
                </label>
              </div>
            </div>
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2621"} alt="Desde" />
                </label>
                <Calendar
                  value={filter.desde}
                  onChange={(e) => setFilter({ ...filter, desde: e.value })}
                  showIcon
                  showButtonBar
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2622"} alt="Hasta" />
                </label>
                <Calendar
                  value={filter.hasta}
                  onChange={(e) => setFilter({ ...filter, hasta: e.value })}
                  showIcon
                  showButtonBar
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "25", alt: "Alta" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "12899", alt: "Ver" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "20973", alt: "Anular" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "42", alt: "Excel" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "16582", alt: "Cerrar" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "17491", alt: "Reabrir" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "19673", alt: "Aprobar" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "2248", alt: "Repuestos" })}
                  text
                  onClick={() =>
                    navigate("/orden-reparacion-servicios", values)
                  }
                />
                <Button
                  label={LanguageProvider({ id: "2249", alt: "Servicios" })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "2689",
                    alt: "Imp.Orden Taller",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "2688",
                    alt: "Imp.Cierre de Orden",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "24504",
                    alt: "Imp.Orden Cotizacion",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columnsNames.map((colum, index) => (
            <Column
              key={index}
              field={colum.id}
              header={colum.label}
              sortable
              filter
            />
          ))}
        </DataTable>
      </Panel>
    </div>
  );
}
export default OrdenReparacion;
