import React, { useEffect, useRef, useState } from "react";

import { Accordion, AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import useAuth from "@shared/AuthContext";
import { useInactivoColor } from "../../../../hooks/useInactivoColor";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { InputText } from "primereact/inputtext";

export function DisponibilidadImportacionForm(props) {
  const { SCREEN_CODE } = props;
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  ScreenCodeValue(SCREEN_CODE);
  const toast = useRef<Toast>(null);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);

  //#region --Methods and Functions
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const { user } = useAuth();

  const inactivoColorT = useInactivoColor();

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeEntradaPatioDestino = (e) => {
    setModel({ ...model, ingresoPlayaDestino: new Date(e.value).getTime() });
  };

  const handleChangeSaidoPatioOrigem = (e) => {
    setModel({ ...model, egresoPlayaOrigen: new Date(e.value).getTime() });
  };

  const selectAll = () => {
    const allRegisters = dt.current.props.value;
    setSelectedReportData(allRegisters);
  };

  const deselectAll = () => {
    setSelectedReportData([]);
  };

  async function validarProcesar() {
    try {
      if (selectedReportData <= 0) {
        showError("753", "Debe seleccionar al menos un registro de la Grilla.");
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  //#endregion

  //#region  --Filters
  const [filter, setFilter] = useState({
    playa: null,
    calle: null,
    columna: null,
    nivel: null,

    playaDestino: null,
    calleDestino: null,
    columnaDestino: null,
    nivelDestino: null,

    vin: null,
    hojaRuta: null,
    marca: null,
    modelo: null,
  });

  const [playa, setPlaya] = useState([]);
  const [loadingPlaya, setLoadingPlaya] = useState(false);
  async function loadPlaya() {
    setLoadingPlaya(true);
    try {
      if (securityFilters?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/playas",
          {
            usuario: {
              id: user?.userName || null,
              idioma: { id: user?.idioma?.codIdioma || null },
              nivel: user?.nivel ?? 0,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
          }
        );

        if (status === 200) {
          const playa = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setPlaya(playa);
          return playa;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlaya(false);
    }
  }

  const [calle, setCalle] = useState([]);
  const [loadingCalle, setLoadingCalle] = useState(false);
  async function loadCalle(playaId) {
    setLoadingCalle(true);
    try {
      if (playaId) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/calle",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: playaId,
            },
          }
        );

        if (status === 200) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
  }

  const [columna, setColumna] = useState([]);
  const [loadingColumna, setLoadingColumna] = useState(false);
  async function loadColumna(calle) {
    setLoadingColumna(true);
    try {
      if (calle) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/columna",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.playa.id,
            },
            calle: calle,
          }
        );

        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
  }

  const [nivel, setNivel] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);
  async function loadNivel(calle, columna) {
    setLoadingNivel(true);
    try {
      if (calle && columna) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/nivel",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.playa.id || null,
            },
            calle: calle,
            columna: columna,
          }
        );

        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
  }

  const [playaDestino, setPlayaDestino] = useState([]);
  const [loadingPlayaDestino, setLoadingPlayaDestino] = useState(false);
  async function loadPlayaDestino() {
    setLoadingPlayaDestino(true);
    try {
      if (securityFilters?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/playas",
          {
            usuario: {
              id: user?.userName || null,
              idioma: { id: user?.idioma?.codIdioma || null },
              nivel: user?.nivel ?? 0,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
          }
        );

        if (status === 200) {
          const playaDestno = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setPlayaDestino(playaDestno);
          return playaDestno;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlayaDestino(false);
    }
  }

  const [calleDestino, setCalleDestino] = useState([]);
  const [loadingCalleDestino, setLoadingCalleDestino] = useState(false);
  async function loadCalleDestino(playaId) {
    setLoadingCalleDestino(true);
    try {
      if (playaId) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/calle",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: playaId,
            },
          }
        );

        if (status === 200) {
          setCalleDestino(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalleDestino(false);
    }
  }

  const [columnaDestino, setColumnaDestino] = useState([]);
  const [loadingColumnaDestino, setLoadingColumnaDestino] = useState(false);
  async function loadColumnaDestino(calle) {
    setLoadingColumnaDestino(true);
    try {
      if (calle) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/columna",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.playaDestino.id,
            },
            calle: calle,
          }
        );

        if (status === 200) {
          setColumnaDestino(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumnaDestino(false);
    }
  }

  const [nivelDestino, setNivelDestino] = useState([]);
  const [loadingNivelDestino, setLoadingNivelDestino] = useState(false);
  async function loadNivelDestino(calle, columna) {
    setLoadingNivelDestino(true);
    try {
      if (calle && columna) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/nivel",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.playaDestino.id || null,
            },
            calle: calle,
            columna: columna,
          }
        );

        if (status === 200) {
          setNivelDestino(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivelDestino(false);
    }
  }

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters?.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/vin",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            vin: {
              id: vin,
            },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError(
              "1184",
              "Para os critérios selecionados a peça não existe"
            );
          }

          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [hojaRuta, setHojaRuta] = useState([]);
  async function loadHojaRuta() {
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/marca",
          {
            pais: { id: securityFilters?.securityValues?.country?.id || null },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
          }
        );

        if (status === 200) {
          const hojaRuta = data.map((x) => ({
            ...x,
            label: `${x.descripcion} - (${x.id})`,
          }));
          setHojaRuta(hojaRuta);
          return hojaRuta;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [marca, setMarca] = useState([]);
  const [loadingMarca, setLoadingMarca] = useState(false);
  async function loadMarca() {
    setLoadingMarca(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/marca",
          {
            pais: { id: securityFilters?.securityValues?.country?.id || null },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
          }
        );

        if (status === 200) {
          const marca = data.map((x) => ({
            ...x,
            label: `${x.descripcionFull}`,
            activo: x.activo ?? true,
          }));

          marca.unshift({
            label: "TODOS",
            id: "TODOS",
            descripcionFull: "TODOS",
            activo: true,
          });

          setMarca(marca);
          return marca;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
  }

  const [modelo, setModelo] = useState([]);
  const [loadingModelo, setLoadingModelo] = useState(false);
  async function loadModelo(marcaId) {
    setLoadingModelo(true);
    try {
      if (
        marcaId &&
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/modelo",
          {
            pais: { id: securityFilters?.securityValues?.country?.id || null },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            marca: { id: marcaId },
          }
        );

        if (status === 200) {
          const modelo = data.map((x) => ({
            ...x,
            label: `${x.descripcionFull}`,
            activo: x.activo ?? true,
          }));

          if (modelo?.length > 0) {
            modelo.unshift({
              label: "TODOS",
              id: 0,
              descripcionFull: "TODOS",
              activo: true,
            });
          }

          setModelo(modelo);
          return modelo;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
    setModelo([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const playas = await loadPlaya();

          if (playas.length > 0) {
            setFilter((prevFilter) => ({
              ...prevFilter,
              playa: playas[0],
            }));
            await loadCalle(playas[0].id);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  // Carregar ruas ao mudar o pátio
  useEffect(() => {
    async function updateCalle() {
      if (filter?.playa) {
        try {
          const calles = await loadCalle(filter.playa.id);
          setFilter((prevFilter) => ({
            ...prevFilter,
            calle: calles?.length > 0 ? calles[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateCalle();
  }, [filter?.playa]);

  // Carregar colunas ao mudar a rua
  useEffect(() => {
    async function updateColumna() {
      if (filter?.calle) {
        try {
          const columnas = await loadColumna(filter.calle);
          setFilter((prevFilter) => ({
            ...prevFilter,
            columna: columnas?.length > 0 ? columnas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColumna();
  }, [filter?.calle]);

  // Carregar níveis ao mudar a coluna
  useEffect(() => {
    async function updateNivel() {
      if (filter?.calle && filter?.columna) {
        try {
          const niveis = await loadNivel(filter.calle, filter.columna);
          setFilter((prevFilter) => ({
            ...prevFilter,
            nivel: niveis?.length > 0 ? niveis[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateNivel();
  }, [filter?.columna]);

  //

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const playaDestinos = await loadPlayaDestino();

          if (playaDestinos.length > 0) {
            setFilter((prevFilter) => ({
              ...prevFilter,
              playaDestino: playaDestinos[0],
            }));
            await loadCalleDestino(playaDestinos[0].id);
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  // Carregar ruas ao mudar o pátioDestino
  useEffect(() => {
    async function updateCalleDestino() {
      if (filter?.playaDestino) {
        try {
          const calles2 = await loadCalleDestino(filter.playaDestino.id);
          setFilter((prevFilter) => ({
            ...prevFilter,
            calleDestino: calles2?.length > 0 ? calles2[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateCalleDestino();
  }, [filter?.playaDestino]);

  // Carregar colunas ao mudar a rua2
  useEffect(() => {
    async function updateColunaDestino() {
      if (filter?.calleDestino) {
        try {
          const colunas2 = await loadColumnaDestino(filter.calleDestino);
          setFilter((prevFilter) => ({
            ...prevFilter,
            columnaDestino: colunas2?.length > 0 ? colunas2[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColunaDestino();
  }, [filter?.calleDestino]);

  // Carregar níveis ao mudar a coluna2
  useEffect(() => {
    async function updateNivelDestino() {
      if (filter?.calleDestino && filter?.columnaDestino) {
        try {
          const niveis2 = await loadNivelDestino(
            filter.calleDestino,
            filter.columnaDestino
          );
          setFilter((prevFilter) => ({
            ...prevFilter,
            nivelDestino: niveis2?.length > 0 ? niveis2[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateNivelDestino();
  }, [filter?.columnaDestino]);

  useEffect(() => {
    async function initialize() {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        try {
          const marcas = await loadMarca();
          setFilter((prevFilter) => ({
            ...prevFilter,
            marca: marcas[0],
          }));

          await loadModelo(marcas[0]?.id);
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  async function handleMarcaChange(value) {
    try {
      setFilter((prevFilter) => ({
        ...prevFilter,
        marca: value,
        modelo: null,
      }));

      const modelos = await loadModelo(value.id);
      setFilter((prevFilter) => ({
        ...prevFilter,
        modelo: modelos?.length > 0 ? modelos[0] : null,
      }));
    } catch (error) {
      console.error(error);
    }
  }
  //#endregion

  //#region --Models
  const [model, setModel] = useState({
    ingresoPlayaDestino: new Date().getTime(),
    instalRadio: null,
    predelivery: null,
    egresoPlayaOrigen: new Date().getTime(),
    proveedorDano: null,
    graboCristal: null,
    esTransferencia: false,
    transportista: null,
    tractor: null,
    bateaTransportin: null,
    chofer: null,
  });

  const [instalRadio, setInstalRadio] = useState([]);
  const [loadingInstalRadio, setLoadingInstalRadio] = useState(false);
  async function loadInstalRadio() {
    setLoadingInstalRadio(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/instalador-radios",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.playa.id || null,
            },
            tipoDeProveedor: {
              id: 2,
            },
          }
        );

        if (status === 200) {
          setInstalRadio(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingInstalRadio(false);
    }
  }

  const [predelivery, setPredelivery] = useState([]);
  const [loadingPredelivery, setLoadingPredelivery] = useState(false);
  async function loadPredelivery() {
    setLoadingPredelivery(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/proveedor-predelivery",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            tipoDeProveedor: {
              id: 8,
            },
          }
        );

        if (status === 200) {
          setPredelivery(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPredelivery(false);
    }
  }

  const [proveedorDano, setProveedorDano] = useState([]);
  const [loadingProveedorDano, setLoadingProveedorDano] = useState(false);
  async function loadProveedorDano() {
    setLoadingProveedorDano(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/proveedor-danios",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.playa.id || null,
            },
            tipoDeProveedor: {
              id: 27,
            },
          }
        );

        if (status === 200) {
          setProveedorDano(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedorDano(false);
    }
  }

  const [graboCristal, setGraboCristal] = useState([]);
  const [loadingGraboCristal, setLoadingGraboCristal] = useState(false);
  async function loadGraboCristal() {
    setLoadingGraboCristal(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/proveedor-cristales",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            tipoDeProveedor: {
              id: 7,
            },
          }
        );

        if (status === 200) {
          setGraboCristal(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGraboCristal(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters.securityValues.country && reporte?.length > 0) {
        try {
          const instaladorRadios = await loadInstalRadio();
          const predeliverys = await loadPredelivery();
          const proveedorDanos = await loadProveedorDano();
          const graboCristales = await loadGraboCristal();
          setModel({
            ...model,
            instalRadio: instaladorRadios[0],
            predelivery: predeliverys[0],
            proveedorDano: proveedorDanos[0],
            graboCristal: graboCristales[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  //#endregion

  //#region --HojaRuta
  const [hr, setHr] = useState({
    transportista: null,
    tractor: null,
    chofer: null,
    bateaTransportin: null,
    cantidadUnidade: null,
  });

  const [transportista, setTransportista] = useState([]);
  const [loadingTransportista, setLoadingTransportista] = useState(false);
  async function loadTransportista() {
    setLoadingTransportista(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/proveedor-transportista",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            subcuenta: {
              id: filter?.playa.id || null,
            },
          }
        );

        if (status === 200) {
          setTransportista(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportista(false);
    }
  }

  const [tractor, setTractor] = useState([]);
  const [loadingTractor, setLoadingTractor] = useState(false);
  async function loadTractor() {
    setLoadingTractor(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/tractor",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            transportista: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            // fechaEgreso: {
            //   id: filter?.playa.id || null,
            // },
          }
        );

        if (status === 200) {
          setTractor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTractor(false);
    }
  }

  const [chofer, setChofer] = useState([]);
  const [loadingChofer, setLoadingChofer] = useState(false);
  async function loadChofer() {
    setLoadingChofer(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/disponibilidad-importacion/tractor",
          {
            pais: {
              id: securityFilters?.securityValues?.country?.id || null,
            },
            // proveedor: {
            //   id: securityFilters?.securityValues?.client?.id || null,
            // },
            // fechaEgreso: {
            //   id: securityFilters?.securityValues?.account?.id || null,
            // },
          }
        );

        if (status === 200) {
          setChofer(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChofer(false);
    }
  }

  const [cantidadUnidade, setCantidadUnidade] = useState([]);
  const [loadingCantidadUnidade, setLoadingCantidadUnidade] = useState(false);

  const [bateaTransportin, setBateaTransportin] = useState([]);
  const [loadingBateaTransportin, setLoadingBateaTransportin] = useState(false);
  async function loadBateaTransportin() {
    setLoadingBateaTransportin(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post("/", {
          pais: {
            id: securityFilters?.securityValues?.country?.id || null,
          },
        });

        if (status === 200) {
          setBateaTransportin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBateaTransportin(false);
    }
  }

  //#endregion

  async function loadReportData() {
    try {
      setLoadingReportData(true);

      const dto = {
        vin: { id: filter?.vin?.id ?? "" },
        subcuenta: { id: filter?.playa.id || null },
        subcuentaDestino: {
          id: filter?.playaDestino.id || null,
        },
        // marca:
        //   filter?.marca?.id === "TODOS"
        //     ? null
        //     : { id: filter?.marca?.id || null },
        marca: { id: filter?.marca.id || null },
        modelo: { id: filter?.modelo?.id ?? 0 },
        pais: { id: securityFilters?.securityValues?.country?.id || null },
        cliente: { id: securityFilters?.securityValues?.client?.id || null },
        cuenta: { id: securityFilters?.securityValues?.account?.id || null },
        nivel: filter?.nivel || null,
        columna: filter?.columna || null,
        calle: filter?.calle || null,
        nivelDestino: filter?.nivelDestino || null,
        columnaDestino: filter?.columnaDestino || null,
        calleDestino: filter?.calleDestino || null,
        // hojaRuta: { id: 0 },
        esTransferencia: model?.esTransferencia,
        // cristales: "",
        listaProcesar: [
          {
            vin: { id: filter?.vin?.id ?? "" },
            subcuenta: { id: filter?.playa.id || null },
            subcuentaDestino: {
              id: filter?.playaDestino.id || null,
            },
            marca: { id: filter?.marca.id || null },
            modelo: { id: filter?.modelo?.id ?? 0 },
            pais: { id: securityFilters?.securityValues?.country?.id || null },
            cliente: {
              id: securityFilters?.securityValues?.client?.id || null,
            },
            cuenta: {
              id: securityFilters?.securityValues?.account?.id || null,
            },
            nivel: filter?.nivel || null,
            columna: filter?.columna || null,
            calle: filter?.calle || null,
            nivelDestino: filter?.nivelDestino || null,
            columnaDestino: filter?.columnaDestino || null,
            calleDestino: filter?.calleDestino || null,
            // hojaRuta: { id: 0 },
            esTransferencia: model?.esTransferencia,
            // cristales: "",
            fechaIngreso: model?.ingresoPlayaDestino || null,
            fechaEgreso: model?.egresoPlayaOrigen || null,
            // instaladorRadios: { id: model?.instalRadio || null },
            // proveedorDanios: { id: model?.fornecedorDanho || null },
            // predelivery: { id: model?.predoivery || null },
            // graboCristales: { id: model?.gravouCristal || null },
            transportista: { id: model?.transportista || null },
            tractor: { id: model?.tractor || null },
            bateaTransportin: { id: model?.bateaTransportin || null },
            chofer: { id: model?.chofer || null },
            // cantUnidades: 1,
          },
        ],
        fechaIngreso: model?.ingresoPlayaDestino,
        fechaEgreso: model?.egresoPlayaOrigen,
        // instaladorRadios: { id: model?.instalRadio || null },
        // proveedorDanios: { id: model?.fornecedorDanho || null },
        // predelivery: { id: model?.predoivery || null },
        // graboCristales: { id: model?.gravouCristal || null },
        transportista: { id: model?.transportista || null },
        tractor: { id: model?.tractor || null },
        bateaTransportin: { id: model?.bateaTransportin || null },
        chofer: { id: model?.chofer || null },
        // cantUnidades: 1,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/disponibilidad-importacion/fill-grid-disponibilidad-importacion",
        { dto: dto }
      );

      if (status === 200) {
        setReporte(data);
      }

      const instaladorRadios = await loadInstalRadio();
      const predoiverys = await loadPredelivery();
      const fornecedorDanhos = await loadProveedorDano();
      const gravouCristais = await loadGraboCristal();
      setModel({
        ...model,
        instalRadio: instaladorRadios[0] || null,
        predelivery: predoiverys[0] || null,
        proveedorDano: fornecedorDanhos[0] || null,
        graboCristal: gravouCristais[0] || null,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  /////////////
  //ATT: falta ver em reuniao um hojaRuta para poder arrumar o accordion de hojaRuta.
  /////////////

  const isPlayasDifferent = filter?.playa?.id !== filter?.playaDestino?.id;

  return (
    <div className="disponibilidad-importacion">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"449"} alt="Playas" />
                </label>
                <Dropdown
                  value={filter.playa}
                  options={playa}
                  onChange={(e) => setFilter({ ...filter, playa: e.value })}
                  optionLabel="label"
                  loading={loadingPlaya}
                  filter
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <Dropdown
                  value={filter.calle}
                  options={calle}
                  onChange={(e) => setFilter({ ...filter, calle: e.value })}
                  optionLabel="descripcionFull"
                  loading={loadingCalle}
                  filter
                />
              </div>

              <div className="sipco-option-row">
                <div className="sipco-option">
                  <Dropdown
                    value={filter.columna}
                    options={columna}
                    onChange={(e) => setFilter({ ...filter, columna: e.value })}
                    optionLabel="descripcionFull"
                    loading={loadingColumna}
                    filter
                  />
                </div>

                <div className="sipco-option">
                  <Dropdown
                    value={filter.nivel}
                    options={nivel}
                    onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                    optionLabel="descripcionFull"
                    loading={loadingNivel}
                    filter
                  />
                </div>
              </div>
            </div>

            <div className="sipco-options-line">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"740"} alt="Playa Destino" />
                </label>
                <Dropdown
                  value={filter.playaDestino}
                  options={playaDestino}
                  onChange={(e) =>
                    setFilter({ ...filter, playaDestino: e.value })
                  }
                  optionLabel="label"
                  loading={loadingPlayaDestino}
                  filter
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <Dropdown
                  value={filter.calleDestino}
                  options={calleDestino}
                  onChange={(e) =>
                    setFilter({ ...filter, calleDestino: e.value })
                  }
                  optionLabel="descripcionFull"
                  loading={loadingCalleDestino}
                  filter
                />
              </div>

              <div className="sipco-option-row">
                <div className="sipco-option">
                  <Dropdown
                    value={filter.columnaDestino}
                    options={columnaDestino}
                    onChange={(e) =>
                      setFilter({ ...filter, columnaDestino: e.value })
                    }
                    optionLabel="descripcionFull"
                    loading={loadingColumnaDestino}
                    filter
                  />
                </div>

                <div className="sipco-option">
                  <Dropdown
                    value={filter.nivelDestino}
                    options={nivelDestino}
                    onChange={(e) =>
                      setFilter({ ...filter, nivelDestino: e.value })
                    }
                    optionLabel="descripcionFull"
                    loading={loadingNivelDestino}
                    filter
                  />
                </div>
              </div>
            </div>

            <div className="sipco-option-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el Vin" />
                </label>
                <AutoComplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="label"
                  dropdown
                  forceSelection
                  maxLength={17}
                />
              </div>

              {isPlayasDifferent && (
                <div className={"sipco-option"}>
                  <label>
                    <LanguageProvider id={"924"} alt="Hoja de Ruta" />
                  </label>
                  <AutoComplete
                    value={filter.hojaRuta}
                    suggestions={hojaRuta}
                    onChange={(e) =>
                      setFilter({ ...filter, hojaRuta: e.value })
                    }
                    field="label"
                    dropdown
                    forceSelection
                  />
                </div>
              )}

              {!isPlayasDifferent && (
                <>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"489"} alt="Marcas" />
                    </label>
                    <Dropdown
                      value={filter.marca}
                      options={marca}
                      onChange={(e) => handleMarcaChange(e.value)}
                      optionLabel="label"
                      itemTemplate={inactivoColorT}
                      loading={loadingMarca}
                      filter
                    />
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"408"} alt="Modelos" />
                    </label>
                    <Dropdown
                      value={filter.modelo}
                      options={modelo}
                      onChange={(e) =>
                        setFilter({ ...filter, modelo: e.value })
                      }
                      optionLabel="label"
                      itemTemplate={inactivoColorT}
                      loading={loadingModelo}
                      filter
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id={"1085"}
                  alt="Disponibilidad de Importación "
                ></LanguageProvider>
              </span>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="multiple"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          {/* /deve colocar status/ */}
          <Column
            field=""
            header={<LanguageProvider id="" alt="" />}
            sortable
            filter
          />
          <Column
            field="vin.id"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <Column
            field="vin.marca.descripcion"
            header={<LanguageProvider id="23888" alt="Marca" />}
            sortable
            filter
          />
          <Column
            field="vin.modelo.descripcion"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <Column
            field="vin.color.descripcion"
            header={<LanguageProvider id="2140" alt="Cor" />}
            sortable
            filter
          />
          <Column
            field="vin.numeroMotor"
            header={<LanguageProvider id="13335" alt="Motor" />}
            sortable
            filter
          />
        </DataTable>
      </Panel>

      <div className="chips">
        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"864"} alt="Registros Selecionados:" />
          </label>
          <Chip
            label={
              selectedReportData && selectedReportData.length > 0
                ? String(selectedReportData.length)
                : "0"
            }
          />
        </div>

        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"841"} alt="Total de Registros:" />
          </label>
          <Chip
            label={
              reporte && Array.isArray(reporte) ? String(reporte.length) : "0"
            }
          />
        </div>
      </div>

      <div className="models">
        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1089"} alt="Ingreso a Playa destino" />
            </label>
            <Calendar
              value={new Date(model?.ingresoPlayaDestino)}
              onChange={handleChangeEntradaPatioDestino}
              showButtonBar
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1332"} alt="Instalador Radios" />
            </label>
            <Dropdown
              value={model?.instalRadio}
              options={instalRadio}
              onChange={(e) => setModel({ ...model, instalRadio: e.value })}
              optionLabel="descripcionFull"
              loading={loadingInstalRadio}
              filter
            />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1416"} alt="Predelivery" />
            </label>
            <Dropdown
              value={model?.predelivery}
              options={predelivery}
              onChange={(e) => setModel({ ...model, predelivery: e.value })}
              optionLabel="descripcionFull"
              loading={loadingPredelivery}
              filter
            />
          </div>
        </div>

        <div className="sipco-options-column">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1090"} alt="Egreso de Playa Origen" />
            </label>
            <Calendar
              value={new Date(model?.egresoPlayaOrigen)}
              onChange={handleChangeSaidoPatioOrigem}
              showButtonBar
              showIcon
              dateFormat="dd/mm/yy"
            />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"16844"} alt="Proveedor daños" />
            </label>
            <Dropdown
              value={model.proveedorDano}
              options={proveedorDano}
              onChange={(e) => setModel({ ...model, proveedorDano: e.value })}
              optionLabel="descripcionFull"
              loading={loadingProveedorDano}
              filter
            />
          </div>

          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1415"} alt="Grabo Cristales" />
            </label>
            <Dropdown
              value={model.graboCristal}
              options={graboCristal}
              onChange={(e) => setModel({ ...model, graboCristal: e.value })}
              optionLabel="descripcionFull"
              loading={loadingGraboCristal}
              filter
            />
          </div>
        </div>
      </div>

      <div className="hoja-ruta">
        {isPlayasDifferent && (
          <Accordion activeIndex={0}>
            <AccordionTab
              header={<LanguageProvider id={"924"} alt="Filtros" />}
            >
              <div className="filter-options">
                <div className="sipco-options-line">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"754"} alt="Transportista" />
                    </label>
                    <Dropdown
                      value={hr.transportista}
                      options={transportista}
                      onChange={(e) => setHr({ ...hr, transportista: e.value })}
                      optionLabel="label"
                      loading={loadingTransportista}
                      filter
                    />
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4655"} alt="Chofer" />
                    </label>
                    <Dropdown
                      value={hr.chofer}
                      options={chofer}
                      onChange={(e) => setHr({ ...hr, chofer: e.value })}
                      optionLabel="descripcionFull"
                      loading={loadingChofer}
                      filter
                    />
                  </div>

                  <div className="sipco-option-row-cdu">
                    <div className="sipco-option">
                      <label>
                        <LanguageProvider
                          id={"8811"}
                          alt="Cantidad de Unidades"
                        />
                      </label>
                      <InputText
                        value={hr.cantidadUnidade}
                        onChange={(e) =>
                          setHr({ ...hr, cantidadUnidade: e.target.value })
                        }
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="sipco-options-line">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4285"} alt="Tractor" />
                    </label>
                    <Dropdown
                      value={hr.tractor}
                      options={tractor}
                      onChange={(e) => setHr({ ...hr, tractor: e.value })}
                      optionLabel="label"
                      loading={loadingTractor}
                      filter
                    />
                  </div>

                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"4284"} alt="Batea / Transportín" />
                    </label>
                    <Dropdown
                      value={hr.bateaTransportin}
                      options={bateaTransportin}
                      onChange={(e) =>
                        setHr({ ...hr, bateaTransportin: e.value })
                      }
                      optionLabel="descripcionFull"
                      loading={loadingBateaTransportin}
                      filter
                    />
                  </div>
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        )}
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({ id: "748", alt: "Seleccionar tudo" })}
          icon="pi pi-plus"
          onClick={selectAll}
        />
        <Button
          label={LanguageProvider({ id: "749", alt: "Deseleccionar tudo" })}
          icon="pi pi-minus"
          onClick={deselectAll}
        />
        <Button
          label={LanguageProvider({
            id: "34",
            alt: "Procesar o guardar cambios",
          })}
          icon="pi pi-save"
          onClick={validarProcesar}
        />
      </div>
    </div>
  );
}
export default DisponibilidadImportacionForm;
