import "./EgresoTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Dropdown } from "primereact/dropdown";
import { Panel } from "primereact/panel";
import useRowStyles from "../../../hooks/useRowStyles";
import useFormattedDate from "../../../hooks/useFormattedDate";

export function EgresoTaller() {
  const SCREEN_CODE = "fun0054_egreso_taller";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  ScreenCodeValue(SCREEN_CODE);
  const toast = useRef<Toast>(null);

  usePageViews();
  const dt = useRef(null);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  const [selectedReportData, setSelectedReportData] = useState(null);

  const [loadingChildReportData, setLoadingChildReportData] = useState(false);
  const [childReportData, setChildReportData] = useState([]);
  const [selectedChildReportData, setSelectedChildReportData] = useState(null);

  const rowStyles = useRowStyles([selectedReportData]);
  const { formatDateTime } = useFormattedDate();

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  async function validarProcesar() {
    try {
      if (selectedReportData <= 0) {
        showError("753", "Debe seleccionar al menos un registro de la Grilla.");
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [filter, setFilter] = useState({
    taller: null,
    calle: null,
    columna: null,
    nivel: null,
    vin: null,
  });

  const [model, setModel] = useState({
    ubicacionTranslado: null,
    columna: null,
    nivel: null,
  });

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);
  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (securityFilters?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/taller",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCliente: securityFilters?.securityValues?.client?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
          }
        );

        if (status === 200) {
          setTaller(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
    setTaller([]);
    return [];
  }

  const [calle, setCalle] = useState([]);
  const [loadingCalle, setLoadingCalle] = useState(false);
  async function loadCalle(taller) {
    setLoadingCalle(true);
    try {
      if (securityFilters?.securityValues?.client) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/taller-calle",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCliente: securityFilters?.securityValues?.client?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
            taller: taller,
          }
        );

        if (status === 200) {
          setCalle(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
    setCalle([]);
    return [];
  }

  const [columna, setColumna] = useState([]);
  const [loadingColumna, setLoadingColumna] = useState(false);
  async function loadColumna(calle) {
    setLoadingColumna(true);
    try {
      if (calle) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/columna",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
            calle: calle,
          }
        );

        if (status === 200) {
          setColumna(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumna(false);
    }
    setColumna([]);
    return [];
  }

  const [nivel, setNivel] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);
  async function loadNivel(calle, columna) {
    setLoadingNivel(true);
    try {
      if (calle && columna) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/nivel",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
            calle: calle,
            columna: columna,
          }
        );

        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
    setNivel([]);
    return [];
  }

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters?.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/vin",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            vin: vin,
          }
        );

        if (status === 200) {
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVin([]);
    return [];
  }

  const [ubicacionTranslado, setUbicacionTranslado] = useState([]);
  const [loadingUbicacionTrans, setLoadingUbicacionTrans] = useState(false);
  async function loadUbicacionTranslado() {
    setLoadingUbicacionTrans(true);
    try {
      if (securityFilters?.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/calle",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCliente: securityFilters?.securityValues?.client?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
          }
        );

        if (status === 200) {
          setUbicacionTranslado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUbicacionTrans(false);
    }
    setUbicacionTranslado([]);
    return [];
  }

  const [columnaModel, setColumnaModel] = useState([]);
  const [loadingColumnaModel, setLoadingColumnaModel] = useState(false);
  async function loadColumnaModel(ubicacionTranslado) {
    setLoadingColumnaModel(true);
    try {
      if (ubicacionTranslado) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/columna",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
            calle: ubicacionTranslado,
          }
        );

        if (status === 200) {
          setColumnaModel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumnaModel(false);
    }
    setColumnaModel([]);
    return [];
  }

  const [nivelModel, setNivelModel] = useState([]);
  const [loadingNivelModel, setLoadingNivelModel] = useState(false);
  async function loadNivelModel(ubicacionTranslado, columna) {
    setLoadingNivelModel(true);
    try {
      if (ubicacionTranslado && columna) {
        const { status, data } = await sipcoAxiosService.post(
          "/egreso-taller/nivel",
          {
            codPais: securityFilters?.securityValues?.country?.id || null,
            codCuenta: securityFilters?.securityValues?.account?.id || null,
            codSubcuenta:
              securityFilters?.securityValues?.subaccount?.id || null,
            calle: ubicacionTranslado,
            columna: columna,
          }
        );

        if (status === 200) {
          setNivelModel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivelModel(false);
    }
    setNivelModel([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const tallers = await loadTaller();
          const callers = await loadCalle(
            securityFilters?.securityValues?.subaccount?.id
          );

          setFilter((prevFilter) => ({
            ...prevFilter,
            taller: tallers?.length > 0 ? tallers[0] : null,
            calle: callers?.length > 0 ? callers[0] : null,
          }));

          const ubicacionTranslados = await loadUbicacionTranslado();

          setModel((prevFilter) => ({
            ...prevFilter,
            ubicacionTranslado: ubicacionTranslados[0],
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  // Carregar columnas ao mudar Calle
  useEffect(() => {
    async function updateColumna() {
      if (filter?.calle) {
        try {
          const columnas = await loadColumna(filter.calle);
          setFilter((prevFilter) => ({
            ...prevFilter,
            columna: columnas?.length > 0 ? columnas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColumna();
  }, [filter?.calle]);

  // Carregar níveis ao mudar a columnas
  useEffect(() => {
    async function updateNivel() {
      if (filter?.calle && filter?.columna) {
        try {
          const niveis = await loadNivel(filter.calle, filter.columna);
          setFilter((prevFilter) => ({
            ...prevFilter,
            nivel: niveis?.length > 0 ? niveis[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateNivel();
  }, [filter?.columna]);

  //MODEL
  // Carregar columnas ao mudar UbicacionTranslado
  useEffect(() => {
    async function updateColumnaModel() {
      if (model?.ubicacionTranslado) {
        try {
          const columnas = await loadColumnaModel(model.ubicacionTranslado);
          setModel((prevFilter) => ({
            ...prevFilter,
            columna: columnas?.length > 0 ? columnas[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateColumnaModel();
  }, [model?.ubicacionTranslado]);

  // Carregar níveis ao mudar a columnas
  useEffect(() => {
    async function updateNivelModel() {
      if (model?.ubicacionTranslado && model?.columna) {
        try {
          const niveis = await loadNivelModel(
            model.ubicacionTranslado,
            model.columna
          );
          setModel((prevFilter) => ({
            ...prevFilter,
            nivel: niveis?.length > 0 ? niveis[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateNivelModel();
  }, [model?.columna]);

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/egreso-taller/historico-taller",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || null,
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
          taller: filter?.taller?.id ?? null,
          vin: filter?.vin?.id ?? null,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  async function loadReportDataDetails() {
    try {
      setLoadingChildReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/egreso-taller/historico-danos",
        {
          codPais: securityFilters?.securityValues?.country?.id || null,
          codCliente: securityFilters?.securityValues?.client?.id || null,
          codCuenta: securityFilters?.securityValues?.account?.id || null,
          vin: selectedReportData?.vin?.id,
        }
      );

      if (status === 200) {
        setChildReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChildReportData(false);
    }
  }

  useEffect(() => {
    if (selectedReportData) {
      loadReportDataDetails();
    }
  }, [selectedReportData]);

  // Seleciona todas as linhas do conjunto de dados
  useEffect(() => {
    if (childReportData && childReportData.length > 0) {
      setSelectedChildReportData(childReportData);
    }
  }, [childReportData]);

  return (
    <div className="egreso-taller">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"664"} alt="Talleres" />
                </label>
                <Dropdown
                  value={filter.taller}
                  options={taller}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                  optionLabel="descripcion"
                  loading={loadingTaller}
                  filter
                  disabled={true}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"990"} alt="Calle" />
                </label>
                <Dropdown
                  value={filter.calle}
                  options={calle}
                  onChange={(e) => setFilter({ ...filter, calle: e.value })}
                  optionLabel="descripcion"
                  loading={loadingCalle}
                  filter
                  disabled={true}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"991"} alt="Columna" />
                </label>
                <Dropdown
                  value={filter.columna}
                  options={columna}
                  onChange={(e) => setFilter({ ...filter, columna: e.value })}
                  optionLabel="descripcion"
                  loading={loadingColumna}
                  filter
                  disabled={true}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"992"} alt="Nivel" />
                </label>
                <Dropdown
                  value={filter.nivel}
                  options={nivel}
                  onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                  optionLabel="descripcion"
                  loading={loadingNivel}
                  filter
                  disabled={true}
                />
              </div>

              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"17088"} alt="Vin" />
                </label>
                <AutoComplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  dropdown
                  forceSelection
                  maxLength={17}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        className="mb-3"
        header={<LanguageProvider id={"320"} alt="Egreso Taller" />}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          <Column
            field="vin.id"
            header={<LanguageProvider id="17088" alt="Vin" />}
            sortable
            filter
          />
          <Column
            field="vin.marca.descripcion"
            header={<LanguageProvider id="396" alt="Marca" />}
            sortable
            filter
          />
          <Column
            field="vin.modelo.descripcion"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <Column
            field="vin.color.descripcion"
            header={<LanguageProvider id="2140" alt="Color" />}
            sortable
            filter
          />
          <Column
            field="vin.patente"
            header={<LanguageProvider id="4724" alt="Patente" />}
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="849" alt="Letra" />}
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="850" alt="Pto.Venta" />}
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="932" alt="Código 4" />}
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="1492" alt="Fecha Emisión" />}
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="3344" alt="Estado" />}
            sortable
            filter
          />
          <Column
            field="fechaIngreso.date"
            header={
              <LanguageProvider id="16797" alt="Fecha de ingreso taller" />
            }
            sortable
            filter
            body={(rowData) =>
              rowData.fechaIngreso?.date
                ? formatDateTime(rowData.fechaIngreso.date)
                : rowData.fechaIngreso || ""
            }
          />
          <Column
            field="tipo"
            header={<LanguageProvider id="802" alt="Tipo de Ingreso" />}
            sortable
            filter
          />
        </DataTable>
      </Panel>

      <Panel>
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={Array.isArray(childReportData) ? childReportData : []}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          loading={loadingChildReportData}
          selectionMode="single"
          onSelectionChange={(e) => setSelectedChildReportData(e.value)}
          selection={selectedChildReportData}
        >
          <Column
            field="tipoDeDanio.id"
            header={<LanguageProvider id="10540" alt="Codigo Daño" />}
            sortable
            filter
          />
          <Column
            field="tipoDeDanio.descripcion"
            header={<LanguageProvider id="10541" alt="Descripción Daño" />}
            sortable
            filter
          />
          <Column
            field="partesDanios.id"
            header={<LanguageProvider id="2490" alt="Parte" />}
            sortable
            filter
          />
          <Column
            field="partesDanios.descripcion"
            header={<LanguageProvider id="16243" alt="Parte dañada" />}
            sortable
            filter
          />
          <Column
            field="gravedadDeDanio.id"
            header={<LanguageProvider id="16575" alt="Cod. Gravedad" />}
            sortable
            filter
          />
          <Column
            field="gravedadDeDanio.descripcion"
            header={<LanguageProvider id="17007" alt="Gravedad del Daño" />}
            sortable
            filter
          />
          <Column
            field="fechaCarga.date"
            header={<LanguageProvider id="16541" alt="Fecha de carga" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaCarga?.date
                ? formatDateTime(rowData.fechaCarga.date)
                : rowData.fechaCarga || ""
            }
          />
        </DataTable>
      </Panel>

      <div>
        <p>
          <LanguageProvider
            id="17475"
            alt="Los registros seleccionados se marcarán como reparados, puede desmarcar con un click"
          ></LanguageProvider>
        </p>
      </div>

      <div className="filter-options">
        <div className="sipco-options-line">
          <div className="sipco-option">
            <label>
              <LanguageProvider id={"741"} alt="Ubicación de Traslado" />
            </label>
            <Dropdown
              value={model.ubicacionTranslado}
              options={ubicacionTranslado}
              onChange={(e) =>
                setModel({ ...model, ubicacionTranslado: e.value })
              }
              optionLabel="label"
              loading={loadingUbicacionTrans}
              filter
            />
          </div>

          <div className="sipco-option">
            <label>
              <LanguageProvider id={"991"} alt="Columna" />
            </label>
            <Dropdown
              value={model.columna}
              options={columnaModel}
              onChange={(e) => setModel({ ...model, columna: e.value })}
              optionLabel="descripcionFull"
              loading={loadingColumnaModel}
              filter
            />
          </div>

          <div className="sipco-option">
            <label>
              <LanguageProvider id={"992"} alt="Nivel" />
            </label>
            <Dropdown
              value={model.nivel}
              options={nivelModel}
              onChange={(e) => setModel({ ...model, nivel: e.value })}
              optionLabel="descripcionFull"
              loading={loadingNivelModel}
              filter
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "34",
            alt: "Procesar o guardar cambios",
          })}
          icon="pi pi-save"
          onClick={validarProcesar}
        />
      </div>
    </div>
  );
}
export default EgresoTaller;
