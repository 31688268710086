import "./InterfazIngresoFord.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

export function InterfazIngresoFord() {
  const SCREEN_CODE = "fun0089_interfaz_ingreso_ford";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [filter, setFilter] = useState({
      tipoUbicacion: null,
      ubicacion: null,
      columna: null,
      nivel: null,
      transportista : null,
      paisDestino: null,
      mercado: null,
  });
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];
    const columnsNamesTable = [
        { field: "codCompra", header: "Error" },
        { field: "tipoTaller", header: "VIN" },
        { field: "descripcion", header: "Marca" },
        { field: "fechaCompra", header: "Modelo" },
        { field: "codEstado", header: "Color" },
        { field: "codEstado", header: "Destino" },
        { field: "codEstado", header: "Punto Venta" },
        { field: "codEstado", header: "Remito" },
        { field: "codEstado", header: "Radios" },
        { field: "codEstado", header: "Concesionario" },
        { field: "codEstado", header: "Boca" },
    ];
  const columnsNames = [];
  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  async function loadReportData() {}

    const tipoUbicacion = [
        { id: 1, descripcion: "Línea de Carga" },
        { id: 2, descripcion: "Línea de Recepción" }
    ];
    const [checkedTransportista, setCheckedTransportista] = useState(false);


    const [ubicacion, setUbicacion] = useState([]);
    const [loadingUbicacion, setLoadingUbicacion] = useState(false);

    const [columna, setColumna] = useState([]);
    const [loadingColumna, setLoadingColumna] = useState(false);

    const [nivel, setNivel] = useState([]);
    const [loadingNivel, setLoadingNivel] = useState(false);

    const [transportista, setTransportista] = useState([]);
    const [loadingTransportista, setLoadingTransportista] = useState(false);

    const [mercado, setMercado] = useState([]);
    const [loadingMercado, setLoadingMercado] = useState(false);
    async function loadTransportista() {
        try {
            setLoadingTransportista(true);
                if (securityFilters?.securityValues?.country != null &&
                    securityFilters?.securityValues?.client != null &&
                    securityFilters?.securityValues?.account != null &&
                    securityFilters?.securityValues?.subaccount != null) {
                    const { status, data } = await sipcoAxiosService.post(
                        "/interfaz-ingreso-ford/proveedor",
                        {
                            pais: securityFilters.securityValues.country,
                            cliente: securityFilters.securityValues.client,
                            cuenta: securityFilters.securityValues.account,
                            subcuenta:  securityFilters.securityValues.subaccount
                        }
                    );
                    if (status === 200) {
                        setTransportista(data);
                        return data;
                    }
                }
        } catch (error) {
            console.error(error);
        }finally {
            setLoadingTransportista(false)
        }
        return [];
    }
    async function loadMercado() {
        try {
            setLoadingMercado(true);
            if (securityFilters?.securityValues?.country != null) {
                const { status, data } = await sipcoAxiosService.post(
                    "/interfaz-ingreso-ford/mercado",
                    {
                        pais: securityFilters.securityValues.country
                    }
                );
                if (status === 200) {
                    setMercado(data);
                    return data;
                }
            }
        } catch (error) {
            console.error(error);
        }finally {
            setLoadingMercado(false)
        }
        return [];
    }
    async function loadUbicacion() {
        try {
            setLoadingUbicacion(true);
           if(filter.tipoUbicacion.id === 1){
               if (securityFilters?.securityValues?.country != null &&
                   securityFilters?.securityValues?.client != null &&
                   securityFilters?.securityValues?.account != null &&
                   securityFilters?.securityValues?.subaccount != null) {
                   const { status, data } = await sipcoAxiosService.post(
                       "/interfaz-ingreso-ford/calle",
                       {
                           pais: securityFilters.securityValues.country,
                           cliente: securityFilters.securityValues.client,
                           cuenta: securityFilters.securityValues.account,
                           subcuenta:  securityFilters.securityValues.subaccount
                       }
                   );

                   if (status === 200) {
                       setUbicacion(data);
                       return data;
                   }
               }
           } else if(filter.tipoUbicacion.id === 2){
               if (securityFilters?.securityValues?.country != null &&
                   securityFilters?.securityValues?.client != null &&
                   securityFilters?.securityValues?.account != null &&
                   securityFilters?.securityValues?.subaccount != null) {
                   const { status, data } = await sipcoAxiosService.post(
                       "/interfaz-ingreso-ford/calles-recepcion",
                       {
                           pais: securityFilters.securityValues.country,
                           cliente: securityFilters.securityValues.client,
                           cuenta: securityFilters.securityValues.account,
                           subcuenta:  securityFilters.securityValues.subaccount
                       }
                   );

                   if (status === 200) {
                       setUbicacion(data);
                       return data;
                   }
               }
           } else{
               console.error("No se ha seleccionado un tipo de ubicacion")
               return []
           }
        } catch (error) {
            console.error(error);
        }finally {
            setLoadingUbicacion(false)
        }
        return [];
    }
    async function loadColumna() {
        try {
            setLoadingColumna(true);
            if(filter.tipoUbicacion.id === 1){
                if (securityFilters?.securityValues?.country != null &&
                    securityFilters?.securityValues?.client != null &&
                    securityFilters?.securityValues?.account != null &&
                    securityFilters?.securityValues?.subaccount != null &&
                    filter.ubicacion != null) {
                    const { status, data } = await sipcoAxiosService.post(
                        "/interfaz-ingreso-ford/columna",
                        {
                            pais: securityFilters.securityValues.country,
                            cliente: securityFilters.securityValues.client,
                            cuenta: securityFilters.securityValues.account,
                            subcuenta:  securityFilters.securityValues.subaccount,
                            calle: filter.ubicacion
                        }
                    );

                    if (status === 200) {
                        setColumna(data);
                        return data;
                    }
                }
            } else if(filter.tipoUbicacion.id === 2){
                if (securityFilters?.securityValues?.country != null &&
                    securityFilters?.securityValues?.client != null &&
                    securityFilters?.securityValues?.account != null &&
                    securityFilters?.securityValues?.subaccount != null &&
                    filter.ubicacion != null) {
                    const { status, data } = await sipcoAxiosService.post(
                        "/interfaz-ingreso-ford/columnas-recepcion",
                        {
                            pais: securityFilters.securityValues.country,
                            cliente: securityFilters.securityValues.client,
                            cuenta: securityFilters.securityValues.account,
                            subcuenta:  securityFilters.securityValues.subaccount,
                            calle: filter.ubicacion

                        }
                    );

                    if (status === 200) {
                        setColumna(data);
                        return data;
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }finally {
            setLoadingColumna(false)
        }
        return [];
    }

    async function loadNivel() {
        try {
            setLoadingNivel(true);
            if(filter.tipoUbicacion.id === 1){
                if (securityFilters?.securityValues?.country != null &&
                    securityFilters?.securityValues?.client != null &&
                    securityFilters?.securityValues?.account != null &&
                    securityFilters?.securityValues?.subaccount != null &&
                    filter.ubicacion != null &&
                    filter.columna != null
                ) {
                    const { status, data } = await sipcoAxiosService.post(
                        "/interfaz-ingreso-ford/nivel",
                        {
                            pais: securityFilters.securityValues.country,
                            cliente: securityFilters.securityValues.client,
                            cuenta: securityFilters.securityValues.account,
                            subcuenta:  securityFilters.securityValues.subaccount,
                            calle: filter.ubicacion,
                            columna: filter.columna
                        }
                    );

                    if (status === 200) {
                        setNivel(data);
                        return data;
                    }
                }
            } else if(filter.tipoUbicacion.id === 2){
                if (securityFilters?.securityValues?.country != null &&
                    securityFilters?.securityValues?.client != null &&
                    securityFilters?.securityValues?.account != null &&
                    securityFilters?.securityValues?.subaccount != null  &&
                    filter.ubicacion != null &&
                 filter.columna != null) {
                    const { status, data } = await sipcoAxiosService.post(
                        "/interfaz-ingreso-ford/niveles-recepcion",
                        {
                            pais: securityFilters.securityValues.country,
                            cliente: securityFilters.securityValues.client,
                            cuenta: securityFilters.securityValues.account,
                            subcuenta:  securityFilters.securityValues.subaccount,
                            calle: filter.ubicacion,
                            columna: filter.columna
                        }
                    );

                    if (status === 200) {
                        setNivel(data);
                        return data;
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }finally {
            setLoadingNivel(false)
        }
        return [];
    }
    useEffect(() => {
        async function handleCalles() {
            console.log("handleCalles");
            if (securityFilters?.securityValues?.country != null &&
                securityFilters?.securityValues?.country != null &&
                securityFilters?.securityValues?.account != null &&
               securityFilters?.securityValues?.subaccount != null &&
                filter.ubicacion != null) {
                try {
                    const columnas = await loadColumna();
                    setFilter({
                        ...filter,
                        columna: columnas[0],
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        }
        handleCalles();
    }, [filter.ubicacion]);

    useEffect(() => {
        async function handleNiveles() {
            if (securityFilters?.securityValues?.country != null &&
                securityFilters?.securityValues?.country != null &&
                securityFilters?.securityValues?.account != null &&
                securityFilters?.securityValues?.subaccount != null &&
                filter.ubicacion != null &&
                filter.columna != null) {
                try {
                    const niveles = await loadNivel();
                    setFilter({
                        ...filter,
                        nivel: niveles[0],
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        }
        handleNiveles();
    }, [filter.columna]);

    useEffect(() => {
        async function initialize() {
            console.log("initialize");
            if (
                securityFilters.securityValues.country != null &&
                securityFilters.securityValues.client != null &&
                securityFilters.securityValues.subaccount != null &&
                securityFilters.securityValues.account != null) {
                try {
                    const ubicaciones = await loadUbicacion();
                    const transportistas = await loadTransportista();
                    const mercados = await loadMercado();
                    setFilter({
                        ...filter,
                        ubicacion: ubicaciones[0],
                        transportista: transportistas[0],
                        mercado: mercados[0],
                    });

                } catch (error) {
                    console.error(error);
                }
            }
        }
        initialize();
    }, [securityFilters.securityValues, filter.tipoUbicacion]);
















    return (
    <div className="interfaz-ingreso-ford">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"22550"} alt="Filtros Por:" />}
        >
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3232"} alt="Fecha Ingreso: " />
                </label>
                <Calendar showIcon showTime />

                <label>
                  <LanguageProvider id={"927"} alt="Fecha Ingreso: " />
                </label>
                <InputText />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"5256"} alt="Tipo Ubicación: " />
                </label>
                  <Dropdown
                      value={filter.tipoUbicacion}
                      options={tipoUbicacion}
                      onChange={(e) => setFilter({ ...filter, tipoUbicacion: e.value })}
                      optionLabel="descripcion"
                      filter
                  />

                <Button label="AgregarArchivo" className="radioButton" />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10748"} alt="Ubicación: " />
                </label>
                  <Dropdown
                      value={filter.ubicacion}
                      options={ubicacion}
                      onChange={(e) => setFilter({ ...filter, ubicacion: e.value })}
                      optionLabel="descripcion"
                      loading={loadingUbicacion}
                      filter
                  />
                <label>
                  <LanguageProvider id={"754"} alt="Transportista: " />
                </label>
                  <Dropdown
                      value={filter.transportista}
                      options={transportista}
                      onChange={(e) => setFilter({ ...filter, transportista: e.value })}
                      optionLabel="descripcion"
                      loading={loadingTransportista}
                      disabled={!checkedTransportista}
                      filter
                  />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"991"} alt="Columna: " />
                </label>
                  <Dropdown
                      value={filter.columna}
                      options={columna}
                      onChange={(e) => setFilter({ ...filter, columna: e.value })}
                      optionLabel="descripcion"
                      loading={loadingColumna}
                      filter
                  />
                <div className="checkboxSipco">
                  <label>
                    <LanguageProvider id={"754"} alt="Transportista: " />
                  </label>
                    <Checkbox checked={checkedTransportista} onChange={(e) => setCheckedTransportista(e.target.checked)} />
                </div>
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"992"} alt="Nivel: " />
                </label>
                  <Dropdown
                      value={filter.nivel}
                      options={nivel}
                      onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                      optionLabel="descripcion"
                      loading={loadingNivel}
                      filter
                  />
                <label>
                  <LanguageProvider id={"367"} alt="Mercado: " />
                </label>
                  value={filter.mercado}
                  options={mercado}
                  onChange={(e) => setFilter({ ...filter, mercado: e.value })}
                  optionLabel="descripcion"
                  loading={loadingMercado}
                  filter
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Ingreso Vines Por Archivo Texto .
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  label={LanguageProvider({ id: "750", alt: "Bloquear VIN" })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "4275",
                    alt: "Chequear la grilla sin cargar el archivo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "26",
                    alt: "Baja",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "25",
                    alt: "Alta",
                  })}
                  text
                />
                 <Button
                  label={LanguageProvider({
                    id: "19267",
                    alt: "Modificar",
                  })}
                  text
                />
                 <Button
                  label={LanguageProvider({
                    id: "34",
                    alt: "Procesar o guardar cambios",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
          <DataTable
              size={"small"}
              showGridlines
              stripedRows
              ref={dt}
              //value={}
              paginator
              scrollHeight="flex"
              filterDisplay="menu"
              rows={25}
              rowsPerPageOptions={[5, 10, 25, 50]}
              paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
              currentPageReportTemplate="{first} to {last} of {totalRecords}"
              style={{ maxWidth: "100%" }}
              loading={loading}
          >
              {columnsNamesTable.map((colum, index) => {
                  if (colum.header === "Fecha Compra") {
                      return (
                          <Column
                              key={index}
                              field={colum.field}
                              body={(e) => colum.field}
                              header={colum.header}
                              sortable
                              filter
                          />
                      );
                  } else {
                      return (
                          <Column
                              key={index}
                              field={colum.field}
                              header={colum.header}
                              sortable
                              filter
                          />
                      );
                  }
              })}
          </DataTable>
        <Divider />

        <div className="form">
          <div className={"form-row"}>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"4276"} alt="Puede corregir los valores sobre la grilla y chequear con el tilde verde " />
              </label>
              <label>
                <LanguageProvider id={"841"} alt="Total de Registros: " />
              </label>
            </div>
           
          </div>
        </div>

      </Panel>
    </div>
  );
}
