import "./ReimpresionRemitos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import useFormattedDate from "../../../hooks/useFormattedDate";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ReimpresionRemitos() {
  const SCREEN_CODE = "fun0045_reimpresion_remitos";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const selectAll = () => {
    const allRegisters = dt.current.props.value;
    setSelectedReportData(allRegisters);
  };

  const deselectAll = () => {
    setSelectedReportData([]);
  };

  async function validaImpresionRemitos() {
    try {
      if (selectedReportData <= 0) {
        showError("753", "Debe seleccionar al menos un registro de la Grilla.");
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);
  const { formatDate } = useFormattedDate();

  const [filter, setFilter] = useState({
    impLoteRemitos: false,
    nroLote: "",
    letraDesde: "",
    letraHasta: "",
    ptoVentaDesde: "",
    ptoVentaHasta: "",
    nroRemitoDesde: "",
    nroRemitoHasta: "",
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    tipoRemito: null,
  });

  const [loadingTipoRemito, setLoadingTipoRemito] = useState(false);
  const [tipoRemito, setTipoRemito] = useState([]);
  async function loadTipoRemito() {
    setLoadingTipoRemito(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/reimpresion-remitos/codigo-playa",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
          }
        );

        if (status === 200) {
          setTipoRemito(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoRemito(false);
    }
    setTipoRemito([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const tipoRemitos = await loadTipoRemito();

          setFilter((prevFilter) => ({
            ...prevFilter,
            tipoRemito: tipoRemitos[0],
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/reimpresion-remitos/remito",
        {
          pais: { id: securityFilters.securityValues.country.id || null },
          cliente: { id: securityFilters.securityValues.client.id || null },
          cuenta: { id: securityFilters.securityValues.account.id || null },
          imprimirLote: filter.impLoteRemitos,
          nroLoteImpresion: filter.nroLote || "",
          letraDesde: filter.letraDesde || "",
          letraHasta: filter.letraHasta || "",
          puntoDeVentaDesde: filter.ptoVentaDesde || 0,
          puntoDeVentaHasta: filter.ptoVentaHasta || 0,
          nroRemitoDesde: filter.nroRemitoDesde || 0,
          nroRemitoHasta: filter.nroRemitoHasta || 0,
          fechaDesde: filter.fechaDesde ? filter.fechaDesde.getTime() : null,
          fechaHasta: filter.fechaHasta ? filter.fechaHasta.getTime() : null,
          tipoRemito: { id: filter.tipoRemito.id || null },
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  return (
    <div className="reimpresion-remitos">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider
                    id={"10997"}
                    alt="Desea reimprimir un lote de remitos?"
                  />
                </label>
                <Checkbox
                  checked={filter.impLoteRemitos}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      impLoteRemitos: !filter.impLoteRemitos,
                    });
                  }}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11695"} alt="Nro. Lote" />
                </label>
                <InputText
                  value={filter.nroLote}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    setFilter({ ...filter, nroLote: numericValue });
                  }}
                  disabled={!filter.impLoteRemitos}
                />
              </div>
            </div>

            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"849"} alt="Letra" />
                </label>
                <InputText
                  value={filter.letraDesde}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      letraDesde: e.target.value.toUpperCase(),
                    })
                  }
                  disabled={filter.impLoteRemitos}
                  maxLength={1}
                />
              </div>

              <div className={"sipco-option"}>
                <InputText
                  value={filter.letraHasta}
                  onChange={(e) =>
                    setFilter({
                      ...filter,
                      letraHasta: e.target.value.toUpperCase(),
                    })
                  }
                  disabled={filter.impLoteRemitos}
                  maxLength={1}
                />
              </div>
            </div>

            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"7868"} alt="Pto. Venta" />
                </label>
                <InputText
                  value={filter.ptoVentaDesde}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    setFilter({ ...filter, ptoVentaDesde: numericValue });
                  }}
                  disabled={filter.impLoteRemitos}
                  maxLength={4}
                />
              </div>

              <div className={"sipco-option"}>
                <InputText
                  value={filter.ptoVentaHasta}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    setFilter({ ...filter, ptoVentaHasta: numericValue });
                  }}
                  disabled={filter.impLoteRemitos}
                  maxLength={4}
                />
              </div>
            </div>

            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"11353"} alt="Número" />
                </label>
                <InputText
                  value={filter.nroRemitoDesde}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    setFilter({ ...filter, nroRemitoDesde: numericValue });
                  }}
                  disabled={filter.impLoteRemitos}
                  maxLength={8}
                />
              </div>

              <div className={"sipco-option"}>
                <InputText
                  value={filter.nroRemitoHasta}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/\D/g, "");
                    setFilter({ ...filter, nroRemitoHasta: numericValue });
                  }}
                  disabled={filter.impLoteRemitos}
                  maxLength={8}
                />
              </div>
            </div>

            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3185"} alt="Fecha Remito" />
                </label>
                <Calendar
                  value={filter.fechaDesde}
                  onChange={(e) => {
                    const date = new Date(e.value);
                    setFilter({ ...filter, fechaDesde: date });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={filter.impLoteRemitos}
                />
              </div>

              <div className={"sipco-option"}>
                <Calendar
                  value={filter.fechaHasta}
                  onChange={(e) => {
                    const date = new Date(e.value);
                    setFilter({ ...filter, fechaHasta: date });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={filter.impLoteRemitos}
                />
              </div>
            </div>
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"848"} alt="Tipo de Remito" />
                </label>
                <Dropdown
                  value={filter?.tipoRemito}
                  options={tipoRemito}
                  onChange={(e) =>
                    setFilter({ ...filter, tipoRemito: e.value })
                  }
                  optionLabel="descripcionFull"
                  loading={loadingTipoRemito}
                  filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id="323"
                  alt="Reimpresión de Remito"
                ></LanguageProvider>
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={reporte}
                  columns={null}
                  screenName={"Reimpresión de Remito"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <div className="dt-descripcion">
          <LanguageProvider
            id="899"
            alt="Marque en la Grilla los Remitos a Reimprimir"
          ></LanguageProvider>
        </div>

        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          // rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="multiple"
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          selection={selectedReportData}
        >
          <Column
            field="leyendaRemito.id"
            header={<LanguageProvider id="16757" alt="Leyenda" />}
            sortable
            filter
          />
          <Column
            field="leyendaRemito.descripcion"
            header={<LanguageProvider id="16757" alt="Leyenda" />}
            sortable
            filter
          />
          <Column
            field="estadoRemito.descripcion"
            header={<LanguageProvider id="20804" alt="Estado" />}
            sortable
            filter
          />
          <Column
            field="concesionario.id"
            header={<LanguageProvider id="2941" alt="Concesionario" />}
            sortable
            filter
          />
          <Column
            field="id"
            header={<LanguageProvider id="23876" alt="Código" />}
            sortable
            filter
          />
          <Column
            field="vin.id"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <Column
            field="vin.fechaSalidaFabrica.date"
            header={<LanguageProvider id="1730" alt="Fecha Salida" />}
            sortable
            filter
            body={(rowData) =>
              rowData.vin.fechaSalidaFabrica?.date
                ? formatDate(rowData.vin.fechaSalidaFabrica.date)
                : rowData.vin.fechaSalidaFabrica || ""
            }
          />
          <Column
            field="fechaEmision.date"
            header={<LanguageProvider id="1492" alt="Fecha Emisión" />}
            sortable
            filter
            body={(rowData) =>
              rowData.vin.fechaEmision?.date
                ? formatDate(rowData.vin.fechaEmision.date)
                : rowData.vin.fechaEmision || ""
            }
          />
          <Column
            field="vin.modelo.descripcion"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <Column
            field="vin.color.descripcion"
            header={<LanguageProvider id="2140" alt="Color" />}
            sortable
            filter
          />
          <Column
            field="letra"
            header={<LanguageProvider id="6781" alt="Letra" />}
            sortable
            filter
          />
          <Column
            field="idPedidoCliente"
            header={<LanguageProvider id="25206" alt="Nro Pedido/Cliente" />}
            sortable
            filter
          />
          <Column
            field="puntoDeVenta"
            header={<LanguageProvider id="850" alt="Pto.Venta" />}
            sortable
            filter
          />
          <Column
            field="concesionario.descripcion"
            header={<LanguageProvider id="2941" alt="Concesionario" />}
            sortable
            filter
          />
          <Column
            field="direccionEntrega.id"
            header={<LanguageProvider id="4216" alt="Boca de Entrega" />}
            sortable
            filter
          />
          <Column
            field="direccionEntrega.direccion"
            header={<LanguageProvider id="4216" alt="Boca de Entrega" />}
            sortable
            filter
          />
          <Column
            field="razonSocialImpresion"
            header={<LanguageProvider id="3591" alt="Razón Social" />}
            sortable
            filter
          />
          <Column
            field="domicilioImpresion"
            header={<LanguageProvider id="8299" alt="Domicilio" />}
            sortable
            filter
          />
          <Column
            field="provinciaImpresion"
            header={<LanguageProvider id="159" alt="Provincia" />}
            sortable
            filter
          />
          <Column
            field="localidadImpresion"
            header={<LanguageProvider id="3186" alt="Localidad" />}
            sortable
            filter
          />
          <Column
            field="vin.modeloLargo"
            header={<LanguageProvider id="13338" alt="Modelo Largo" />}
            sortable
            filter
          />
          <Column
            field="vin.mercado.descripcion"
            header={<LanguageProvider id="367" alt="Mercado" />}
            sortable
            filter
          />
          <Column
            field="vin.descripcionIngresoTaller"
            header={<LanguageProvider id="17092" alt="Desc. Pend. Ingreso" />}
            sortable
            filter
          />
          <Column
            field="remitoTransferencia"
            header={<LanguageProvider id="10906" alt="Transferencia" />}
            sortable
            filter
          />
          <Column
            field="numeroLoteImpresion"
            header={<LanguageProvider id="1252" alt="Lote" />}
            sortable
            filter
          />
        </DataTable>
      </Panel>

      <div className="chips">
        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"841"} alt="Total de Registros:" />
          </label>
          <Chip
            label={
              reporte && Array.isArray(reporte) ? String(reporte.length) : "0"
            }
          />
        </div>

        <div className="sipco-chip">
          <label>
            <LanguageProvider id={"864"} alt="Registros Selecionados:" />
          </label>
          <Chip
            label={
              selectedReportData && selectedReportData.length > 0
                ? String(selectedReportData.length)
                : "0"
            }
          />
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({ id: "748", alt: "Seleccionar tudo" })}
          icon="pi pi-plus"
          onClick={selectAll}
        />
        <Button
          label={LanguageProvider({ id: "749", alt: "Deseleccionar tudo" })}
          icon="pi pi-minus"
          onClick={deselectAll}
        />
        <Button
          label={LanguageProvider({
            id: "11975",
            alt: "Impresión del listado de remitos",
          })}
          icon="pi pi-save"
          onClick={validaImpresionRemitos}
        />
      </div>
    </div>
  );
}
export default ReimpresionRemitos;
