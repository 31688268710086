import "./DevolucionMan.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DevolucionMan() {
  const SCREEN_CODE = "fun0219_devolucion_man";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const [validationDatosDialog, setValidationDatosDialog] = useState(false);
  const [completarDatosDialog, setCompletarDatosDialog] = useState(false);
  const [remitos, setRemitos] = useState(null);
  const [loadingProcesar, setLoadingProcesar] = useState(false);
  const [procesarDialog, setProcesarDialog] = useState(false);

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  async function handleVinInputKeyDown(event) {
    if (event.key === "Enter") {
      try {
        if (filter.vin.id.length >= 8 && filter.vin.id.length <= 17) {
          await loadRemitos(filter.vin.id ?? null);
          await loadVin(filter.vin.id ?? null);
        } else {
          showError(
            "653",
            "Debe ingresar un minimo de 8 y un maximo de 17 dígitos"
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  const procesarClick = () => {
    try {
      if (validationFields()) {
        return;
      }
      aProcesar();
    } catch (error) {
      console.error(error);
    }
  };

  function validationFields() {
    try {
      if (!filter?.vin) {
        setValidationDatosDialog(true);
        return true;
      }
      if (!model?.serieNfBenef || !model?.codNfBenef) {
        setCompletarDatosDialog(true);
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return true;
    }
  }

  const handleProcesar = (isConfirmed) => {
    setProcesarDialog(false);
    if (isConfirmed) {
      aProcesar();
    }
  };

  async function aProcesar() {
    setLoadingProcesar(true);
    try {
      const filters = {
        url: "",
        pais: securityFilters.securityValues.country ?? null,
        cliente: securityFilters.securityValues.client ?? null,
        cuenta: securityFilters.securityValues.account ?? null,
        subcuenta: {
          ...securityFilters.securityValues.subaccount,
          subcuentaDetalle: securityFilters.subAccountDetails,
        },
        vin: filter.vin,
        transportista: { id: model?.transportista.id || null },
        chofer: { id: model?.chofer.id || null },
        serie: model?.serieNfBenef || null,
        notaFiscal: { id: model?.codNfBenef || null },
        vinMarketing: model.vinMarketing,
        fechaNfProveedor: model.fechaNfBenef,
        fechaDevolucion: filter.fecha.getTime(),
      };

      const { status } = await sipcoAxiosService.post(
        "/devolucion-man/devolucion-man",
        filters
      );
      if (status === 200) {
        // setConfirmProcessDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  const cancelarClick = () => {
    try {
      clearFields();
    } catch (error) {
      console.error(error);
    }
  };

  function clearFields() {
    try {
      setFilter((prevFilter) => ({
        ...prevFilter,
        vin: null,
      }));
      setModel((prevFilter) => ({
        ...prevFilter,
        transportista: null,
        chofer: null,
        serieNfBenef: null,
        codNfBenef: null,
        fechaNfBenef: null,
        vinMarketing: false,
      }));
      setRemitos({
        puntoDeVenta: "",
        id: "",
      });
    } catch (error) {
      console.error(error);
    }
  }

  const handleValidationClose = () => {
    setValidationDatosDialog(false);
    setCompletarDatosDialog(false);
  };

  const [filter, setFilter] = useState({
    vin: null,
    fecha: new Date(),
  });

  const [model, setModel] = useState({
    transportista: null,
    chofer: null,
    serieNfBenef: null,
    codNfBenef: null,
    fechaNfBenef: null,
    serieNfMan: null,
    codNfMan: null,
    vinMarketing: false,
  });

  async function loadRemitos(vin) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/devolucion-man/remitos",
        {
          pais: { id: securityFilters.securityValues.country.id },
          cliente: { id: securityFilters.securityValues.client.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          vin: { id: vin },
        }
      );

      if (status === 200) {
        setRemitos(data[0] || null);
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country && vin !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-man/vin",
          {
            pais: { id: securityFilters.securityValues.country.id },
            cliente: { id: securityFilters.securityValues.client.id },
            cuenta: { id: securityFilters.securityValues.account.id },
            vin: { id: vin },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError("1480", "El VIN no existe");
          }
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVin([]);
    return [];
  }

  const [loadingTransportista, setLoadingTransportista] = useState(false);
  const [transportista, setTransportista] = useState([]);
  async function loadTransportista() {
    setLoadingTransportista(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-man/proveedor",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
          }
        );

        if (status === 200) {
          const transportistaData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} (${x.id})` };
          });
          setTransportista(transportistaData);
          return transportistaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportista(false);
    }
    setTransportista([]);
    return [];
  }

  const [loadingChofer, setLoadingChofer] = useState(false);
  const [chofer, setChofer] = useState([]);
  async function loadChofer(transportistaId) {
    setLoadingChofer(true);
    try {
      if (transportistaId) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-man/chofer",
          {
            transportista: { id: transportistaId },
          }
        );

        if (status === 200) {
          const choferData = data.map((x: any) => {
            return { ...x, label: `${x.nombre} (${x.id})` };
          });
          setChofer(choferData);
          return choferData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChofer(false);
    }
    setChofer([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const transportistas = await loadTransportista();

          if (transportistas.length > 0) {
            setModel((prevFilter) => ({
              ...prevFilter,
              transportista:
                transportistas?.length > 0 ? transportistas[0] : null,
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  useEffect(() => {
    async function updateChofer() {
      if (model.transportista) {
        try {
          const choferes = await loadChofer(model.transportista.id);

          if (choferes.length > 0) {
            setModel((prevFilter) => ({
              ...prevFilter,
              chofer: choferes?.length > 0 ? choferes[0] : null,
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateChofer();
  }, [model?.transportista]);

  return (
    <div className="devolucion-man">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset legend={<LanguageProvider id={"21543"} alt="Devolución MAN" />}>
        <div className="form">
          <div className="form-row">
            <div className={"sipco-option vin-highlight"}>
              <label>
                <LanguageProvider id={"662"} alt="Ingrese el VIN" />
              </label>
              <AutoComplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                field="id"
                dropdown
                forceSelection
                maxLength={17}
                onKeyDown={handleVinInputKeyDown}
              />
            </div>

            <div className={"sipco-option-not-label"}>
              <Calendar
                value={filter.fecha}
                onChange={(e) => {
                  setFilter({ ...filter, fecha: e.value });
                }}
                showButtonBar
                showTime
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
          </div>
        </div>
      </Fieldset>

      <Fieldset>
        <div className="form">
          <div className="form-row ">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"754"} alt="Transportista" />
              </label>
              <Dropdown
                value={model.transportista}
                options={transportista}
                onChange={(e) => setModel({ ...model, transportista: e.value })}
                optionLabel="label"
                loading={loadingTransportista}
                filter
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"4655"} alt="Chofer" />
              </label>
              <Dropdown
                value={model.chofer}
                options={chofer}
                onChange={(e) => setModel({ ...model, chofer: e.value })}
                optionLabel="label"
                loading={loadingChofer}
                filter
              />
            </div>
          </div>

          <div className="form-row ">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"21538"} alt="Serie NF Beneficiario" />
              </label>
              <InputText
                value={model?.serieNfBenef || ""}
                onChange={(e) =>
                  setModel({ ...model, serieNfBenef: e.target.value })
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"21539"} alt="Código NF Beneficiario" />
              </label>
              <InputText
                value={model?.codNfBenef || ""}
                onChange={(e) =>
                  setModel({ ...model, codNfBenef: e.target.value })
                }
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"21540"} alt="Fecha NF Beneficiario" />
              </label>
              <Calendar
                value={model.fechaNfBenef}
                onChange={(e) => {
                  const date = new Date(e.value);
                  setModel({ ...model, fechaNfBenef: date });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
          </div>

          <div className="form-row ">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"21334"} alt="Serie NF Man" />
              </label>
              <InputText value={remitos?.puntoDeVenta || ""} disabled={true} />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"21335"} alt="Código NF Man" />
              </label>
              <InputText value={remitos?.id || ""} disabled={true} />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"21471"} alt="Vin Marketing?" />
              </label>
              <Checkbox
                checked={model.vinMarketing}
                onChange={(e) => {
                  setModel({
                    ...model,
                    vinMarketing: !model.vinMarketing,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </Fieldset>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "16283",
            alt: "Procesar",
          })}
          icon="pi pi-save"
          onClick={procesarClick}
        />
        {/* Ingrese el VIN Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validationDatosDialog}
          style={{ width: "7vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider id="662" alt="Ingrese el VIN" />
          </p>
        </Dialog>

        {/* Faltan completar datos Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={completarDatosDialog}
          style={{ width: "7vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider id="25208" alt="Faltan completar datos:" />
            {!model?.serieNfBenef || model?.serieNfBenef === "" ? (
              <strong>{getLabel("21481", "Serie Incorrecta")}</strong>
            ) : (
              (!model?.codNfBenef || model?.codNfBenef === "") && (
                <strong>{getLabel("21539", "Código NF Beneficiario")}</strong>
              )
            )}
          </p>
        </Dialog>

        {/* Procesar Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={procesarDialog}
          style={{ width: "30vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleProcesar(true)}
                autoFocus
                disabled={loadingProcesar}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleProcesar(false)}
                className="p-button-text"
                disabled={loadingProcesar}
              />
            </div>
          }
          onHide={() => setProcesarDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="13275"
              alt="¿Desea procesar los cambios realizados?"
            />
          </p>
          {loadingProcesar && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog>

        <Button
          label={LanguageProvider({
            id: "3155",
            alt: "Cancelar",
          })}
          icon="pi pi-times-circle"
          onClick={cancelarClick}
        />
      </div>
    </div>
  );
}
export default DevolucionMan;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
