import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { PropsTypeCode } from "../PropsTypeCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format, sub } from "date-fns";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Panel } from "primereact/panel";
import ExportButtons from "@shared/components/Export";
import { Toast } from "primereact/toast";

const GenerarOtAba: React.FC<{ vinData: PropsTypeCode }> = ({ vinData }) => {
  const [reporte, setReporte] = useState(null);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const dt = useRef(null);
  const toast = useRef<Toast>(null);
  const columnsNames = [];
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const filterColumnsIdLectora = [14369, 1491, 1569, 5788, 8380];
  const [selectedRows, setSelectedRows] = useState([]);


  const [filter, setFilter] = useState({
    grupo: null,
    operacion: null,
    vin: null,
    porOt: false,
    listadoOperacion: false,
    // CheckBox Generar
    fechaPendieteGenerarOt: false,
    fechaPriorizacionGenerarOt: false,
    //CheckBox Cerrar
    fechaPendieteCerrarOt: false,
    fechaPriorizacionCerrarOt: false,
    // Fecha Pendiete Desde - Hasta
    fechaPendieteGenerarOtDesde: new Date(),
    fechaPendieteGenerarOtHasta: new Date(),
    fechaPendieteCerrarOtDesde: new Date(),
    fechaPendieteCerrarOtHasta: new Date(),
    // Fecha Priorizacion Desde - Hasta
    fechaPriorizacionGenerarOtDesde: new Date(),
    fechaPriorizacionGenerarOtHasta: new Date(),
    fechaPriorizacionCerrarOtDesde: new Date(),
    fechaPriorizacionCerrarOtHasta: new Date(),
  });


  const [grupo, setGrupo] = useState(null);
  const [loadingGrupo, setLoadingGrupo] = useState(false);

  const [operacion, setOperacion] = useState(null);
  const [loadingOperacion, setLoadingOperacion] = useState(false);

  const [vin, setVin] = useState(null);
  const [loadingVin, setLoadingVin] = useState(false);

  const [generarOT, setGenerarOT] = useState([]);

  const [ordenTrabajo, setOrdenTrabajo] = useState([]);

  const handleSelectAll = () => {
    setSelectedRows([...ordenTrabajo]);
  };

  const handleDeselectAll = () => {
    setSelectedRows([]);
  };


// Lógica para comparar um array do datatable e o array da resposta da requisição
  function compareArrays(selectRows: any[], generarOT: any[]): any[] {
    const result = [];
    if (selectRows.length > 0 && generarOT.length > 0) {
      selectRows.forEach((row: any) => {
        const vinValue = row.vin;
        const matchingObject = generarOT.find((item: any) => item?.vin.id === vinValue);
        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }
    return result;
  }

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/generacio-cierre-ot/vin",
          {
            codPais: vinData?.codPais,
            codCliente: vinData?.codCliente,
            codCuenta: vinData?.codCuenta,
            vin: query,
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  async function loadGrupo() {
    try {
      setLoadingGrupo(true);
      const { status, data } = await sipcoAxiosService.post(
        "/generacio-cierre-ot/grupo-operacion",
        {
          codPais: vinData.codPais,
        }
      );
      if (status === 200) {
        data.unshift({ descripcion: "TODOS" });
        setGrupo(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrupo(false);
    }
  }

  async function loadOperacion() {
    try {
      setLoadingOperacion(true);
      const { status, data } = await sipcoAxiosService.post(
        "/generacio-cierre-ot/operacion",
        {
          codPais: vinData.codPais,
        }
      );
      if (status === 200) {
        data.unshift({ id: 0, label: "TODOS" });
        const response = data.map((x: any) => {
          return {
            label: `${x.label}`,
          };
        });

        setOperacion(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperacion(false);
    }
  }

  useEffect(() => {
    try {
      const init = async () => {
        const grupo = await loadGrupo();
        const operacion = await loadOperacion();

        setFilter({
          ...filter,
          grupo: grupo ? grupo[0] : null,
          operacion: operacion,
        });
      };

      init();
    } catch (error) {
      console.error(error);
    }
  }, [vinData]);

  useEffect(() => {
    const report = async () => {
      try {
        if (vinData.folder === 0 && vinData.setFolder.folderZero === true) {
          const reporte = await loadReportDataOrdenTrabajo();

          if (reporte) {
            setOrdenTrabajo(reporte);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    report();
  }, [vinData.setFolder.folderZero]);

  const data = convertDateObjects(reporte?.filas);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  const [generarOtFunction, setGenerarOtFunction] = useState([]);
  const [loadingGenerarOtFunction, setLoadingGenerarOtFunction] = useState(false);

  async function generarOt(selectRows: any[], generarOt: any[]) {
    try {
      setLoadingGenerarOtFunction(true);
      if (ordenTrabajo.length > 0 && vinData.codPais != null
        && vinData.codCuenta != null
        && vinData.codCliente != null && vinData.codSubcuenta != null) {

        const { status, data } = await sipcoAxiosService.post(
          "/generacio-cierre-ot/generar",
          {
            pais: {
              id: vinData.codPais
            },
            cliente: {
              id: vinData.codCliente
            },
            cuenta: {
              id: vinData.codCuenta
            },
            subcuenta: {
              id: vinData.codSubcuenta
            },
            otList: compareArrays(selectRows, generarOt) || null
          }
        );
        if (status === 200) {
          toast.current?.show({
            severity: "success",
            detail: (
              <LanguageProvider
                id={"36"}
                alt="Su transacción se realizó con éxito."
              />
            ),
            life: 3000,
          });
          setGenerarOtFunction(data);
          return data;
        }
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGenerarOtFunction(false);
    }
  }

  async function loadReportDataOrdenTrabajo() {

    const grupo = filter.grupo === "TODOS" ?  filter.grupo : { id : null};
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/generacio-cierre-ot/orden-trabajo",
        {
          pais: {
            id: vinData.codPais
          },
          cuenta: {
            id: vinData.codCuenta
          },
          cliente: {
            id: vinData.codCliente
          },
          subcuenta: {
            id: vinData.codSubcuenta
          },
          grupoOperacion:  grupo ,
          vin: filter.vin || null,
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          const response = data.map((x: any) => {
            return {
              ...x,
              vin: x.vin.id,
              modelo: x?.vin?.modelo?.descripcion,
              concesionario: x?.concesionario?.descripcion,
              ubicacion: x?.ubicacion,
              operacion: x?.operacion?.descripcion,
              // Método de gerar OT
              idoperacion: x?.operacion?.id,
              grupoOperacion: x?.grupoOperacion?.descripcion,
              // Método de gerar OT
              idgrupoOperacion: x?.grupoOperacion?.id,
              remito: x?.remito,
              fechaLiberacion: x?.fechaLiberacion,
              fechaAsignacion: x?.fechaAsignacion,
              color: x?.vin?.color?.descripcion,
              direccion: x?.direccionEntrega?.direccion,
              usuarioSolicitud: x?.usuarioSolicitud?.apellidoNombreId,
              // Método de gerar OT
              idusuarioSolicitud: x?.usuarioSolicitud?.id,
              fechaSolicitud: x?.fechaSolicitud?.date,
              fechaSolicitudtime: x?.fechaSolicitud?.timestamp,
            };
          });

          setGenerarOT(data);
          setOrdenTrabajo(response);
          setReporte(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }



  const columnsNameGerarOt = [
    { field: "vin", header: "VIN" },
    { field: "modelo", header: "Modelo" },
    { field: "concesionario", header: "Concesionario" },
    { field: "ubicacion", header: "Ubicación" },
    { field: "operacion", header: "Operación" },
    { field: "grupoOperacion", header: "Grupo Operación" },
    { field: "remito", header: "Remito" },
    { field: "fechaLiberacion", header: "Fecha Liberación" },
    { field: "fechaAsignacion", header: "Fecha Asignación" },
    { field: "color", header: "Color" },
    { field: "direccion", header: "Direccion" },
    { field: "usuarioSolicitud", header: "Usuario Solicitud" },
    { field: "fechaSolicitud", header: "Fecha Solicitud" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };




  return (
    <div className="generar-ot">
      <Toast ref={toast} position="top-center" />
      <div className="search-action mb-3">
        <Button
          label={LanguageProvider({ id: "9665", alt: "Buscar" })}
          onClick={loadReportDataOrdenTrabajo}
        />
      </div>


      <div className="form mb-3">
        <div className="form-row">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1799"} alt="Grupo" />
            </label>
            <Dropdown
              value={filter.grupo}
              options={grupo}
              onChange={(e) => setFilter({ ...filter, grupo: e.value || null })}
              optionLabel="descripcion"
              filter
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1569"} alt="Operación" />
            </label>
            <MultiSelect
              value={filter.operacion}
              options={operacion}
              onChange={(e) =>
                setFilter({ ...filter, operacion: e.value || null })
              }
              optionLabel="label"
              display="chip"
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"662"} alt="Ingrese el Vin" />
            </label>
            <AutoComplete
              value={filter.vin}
              suggestions={vin}
              completeMethod={searchVin}
              onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
              field="label"
              dropdown
              forceSelection
            />
          </div>
          <div className="RadioButtonFilter">
            <Checkbox
              checked={filter.porOt}
              onChange={(e) => {
                setFilter({ ...filter, porOt: e.checked });
              }}
              style={{
                justifyContent: "center",
                position: "relative",
              }}
            />
            <label>
              <LanguageProvider id={"18603"} alt="Por OT" />
            </label>
          </div>
          <div className="RadioButtonFilter">
            <Checkbox
              checked={filter.listadoOperacion}
              onChange={(e) => {
                setFilter({ ...filter, listadoOperacion: e.checked });
              }}
              style={{
                justifyContent: "center",
                position: "relative",
              }}
            />
            <label>
              <LanguageProvider id={"18513"} alt="Listado por Operación" />
            </label>
          </div>
        </div>
        <div className="form-row">
          <div className="RadioButtonFilter">
            <Checkbox
              checked={filter.fechaPendieteGenerarOt}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPendieteGenerarOt: e.checked,
                });
              }}
              style={{
                justifyContent: "center",
                position: "relative",
              }}
            />
            <label>
              <LanguageProvider id={"11507"} alt="Fecha Pendiente" />
            </label>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"467"} alt="Desde :" />
            </label>
            <Calendar
              value={filter.fechaPendieteGenerarOtDesde}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPendieteGenerarOtDesde: e.value,
                });
              }}
              disabled={!filter.fechaPendieteGenerarOt}
              showIcon
              showButtonBar
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"468"} alt="Hasta :" />
            </label>
            <Calendar
              value={filter.fechaPendieteGenerarOtHasta}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPendieteGenerarOtHasta: e.value,
                });
              }}
              disabled={!filter.fechaPendieteGenerarOt}
              showIcon
              showButtonBar
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"11720"} alt="Cantidad de Vines" />
            </label>
            <Chip label="0" />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"18358"} alt="Total vines seleccionados" />
            </label>
            <Chip label="0" />
          </div>
        </div>
        <div className="form-row">
          <div className="RadioButtonFilter">
            <Checkbox
              checked={filter.fechaPriorizacionGenerarOt}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPriorizacionGenerarOt: e.checked,
                });
              }}
              style={{
                justifyContent: "center",
                position: "relative",
              }}
            />
            <label>
              <LanguageProvider id={"19690"} alt="Fecha Priorización" />
            </label>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"467"} alt="Desde :" />
            </label>
            <Calendar
              value={filter.fechaPriorizacionGenerarOtDesde}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPriorizacionGenerarOtDesde: e.value,
                });
              }}
              showIcon
              disabled={!filter.fechaPriorizacionGenerarOt}
              showButtonBar
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"468"} alt="Hasta :" />
            </label>
            <Calendar
              value={filter.fechaPriorizacionGenerarOtHasta}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPriorizacionGenerarOtHasta: e.value,
                });
              }}
              showIcon
              disabled={!filter.fechaPriorizacionGenerarOt}
              showButtonBar
            />
          </div>
        </div>
      </div>

      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}><LanguageProvider id={"14379"} alt="Generación y Cierre de Ordenes de Trabajo" /></span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  label={LanguageProvider({
                    id: "14357",
                    alt: "Generar OT",
                  })}
                  text
                  onClick={() => generarOt(selectedRows, generarOT)}
                />
                <Button
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  text

                  onClick={handleSelectAll}
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  text
                  onClick={handleDeselectAll}
                />

              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={ordenTrabajo}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection

        >
          {columnsNameGerarOt.map((colum, index) => {
            if (colum.header === "Fecha Solicitud") {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}



        </DataTable>
      </Panel>


    </div>
  );
};
export default GenerarOtAba;
