import "./DevolucionMercadoLocal.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { format } from "date-fns";

export function DevolucionMercadoLocal() {
  const SCREEN_CODE = "fun0057_devolucion_mercado_local";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [filter, setFilter] = useState({
    hojaRuta: null,
    vin: null,
    called: null,
    column: null,
    nivel: null,
    transportista: "",
    chofer: "",
    tractor: null,
    cantidadUnidades: "",
    compraFlete: false,
    ventaFlete: false,
  });

  const [tableHojaRuta, setTableHojaRuta] = useState(null);

  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];
  const filterColumnsId = [
    849, 850, 24084, 1491, 23892, 2139, 2140, 2941, 4216,
  ];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [vin, setVin] = useState([]);

  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: {
              id: query,
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return {
              ...x,
              label: `${x.id}`,
            };
          });

          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  const [hojaRuta, setHojaRuta] = useState([]);
  const [loadingHojaRuta, setLoadingHojaRuta] = useState(false);

  async function loadHojaRuta() {
    setLoadingHojaRuta(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/hoja-ruta",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return {
              ...x,
              label: `${x.id}`,
            };
          });

          setHojaRuta(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingHojaRuta(false);
    }
  }

  const [loadingFullHojaRuta, setLoadingFullHojaRuta] = useState(false);
  const [fullHojaRuta, setFullHojaRuta] = useState(null);

  async function loadFullSearch(hojaRuta: any) {
    setLoadingFullHojaRuta(true);
    try {
      if (securityFilters?.securityValues?.country && hojaRuta) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/full-search",
          {
            hojaRuta: hojaRuta ?? null,
          }
        );

        if (status === 200) {
          setFullHojaRuta(data);
          return data;
        }
      }
    } catch (error) {
      console.error("Error in loadFullSearch:", error);
    } finally {
      setLoadingFullHojaRuta(false);
    }
  }

  // Refatorar
  const [column, setColumn] = useState([]);
  const [loadingColumns, setLoadingColumns] = useState(false);

  async function loadColumns(called: any) {
    setLoadingColumns(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/columna",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: called ?? null,
          }
        );
        if (status === 200) {
          setColumn(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumns(false);
    }
  }

  const [called, setCalled] = useState([]);
  const [loadingCalled, setLoadingCalled] = useState(false);

  async function loadCalled() {
    setLoadingCalled(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/calle",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
          }
        );
        if (status === 200) {
          setCalled(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalled(false);
    }
  }

  const [nivel, setNivel] = useState([]);
  const [loadingNivel, setLoadingNivel] = useState(false);

  async function loadNivel(called: any, column: any) {
    setLoadingNivel(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/nivel",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            calle: called ?? null,
            columna: column ?? null,
          }
        );
        if (status === 200) {
          setNivel(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNivel(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      const { country, client, account, subaccount } =
        securityFilters.securityValues;

      if (country || client || account || subaccount) {
        try {
          const [hojaRuta, called] = await Promise.all([
            loadHojaRuta(),
            loadCalled(),
          ]);

          setFilter({
            ...filter,
            hojaRuta: hojaRuta?.[0] ?? null,
            called: called?.[0] ?? null,
          });
        } catch (error) {
          console.error("Error in init:", error);
        }
      }
    };

    init();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    const handleHojaRutaChange = async () => {
      await loadFullSearch(filter.hojaRuta);
    };
    handleHojaRutaChange();
  }, [filter.hojaRuta]);

  useEffect(() => {
    const init = async () => {
      if (
        securityFilters.securityValues.country != null &&
        securityFilters.securityValues.client != null &&
        securityFilters.securityValues.account != null &&
        securityFilters.securityValues.subaccount != null
      ) {
        const called = await loadCalled();
        if (called.length > 0) {
          const column = await loadColumns(filter.called ?? null);
          if (column.length > 0) {
            const nivel = await loadNivel(
              filter.called ?? null,
              filter.column ?? null
            );

            setFilter({
              ...filter,
              column: column[0] ?? null,
              nivel: nivel[0] ?? null,
            });
          }
        }
      }
    };
    init();
  }, [filter.called]);

  async function loadReportData(hojaRuta: any) {
    setLoading(true);
    try {
      if (securityFilters?.securityValues?.country && hojaRuta != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/devolucion-mercado-local/linea-hora-ruta",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            cliente: securityFilters.securityValues.client,
            subcuenta: securityFilters.securityValues.subaccount,
            hojaRuta: hojaRuta ?? null,
          }
        );

        if (status === 200) {
          console.log("data", data);
          const response = data.map((x: any) => {
            return {
              ...x,
              remitoLetra: `${x?.remito?.letra}`,
              puntoDeVenta: `${x?.remito?.puntoDeVenta}`,
              codRemito: `${x?.remito?.id}`,
              vin: `${x?.vin?.id}`,
              cliente: `${x?.cliente?.descripcion}`,
              marca: `${x?.vin?.marca?.descripcion}`,
              color: `${x?.vin?.color?.descripcion}`,
              modelo: `${x?.vin?.modelo?.descripcion}`,
              concesionarioP: `${x?.concesionarioP?.descripcion}`,
              direccionEntregaP: `${x?.direccionEntregaP?.id}`,
            };
          });

          setTableHojaRuta(response);
          return response;
        }
      }
    } catch (error) {
      console.error("Error in loadFullSearch:", error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTable = [
    { field: "remitoLetra", header: "Letra" },
    { field: "puntoDeVenta", header: "Punto de Venta" },
    { field: "codRemito", header: "Código Remito" },
    { field: "cliente", header: "Cliente" },
    { field: "vin", header: "Vin" },
    { field: "marca", header: "Marca" },
    { field: "modelo", header: "Modelo" },
    { field: "color", header: "Color" },
    { field: "concesionarioP", header: "Concesionario" },
    { field: "direccionEntregaP", header: "Dirección Entrega" },

  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  return (
    <div className="devolucion-mercado-local">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={() => loadReportData(filter.hojaRuta)}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN " />
                </label>
                <AutoComplete
                  value={filter.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.value || null })
                  }
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"924"} alt="Hoja de Ruta " />
                </label>
                <Dropdown
                  value={filter.hojaRuta}
                  options={hojaRuta}
                  optionLabel="label"
                  onChange={(e) => setFilter({ ...filter, hojaRuta: e.value })}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Devolucion Nacional</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "16283", alt: "Procesar" })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "20947",
                    alt: "Modificar Daños",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={tableHojaRuta}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.header === "Fecha Compra") {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </DataTable>
        <div className="filter-options">
          <Tag
            style={{ background: "orange" }}
            value={
              <LanguageProvider
                id={"3674"}
                alt="Vehículo con daños antes de reingresarlo revise y deje los que están vigentes y cargue los nuevos"
              />
            }
          />
        </div>
        <div className="filter-options">
          <div className={"sipco-options-line"}>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"TODO"} alt="Ubicacón" />
              </label>
              <Dropdown
                value={filter.called}
                options={called}
                onChange={(e) => setFilter({ ...filter, called: e.value })}
                filter
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"TODO"} alt="Columna" />
              </label>
              <Dropdown
                value={filter.column}
                options={column}
                onChange={(e) => setFilter({ ...filter, column: e.value })}
                filter
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"TODO"} alt="Nivel" />
              </label>
              <Dropdown
                value={filter.nivel}
                options={nivel}
                onChange={(e) => setFilter({ ...filter, nivel: e.value })}
                filter
              />
            </div>
          </div>

          <div className={"sipco-options-line"}>
            <div className="sipco-option" style={{ width: "100%" }}>
              <label>
                <LanguageProvider id={"TODO"} alt="Nota" />
              </label>
              <InputText style={{ paddingRight: "500px" }} />
            </div>
          </div>
        </div>
        <Accordion>
          <AccordionTab
            header={<LanguageProvider id={"17355"} alt="Hoja Ruta" />}
          >
            <div className="form" style={{ display: "flex", gap: "20px" }}>
              <div
                className="colunm"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"754"} alt="Transportista" />
                  </label>
                  <InputText
                    value={fullHojaRuta?.administradorTractor?.descripcion}
                    readOnly
                  />
                </div>
                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"4655"} alt="Chofer" />
                  </label>
                  <InputText
                    value={`${fullHojaRuta?.chofer.apellido} ${fullHojaRuta?.chofer.nombre}`}
                    readOnly
                  />
                </div>
              </div>
              <div
                className="colunm"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="sipco-option"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                  }}
                >
                  <label>
                    <LanguageProvider id={"4285"} alt="Tractor" />
                  </label>
                  <InputText
                    value={fullHojaRuta?.tractor?.descripcion}
                    readOnly
                  />
                </div>

                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"8811"} alt="Cantidad de Unidades" />
                  </label>
                  <InputText
                    value={
                      fullHojaRuta?.batea?.tipoVehiculo?.cantidadDeVehiculos
                    }
                    readOnly
                  />
                </div>
              </div>

              <div
                className="colunm"
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  className="sipco-option"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>
                    <LanguageProvider id={"4284"} alt="Batea / Transportín" />
                  </label>
                  <InputText
                    value={fullHojaRuta?.batea?.descripcion}
                    readOnly
                  />
                </div>

                <div
                  className="sipco-option"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <label>
                    <LanguageProvider id={"16875"} alt="Compra flete" />
                  </label>
                  <Checkbox
                    checked={fullHojaRuta?.lineas[0]?.compra}
                    readOnly
                  />
                  <label>
                    <LanguageProvider id={"16876"} alt="Venta flete" />
                  </label>
                  <Checkbox checked={fullHojaRuta?.lineas[0]?.venta} readOnly />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </Panel>
    </div>
  );
}
