import "./CargaEstadoVin.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dropdown } from "primereact/dropdown";
import { format } from "date-fns";

export function CargaEstadoVin() {
  const SCREEN_CODE = "fun0204_carga_estado_vin";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const [selectedRows, setSelectedRows] = useState([]);
  usePageViews();
  const dt = useRef(null);
  const [cargaEstadoVin, setCargaEstadoVin] = useState([]);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  function compareArrays(selectRows: any[], arrayBase: any[]): any[] {
    const result = [];
    if (selectRows.length > 0 && arrayBase.length > 0) {
      selectRows.forEach((row: any) => {
        const vinValue = row.vin;


        const matchingObject = arrayBase.find(
          (item: any) => item?.id === vinValue
        );

        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }
    return result;
  }


  function baja(selectRows: any[], arrayBase: any[]) {
    if (selectRows.length > 0 && arrayBase.length > 0) {
      selectRows.forEach((row: any) => {
        const vinValue = row.vin;
        const estadoVehiculo = row.estadoVehiculo;
  
        const index = arrayBase.findIndex(
          (item: any) => item?.id === vinValue && item?.estadoVehiculo?.descripcion === estadoVehiculo
        );
  
        if (index !== -1) {
          arrayBase.splice(index, 1);
        }
      });
  
      setCargaEstadoVin(arrayBase);
    }
  }
  


  const handleSelectAll = () => {
    setSelectedRows([...cargaEstadoVin]);
  };

  const handleDeselectAll = () => {
    setSelectedRows([]);
  };
  const [filter, setFilter] = useState({
    vin: [],
    estado: [],
  });
  const data = convertDateObjects(reporte?.filas);
  
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];

  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }
  const [estado, setEstado] = useState([]);
  const [loadingEstado, setLoadingEstado] = useState(false);


  async function loadReportData() {
    try {
      setLoading(true);
      if (securityFilters?.securityValues?.country != null && filter.vin !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-estado-vin/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            vin: filter.vin || null
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return {
              ...x,
              vin: x?.id,
              cliente: x?.cliente?.descripcion,
              estadoVehiculo: x?.estadoVehiculo?.descripcion,
              subcuenta: x?.subcuenta?.descripcion,
              calle: x?.stockPlaya?.calle,
              column: x?.stockPlaya?.columna,
              nivel: x?.stockPlaya?.nivel
            };
          });


          setCargaEstadoVin((prevValue) => [...prevValue, ...response]);
        
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsName = [
    { field: "vin", header: "VIN" },
    { field: "cliente", header: "Cliente" },
    { field: "estadoVehiculo", header: "Estado Vehiculo" },
    { field: "subcuenta", header: "Subcuenta" },
    { field: "calle", header: "Calle" },
    { field: "column", header: "Column" },
    { field: "nivel", header: "Nivel" },
  ];

  async function loadEstado() {
    try {
      setLoadingEstado(true);
      if (securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.account != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-estado-vin/estado-vehiculo",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
          }
        );

        if (status === 200) {
          setEstado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstado(false)
    }
    return [];
  }
  const [vin, setVin] = useState(null);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-estado-vin/vin",
          {

            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            vin: {
              id: query
            }
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters.securityValues.country != null &&
        securityFilters.securityValues.account != null &&
        securityFilters.securityValues.subaccount != null) {
        try {
          const estados = await loadEstado();

          setFilter({
            ...filter,
            estado: estados[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  const resetFilter = () => {
    setFilter({
      ...filter,
      vin: [],
      estado: [],
    });

    setCargaEstadoVin([]);
  };


  async function procesar() {
    try {
      setLoading(true);
      if (securityFilters?.securityValues?.country != null && cargaEstadoVin.length > 0) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-estado-vin/update",
          {
            lista: [{
              cliente: compareArrays(selectedRows, cargaEstadoVin).map(x => x?.stockPlaya?.cliente)[0] || null,
              cuenta: compareArrays(selectedRows, cargaEstadoVin).map(x => x?.stockPlaya?.cuenta)[0] || null,
              pais: compareArrays(selectedRows, cargaEstadoVin).map(x => x?.stockPlaya?.pais)[0] || null,
              id: compareArrays(selectedRows, cargaEstadoVin).map(x => x?.id)[0] || null,
            }]

          }
        );
        if (status === 200) {

          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }


  }

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };

  return (
    <div className="carga-estado-vin">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Modificar estado del vin</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  label={LanguageProvider({ id: "16283", alt: "Procesar" })}
                  text
                  onClick={procesar}
                />
                <Button
                  label={LanguageProvider({ id: "750", alt: "Limpiar Grillia" })}
                  text
                  onClick={resetFilter}
                />
                <Button
                  label={LanguageProvider({ id: "26", alt: "Baja" })}
                  text
                  onClick={() => baja(selectedRows, cargaEstadoVin)}
                />

                <Button
                  label={LanguageProvider({ id: "748", alt: "Seleccionar todo" })}
                  text
                  onClick={handleSelectAll}
                />
                <Button
                  label={LanguageProvider({ id: "749", alt: "Deseleccionar todo" })}
                  text
                  onClick={handleDeselectAll}
                />
              </div>
            </div>
          );
        }}
      >

        <div className="filter-options">
          <div className={"sipco-options-line"}>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Estado vehículo" />
              </label>
              <Dropdown
                value={filter.estado}
                options={estado}
                onChange={(e) => setFilter({ ...filter, estado: e.value })}
                loading={loadingEstado}
                filter
                optionLabel="descripcion"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Ingrese el VIN" />
              </label>
              <AutoComplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) =>
                  setFilter({ ...filter, vin: e.value || null })
                }
                field="label"
                dropdown
                forceSelection
              />
            </div>
          </div>
        </div>


        <Panel
          className="sipco-AdmRemitosConcesionarios"
          headerTemplate={(props) => {
            return (
              <div className={props.className} onClick={props.onTogglerClick}>
                <span className={props.titleClassName}></span>
                <div className="action">
                  <ExportButtons
                    dt={dt}
                    data={data}
                    columns={columnsNames}
                    screenName={"AdministracionRemitos"}
                  />
                </div>
              </div>
            );
          }}
        >
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={cargaEstadoVin}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={25}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
            selectionMode="multiple"
            selection={selectedRows}
            onSelectionChange={(e) => setSelectedRows(e.value)}
            metaKeySelection={true}
            dragSelection

          >
            {columnsName.map((colum, index) => {
              if (colum.header === "Actualizo con Fecha") {
                return (
                  <Column
                    key={index}
                    field={colum.field}
                    body={(e) => dateColumnTemplate(e, colum.field)}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              } else {
                return (
                  <Column
                    key={index}
                    field={colum.field}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              }
            })}

          </DataTable>
        </Panel>

      </Panel>
    </div>
  );
}
