import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { LanguageProvider } from "@shared/components/language-provider";
import { MultiSelect } from "primereact/multiselect";
import { PropsTypeCode } from "../PropsTypeCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { format, sub } from "date-fns";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Panel } from "primereact/panel";
import ExportButtons from "@shared/components/Export";
import { Toast } from "primereact/toast";

const CerrarOtAba: React.FC<{ vinData: PropsTypeCode }> = ({ vinData }) => {
  const [reporte, setReporte] = useState(null);

  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const dt = useRef(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const columnsNames = [];
  const filterColumnsIdLectora = [14369, 1491, 1569, 5788, 8380];

  const [filter, setFilter] = useState({
    grupo: null,
    operacion: null,
    vin: null,
    porOt: false,
    listadoOperacion: false,
    observaciones: null,

    // CheckBox Generar
    fechaPendieteGenerarOt: false,
    fechaPriorizacionGenerarOt: false,

    //CheckBox Cerrar
    fechaPendieteCerrarOt: false,
    fechaPriorizacionCerrarOt: false,

    // Fecha Pendiete Desde - Hasta
    fechaPendieteGenerarOtDesde: new Date(),
    fechaPendieteGenerarOtHasta: new Date(),
    fechaPendieteCerrarOtDesde: new Date(),
    fechaPendieteCerrarOtHasta: new Date(),

    // Fecha Priorizacion Desde - Hasta
    fechaPriorizacionGenerarOtDesde: new Date(),
    fechaPriorizacionGenerarOtHasta: new Date(),
    fechaPriorizacionCerrarOtDesde: new Date(),
    fechaPriorizacionCerrarOtHasta: new Date(),
  });


  const [grupo, setGrupo] = useState(null);
  const [loadingGrupo, setLoadingGrupo] = useState(false);

  const [operacion, setOperacion] = useState(null);
  const [loadingOperacion, setLoadingOperacion] = useState(false);

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  const [cerrarOT, setCerrarOT] = useState([]);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/generacio-cierre-ot/vin",
          {
            codPais: vinData?.codPais,
            codCliente: vinData?.codCliente,
            codCuenta: vinData?.codCuenta,
            vin: query,
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }
  async function loadGrupo() {
    try {
      setLoadingGrupo(true);
      const { status, data } = await sipcoAxiosService.post(
        "/generacio-cierre-ot/grupo-operacion",
        {
          codPais: vinData.codPais,
        }
      );
      if (status === 200) {
        data.unshift({ id: 0, descripcion: "TODOS" });
        const response = data.map((x: any) => {
          return {
            label: `${x.descripcion} - (${x.id})`,
          };
        });

        setGrupo(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrupo(false);
    }
  }
  async function loadOperacion() {
    try {
      setLoadingOperacion(true);
      const { status, data } = await sipcoAxiosService.post(
        "/generacio-cierre-ot/operacion",
        {
          codPais: vinData.codPais,
        }
      );
      if (status === 200) {
        data.unshift({ id: 0, label: "TODOS" });
        const response = data.map((x: any) => {
          return {
            label: `${x.label}`,
          };
        });

        setOperacion(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperacion(false);
    }
  }

  useEffect(() => {
    try {
      const init = async () => {
        const grupo = await loadGrupo();
        const operacion = await loadOperacion();

        setFilter({
          ...filter,
          grupo: grupo ? grupo[0] : null,
          operacion: operacion,
        });
      };

      init();
    } catch (error) {
      console.error(error);
    }
  }, [vinData]);

  const data = convertDateObjects(reporte?.filas);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }
  const filterColumnsIdCerrar = [14369, 1491, 3344, 14370, 13706, 5271];
  const columnsNamesCerrar = filterColumnsIds(filterColumnsIdCerrar);

  function getId(sedding: Array<{ id: string | number }>): Array<string | number> {
    return sedding.map(({ id }) => id);
  }

  function getVin(sedding: Array<{ vin: string | number }>): Array<string | number> {
    return sedding.map(({ vin }) => vin);
  }

  const [ctOTFunction, setCTOTFunction] = useState([]);
  const [loadingCerrarOT, setLoadingCerrarOT] = useState(false);
  async function cerrarOtFunction(numeroOt: any[]) {
    try {
      setLoadingCerrarOT(true);
      if (vinData.codPais !== null && vinData.codCuenta !== null && vinData.codCliente !== null && vinData.codSubcuenta !== null) {

        const reportResult = await sipcoAxiosService.post(
          "/generacio-cierre-ot/cerrar-ficticio",
          {
            pais: {
              id: vinData.codPais
            },
            cuenta: {
              id: vinData.codCuenta
            },
            cliente: {
              id: vinData.codCliente
            },
            subcuenta: {
              id: vinData.codSubcuenta
            },
            ordensTrabajo: [
              {
                id: getId(numeroOt)[0] || null,
                vin: {
                  id: getVin(numeroOt)[0] || null
                },
              },
            ],
            observacion: filter.observaciones || null
          }
        );

        if (reportResult) {
          const { status, data } = reportResult;
          if (status === 200 && data) {
            toast.current?.show({
              severity: "success",
              detail: (
                <LanguageProvider
                  id={"36"}
                  alt="Su transacción se realizó con éxito."
                />
              ),
              life: 3000,
            });

            setCTOTFunction(data);
            return data;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCerrarOT(false);
    }
  }

  async function loadReportDataCierres() {
    try {
      setLoading(true);
      if (vinData.codPais !== null && vinData.codCuenta !== null && vinData.codCliente !== null && vinData.codSubcuenta !== null) {

        const reportResult = await sipcoAxiosService.post(
          "/generacio-cierre-ot/cierres",
          {
            pais: {
              id: vinData.codPais
            },
            cuenta: {
              id: vinData.codCuenta
            },
            cliente: {
              id: vinData.codCliente
            },
            subcuenta: {
              id: vinData.codSubcuenta
            },
            vin: filter.vin || null
          }
        );

        if (reportResult) {
          const { status, data } = reportResult;
          if (status === 200 && data) {
            const response = data.map((x) => {
              return {
                ...x,
                id: x?.id,
                vin: x?.vin?.id,
                estado: x?.estado?.descripcion,
                fechaOt: x?.fechaOt?.date,
                usuarioUlt: x?.usuarioUltimaModificacion?.id,
                fechaUlt: x?.fechaUltimaModificacion?.date,
              };
            });

            setCerrarOT(response);
            setReporte(response);
            return response;
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNameCerrarOt = [
    { field: "id", header: "Número OT" },
    { field: "vin", header: "VIN" },
    { field: "estado", header: "Estado" },
    { field: "fechaOt", header: "Fecha generación OT" },
    { field: "usuarioUlt", header: "Usuario Últ. Modificacion" },
    { field: "fechaUlt", header: "Fec. Últ. Modif" },
  ];

  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };
  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    // Verifica se o valor existe e se é um string ou número que representa uma data
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>; // Retorna N/A se o valor for inválido ou vazio
  };

  return (
    <div className="cerrar-ot">
      <Toast ref={toast} position="top-center" />
      <div className="search-action mb-3">
        <Button
          label={LanguageProvider({ id: "9665", alt: "Buscar" })}
          onClick={loadReportDataCierres}
        />
      </div>
      <div className="form  mb-3">
        <div className="form-row">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1799"} alt="Grupo" />
            </label>
            <Dropdown
              value={filter.grupo}
              options={grupo}
              onChange={(e) => setFilter({ ...filter, grupo: e.value || null })}
              optionLabel="label"
              filter
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1569"} alt="Operación" />
            </label>
            <MultiSelect
              value={filter.operacion}
              options={operacion}
              onChange={(e) =>
                setFilter({ ...filter, operacion: e.value || null })
              }
              optionLabel="label"
              display="chip"
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"662"} alt="Ingrese el Vin" />
            </label>
            <AutoComplete
              value={filter.vin}
              suggestions={vin}
              completeMethod={searchVin}
              onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
              field="label"
              dropdown
              forceSelection
            />
          </div>
        </div>
        <div className="form-row">
          <div className="RadioButtonFilter">
            <Checkbox
              checked={filter.fechaPendieteCerrarOt}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPendieteCerrarOt: e.checked,
                });
              }}
              style={{
                justifyContent: "center",
                position: "relative",
              }}
            />
            <label>
              <LanguageProvider id={"11507"} alt="Fecha Pendiente" />
            </label>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"467"} alt="Desde :" />
            </label>
            <Calendar
              value={filter.fechaPendieteCerrarOtDesde}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPendieteCerrarOtDesde: e.value,
                });
              }}
              disabled={!filter.fechaPendieteCerrarOt}
              showIcon
              showButtonBar
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"468"} alt="Hasta :" />
            </label>
            <Calendar
              value={filter.fechaPendieteCerrarOtHasta}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPendieteCerrarOtHasta: e.value,
                });
              }}
              disabled={!filter.fechaPendieteCerrarOt}
              showIcon
              showButtonBar
            />
          </div>
        </div>
        <div className="form-row">
          <div className="RadioButtonFilter">
            <Checkbox
              checked={filter.fechaPriorizacionCerrarOt}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPriorizacionCerrarOt: e.checked,
                });
              }}
              style={{
                justifyContent: "center",
                position: "relative",
              }}
            />
            <label>
              <LanguageProvider id={"19690"} alt="Fecha Priorización" />
            </label>
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"467"} alt="Desde :" />
            </label>
            <Calendar
              value={filter.fechaPriorizacionCerrarOtDesde}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPriorizacionCerrarOtDesde: e.value,
                });
              }}
              disabled={!filter.fechaPriorizacionCerrarOt}
              showIcon
              showTime
              showButtonBar
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"468"} alt="Hasta :" />
            </label>
            <Calendar
              value={filter.fechaPriorizacionCerrarOtHasta}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  fechaPriorizacionCerrarOtHasta: e.value,
                });
              }}
              disabled={!filter.fechaPriorizacionCerrarOt}
              showIcon
              showTime
              showButtonBar
            />
          </div>
        </div>
      </div>


      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}><LanguageProvider id={"14379"} alt="Generación y Cierre de Ordenes de Trabajo" /></span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>

                <Button
                  label={LanguageProvider({ id: "12256", alt: "Desactivar" })}
                  text
                  onClick={() => {
                    if (Array.isArray(selectedRows)) {
                      if (selectedRows) {
                        cerrarOtFunction(selectedRows);
                      } else {
                        console.error("SelectedRows is not exists.");
                      }
                    } else {
                      console.error("selectedRows não é um array válido.");
                    }
                  }}
                />

              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={cerrarOT}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
          selectionMode="multiple"
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
          metaKeySelection={true}
          dragSelection

        >
          {columnsNameCerrarOt.map((colum, index) => {
            if (
              colum.header === "Fec. Últ. Modif" ||
              colum.header === "	Fecha generación OT"
            ) {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  body={(e) => dateColumnTemplate(e, colum.field)}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}


        </DataTable>
      </Panel>



      <div className="form">
        <div className="form-row">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"3625"} alt="Observaciones" />
            </label>
            <InputText
              value={filter.observaciones}
              onChange={(e) => {
                setFilter({
                  ...filter,
                  observaciones: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </div>

      <Divider />



    </div>
  );
};
export default CerrarOtAba;
