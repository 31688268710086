import "./IngressoTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useFilterContext } from "../../../protected-routes";

export function ConIngresoTaller() {
  const SCREEN_CODE = "con0043_ingreso_taller";
  ScreenCodeValue(SCREEN_CODE);

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();
  const dt = useRef(null);
  
  const columns = [];
  const filterLabelId = [327, 467, 468, 1151];
  const filterNames = [];
  const filterColumnsId = [
    5701, 3344, 1491, 2139, 319, 3445, 16492, 16493, 320, 3444, 3443, 3446,
    3447,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    65, 5583, 5044, 54, 1791, 4791, 327, 467, 468, 1151, 1002, 1182, 5701, 3344,
    1491, 2139, 319, 3445, 16492, 16493, 320, 3443, 3444, 3447, 3446, 16531,
  ];

  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }


  const [filter, setFilter] = useState({
    taller: "",
    estados: null,
    fechaDesde: new Date(),
    fechaHasta: new Date(),
  });


  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);

  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const { status, data } = await sipcoAxiosService.post(
        "consulta-ingreso-taller/taller",
        {
          codPais: securityFilters?.securityValues?.country?.id,
          codSubcuenta: securityFilters?.securityValues?.subaccount?.id,
          codCuenta: securityFilters?.securityValues?.account?.id,
        }
      );
      if (status === 200) {
        const response = data.map((x : any) => {
          return {...x , label: `${x.descripcion} - (${x.id})`};
        })
      
        setTaller(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
  }


  const [estados, setEstados] = useState(null);
  const [loadingEstados, setLoadingEstados] = useState(false);

  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const { status, data } = await sipcoAxiosService.post(
        "/consulta-ingreso-taller/estado",
        
         { codPais: securityFilters?.securityValues?.country?.id || null }
          
        
      );
      if (status === 200) {
        data.unshift({ id: 0, descripcionFull: "TODOS", descripcion: "TODOS" });

        const response = data.map((x : any) => {
          return {...x , label: `${x.descripcion} - (${x.id})`};
        })

        setEstados(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }


  useEffect(() => {
    const initialize = async () => {
        try{
          const taller = await loadTaller();
          const estados = await loadEstados();
          
          console.log(estados);

          setFilter({
            ...filter,
            estados: estados[0],
            taller: taller[0],
          })
        }catch(e){
          console.error(e);
        }
    }

    initialize();
  }, [securityFilters.securityValues]);
  const [reporte, setReporte] = useState(null);

  const [loadingReportData, setLoadingReportData] = useState(false);
  async function loadReport() {
    try {
      setLoadingReportData(true);
  
      
      const formatToLocalDate = (date) => {
        if (!date) return null;
        const d = new Date(date);
        return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
      };
  
      
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        taller: taller[0]?.id || null,
        estado: filter.estados?.id || null,
        desde: formatToLocalDate(filter.fechaDesde),
        hasta: formatToLocalDate(filter.fechaHasta),
      };
  
     
      const { status, data } = await sipcoAxiosService.post(
        "/consulta-ingreso-taller/ingreso",
        filters
      );
  
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error("Erro ao carregar o relatório:", error);
    } finally {
      setLoadingReportData(false);
    }
  }
  

  const data = convertDateObjects(reporte?.filas);

  return (
    <div className="ingresso-taller">
      <Filter
        onSearch={loadReport}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>Taller</label>
                <Dropdown 
                  loading={loadingTaller}
                  value={filter.taller}
                  options={taller}
                  onChange={(e) => setFilter({ ...filter, taller: e.value })}
                />
              </div>
              <div className={"sipco-option"}>
                <label>Desde</label>
                <Calendar 
                value={filter.fechaDesde}
                onChange={(e) => setFilter({ ...filter, fechaDesde: e.value })} 
                showIcon showTime 
                />
              </div>
              <div className={"sipco-option"}>
                <label>Hasta</label>
                <Calendar 
                value={filter.fechaHasta}
                onChange={(e) => setFilter({ ...filter, fechaHasta: e.value })} 
                showIcon showTime 
                />
              </div>
              <div className={"sipco-option"}>
                <label>Estados</label>
                <Dropdown 
                  loading={loadingEstados}
                  value={filter.estados}
                  options={estados}
                  onChange={(e) => setFilter({ ...filter, estados: e.value })}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        header="Tiempos de Entrega"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Tiempos de Entrega</span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Tiempos de Entrega"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsNames[0].label} sortable filter />
          <Column field="1" header={columnsNames[1].label} sortable filter />
          <Column field="2" header={columnsNames[2].label} sortable filter />
          <Column field="3" header={columnsNames[3].label} sortable filter />
          <Column field="4" header={columnsNames[4].label} sortable filter />
          <Column field="5" header={columnsNames[5].label} sortable filter />
          <Column field="6" header={columnsNames[6].label} sortable filter />
          <Column field="7" header={columnsNames[7].label} sortable filter />
          <Column field="8" header={columnsNames[8].label} sortable filter />
          <Column field="9" header={columnsNames[9].label} sortable filter />
          <Column field="10" header={columnsNames[10].label} sortable filter />
          <Column field="11" header={columnsNames[11].label} sortable filter />
          <Column field="12" header={columnsNames[12].label} sortable filter />

        </DataTable>
      </Panel>
    </div>
  );
}
export default ConIngresoTaller;
