import "./Colecta.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import useFormattedDate from "../../../hooks/useFormattedDate";
import usePageViews from "../../../hooks/usePageViews";
import useRowStyles from "../../../hooks/useRowStyles";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function Colecta() {
  const SCREEN_CODE = "fun0103_colecta";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);

  const filterLabelId = [662, 5431, 25192, 2622];
  const filterNames = [];
  const filterColumnsId = [
    924, 16453, 3344, 10136, 4285, 10902, 4655, 4655, 3206, 11787, 11787, 16454,
    16454, 16773, 16455, 16456, 16774, 1849, 1850, 5592, 23020, 3625, 5371,
    23097, 25193, 24991, 24992, 54, 1491, 4724, 3184, 16457, 740, 2941, 22927,
    22928, 4399, 16459, 16456, 5271, 164, 504, 3344, 396, 2139, 16460, 16460,
    396, 25194,
  ];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const getRowStyles = (data) => {
    let className = "";

    if (
      selectedReportData &&
      JSON.stringify(selectedReportData) === JSON.stringify(data)
    ) {
      className = "highlight-row";
    }

    if (data.vencido === 1) {
      className += " color-colecta-quitada-del-jumelage ";
    }

    return className.trim();
  };

  const [filter, setFilter] = useState({
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    vin: null,
    hr: null,
    estado: null,
  });

  const [selectedReportData, setSelectedReportData] = useState(null);
  const [selectedChildReportData, setSelectedChildReportData] = useState(null);

  const rowStyles = useRowStyles([selectedChildReportData]);
  const { formatDate, formatDateTime } = useFormattedDate();

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reportData, setReportData] = useState(null);

  const [loadingChildReportData, setLoadingChildReportData] = useState(false);
  const [childReportData, setChildReportData] = useState([]);

  const [hr, setHr] = useState([]);

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post("/colectas/vin", {
          pais: {
            id: securityFilters.securityValues.country.id,
          },
          cuenta: {
            id: securityFilters.securityValues.account.id,
          },
          vin: { id: vin },
        });

        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  const [estado, setEstado] = useState([]);
  const [loadingEstado, setLoadingEstado] = useState(false);
  async function loadEstado() {
    setLoadingEstado(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/colectas/estado",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
          }
        );

        if (status === 200) {
          data.unshift({ id: "TODOS", descripcionFull: "TODOS" });
          setEstado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstado(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters.securityValues.country) {
        try {
          const estados = await loadEstado();
          setFilter({
            ...filter,
            estado: estados[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues.country]);

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/colectas/hoja-ruta",
        {
          pais: { id: securityFilters?.securityValues?.country?.id || null },
          cliente: {
            id: securityFilters?.securityValues?.client?.id || null,
          },
          cuenta: {
            id: securityFilters?.securityValues?.account?.id || null,
          },
          subcuenta: {
            id: securityFilters?.securityValues?.subaccount?.id || null,
          },

          vin: { id: filter?.vin || "" },
          estado: { id: filter?.estado.id || "" },
          hojaRuta: { id: filter?.hr || "" },
          fechaDesde: { date: filter?.fechaDesde || [] },
          fechaHasta: { date: filter?.fechaHasta || [] },
        }
      );

      if (status === 200) {
        setReportData(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  async function loadReportDataDetails() {
    setLoadingChildReportData(true);
    try {
      let filters = {
        pais: { id: securityFilters?.securityValues?.country?.id || null },
        cuenta: {
          id: securityFilters?.securityValues?.account?.id || null,
        },
        hojaRuta: {
          id: selectedReportData.id || "",
        },
      };

      const { status, data } = await sipcoAxiosService.post(
        "/colectas/linea",
        filters
      );

      if (status === 200) {
        setChildReportData(Array.isArray(data) ? data : []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChildReportData(false);
    }
  }

  useEffect(() => {
    if (selectedReportData) {
      loadReportDataDetails();
    }
  }, [selectedReportData]);

  //trazer primeira linha do listado selecionada
  useEffect(() => {
    if (childReportData && childReportData.length > 0) {
      setSelectedChildReportData(childReportData[0]);
    }
  }, [childReportData]);

  return (
    <div className="colecta">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id="662" alt="Ingrese el VIN" />
              </label>
              <AutoComplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                field="label"
                dropdown
                // forceSelection N/A
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"20804"} alt="Estado" />
              </label>
              <Dropdown
                value={filter.estado}
                options={estado}
                onChange={(e) => setFilter({ ...filter, estado: e.value })}
                optionLabel="descripcionFull"
                loading={loadingEstado}
                filter
              />
            </div>

            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id="5431" alt="HR" />
              </label>
              <AutoComplete
                value={filter.hr}
                suggestions={hr}
                onChange={(e) => setFilter({ ...filter, hr: e.value })}
                field="label"
              />
            </div>

            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id="25192" alt="Fecha Despacho Desde" />
              </label>
              <Calendar
                value={filter.fechaDesde}
                onChange={(e) => {
                  setFilter({ ...filter, fechaDesde: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
            <div className={"sipco-option"} style={{ maxWidth: "10%" }}>
              <label>
                <LanguageProvider id="2622" alt="Hasta" />
              </label>
              <Calendar
                value={filter.fechaHasta}
                onChange={(e) => {
                  setFilter({ ...filter, fechaHasta: e.value });
                }}
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="16937" alt="Geracao de Coletas" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={reportData}
                  columns={columnsNames}
                  screenName={"Geracao de Coletas"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          className="dataTable-spacing"
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reportData}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={getRowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          selection={selectedReportData}
        >
          <Column
            field="id"
            header={<LanguageProvider id="924" alt="Hoja de Ruta" />}
            sortable
            filter
          />
          <Column
            field="fechaHojaRuta.date"
            header={<LanguageProvider id="16453" alt="Fecha Hoja de Ruta" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaHojaRuta?.date
                ? formatDateTime(rowData.fechaHojaRuta.date)
                : rowData.fechaHojaRuta || ""
            }
          />
          <Column
            field="estado.descripcion"
            header={<LanguageProvider id="3344" alt="Estado" />}
            sortable
            filter
          />
          <Column
            field="km"
            header={<LanguageProvider id="10136" alt="Km." />}
            sortable
            filter
          />
          <Column
            field="tractor.administrador"
            header={<LanguageProvider id="16295" alt="Tractor" />}
            sortable
            filter
          />
          <Column
            field="batea.administrador"
            header={<LanguageProvider id="10902" alt="Batea" />}
            sortable
            filter
          />
          <Column
            field="chofer.nombre"
            header={<LanguageProvider id="23894" alt="Chofer" />}
            sortable
            filter
          />
          <Column
            field="chofer.apellido"
            header={<LanguageProvider id="23894" alt="Chofer" />}
            sortable
            filter
          />
          <Column
            field="administradorTractor.id"
            header={<LanguageProvider id="11787" alt="Proveedor Tractor" />}
            sortable
            filter
          />
          <Column
            field="administradorTractor.descripcion"
            header={<LanguageProvider id="11787" alt="Proveedor Tractor" />}
            sortable
            filter
          />
          <Column
            field="aforoVenta"
            header={<LanguageProvider id="16455" alt="Aforo Venta" />}
            sortable
            body={(rowData) =>
              rowData.diasVencido ? rowData.diasVencido.toFixed(2) : "0,00"
            }
            filter
          />
          <Column
            field="usuarioUltimaModificacion.id"
            header={<LanguageProvider id="16456" alt="Modificó" />}
            sortable
            filter
          />
          <Column
            field="hojaRutaAnterior"
            header={<LanguageProvider id="16774" alt="Hoja ruta anterior" />}
            sortable
            filter
          />
          <Column
            field="chofer.numeroDocumento"
            header={<LanguageProvider id="23894" alt="Chofer" />}
            sortable
            filter
          />
        </DataTable>

        <div className="tags">
          <Tag
            value={
              <LanguageProvider id="11301" alt="Colecta quitada del Jumelage" />
            }
            className="tag-colecta-quitada-del-jumelage"
          />
        </div>

        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={Array.isArray(childReportData) ? childReportData : []}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingChildReportData}
          selectionMode="single"
          onSelectionChange={(e) => setSelectedChildReportData(e.value)}
          selection={selectedChildReportData}
        >
          <Column
            field="cliente.descripcion"
            header={<LanguageProvider id="54" alt="Cliente" />}
            sortable
            filter
          />
          <Column
            field="vin.id"
            header={<LanguageProvider id="1491" alt="VIN" />}
            sortable
            filter
          />
          <Column
            field="vin.patente"
            header={<LanguageProvider id="4724" alt="Patente" />}
            sortable
            filter
          />
          <Column
            field="remito"
            header={<LanguageProvider id="3184" alt="Remito" />}
            sortable
            filter
          />
          <Column
            field="subcuentaOrigen.descripcion"
            header={<LanguageProvider id="16457" alt="Playa de la Unidad" />}
            sortable
            filter
          />
          <Column
            field="subcuenta.descripcion"
            header={<LanguageProvider id="740" alt="Playa Desino" />}
            sortable
            filter
          />
          <Column
            field="concesionarioP.descripcion"
            header={<LanguageProvider id="2941" alt="Concesionario" />}
            sortable
            filter
          />
          <Column
            field="posicion"
            header={<LanguageProvider id="4399" alt="Posición" />}
            sortable
            filter
          />
          <Column
            field="ordenEntrega"
            header={<LanguageProvider id="16459" alt="Orden entrega" />}
            sortable
            filter
          />
          <Column
            field="usuarioUltimaModificacion.id"
            header={<LanguageProvider id="16456" alt="Modificó" />}
            sortable
            filter
          />
          <Column
            field="fechaUltimaModificacion.date"
            header={<LanguageProvider id="5271" alt="Fec. Últ. Modificación" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaUltimaModificacion?.date
                ? formatDate(rowData.fechaUltimaModificacion.date)
                : rowData.fechaUltimaModificacion || ""
            }
          />
          <Column
            field="estado.id"
            header={<LanguageProvider id="3344" alt="Estado" />}
            sortable
            filter
          />
          <Column
            field="tipoRegistro.descripcion"
            header={<LanguageProvider id="504" alt="Tipo Despacho" />}
            sortable
            filter
          />
          <Column
            field="estado.descripcion"
            header={<LanguageProvider id="3344" alt="Estado" />}
            sortable
            filter
          />
          <Column
            field="vin.marca.descripcion"
            header={<LanguageProvider id="396" alt="Marca" />}
            sortable
            filter
          />
          <Column
            field="vin.modelo.descripcion"
            header={<LanguageProvider id="2139" alt="Modelo" />}
            sortable
            filter
          />
          <Column
            field="contablePuntoRetiro"
            header={<LanguageProvider id="16460" alt="Pto Retiro" />}
            sortable
            filter
          />
          <Column
            field="contablePuntoRetiro"
            header={<LanguageProvider id="16460" alt="Pto Retiro" />}
            sortable
            filter
          />
          <Column
            field="vin.marca.id"
            header={<LanguageProvider id="396" alt="Marca" />}
            sortable
            filter
          />
          <Column
            field="jumelage"
            header={<LanguageProvider id="11307" alt="Jumelage" />}
            sortable
            filter
            body={(rowData) =>
              rowData.jumelage === 0 ? (
                <LanguageProvider id="11143" alt="Vin no usado en Jumelage" />
              ) : rowData.jumelage === 1 ? (
                <LanguageProvider id="11142" alt="Vin usado en jumelage" />
              ) : rowData.jumelage === 2 ? (
                <LanguageProvider id="11144" alt="Vin eliminado del Jumelage" />
              ) : (
                ""
              )
            }
          />
          <Column
            field="algoritmoUbicacionPlaya.descripcion"
            header={<LanguageProvider id="1269" alt="Ubicación" />}
            sortable
            filter
          />
          <Column
            field="tipoTransferencia"
            header={<LanguageProvider id="21377" alt="Tipo de transferencia" />}
            sortable
            filter
          />
        </DataTable>

        <div className="button-container">
          <Button
            label={LanguageProvider({ id: "23824", alt: "Alta" })}
            icon="pi pi-plus-circle"
          />
          <Button
            label={LanguageProvider({ id: "19267", alt: "Modificar" })}
            icon="pi pi-pencil"
          />
          <Button
            label={LanguageProvider({ id: "12899", alt: "Ver" })}
            icon="pi pi-box"
          />
          <Button
            label={LanguageProvider({ id: "2016", alt: "Borrar" })}
            icon="pi pi-times"
          />
          <Button
            label={LanguageProvider({ id: "17038", alt: "Eliminar línea" })}
            icon="pi pi-times"
          />
          <Button
            label={LanguageProvider({ id: "10712", alt: "Confirmar Colecta" })}
            icon="pi pi-arrows-h"
          />
          <Button
            label={LanguageProvider({
              id: "11060",
              alt: "Quitar del Jumelage",
            })}
            icon="pi pi-times-circle"
          />
          <Button
            label={LanguageProvider({
              id: "11061",
              alt: "Agregar al Jumelage",
            })}
            icon="pi pi-check"
          />
          <Button
            label={LanguageProvider({
              id: "6697",
              alt: "Listado",
            })}
            icon="pi pi-file"
            onClick={() => window.open("/listado-colectas", "_blank")}
          />
        </div>
      </Panel>
    </div>
  );
}
export default Colecta;
