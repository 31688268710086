import React, { useEffect, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { RadioButton } from "primereact/radiobutton";
import useAuth from "@shared/AuthContext";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

interface MantenerHojaRutaDialogProps {
  visible: boolean;
  model: any;
  onClose: () => void;
}

const MantenerHojaRutaDialog: React.FC<MantenerHojaRutaDialogProps> = ({
  visible,
  onClose,
  model,
}) => {
  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();
  const { user } = useAuth();

  const [hojaRutaModel, setHojaRutaModel] = useState(null);
  const [lineaHojaRutaModel, setLineaHojaRutaModel] = useState(null);
  const [tipoHojaRuta, setTipoHojaRuta] = useState(
    TipoHojaRuta.DESPACHO_DESDE_STOCK
  );
  const [userBarcode, setUserBarcode] = useState(false);
  const [vinSuggestions, setVinSuggestions] = useState([]);
  const [selectedReportData, setSelectedReportData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [loadingReportData, setLoadingReportData] = useState(false);
  const [txtDescripcionCliente, setTxtDescripcionCliente] = useState("");

  const [estadosHojaRuta, setEstadosHojaRuta] = useState([]);
  const [loadingEstadosHojaRuta, setLoadingEstadosHojaRuta] = useState(false);

  const [disableDropdownBatea, setDisableDropdownBatea] = useState(false);

  const [mensageAlertaVisual, setMensageAlertaVisual] = useState(null);

  const NO_LIMITES_CONFIGURADOS =
    " No tiene configurado los limites para el Tipo de Vehiculo ";

  async function loadEstadosHojaRuta() {
    setLoadingEstadosHojaRuta(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/estado-hoja-ruta",
          {
            codPais: securityFilters.securityValues.country.id,
          }
        );

        if (status === 200) {
          setEstadosHojaRuta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadosHojaRuta(false);
    }
  }
  const [loadingTransportista, setLoadingTransportista] = useState(false);
  const [transportista, setTransportista] = useState([]);
  async function loadTransportista() {
    setLoadingTransportista(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/proveedor",
          {
            codPais: securityFilters.securityValues.country.id,
            codCuenta: securityFilters.securityValues.account.id,
            codSubcuenta: securityFilters.securityValues.subaccount.id,
          }
        );

        if (status === 200) {
          setTransportista(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransportista(false);
    }
  }
  const [loadingTractor, setLoadingTractor] = useState(false);
  const [tractor, setTractor] = useState([]);
  async function loadTractor(transportista: any, fechaDespacho: any) {
    setLoadingTractor(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account &&
        transportista &&
        fechaDespacho
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/tractores",
          {
            codPais: securityFilters.securityValues.country.id,
            codCuenta: securityFilters.securityValues.account.id,
            transportista: transportista,
            fecha: fechaDespacho,
          }
        );

        if (status === 200) {
          setTractor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTractor(false);
    }
  }

  const [
    estadoDocumentacionTractorMessage,
    setEstadoDocumentacionTractorMessage,
  ] = useState(null);
  async function findEstadoDocumentacionTractor(
    tractor: any,
    transportista: any,
    fechaDespacho: any
  ) {
    let bateas = [];
    let choferes = [];
    try {
      if (tractor && transportista && fechaDespacho) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/estado-documentacion-tractor",
          tractor
        );

        if (status === 200) {
          if (data === 2) {
            setEstadoDocumentacionTractorMessage("18632");
          } else {
            setEstadoDocumentacionTractorMessage(null);
          }
          if (tractor != null) {
            if (tractor.tipoVehiculo.tipo === "1") {
              if (!hojaRutaModel.origenWs) {
                setDisableDropdownBatea(false);
              }
              bateas = await loadBatea(tractor, fechaDespacho);
            } else {
              setDisableDropdownBatea(true);
            }
            if (
              !hojaRutaModel.origenWs ||
              (hojaRutaModel.origenWs && hojaRutaModel.chofer != null)
            ) {
              choferes = await loadChofer(transportista, fechaDespacho);
            }
          }
          setHojaRutaModel({
            ...hojaRutaModel,
            batea: bateas[0],
            chofer: choferes[0],
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  const [estadoDocumentacionBateaMessage, setEstadoDocumentacionBateaMessage] =
    useState(null);
  async function findEstadoDocumentacionBatea(batea: any) {
    try {
      if (batea) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/estado-documentacion-batea",
          {
            tipo: "T",
            batea: batea,
          }
        );

        if (status === 200) {
          if (data === 2) {
            setEstadoDocumentacionBateaMessage("18633");
          } else {
            setEstadoDocumentacionBateaMessage(null);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  const [loadingBateaTransportin, setLoadingBateaTransportin] = useState(false);
  const [bateaTransportin, setBateaTransportin] = useState([]);
  async function loadBatea(tractor: any, fechaDespacho: any) {
    setLoadingBateaTransportin(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        tractor &&
        fechaDespacho
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/remolque-batea",
          {
            codPais: securityFilters.securityValues.country.id,
            transporte: tractor,
            fecha: fechaDespacho,
          }
        );

        if (status === 200) {
          setBateaTransportin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBateaTransportin(false);
    }
  }
  const [loadingChofer, setLoadingChofer] = useState(false);
  const [chofer, setChofer] = useState([]);
  async function loadChofer(provedor: any, fechaDespacho: any) {
    setLoadingChofer(true);
    try {
      if (securityFilters?.securityValues?.country && provedor) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/chofer",
          {
            codPais: securityFilters.securityValues.country.id,
            administrador: provedor,
            fecha: fechaDespacho,
          }
        );

        if (status === 200) {
          setChofer(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChofer(false);
    }
  }
  const [loadingTipoServicio, setLoadingTipoServicio] = useState(false);
  const [tipoServicio, setTipoServicio] = useState([]);
  async function loadTipoServicio() {
    setLoadingTipoServicio(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/",
          {
            // codPais: securityFilters.securityValues.country.id,
          }
        );

        if (status === 200) {
          setTipoServicio(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoServicio(false);
    }
  }
  const [loadingTipoViajeRetorno, setLoadingTipoViajeRetorno] = useState(false);
  const [tipoViajeRetorno, setTipoViajeRetorno] = useState([]);
  async function loadTipoViajeRetorno() {
    setLoadingTipoViajeRetorno(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/tipo-viaje-retorno",
          {
            codPais: securityFilters.securityValues.country.id,
          }
        );

        if (status === 200) {
          setTipoViajeRetorno(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTipoViajeRetorno(false);
    }
  }
  const [clientes, setClientes] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);
  async function loadClientes() {
    setLoadingClientes(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/cliente",
          {
            pais: securityFilters.securityValues.country,
          }
        );

        if (status === 200) {
          setClientes(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClientes(false);
    }
  }
  const [estadosLineHojaRuta, setEstadosLineHojaRuta] = useState([]);
  const [loadingEstadosLineHojaRuta, setLoadingEstadosLineHojaRuta] =
    useState(false);
  async function loadEstadosLineHojaRuta() {
    setLoadingEstadosLineHojaRuta(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/estado-linea-hoja-ruta",
          {
            pais: securityFilters.securityValues.country.id,
          }
        );

        if (status === 200) {
          setEstadosLineHojaRuta(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstadosLineHojaRuta(false);
    }
  }
  const [remitos, setRemitos] = useState([]);
  const [loadingRemitos, setLoadingRemitos] = useState(false);
  async function loadRemitos(vin: any) {
    setLoadingRemitos(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.account &&
        vin
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/remito",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: vin,
          }
        );
        if (status === 200) {
          setRemitos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingRemitos(false);
    }
  }
  const [puntosRetiroContable, setPuntosRetiroContable] = useState([]);
  const [loadingPuntosRetiroContable, setLoadingPuntosRetiroContable] =
    useState(false);
  async function loadPuntosRetiroContable(vin: any) {
    setLoadingTipoViajeRetorno(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.account &&
        vin
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/contable-punto-retiro",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: vin,
          }
        );

        if (status === 200) {
          setPuntosRetiroContable(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPuntosRetiroContable(false);
    }
  }
  const [playas, setPlayas] = useState([]);
  const [loadingPlayas, setLoadingPlayas] = useState(false);
  async function loadPlayas() {
    setLoadingPlayas(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/subcuenta",
          {
            usuario: user,
            cuenta: securityFilters.securityValues.account,
          }
        );

        if (status === 200) {
          setPlayas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlayas(false);
    }
  }
  const [concesionarios, setConcesionarios] = useState([]);
  const [loadingConcesionarios, setLoadingConcesionarios] = useState(false);
  async function loadConcesionarios(vin: any) {
    setLoadingConcesionarios(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.account &&
        vin
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/concesionario",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: vin,
          }
        );

        if (status === 200) {
          setConcesionarios(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingConcesionarios(false);
    }
  }
  const [direccionesEntrega, setDireccionesEntrega] = useState([]);
  const [loadingDireccionesEntrega, setLoadingDireccionesEntrega] =
    useState(false);
  async function loadDireccionesEntrega(vin: any, concesionario: any) {
    setLoadingDireccionesEntrega(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.account &&
        vin &&
        concesionario
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/direccion-entrega",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: vin,
            concesionario: concesionario,
          }
        );

        if (status === 200) {
          setDireccionesEntrega(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDireccionesEntrega(false);
    }
  }

  async function obtenerLimites() {
    try {
      let tipoVehiculoTope = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        provedor: hojaRutaModel.administradorTractor,
        tipoVehiculo: null,
      };
      let tipoVehiculo = null;
      if (hojaRutaModel.batea !== null) {
        tipoVehiculo = hojaRutaModel.batea?.tipoVehiculo ?? null;
      } else {
        tipoVehiculo = hojaRutaModel.tractor?.tipoVehiculo ?? null;
      }
      if (tipoVehiculo != null) {
        if (
          tipoVehiculo.id !== 0 &&
          (isNaN(hojaRutaModel.aforoVenta) || hojaRutaModel.aforoVenta < 1)
        ) {
          tipoVehiculoTope.tipoVehiculo = tipoVehiculo;
          await findTipoVehiculoPlayaTope(tipoVehiculoTope);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function findTipoVehiculoPlayaTope(tipoVehiculoPlayaTope: any) {
    try {
      if (tipoVehiculoPlayaTope) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-manual-hoja-ruta/tope",
          tipoVehiculoPlayaTope
        );
        if (status === 200) {
          if (hojaRutaModel.aforoVenta < 1) {
            if (data == null) {
              setMensageAlertaVisual(NO_LIMITES_CONFIGURADOS);
            } else {
              setMensageAlertaVisual(null);
              setHojaRutaModel({
                limiteInferior: data.limiteInferior,
                limiteSuperior: data.limiteSuperior,
              });
            }
          } else {
            setMensageAlertaVisual(null);
            setHojaRutaModel({
              limiteInferior: data.limiteInferior,
              limiteSuperior: data.limiteSuperior,
            });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function cmbTransportistaChange(value: any) {
    try {
      //This function triggers a effect change to fetch the tractor documentation status
      const tractores = await loadTractor(value, hojaRutaModel.fechaDespacho);
      setHojaRutaModel({
        ...hojaRutaModel,
        administradorTractor: value,
        tractor: tractores[0],
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function cmbTractorChange(value: any) {
    //This function triggers a effect change to fetch the tractor documentation status
    try {
      setHojaRutaModel({
        tractor: value,
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function cmbBateaChange(value: any) {
    try {
      setHojaRutaModel({
        batea: value,
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function initialize() {
      if (visible) {
        let tractores = [];
        let bateasTransportin = [];
        const [transportistas, estadosHojaRuta] = await Promise.all([
          loadTransportista(),
          loadEstadosHojaRuta(),
        ]);

        if (transportistas.length > 0) {
          tractores = await loadTractor(
            transportistas[0],
            hojaRutaModel?.fechaDespacho ?? new Date()
          );
        }
        if (model.id != 0) {
          setHojaRutaModel(model);
        } else {
          setHojaRutaModel({
            ...model,
            id: 0,
            administradorTractor: transportistas[0],
            tractor: tractores[0] ?? null,
            bateaTransportin: bateasTransportin[0] ?? null,
            estado: estadosHojaRuta.find((x) => x.id === "1"),
          });
        }
      }
    }
    initialize();
  }, [visible]);

  useEffect(() => {
    if (visible) {
      obtenerLimites();
    }
  }, [hojaRutaModel?.batea, hojaRutaModel?.tractor]);
  useEffect(() => {
    if (visible) {
      findEstadoDocumentacionTractor(
        hojaRutaModel?.tractor,
        hojaRutaModel?.administradorTractor,
        hojaRutaModel?.fechaDespacho
      );
    }
  }, [hojaRutaModel?.tractor?.id]);
  useEffect(() => {
    if (visible) {
      findEstadoDocumentacionBatea(hojaRutaModel?.batea);
    }
  }, [hojaRutaModel?.batea]);

  const isUpdateMode = model?.id > 0;

  return (
    <Dialog
      className="mantener-hoja-ruta-dialog"
      header={`${LanguageProvider({ id: "4301", alt: "Manter Folhas de Rota" })} - ${model?.id > 0 ? LanguageProvider({ id: "19377", alt: "Modificar" }) : LanguageProvider({ id: "23824", alt: "Alta" })}`}
      visible={visible}
      onHide={onClose}
      style={{ width: "50vw" }}
      footer={() => {
        return (
          <div>
            <Button
              label={LanguageProvider({ id: "23826", alt: "Cancelar" })}
              icon="pi pi-times"
              onClick={onClose}
              className="p-button-text"
            />
            <Button
              label={LanguageProvider({ id: "15447", alt: "Confirmar" })}
              icon="pi pi-check"
              onClick={onClose}
              autoFocus
            />
          </div>
        );
      }}
    >
      <TabView>
        <TabPanel header={LanguageProvider({ id: "6693", alt: "Cabecera" })}>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="1342" alt="Fecha Despacho" />
                </label>
                <Calendar
                  value={hojaRutaModel?.fechaDespacho}
                  onChange={(e) =>
                    setHojaRutaModel({
                      ...hojaRutaModel,
                      fechaDespacho: e.value,
                    })
                  }
                  showButtonBar
                  showIcon
                  showTime
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="754" alt="Transportista" />
                </label>
                <Dropdown
                  value={hojaRutaModel?.administradorTractor}
                  options={transportista}
                  onChange={(e) => cmbTransportistaChange(e.value)}
                  optionLabel="descripcion"
                  loading={loadingTransportista}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="3344" alt="Estado" />
                </label>
                <Dropdown
                  value={hojaRutaModel?.estado}
                  options={estadosHojaRuta}
                  onChange={(e) =>
                    setHojaRutaModel({ ...hojaRutaModel, estado: e.value })
                  }
                  optionLabel="descripcion"
                  loading={loadingEstadosHojaRuta}
                  filter
                  disabled={!isUpdateMode}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="4285" alt="Tractor" />
                </label>
                <Dropdown
                  value={hojaRutaModel?.tractor}
                  options={tractor}
                  onChange={cmbTractorChange}
                  optionLabel="descripcion"
                  loading={loadingTractor}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="12305" alt="Nro. Hoja de Ruta Prov." />
                </label>
                <InputText
                  value={hojaRutaModel?.numeroHojaRutaProveedor}
                  onChange={(e) => {
                    setHojaRutaModel({
                      ...hojaRutaModel,
                      numeroHojaRutaProveedor: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="4284" alt="Batea / Transportín" />
                </label>
                <Dropdown
                  value={hojaRutaModel?.batea}
                  options={bateaTransportin}
                  onChange={(e) => cmbBateaChange(e.value)}
                  optionLabel="descripcion"
                  loading={loadingBateaTransportin}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="3150" alt="Total KM:" />
                </label>
                <InputNumber
                  value={hojaRutaModel?.km}
                  onChange={(e) => {
                    setHojaRutaModel({ ...hojaRutaModel, km: e.value });
                  }}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="3141" alt="Chofer" />
                </label>
                <Dropdown
                  value={hojaRutaModel?.chofer}
                  options={chofer}
                  onChange={(e) =>
                    setHojaRutaModel({ ...hojaRutaModel, chofer: e.value })
                  }
                  optionLabel="nombre"
                  itemTemplate={choferTemplate}
                  valueTemplate={choferTemplate}
                  loading={loadingChofer}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className="flex flex-wrap justify-content-center gap-3">
                <div className="flex align-items-center">
                  <Checkbox
                    checked={hojaRutaModel?.chkCargaCombinada}
                    onChange={(e) =>
                      setHojaRutaModel({
                        ...hojaRutaModel,
                        chkCargaCombinada: !hojaRutaModel?.chkCargaCombinada,
                      })
                    }
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                    <LanguageProvider id="5592" alt="Carga Combinada" />
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    checked={hojaRutaModel?.chkVenta}
                    onChange={(e) =>
                      setHojaRutaModel({
                        ...hojaRutaModel,
                        chkVenta: !hojaRutaModel?.chkVenta,
                      })
                    }
                  />
                  <label htmlFor="ingredient2" className="ml-2">
                    <LanguageProvider id="1850" alt="Venta" />
                  </label>
                </div>
                <div className="flex align-items-center">
                  <Checkbox
                    checked={hojaRutaModel?.chkCompra}
                    onChange={(e) =>
                      setHojaRutaModel({
                        ...hojaRutaModel,
                        chkCompra: !hojaRutaModel?.chkCompra,
                      })
                    }
                  />
                  <label htmlFor="ingredient3" className="ml-2">
                    <LanguageProvider id="1849" alt="Compra" />
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="3206" alt="Tipo Servicio" />
                </label>
                <Dropdown
                  value={hojaRutaModel?.tipoServicio}
                  options={tipoServicio}
                  onChange={(e) =>
                    setHojaRutaModel({
                      ...hojaRutaModel,
                      tipoServicio: e.value,
                    })
                  }
                  optionLabel="descripcion"
                  loading={loadingTipoServicio}
                  filter
                  disabled={true}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="4145" alt="Obs." />
                </label>
                <InputText
                  value={hojaRutaModel?.observaciones}
                  onChange={(e) => {
                    setHojaRutaModel({
                      ...hojaRutaModel,
                      observaciones: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="23020" alt="Tipo Viaje Retorno" />
                </label>
                <Dropdown
                  value={hojaRutaModel?.tipoViajeRetorno}
                  options={tipoViajeRetorno}
                  onChange={(e) =>
                    setHojaRutaModel({
                      ...hojaRutaModel,
                      tipoViajeRetorno: e.value,
                    })
                  }
                  optionLabel="descripcion"
                  loading={loadingTipoViajeRetorno}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="16455" alt="Aforo Venta" />
                </label>
                <InputText
                  value={hojaRutaModel?.aforoVenta}
                  onChange={(e) => {
                    setHojaRutaModel({
                      ...hojaRutaModel,
                      aforoVenta: e.target.value,
                    });
                  }}
                  onBlur={obtenerLimites}
                />
              </div>
            </div>
          </div>
          {estadoDocumentacionTractorMessage && (
            <div className="estado-documentacion-label my-3">
              <LanguageProvider id={estadoDocumentacionTractorMessage} alt="" />
            </div>
          )}
          {estadoDocumentacionBateaMessage && (
            <div className="estado-documentacion-label my-3">
              <LanguageProvider id={estadoDocumentacionBateaMessage} alt="" />
            </div>
          )}
        </TabPanel>
        <TabPanel header={LanguageProvider({ id: "6694", alt: "Detalle" })}>
          <Button
            label={LanguageProvider({
              id: "22170",
              alt: "Pedidos Playa (Despacho desde Stock)",
            })}
            onClick={onClose}
            className="mb-3"
          />
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            value={reportData}
            paginator
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            resizableColumns
            removableSort
            filterDisplay="menu"
            loading={loadingReportData}
            selectionMode="single"
            onSelectionChange={(e) => setSelectedReportData(e.value)}
            selection={selectedReportData}
          >
            <Column
              field="cliente.descripcion"
              header={<LanguageProvider id="0" alt="Cliente" />}
              sortable
              filter
            />
            <Column
              field="vin.id"
              header={<LanguageProvider id="1491" alt="VIN" />}
              sortable
              filter
            />
            <Column
              field="modelo.patente"
              header={<LanguageProvider id="4724" alt="Patente" />}
              sortable
              filter
            />
            <Column
              field="remito"
              header={<LanguageProvider id="3184" alt="Remito" />}
              sortable
              filter
            />
            <Column
              field="subcuentaOrigen.descripcion"
              header={<LanguageProvider id="16457" alt="Playa de la Unidad" />}
              sortable
              filter
            />
            <Column
              field="direccionEntregaP.descripcion"
              header={<LanguageProvider id="740" alt="Playa Destino" />}
              sortable
              filter
            />
            <Column
              field="concesionarioP.descripcion"
              header={<LanguageProvider id="2941" alt="Concesionario" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="22927" alt="Playa/Punto Origen" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="22928" alt="Playa/Punto destino" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="4399" alt="Posición" />}
              sortable
              filter
            />
            <Column
              field="ordenEntrega"
              header={<LanguageProvider id="16459" alt="Orden entrega" />}
              sortable
              filter
            />
            <Column
              field="usuarioUltimaModificacion.id"
              header={<LanguageProvider id="id" alt="Modificó" />}
              sortable
              filter
            />
            <Column
              field="fechaUltimaModificacion.date"
              header={
                <LanguageProvider id="5271" alt="Fec. Últ. Modificación" />
              }
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="164" alt="Ciudad" />}
              sortable
              filter
            />
            <Column
              field="tipoRegistro.descripcion"
              header={<LanguageProvider id="504" alt="Tipo Despacho" />}
              sortable
              filter
            />
            <Column
              field="estado.descripcion"
              header={<LanguageProvider id="3344" alt="Estado" />}
              sortable
              filter
            />
            <Column
              field="vin.marca.descripcion"
              header={<LanguageProvider id="396" alt="Marca" />}
              sortable
              filter
            />
            <Column
              field="vin.modelo.descripcion"
              header={<LanguageProvider id="2139" alt="Modelo" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="16460" alt="Pto Retiro" />}
              sortable
              filter
            />
            <Column
              field=""
              header={<LanguageProvider id="16460" alt="Pto Retiro" />}
              sortable
              filter
            />
            <Column
              field="vin.marca.id"
              header={<LanguageProvider id="396" alt="Marca" />}
              sortable
              filter
            />
            <Column
              field="coeficienteVin"
              header={<LanguageProvider id="25194" alt="Coef Vin" />}
              sortable
              filter
            />
          </DataTable>
          <Button
            label={LanguageProvider({ id: "273", alt: "Consulta Vin" })}
            icon="pi pi-times"
            onClick={() => window.open("consulta-vin", "_blank")}
            className="my-3"
          />
          {mensageAlertaVisual && (
            <div className="alerta-visual">
              <LanguageProvider
                id={mensageAlertaVisual}
                alt={mensageAlertaVisual}
              />
            </div>
          )}
          <div className="form">
            <div className="form-row">
              <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                  <RadioButton
                    value={TipoHojaRuta.DESPACHO_DESDE_STOCK}
                    onChange={(e) => setTipoHojaRuta(e.value)}
                    checked={tipoHojaRuta === TipoHojaRuta.DESPACHO_DESDE_STOCK}
                  />
                  <label className="ml-2">
                    <LanguageProvider id="5394" alt="Despacho Desde Stock" />
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    value={TipoHojaRuta.DESPACHO_PATENTEADO}
                    onChange={(e) => setTipoHojaRuta(e.value)}
                    checked={tipoHojaRuta === TipoHojaRuta.DESPACHO_PATENTEADO}
                  />
                  <label className="ml-2">
                    <LanguageProvider id="5396" alt="Despacho Patentado" />
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    value={TipoHojaRuta.DEVOLUCION}
                    onChange={(e) => setTipoHojaRuta(e.value)}
                    checked={tipoHojaRuta === TipoHojaRuta.DEVOLUCION}
                  />
                  <label className="ml-2">
                    <LanguageProvider id="846" alt="Devolución" />
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    value={TipoHojaRuta.VIAJE_TERCERO}
                    onChange={(e) => setTipoHojaRuta(e.value)}
                    checked={tipoHojaRuta === TipoHojaRuta.VIAJE_TERCERO}
                  />
                  <label className="ml-2">
                    <LanguageProvider id="22913" alt="Viaje Tercero" />
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                  <RadioButton
                    value={TipoHojaRuta.IMPORTADO_LLEGADA_BUQUE_CAMION}
                    onChange={(e) => setTipoHojaRuta(e.value)}
                    checked={
                      tipoHojaRuta ===
                      TipoHojaRuta.IMPORTADO_LLEGADA_BUQUE_CAMION
                    }
                  />
                  <label className="ml-2">
                    <LanguageProvider
                      id="5537"
                      alt="Importado (Llegada Buque Camión)"
                    />
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    value={TipoHojaRuta.TRANSFERENCIA_E_PLAYAS_DISP_IMPORTACION}
                    onChange={(e) => setTipoHojaRuta(e.value)}
                    checked={
                      tipoHojaRuta ===
                      TipoHojaRuta.TRANSFERENCIA_E_PLAYAS_DISP_IMPORTACION
                    }
                  />
                  <label className="ml-2">
                    <LanguageProvider
                      id="5395"
                      alt="Transferencia. e/playas - Disp. Importación"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="form my-3">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="662" alt="Ingrese el VIN" />
                </label>
                <AutoComplete
                  value={lineaHojaRutaModel?.vin}
                  onChange={(e) =>
                    setLineaHojaRutaModel({
                      ...lineaHojaRutaModel,
                      vin: e.value,
                    })
                  }
                  suggestions={vinSuggestions}
                  dropdown
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="3750" alt="Cliente" />
                </label>
                {tipoHojaRuta !== TipoHojaRuta.VIAJE_TERCERO ? (
                  <Dropdown
                    value={lineaHojaRutaModel?.vin?.cliente}
                    onChange={(e) =>
                      setLineaHojaRutaModel({
                        ...lineaHojaRutaModel,
                        vin: { ...lineaHojaRutaModel.vin, cliente: e.value },
                      })
                    }
                    options={clientes}
                    loading={loadingClientes}
                    filter
                  />
                ) : (
                  <InputText
                    value={txtDescripcionCliente}
                    onChange={(e) => setTxtDescripcionCliente(e.target.value)}
                  />
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="20804" alt="Estado" />
                </label>
                <Dropdown
                  value={lineaHojaRutaModel?.estado}
                  options={estadosLineHojaRuta}
                  loading={loadingEstadosLineHojaRuta}
                  filter
                  readOnly
                  disabled={tipoHojaRuta === TipoHojaRuta.VIAJE_TERCERO}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="3774" alt="Nro. Remito" />
                </label>
                <Dropdown
                  value={lineaHojaRutaModel?.remito}
                  onChange={(e) =>
                    setLineaHojaRutaModel({
                      ...lineaHojaRutaModel,
                      remito: e.value,
                    })
                  }
                  options={remitos}
                  loading={loadingRemitos}
                  filter
                  disabled={
                    tipoHojaRuta === TipoHojaRuta.VIAJE_TERCERO ||
                    tipoHojaRuta === TipoHojaRuta.DESPACHO_PATENTEADO
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="4724" alt="Patente" />
                </label>
                <InputText
                  value={lineaHojaRutaModel?.vin?.patente}
                  onChange={(e) =>
                    setLineaHojaRutaModel({
                      ...lineaHojaRutaModel,
                      vin: {
                        ...lineaHojaRutaModel.vin,
                        patente: e.target.value,
                      },
                    })
                  }
                  max={8}
                  disabled={tipoHojaRuta === TipoHojaRuta.VIAJE_TERCERO}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="16368" alt="Punto retiro contable" />
                </label>
                <Dropdown
                  value={lineaHojaRutaModel?.contablePuntoRetiro}
                  onChange={(e) =>
                    setLineaHojaRutaModel({
                      ...lineaHojaRutaModel,
                      contablePuntoRetiro: e.value,
                    })
                  }
                  options={puntosRetiroContable}
                  loading={loadingPuntosRetiroContable}
                  filter
                  disabled={
                    tipoHojaRuta !== TipoHojaRuta.IMPORTADO_LLEGADA_BUQUE_CAMION
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="1269" alt="Ubicación" />
                </label>
                <InputText
                  value={lineaHojaRutaModel?.posicion}
                  onChange={(e) =>
                    setLineaHojaRutaModel({
                      ...lineaHojaRutaModel,
                      posicion: e.target.value,
                    })
                  }
                  max={2}
                />
              </div>
              {tipoHojaRuta === TipoHojaRuta.DESPACHO_DESDE_STOCK ||
                tipoHojaRuta === TipoHojaRuta.DESPACHO_PATENTEADO ||
                (tipoHojaRuta ===
                  TipoHojaRuta.TRANSFERENCIA_E_PLAYAS_DISP_IMPORTACION && (
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="22927" alt="Playa/Punto Origen" />
                    </label>
                    <Dropdown
                      value={lineaHojaRutaModel?.subcuentaOrigen}
                      options={playas}
                      loading={loadingPlayas}
                      onChange={(e) =>
                        setLineaHojaRutaModel({
                          ...lineaHojaRutaModel,
                          subcuentaOrigen: e.target.value,
                        })
                      }
                      filter
                    />
                  </div>
                ))}
              {tipoHojaRuta === TipoHojaRuta.VIAJE_TERCERO && (
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id="22927" alt="Playa/Punto Origen" />
                  </label>
                  <Dropdown
                    value={lineaHojaRutaModel?.puntoOrigen}
                    options={playas}
                    loading={loadingPlayas}
                    onChange={(e) =>
                      setLineaHojaRutaModel({
                        ...lineaHojaRutaModel,
                        puntoOrigen: e.target.value,
                      })
                    }
                    filter
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="sipco-option flex flex-wrap justify-content-center gap-3">
                <div className="flex align-items-center">
                  <Checkbox
                    checked={userBarcode}
                    onChange={() => setUserBarcode(!userBarcode)}
                  />
                  <label className="ml-2">
                    <LanguageProvider
                      id="1500"
                      alt="Trabajar con Lectora Cod.Barras"
                    />
                  </label>
                </div>
              </div>
              {tipoHojaRuta === TipoHojaRuta.DESPACHO_DESDE_STOCK ||
                tipoHojaRuta === TipoHojaRuta.DESPACHO_PATENTEADO ||
                (tipoHojaRuta ===
                  TipoHojaRuta.TRANSFERENCIA_E_PLAYAS_DISP_IMPORTACION && (
                  <div className="sipco-option">
                    <label>
                      <LanguageProvider id="22928" alt="Playa/Punto destino" />
                    </label>
                    <Dropdown
                      value={lineaHojaRutaModel?.puntoDestino}
                      options={playas}
                      loading={loadingPlayas}
                      onChange={(e) =>
                        setLineaHojaRutaModel({
                          ...lineaHojaRutaModel,
                          puntoDestino: e.target.value,
                        })
                      }
                      filter
                    />
                  </div>
                ))}
              {tipoHojaRuta === TipoHojaRuta.VIAJE_TERCERO && (
                <div className="sipco-option">
                  <label>
                    <LanguageProvider id="22928" alt="Playa/Punto destino" />
                  </label>
                  <Dropdown
                    value={lineaHojaRutaModel?.puntoDestino}
                    options={playas}
                    loading={loadingPlayas}
                    onChange={(e) =>
                      setLineaHojaRutaModel({
                        ...lineaHojaRutaModel,
                        puntoDestino: e.target.value,
                      })
                    }
                    filter
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="266" alt="Concesionarios" />
                </label>
                <Dropdown
                  value={lineaHojaRutaModel?.concesionarioP}
                  options={concesionarios}
                  loading={loadingConcesionarios}
                  onChange={(e) =>
                    setLineaHojaRutaModel({
                      ...lineaHojaRutaModel,
                      concesionarioP: e.target.value,
                    })
                  }
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id="3069" alt="Dirección" />
                </label>
                <Dropdown
                  value={lineaHojaRutaModel?.direccionEntregaP}
                  options={direccionesEntrega}
                  loading={loadingDireccionesEntrega}
                  onChange={(e) =>
                    setLineaHojaRutaModel({
                      ...lineaHojaRutaModel,
                      direccionEntregaP: e.target.value,
                    })
                  }
                  filter
                />
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>
    </Dialog>
  );
};

export default MantenerHojaRutaDialog;

enum TipoHojaRuta {
  DESPACHO_DESDE_STOCK = 1,
  DESPACHO_PATENTEADO = 2,
  DEVOLUCION = 3,
  VIAJE_TERCERO = 4,
  IMPORTADO_LLEGADA_BUQUE_CAMION = 5,
  TRANSFERENCIA_E_PLAYAS_DISP_IMPORTACION = 6,
}

function choferTemplate(option: any, props?: any) {
  if (option) {
    return (
      <div className="flex align-items-center">
        <div>
          {option.apellido} {option.nombre}
        </div>
      </div>
    );
  }
  return <span>{props.placeholder ?? "Seleccione un Chofer"}</span>;
}
