import "./GeneracionOt.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import { PropsTypeCode, setFolder } from "./PropsTypeCode";
import React, { useEffect, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import CerrarOtAba from "./components/CerrarOTAba";
import { Filter } from "@shared/components/filter/filter";
import GenerarOtAba from "./components/GenerarOtAba";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function GeneracionOt() {
  const SCREEN_CODE = "fun0114_generacion_ot";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const [seachFolder, setSeachFolder] = useState<setFolder>({
    folderZero: false,
    folderOne: false,
  });
  const [tabIndex, setTabIndex] = useState(0);

  function handleTabChange(e) {
    const newIndex = e.index;
    setTabIndex(newIndex);

    if (tabIndex === 0) {
      setSeachFolder({ ...seachFolder, folderOne: false });
    } else if (tabIndex === 1) {
      setSeachFolder({ ...seachFolder, folderZero: false });
    }
  }

  const [filter, setFilter] = useState({
    grupo: null,
    operacion: null,
    vin: null,
    porOt: false,
    listadoOperacion: false,

    // CheckBox Generar
    fechaPendieteGenerarOt: false,
    fechaPriorizacionGenerarOt: false,

    //CheckBox Cerrar
    fechaPendieteCerrarOt: false,
    fechaPriorizacionCerrarOt: false,

    // Fecha Pendiete Desde - Hasta
    fechaPendieteGenerarOtDesde: new Date(),
    fechaPendieteGenerarOtHasta: new Date(),
    fechaPendieteCerrarOtDesde: new Date(),
    fechaPendieteCerrarOtHasta: new Date(),

    // Fecha Priorizacion Desde - Hasta
    fechaPriorizacionGenerarOtDesde: new Date(),
    fechaPriorizacionGenerarOtHasta: new Date(),
    fechaPriorizacionCerrarOtDesde: new Date(),
    fechaPriorizacionCerrarOtHasta: new Date(),
  });



  const data = convertDateObjects(reporte?.filas);

  const valuesData: PropsTypeCode = {
    codPais: securityFilters.securityValues?.country?.id || null,
    codCuenta: securityFilters.securityValues?.account?.id,
    codCliente: securityFilters.securityValues?.client?.id,
    codSubcuenta: securityFilters.securityValues?.subaccount?.id,
    folder: tabIndex,
    setFolder: seachFolder,
  };

  return (
    <div className="generacion-ot">
      <Toast ref={toast} position="top-center" />
      <Filter
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      />
      <Accordion multiple activeIndex={[0, 1]}>
        <AccordionTab
          header={
            <LanguageProvider
              id={"14379"}
              alt="Generación y Cierre de Ordenes de Trabajo"
            />
          }
        >
          <TabView activeIndex={tabIndex} onTabChange={handleTabChange}>
            <TabPanel
              header={<LanguageProvider id={"14357"} alt="Generar OT" />}
            >
              <GenerarOtAba vinData={valuesData} />
            </TabPanel>
            <TabPanel
              header={<LanguageProvider id={"14377"} alt="Cerrar OT" />}
            >
              <CerrarOtAba vinData={valuesData} />
            </TabPanel>
          </TabView>
        </AccordionTab>
      </Accordion>
    </div>
  );
}
export default GeneracionOt;
