import "./mantener-usuarios.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import ModalGeral from "./components/modalgeral";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import ExportButtons from "@shared/components/Export";
import CopiarPermisos from "./components/CopiarPermisos";

export function MantenerUsuarios() {
  const [tiposUsuario, setTiposUsuario] = useState(null);
  const [codUsuario, setCodUsuario] = useState(null);
  const [codApelido, setCodApelido] = useState(null);
  const [codArea, setCodArea] = useState(null);
  const [activeModal, setActiveModal] = useState(false);
  const [codActivo, setCodActivo] = useState(null);
  const [resultFindAllUsers, setResultFindAllUsers] = useState([]);
  const [usuarioSelected, setUsuarioSelected] = useState(null);

  const [optionesActivo, setOptionesActivo] = useState([]);
  const [optionesTipoUsuarios, setOptionesTipoUsuarios] = useState([]);
  const [optionesAreas, setOptionesAreas] = useState([]);
  const [titleModal, setTitleModal] = useState("");

  const [auxSelectedActivo, setAuxSelectedActivo] = useState("");

  usePageViews();
  const SCREEN_CODE = "fun0004_mantener_usuarios";
  ScreenCodeValue(SCREEN_CODE);
  
  const columnsNames = [{id: "TODO", label:"Usuario"}, {id: "TODO", label:"Nombre"}, {id: "TODO", label:"Apelido"}, {id: "TODO", label:"Email"}, {id: "TODO", label:"Tipo usuario"}, {id: "TODO", label:"Activo"}, {id: "TODO", label:"Idioma"}, {id: "TODO", label:"archivo de log"}];
  const columns = [
    { field: "column1", label: "Column 1" },
    { field: "column2", label: "Column 2" },
    { field: "column3", label: "Column 3" },
  ];
  
  const data = [
    { column1: "Row1Value1", column2: "Row1Value2", column3: "Row1Value3" },
    { column1: "Row2Value1", column2: "Row2Value2", column3: "Row2Value3" },
  ];
  const [reporte, setReporte] = useState(null);
    const dt = useRef(null);
    const dataFooter = convertDateObjects(reporte?.filas);

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  //     const userData = localStorage.getItem("loginData")
  //   const authenticatedUser = JSON.parse(userData);
  // console.log(authenticatedUser)

  async function findByUser() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/findByUser",
        {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.id),
            codTipo: tiposUsuario?.id,
            codUsuario,
            codApelido,
            codArea: codArea?.id ? Number(codArea?.id) : null,
            codActivo,
          },
        }
      );
      if (status === 200) {
        setResultFindAllUsers(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findTipoUsuarioService() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/tipoUsuarioService",
        {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.id)
          }
        });
      if (status === 200) {
        setOptionesTipoUsuarios([{ descripcion: "Todos" }, ...data]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function findAreasSistemas() {
    try {
      const { status, data } = await sipcoAxiosService.get(
        "/mantenerUsuario/areaSistemasService",
        {
          params: {
            codPais: Number(securityFilters.securityValues?.country?.id)
          }
        });
      if (status === 200) {
        setOptionesAreas([{ descripcion: "Todos" }, ...data]);
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  console.log(usuarioSelected)

  return (
    <div className="mantener-usuarios">
      <Filter
        onSearch={findByUser}
        securityOptions={{
          account: false,
          client: false,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="organizeHorizontally">
            <div className={"sipco-option-modal"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Tipo" />
              </label>
              <AutoComplete
                completeMethod={() => findTipoUsuarioService()}
                placeholder={"Todos"}
                value={
                  codArea?.descripcion
                    ? `${codArea?.descripcion} (${codArea?.id})`
                    : ""
                }
                suggestions={optionesTipoUsuarios}
                onChange={(e) => {
                  setTiposUsuario(e.value);
                }}
                itemTemplate={(option) => {
                  if (option?.descripcion === "Todos") return "Todos";
                  return (
                    <>
                      {option?.descripcion} ({option?.id})
                    </>
                  );
                }}
                field="descripcion"
                dropdown
              />
            </div>

            <div className={"sipco-option-modal"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Usuario" />
              </label>
              <AutoComplete
                value={codUsuario}
                onChange={(e) => {
                  if (e.value.descripcion === "") return setTiposUsuario(null);
                  setCodUsuario(e.target.value);
                }}
              />
            </div>

            <div className={"sipco-option-modal"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Apelido" />
              </label>
              <AutoComplete
                value={codApelido}
                onChange={(e) => {
                  if (e.value.descripcion === "") return setCodApelido(null);
                  setCodApelido(e.target.value);
                }}
              />
            </div>

            <div className={"sipco-option-modal"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Área" />
              </label>
              <AutoComplete
                completeMethod={() => findAreasSistemas()}
                placeholder={"Todos"}
                value={
                  codArea?.descripcion
                    ? `${codArea?.descripcion} (${codArea?.id})`
                    : ""
                }
                suggestions={optionesAreas}
                onChange={(e) => {
                  if (e.value.descripcion === "Todos") return setCodArea(null);
                  setCodArea(e.value);
                }}
                itemTemplate={(option) => {
                  if (option?.descripcion === "Todos") return "Todos";
                  return (
                    <>
                      {option?.descripcion} ({option?.id})
                    </>
                  );
                }}
                field="descripcion"
                dropdown
              />
            </div>

            <div className={"sipco-option-modal"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Activo" />
              </label>
              <AutoComplete
                completeMethod={() => {
                  setOptionesActivo([
                    { result: "Sí (1)", cod: 1 },
                    { result: "No (0)", cod: 0 },
                    { result: "Todos", cod: null },
                  ]);
                }}
                placeholder={"Todos"}
                value={auxSelectedActivo}
                suggestions={optionesActivo}
                onChange={(e) => {
                  setCodActivo(e.value.cod);
                  setAuxSelectedActivo(e.value.result);
                }}
                field="result"
                dropdown
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>Listado</span>
            </div>
          );
        }}
      >
        <div className="card">
          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            paginator
            scrollable
            scrollHeight="flex"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            resizableColumns
            removableSort
            selection={usuarioSelected}
            value={resultFindAllUsers}
            rowGroupMode="rowspan"
            sortMode="single"
            sortOrder={1}
            tableStyle={{ minWidth: "50rem" }}
            selectionMode="single"
            dataKey="id"
            onSelectionChange={(e) => {
              setUsuarioSelected(e.value)
            }}
            
          >
            <Column
              field="id"
              header={<LanguageProvider id={"TODO"} alt="Usuario" />}
            />
            <Column
              field="nombre"
              header={<LanguageProvider id={"TODO"} alt="Nombre" />}
            />
            <Column
              field="apellido"
              header={<LanguageProvider id={"TODO"} alt="Apelido" />}
            />
            <Column
              field="email"
              header={<LanguageProvider id={"TODO"} alt="email" />}
            />
            <Column
              field="tipo.descripcion"
              header={<LanguageProvider id={"TODO"} alt="Tipo Usuario" />}
            />
            <Column
              field="activo"
              header={<LanguageProvider id={"TODO"} alt="Activo" />}
            />
            <Column
              field="idioma.descripcion"
              header={<LanguageProvider id={"TODO"} alt="Idioma" />}
            />
            <Column
              field="pathLog"
              header={<LanguageProvider id={"TODO"} alt="Archivo de log" />}
            />
          </DataTable>
        </div>
      </Panel>

      <div className="orgHor">
        <div>

       
        <Button 
          label={LanguageProvider({
            id: "TODO",
            alt: "Alta",
          })}
          onClick={() => {
            setTitleModal("Mantener Usuarios - Alta");
            setActiveModal(true);
          }}
          text
        />
        <Button
          label={LanguageProvider({
            id: "TODO",
            alt: "Modificar",
          })}
          onClick={() => {
            if (resultFindAllUsers.length === 0) return alert("selecione um usuario");
            if(!usuarioSelected?.id) return alert("Debe seleccionar al menos un registro de la Grilla.");
            
            setTitleModal("Mantener Usuarios - Modificar");
            setActiveModal(true);
          }}
          text
        />
        <Button
          label={LanguageProvider({
            id: "TODO",
            alt: "Copiar Permisos",
          })}
          onClick={() => {
            if (resultFindAllUsers.length === 0) return alert("selecione um usuario");
            if(!usuarioSelected?.id) return alert("Debe seleccionar al menos un registro de la Grilla.");
            
            setTitleModal("Copiar Permisos");
            setActiveModal(true);
          }}
          text
        />
        <Button
          label={LanguageProvider({
            id: "TODO",
            alt: "Consecionarios",
          })}
          //onClick={}
          text
        />
        <Button
          label={LanguageProvider({
            id: "1146",
            alt: "Marca",
          })}
          //onClick={}
          text
        />
        <Button
          label={LanguageProvider({
            id: "18592",
            alt: "Operaciones",
          })}
          //onClick={}
          text
        />
        <Button
          label={LanguageProvider({
            id: "TODO",
            alt: "Desbloquear",
          })}
          //onClick={}
          text
        />
        <Button
          label={LanguageProvider({
            id: "TODO",
            alt: "Forzar preparado",
          })}
          //onClick={}
          text
        />
         </div>

        <ExportButtons
          dt={dt}
          data={data}
          columns={columnsNames}
          csv={false}
          pdf={false}
          screenName={"Mantener-usuarios"}
        />
      
      </div>

      {/*O MODAL*/}
      {(titleModal === "Mantener Usuarios - Alta" || titleModal === "Mantener Usuarios - Modificar") && (
        <ModalGeral
          title={titleModal}
          setTitleModal={setTitleModal}
          userData={usuarioSelected}
        />
      )}
      {activeModal && titleModal === "Copiar Permisos" && (
        <CopiarPermisos
        titleModal={titleModal}
          userData={usuarioSelected}
          activeModal={activeModal}
          setActiveModal={setActiveModal}
        />
      )}
    </div>
  );
}

export default MantenerUsuarios;
