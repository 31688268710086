import "./AdministracionRemitosFicticio.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dropdown } from "primereact/dropdown";
import { format } from "date-fns";
import { Calendar } from "primereact/calendar";
import { Tag } from "primereact/tag";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import {HttpStatusCode} from "axios";

export function AdministracionRemitosFicticio() {
  const SCREEN_CODE = "fun0162_administracion_remitos_ficticio";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);


  const [filter, setFilter] = useState({
    tipoRemito: null,
    mercado: null,
    capitalYGba: true,
    vin: null,
    letra: null,
    puntoDeVenta: null,
    numero: null,
   estados: null,
    concesionario: null,
    marca: null,
    bloqueoCalidad: null,
    direccion: null,
    modelo: null,

  });
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];
  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  async function loadReportData() {  }

  const columnsNamesTable = [
    { field: "codCompra", header: "Leyenda" },
    { field: "tipoTaller", header: "Leyenda" },
    { field: "descripcion", header: "Estado" },
    { field: "fechaCompra", header: "Concesionario" },
    { field: "codEstado", header: "Código Remito" },
    { field: "codEstado", header: "Fecha generación OT" },
    { field: "codEstado", header: "Fecha Emision" },
    { field: "codEstado", header: "Marca" },
    { field: "codEstado", header: "Modelo" },
    { field: "codEstado", header: "Color" },
  ];

  const [tipoRemito, setTipoRemito] = useState([]);
  const [loadingTipoRemito, setLoadingTipoRemito] = useState(false);

  const [mercado, setMercado] = useState([]);
  const [loadingMercado, setLoadingMercado] = useState(false);

  const [capitalYGba, setCapitalYGba] = useState([]);
  const [loadingCapitalYGba, setLoadingCapitalYGba] = useState(false);

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  const [estados, setEstados] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);

  const [concesionario, setConcesionario] = useState([]);
  const [loadingConcesionario, setLoadingConcesionario] = useState(false);

  const [marcas, setMarcas] = useState([]);
  const [loadingMarcas, setLoadingMarcas] = useState(false);

  const [bloqueoCalidad, setBloqueoCalidad] = useState([]);
  const [loadingBloqueoCalidad, setLoadingBloqueoCalidad] = useState(false);

  const [direccion, setDireccion] = useState([]);
  const [loadingDireccion, setLoadingDireccion] = useState(false);

  const [modelo, setModelo] = useState([]);
  const [loadingModelo, setLoadingModelo] = useState(false);

  async function loadTipoRemito() {
    try {
      setLoadingTipoRemito(true);
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/administracion-remitos-ficticio/tipo-remito",
            {
              pais: securityFilters.securityValues.country
            }
        );
        if (status === 200) {
          setTipoRemito(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoadingTipoRemito(false)
    }
    return [];
  }
  async function loadMercado() {
    try {
      setLoadingMercado(true);
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/administracion-remitos-ficticio/mercado",
            {
              pais: securityFilters.securityValues.country
            }
        );
        if (status === 200) {
          setMercado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoadingMercado(false)
    }
    return [];
  }

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/administracion-remitos-ficticio/vin",
            {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,
              cuenta: securityFilters.securityValues.account,
              vin: {
                id: query,
              },
            }
        );
        if (status === HttpStatusCode.Ok) {
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }
  async function loadEstados() {
    try {
      setLoadingEstados(true);
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/administracion-remitos-ficticio/estado-remito",
            {
              pais: securityFilters.securityValues.country
            }
        );
        if (status === 200) {
          data.unshift({ id: "", descripcion: "TODOS" });
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoadingEstados(false)
    }
    return [];
  }
  async function loadConcesionario() {
    try {
      setLoadingConcesionario(true);
      if (securityFilters?.securityValues?.country != null &&
          securityFilters?.securityValues?.client != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/administracion-remitos-ficticio/concesionario",
            {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client
            }
        );
        if (status === 200) {
          data.unshift({ id: "", descripcion: "TODOS" });
          setConcesionario(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoadingConcesionario(false)
    }
    return [];
  }
  async function loadDireccion() {
    try {
      setLoadingDireccion(true);
      if (securityFilters?.securityValues?.country != null &&
          securityFilters?.securityValues?.client != null && filter.concesionario != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/administracion-remitos-ficticio/direccion-entrega",
            {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,
              concesionario: filter.concesionario
            }

        );
        if (status === 200) {
          data.unshift({ id: "", direccion: "TODOS" });
          setDireccion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoadingDireccion(false)
    }
    return [];
  }
  async function loadMarca() {
    try {
      setLoadingMarcas(true);
      if (securityFilters?.securityValues?.country != null &&
          securityFilters?.securityValues?.client != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/administracion-remitos-ficticio/marca",
            {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client
            }
        );
        if (status === 200) {
          data.unshift({ id: "", descripcion: "TODOS" });
          setMarcas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoadingMarcas(false)
    }
    return [];
  }
  async function loadModelo() {
    try {
      setLoadingModelo(true);
      if (securityFilters?.securityValues?.country != null &&
          securityFilters?.securityValues?.client != null && filter.marca != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/administracion-remitos-ficticio/modelo",
            {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,
              marca: filter.marca,
            }
        );
        if (status === 200) {
          data.unshift({ id: "", descripcion: "TODOS" });
          setModelo(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoadingModelo(false)
    }
    return [];
  }

  async function loadBloqueoCalidad() {
    try {
      setLoadingBloqueoCalidad(true);
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/administracion-remitos-ficticio/bloqueo",
            {
              pais: securityFilters.securityValues.country}
        );
        if (status === 200) {
          data.unshift({ id: "", descripcion: "TODOS" });
          setBloqueoCalidad(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }finally {
      setLoadingBloqueoCalidad(false)
    }
    return [];
  }
  useEffect(() => {
    async function initialize() {
      console.log("initialize");
      if (
          securityFilters.securityValues.country != null &&
          securityFilters.securityValues.client != null &&
          securityFilters.securityValues.subaccount != null &&
          securityFilters.securityValues.account != null) {
        try {
          const tipoRemito = await loadTipoRemito();
          const mercado = await loadMercado();
          const estados = await loadEstados();
          const concesionario = await loadConcesionario();
          const marca = await loadMarca();
          const bloqueoCalidad = await loadBloqueoCalidad();
          setFilter({
            ...filter,
            tipoRemito: tipoRemito[0],
            mercado: mercado[0],
            estados: estados[0],
            concesionario: concesionario[0],
            marca: marca[0],
            bloqueoCalidad: bloqueoCalidad[0],
          });

        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  useEffect(() => {
    async function handleDireccion() {
      if (filter.concesionario != null ) {
        try {
          const direccion = await loadDireccion();
          setFilter({
            ...filter,
            direccion: direccion[0],
          });

        } catch (error) {
          console.error(error);
        }
      }
    }
    handleDireccion();
  }, [filter.concesionario]);

  useEffect(() => {
    async function handleModelo() {
      if (filter.marca != null ) {
        try {
          const modelo = await loadModelo();
          setFilter({
            ...filter,
            modelo: modelo[0],
          });

        } catch (error) {
          console.error(error);
        }
      }
    }
    handleModelo();
  }, [filter.marca]);

  return (
    <div className="administracion-remitos-ficticio">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"23671"} alt="Tipo Venta" />}
        >
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"848"} alt="Tipo de Remito " />
                </label>
                <Dropdown
                    value={filter.tipoRemito}
                    options={tipoRemito}
                    onChange={(e) => setFilter({ ...filter, tipoRemito: e.value })}
                    optionLabel="descripcion"
                    loading={loadingTipoRemito}
                    filter
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                </label>
                <Dropdown
                    value={filter.mercado}
                    options={mercado}
                    onChange={(e) => setFilter({ ...filter, mercado: e.value })}
                    optionLabel="descripcion"
                    loading={loadingMercado}
                    filter
                />
              </div>

              <div className={"sipco-option"}>
                <div className="checkboxSipco">
                  <label>
                    <LanguageProvider id={"8588"} alt="Capital y GBA" />
                  </label>
                  <Checkbox checked />
                </div>
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN" />
                </label>
                <AutoComplete
                    value={filter.vin}
                    suggestions={vin}
                    completeMethod={searchVin}
                    onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
                    field="id"
                    forceSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab
          header={<LanguageProvider id={"22550"} alt="Filtrar por" />}
        >
          <div className="form">
            <div className={"form-row"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"849"} alt="Letra " />
                </label>
                <InputText />
                <label>
                  <LanguageProvider id={"1151"} alt="Estados " />
                </label>
                <Dropdown
                    value={filter.estados}
                    options={estados}
                    onChange={(e) => setFilter({ ...filter, estados: e.value })}
                    optionLabel="descripcion"
                    loading={loadingEstados}
                    filter
                />
                <label>
                  <LanguageProvider id={"1595"} alt="Bloqueo Calidad " />
                </label>
                <Dropdown
                    value={filter.bloqueoCalidad}
                    options={bloqueoCalidad}
                    onChange={(e) => setFilter({ ...filter, bloqueoCalidad: e.value })}
                    optionLabel="descripcion"
                    loading={loadingBloqueoCalidad}
                    filter
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13358"} alt="Punto Venta " />
                </label>
                <InputText />
                <label>
                  <LanguageProvider id={"2941"} alt="Concesionario " />
                </label>
                <Dropdown
                    value={filter.concesionario}
                    options={concesionario}
                    onChange={(e) => setFilter({ ...filter, concesionario: e.value })}
                    optionLabel="descripcion"
                    loading={loadingConcesionario}
                    filter
                />
                <label>
                  <LanguageProvider id={"3069"} alt="Dirección " />
                </label>
                <Dropdown
                    value={filter.direccion}
                    options={direccion}
                    onChange={(e) => setFilter({ ...filter, direccion: e.value })}
                    optionLabel="direccion"
                    loading={loadingDireccion}
                    filter
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <InputText />
                <label>
                  <LanguageProvider id={"23888"} alt="Marca " />
                </label>
                <Dropdown
                    value={filter.marca}
                    options={marcas}
                    onChange={(e) => setFilter({ ...filter, marca: e.value })}
                    optionLabel="descripcion"
                    loading={loadingMarcas}
                    filter
                />
                <label>
                  <LanguageProvider id={"10746"} alt="Modelo " />
                </label>
                <Dropdown
                    value={filter.modelo}
                    options={modelo}
                    onChange={(e) => setFilter({ ...filter, modelo: e.value })}
                    optionLabel="descripcion"
                    loading={loadingModelo}
                    filter
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Ingreso Vines y Daños Por Archivo Texto.
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "24447",
                    alt: "Marcar Ficticio",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "24448",
                    alt: "Desmarcar Ficticio",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.header === "Fecha Compra") {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  body={(e) => colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </DataTable>
        <Divider />

        <div className="form">
          <div className={"form-row"}>
            <div className="sipco-option">
              <Tag
                value={<LanguageProvider id={"4009"} alt="VIN en taller" />}
                style={{ background: "red" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={<LanguageProvider id={"9428"} alt="VIN Indisponible" />}
                style={{ background: "blue" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider
                    id={"9854"}
                    alt="Vehiculos Pendiente Taller"
                  />
                }
                style={{ background: "#DEDE7A" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider id={"11780"} alt="VIN No Nacionalizado" />
                }
                style={{ background: "orange" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider
                    id={"12003"}
                    alt="Vin con 150 dias desde el Ecom"
                  />
                }
                style={{ background: "#91911C" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider
                    id={"13534"}
                    alt="Operaciones bloqueantes y no bloqueantes"
                  />
                }
                style={{ background: "#811C91" }}
              />
            </div>
            <div className="sipco-option">
              <Tag
                value={
                  <LanguageProvider
                    id={"11454"}
                    alt="Liberacion Remito Bloqueada"
                  />
                }
                style={{ background: "green" }}
              />
            </div>
          </div>
        </div>

        <div className="form" style={{ marginTop: "40px" }}>
          <div className={"form-row"}>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"864"} alt="Registros Seleccionados: " />
              </label>
              <InputText />

              <label>
                <LanguageProvider id={"3116"} alt="Cantidad de Registros " />
              </label>
              <Dropdown />
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}
