import "./DespachoTerceros.scss";
import "primeicons/primeicons.css";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { HttpStatusCode } from "axios";
import { Panel } from "primereact/panel";
import ExportButtons from "@shared/components/Export";
import { format } from "date-fns";

export function DespachoTerceros() {

  const SCREEN_CODE = "fun0100_despacho_terceros";
  ScreenCodeValue(SCREEN_CODE);
  const columnsNames = [];
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [listRemitos, setListRemitos] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const toast = useRef<Toast>(null);

  const [despacho, setDespacho] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remito, setRemito] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const [filter, setFilter] = useState({
    vin: null,
    nuevoCliente: null,
    provSaticker: null,
    provLogo: null,
    provDanos: null,
    instaladorRadios: null,
    pasajeGuarda: false,
    fechaDespacho: new Date(),
  });

  function iso8601ToTimestamp(isoDate) {
    const date = new Date(isoDate);
    return date.getTime();
  }
  const [procesar, setProcesar] = useState([]);
  const [loadingProcesar, setLoadingProcesar] = useState(false);
  async function loadProcesarDespachoTerceros() {
    setLoadingProcesar(true);
    try {

      const { status, data } = await sipcoAxiosService.post(
        "/despacho-terceros/procesar-despacho-terceros",
        {
          remitos: compareArrays(selectedRows, [remito]) || null,
          subcuenta: securityFilters.securityValues.subaccount,
          cliente: securityFilters.securityValues.client,
          fechaDespacho: iso8601ToTimestamp(filter.fechaDespacho) || null,
          instaladorRadios: filter.instaladorRadios || null,
          proveedorControlDanos: filter.provDanos || null,
          proveedorLogo: filter.provLogo || null,
          proveedorSticker: filter.provSaticker || null,
        }
      );
      if (status === 200) {
        setProcesar(data);
        return data;
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "No existe la dirección de entrega para el cliente seleccionado",
          life: 3000,
        })
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }

  const [nuevoCliente, setNuevoCliente] = useState([]);
  const [loadingNuevoCliente, setLoadingNuevoCliente] = useState(false);

  async function loadNuevoCliente() {
    setLoadingNuevoCliente(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-terceros/cliente-tercero",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setNuevoCliente(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNuevoCliente(false);
    }
  }

  const [provSaticker, setProvSaticker] = useState([]);
  const [loadingProvSaticker, setLoadingProvSaticker] = useState(false);

  async function loadProvSaticker() {
    setLoadingProvSaticker(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-terceros/proveedor-sticker",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
            cliente: {
              id: securityFilters.securityValues.client.id,
            },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id,
            },
            cuenta: {
              id: securityFilters.securityValues.account.id,
            }
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return { ...x, label: `${x.descripcion}` };
          });
          setProvSaticker(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvSaticker(false);
    }
  }

  const [provLogo, setProvLogo] = useState([]);
  const [loadingProvLogo, setLoadingProvLogo] = useState(false);

  async function loadProvLogo() {
    setLoadingProvLogo(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-terceros/proveedor-logo",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
            cliente: {
              id: securityFilters.securityValues.client.id,
            },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id,
            },
            cuenta: {
              id: securityFilters.securityValues.account.id,
            }
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return { ...x, label: `${x.descripcion}` };
          });
          setProvLogo(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProvLogo(false);
    }
  }

  const [proveedorDanos, setProveedorDanos] = useState([]);
  const [loadingProveedorDanos, setLoadingProveedorDanos] = useState(false);

  async function loadProveedorDanos() {
    setLoadingProveedorDanos(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-terceros/proveedor-danios",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
            cliente: {
              id: securityFilters.securityValues.client.id,
            },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id,
            },
            cuenta: {
              id: securityFilters.securityValues.account.id,
            }
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return { ...x, label: `${x.descripcion}` };
          });
          setProveedorDanos(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedorDanos(false);
    }
  }

  const [instaladorRadios, setInstaladorRadios] = useState([]);
  const [loadingInstaladorRadios, setLoadingInstaladorRadios] = useState(false);

  async function loadInstaladorRadios() {
    setLoadingInstaladorRadios(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-terceros/proveedor-instalador-radios",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
            cliente: {
              id: securityFilters.securityValues.client.id,
            },
            subcuenta: {
              id: securityFilters.securityValues.subaccount.id,
            },
            cuenta: {
              id: securityFilters.securityValues.account.id,
            }
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return { ...x, label: `${x.descripcion}` };
          });
          setInstaladorRadios(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingInstaladorRadios(false);
    }
  }

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-terceros/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            vin: {
              id: query
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });

          console.log("DateLog ", data)
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const nuevoCliente = await loadNuevoCliente();
        const provSaticker = await loadProvSaticker();
        const provLogo = await loadProvLogo();
        const proveedorDanos = await loadProveedorDanos();
        const instaladorRadios = await loadInstaladorRadios();

        setFilter({
          ...filter,
          nuevoCliente: nuevoCliente[0],
          provSaticker: provSaticker[0],
          provLogo: provLogo[0],
          provDanos: proveedorDanos[0],
          instaladorRadios: instaladorRadios[0],
        });
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [securityFilters.securityValues]);

  const table1 = [
    849, 850, 932, 9387, 1284, 1491, 396, 2139, 2140, 2496, 13335, 16923, 2941,
    4216, 3591, 8299, 159, 3186, 440,
  ];

  const columNames = filterColumnsIds(table1);

  function filterColumnsIds(ids) {
    const result = [];
    for (let i = 0; i < ids.length; i++) {
      result.push({
        id: ids[i],
        label: LanguageProvider({
          id: ids[i],
          alt: "Error Columns Labels",
        }),
      });
    }
    return result;
  }

  async function loadReport() {
    setLoading(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/despacho-terceros/remito",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            subcuenta: securityFilters.securityValues.subaccount,
            vin: filter.vin || null,
          }
        );
        if (status === 200) {

          const response = [data].map((x: any) => ({
            letra: x?.letra,
            puntoDeVenta: x?.puntoDeVenta,
            codRemito: x?.id,
            estadoRemito: x?.estadoRemito?.descripcion,
            liberacion: x?.fechaLiberacion?.date,
            vin: x?.vin?.id,
            marca: x?.vin?.marca?.descripcion,
            modelo: x?.vin?.modelo?.descripcion,
            color: x?.vin?.color?.descripcion,
            origen: x?.vin?.origen?.descripcion,
            motor: x?.vin?.numeroMotor,
            codPedidoCliente: x?.idPedidoCliente,
            concessionario: x?.concesionario?.id,
            bocaEntrega: x?.direccionEntrega?.id,
            razonSocial: x?.razonSocialImpresion,
            domicilio: x?.domicilioImpresion,
            provincia: x?.provinciaImpresion,
            localidad: x?.localidadImpresion,
            salida: x?.fechaSalidaReal
          }));

          setRemito(data);
          setDespacho(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }



  const columnsName = [
    { field: "letra", header: "Letra" },
    { field: "puntoDeVenta", header: "Pto. Venda" },
    { field: "codRemito", header: "Cód. Remito" },
    { field: "estadoRemito", header: "Estado Remito" },
    { field: "liberacion", header: "Liberação" },
    { field: "vin", header: "VIN" },
    { field: "marca", header: "Marca" },
    { field: "modelo", header: "Modelo" },
    { field: "color", header: "Cor" },
    { field: "origen", header: "Origem" },
    { field: "motor", header: "Motor" },
    { field: "codPedidoCliente", header: "Cód. Pedido Cliente" },
    { field: "concessionario", header: "Concessionário" },
    { field: "bocaEntrega", header: "Boca Entrega" },
    { field: "razonSocial", header: "Razão Social" },
    { field: "domicilio", header: "Domicílio" },
    { field: "provincia", header: "Província" },
    { field: "localidad", header: "Localidade" },
    { field: "salida", header: "Saída" },
  ];


  const getNestedValue = (obj, key) => {
    if (typeof key !== "string" || key.trim() === "") {
      return null; // Retorna null se a chave não for válida
    }
    return key.split(".").reduce((o, i) => (o ? o[i] : null), obj);
  };

  const dateColumnTemplate = (data, key) => {
    const value = getNestedValue(data, key);
    if (value) {
      const dateValue = new Date(value);

      // Verifica se a data é válida
      if (!isNaN(dateValue.getTime())) {
        const filter = format(dateValue, "dd/MM/yyyy");
        return <td>{filter}</td>;
      }
    }
    return <td>N/A</td>;
  };
  function compareArrays(selectRows: any[], arrayBase: any[]): any[] {
    const result = [];
    if (selectRows.length > 0 && arrayBase.length > 0) {
      selectRows.forEach((row: any) => {
        const vinValue = row.codRemito;


        const matchingObject = arrayBase.find(
          (item: any) => item?.id === vinValue
        );

        if (matchingObject) {
          result.push(matchingObject);
        }
      });
    }
    return result;
  }

  return (
    <div className="despacho-terceros">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReport}
        securityOptions={{
          client: true,
          country: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset legend={<LanguageProvider id={"14821"} alt="Despachos Terceros  " />}>
        <div className="filter-options">
          <div className="sipco-options-line">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"662"} alt="Ingrese el VIN" />
              </label>
              <AutoComplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
                field="label"
                dropdown
                forceSelection
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"4567"} alt="Nuevo Cliente" />
              </label>
              <Dropdown
                value={filter.nuevoCliente}
                options={nuevoCliente}
                optionLabel="label"
                loading={loadingNuevoCliente}
                onChange={(e) =>
                  setFilter({ ...filter, nuevoCliente: e.value })
                }
                filter
                filterBy="label"
              />
            </div>
          </div>
        </div>

        <Panel
          className="sipco-AdmRemitosConcesionarios"
          headerTemplate={(props) => {
            return (
              <div className={props.className} onClick={props.onTogglerClick}>
                <span className={props.titleClassName}></span>
                <div className="action">
                  <ExportButtons
                    dt={dt}
                    data={data}
                    columns={columnsNames}
                    screenName={"AdministracionRemitos"}
                  />
                </div>
              </div>
            );
          }}
        >


          <DataTable
            size={"small"}
            showGridlines
            stripedRows
            ref={dt}
            value={despacho}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={25}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{ maxWidth: "100%" }}
            loading={loading}
            selectionMode="multiple"
            selection={selectedRows}
            onSelectionChange={(e: any) => setSelectedRows(e.value)}
            metaKeySelection={true}
            dragSelection
          >


            {columnsName.map((colum, index) => {
              if (colum.header === "Liberação") {
                return (
                  <Column
                    key={index}
                    field={colum.field}
                    body={(e) => dateColumnTemplate(e, colum.field)}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              } else {
                return (
                  <Column
                    key={index}
                    field={colum.field}
                    header={colum.header}
                    sortable
                    filter
                  />
                );
              }
            })}

          </DataTable>
        </Panel>


        <div className="filter-options-column" style={{ maxWidth: "75%" }}>
          <div className="sipco-options-column">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"1342"} alt="Fecha Despacho" />
              </label>
              <Calendar
                value={filter.fechaDespacho}
                onChange={(e) =>
                  setFilter({ ...filter, fechaDespacho: e.value })
                }
                showButtonBar showIcon showTime />
            </div>
          </div>
          <div className="sipco-options-column">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"1332"} alt="Instalador Radios" />
              </label>
              <Dropdown
                value={filter.instaladorRadios}
                options={instaladorRadios}
                optionLabel="label"
                loading={loadingInstaladorRadios}
                onChange={(e) =>
                  setFilter({ ...filter, instaladorRadios: e.value })
                }
                filter
                filterBy="label"
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"16844"} alt="Proveedor daños" />
              </label>
              <Dropdown
                value={filter.provDanos} 
                options={proveedorDanos}
                optionLabel="label"
                loading={loadingProveedorDanos}
                onChange={(e) =>
                  setFilter({ ...filter, provDanos: e.value })
                }
                filter
                filterBy="label"
              />
            </div>
          </div>
          <div className="sipco-options-column">
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"16947"} alt="Prov. Logo" />
              </label>
              <Dropdown
                value={filter.provLogo}
                options={provLogo}
                optionLabel="label"
                loading={loadingProvLogo}
                onChange={(e) =>
                  setFilter({ ...filter, provLogo: e.value })
                }
                filter
                filterBy="label"
              />
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"16948"} alt="Prov. Sticker" />
              </label>
              <Dropdown
                value={filter.provSaticker}
                options={provSaticker}
                optionLabel="label"
                loading={loadingProvSaticker}
                onChange={(e) =>
                  setFilter({ ...filter, provSaticker: e.value })
                }
                filter
                filterBy="label"
              />
            </div>
          </div>
        </div>
        <div className="filter-options">
          <div className="sipco-options-line">
            <div className="RadioButtonFilter">
              <label>
                <LanguageProvider id={"25074"} alt="Pasaje a Guarda" />
              </label>
              <Checkbox
                value={filter.pasajeGuarda}
                onChange={(e) => setFilter({ ...filter, pasajeGuarda: e.checked })}
                checked={filter.pasajeGuarda}
                disabled={filter.pasajeGuarda} />
            </div>
          </div>
        </div>
        <div className="buttons">
          {/* buttons */}
          <Button
            label={LanguageProvider({
              id: "34",
              alt: "Procesar o guardar cambios",
            })}
            text
            onClick={loadProcesarDespachoTerceros}

          />
        </div>
      </Fieldset>
    </div>
  );
}
export default DespachoTerceros;
