import "./ModificacionVin.scss";
import "primeicons/primeicons.css";

import React, { useRef, useState } from "react";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ProgressSpinner } from "primereact/progressspinner";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import { useInactivoColor } from "../../../hooks/useInactivoColor";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ModificacionVin() {
  const SCREEN_CODE = "fun0046_modificacion_vin";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);

  usePageViews();
  ScreenCodeValue(SCREEN_CODE);

  const [procesarDialog, setProcesarDialog] = useState(false);
  const [confirmProcessDialog, setConfirmProcessDialog] = useState(false);
  const [loadingProcesar, setLoadingProcesar] = useState(false);
  const [fieldsEnabled, setFieldsEnabled] = useState(false);

  const [filter, setFilter] = useState({
    vin: "",
  });

  const [vinAnterior, setVinAnterior] = useState(null);

  const [model, setModel] = useState(null);
  const [loadingModel, setLoadingModel] = useState(false);
  const inactivoColor = useInactivoColor();

  const [marcas, setMarcas] = useState([]);
  const [loadingMarcas, setLoadingMarcas] = useState(false);
  async function loadMarcas() {
    setLoadingMarcas(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/marca",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          setMarcas(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
  }
  const [modelos, setModelos] = useState([]);
  const [loadingModelos, setLoadingModelos] = useState(false);
  async function loadModelos(marca: any) {
    setLoadingModelos(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/modelo",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: marca,
          }
        );
        if (status === 200) {
          setModelos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }
  const [cores, setCores] = useState([]);
  const [loadingCores, setLoadingCores] = useState(false);
  async function loadCores(marca: any) {
    setLoadingCores(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/color",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: marca,
          }
        );
        if (status === 200) {
          setCores(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCores(false);
    }
  }
  const [denoComerciais, setDenoComerciais] = useState([]);
  const [loadingDenoComerciais, setLoadingDenoComerciais] = useState(false);
  async function loadDenoComerciais(marca: any) {
    setLoadingDenoComerciais(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/denominacion-comercial",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: marca,
          }
        );
        if (status === 200) {
          setDenoComerciais(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDenoComerciais(false);
    }
  }
  const [mercados, setMercados] = useState([]);
  const [loadingMercados, setLoadingMercados] = useState(false);
  async function loadMercados() {
    setLoadingMercados(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/mercado",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setMercados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
  }
  const [destinos, setDestinos] = useState([]);
  const [loadingDestinos, setLoadingDestinos] = useState(false);
  async function loadDestinos() {
    setLoadingDestinos(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/destino",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setDestinos(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinos(false);
    }
  }
  const [destinosFrancias, setDestinosFrancias] = useState([]);
  const [loadingDestinosFrancias, setLoadingDestinosFrancias] = useState(false);
  async function loadDestinosFrancias(marca: any, destino: any) {
    setLoadingDestinosFrancias(true);
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.client &&
        destino &&
        marca
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/modificacion-datos-vin/marca-destino",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: marca,
            destino: destino,
          }
        );
        if (status === 200) {
          setDestinosFrancias(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinosFrancias(false);
    }
  }

  const showError = (id: string, alt: string) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: <LanguageProvider id={id} alt={alt} />,
      life: 3000,
    });
  };
  function handleClearFields() {
    setModel(null);
    setFilter({ vin: "" });
  }
  const handleProcesar = (isConfirmed) => {
    setProcesarDialog(false);
    if (isConfirmed) {
      aProcesar();
    }
  };
  const handleConfirmationClose = () => {
    handleClearFields();
    setConfirmProcessDialog(false);
  };
  async function handleVinInputKeyDown(event) {
    setLoadingModel(true);
    try {
      if (event.key === "Enter") {
        if (filter.vin.length >= 8 && filter.vin.length <= 17) {
          handleClearFields();
          await loadVinActivo();
        } else {
          return showError(
            "653",
            "Debe ingresar un minimo de 8 y un maximo de 17 dígitos"
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModel(false);
    }
  }
  async function loadVinActivo() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/modificacion-datos-vin/vin-activo",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          vin: filter.vin,
        }
      );

      if (status === 200) {
        let vin = data[0];
        setVinAnterior(vin);
        if (vin.id.length === 0) {
          setFieldsEnabled(false);
          return showError("1480", "El VIN no existe");
        }
        setFilter({ vin: vin.id });
        const [
          marcas,
          modelos,
          colores,
          denominacionesComerciales,
          mercados,
          destinos,
          destinosFrancia,
        ] = await Promise.all([
          loadMarcas(),
          loadModelos(vin.marca),
          loadCores(vin.marca),
          loadDenoComerciais(vin.marca),
          loadMercados(),
          loadDestinos(),
          loadDestinosFrancias(vin.marca, vin.destino),
        ]);

        const selectedDestino = destinos.find((x) => x.id === vin.destino.id);

        vin.marca = marcas.find((x) => x.id === vin.marca.id);
        vin.modelo = modelos.find((x) => x.id === vin.modelo.id);
        vin.color = colores.find((x) => x.id === vin.color.id);
        vin.denominacionComercial = denominacionesComerciales.find(
          (x) => x.id === vin.denominacionComercial.id
        );
        vin.destinoFrancia = destinosFrancia.find(
          (x) => x.id === vin.destinoFrancia
        );
        vin.mercado = mercados.find((x) => x.id === vin.mercado.id);
        vin.destino = selectedDestino;
        setModel({ ...vin });
        setFieldsEnabled(true);
      }
    } catch (error) {
      console.error(error);
      setFieldsEnabled(false);
      showError("500", "Ocorreu um erro ao carregar o VIN ativo");
    }
  }
  async function aProcesar() {
    setLoadingProcesar(true);
    try {
      const filters = {
        vinAnterior: {
          id: vinAnterior.id,
          pais: vinAnterior.pais,
          cliente: vinAnterior.cliente,
          cuenta: vinAnterior.cuenta,
          patente: vinAnterior.patente,
          fechaPendienteIngresoTaller: vinAnterior.fechaPendienteIngresoTaller,
          numeroMotor: vinAnterior.numeroMotor,
          color: vinAnterior.color,
          marca: vinAnterior.marca,
          modelo: vinAnterior.modelo,
          modeloLargo: vinAnterior.modeloLargo,
          mercado: vinAnterior.mercado,
          origen: vinAnterior.origen,
          destino: vinAnterior.destino,
          descripcionIngresoTaller: vinAnterior.descripcionIngresoTaller,
          fechaBloqueaLiberacionRemito:
            vinAnterior.fechaBloqueaLiberacionRemito,
          descripcionBloqueaLiberacionRemito:
            vinAnterior.descripcionBloqueaLiberacionRemito,
          destinoFranciaFinal: vinAnterior.destinoFranciaFinal,
          denominacionComercial: vinAnterior.denominacionComercial,
          fechaSalidaFabrica: vinAnterior.fechaSalidaFabrica,
          ncm: vinAnterior.ncm,
          codigoPlataforma: vinAnterior.codigoPlataforma,
        },
        vinNovo: {
          id: model.id,
          pais: model.pais,
          cliente: model.cliente,
          cuenta: model.cuenta,
          patente: model.patente,
          fechaPendienteIngresoTaller: model.fechaPendienteIngresoTaller,
          numeroMotor: model.numeroMotor,
          color: model.color,
          marca: model.marca,
          modelo: model.modelo,
          modeloLargo: model.modeloLargo,
          mercado: model.mercado,
          origen: model.origen,
          destino: model.destino,
          descripcionIngresoTaller: model.descripcionIngresoTaller,
          fechaBloqueaLiberacionRemito: model.fechaBloqueaLiberacionRemito,
          descripcionBloqueaLiberacionRemito:
            model.descripcionBloqueaLiberacionRemito,
          destinoFranciaFinal: model.destinoFranciaFinal,
          denominacionComercial: model.denominacionComercial,
          fechaSalidaFabrica: model.fechaSalidaFabrica,
          ncm: model.ncm,
          codigoPlataforma: model.codigoPlataforma,
        },
      };

      const { status } = await sipcoAxiosService.post(
        "/modificacion-datos-vin/update-vin",
        filters
      );
      if (status === 200) {
        setConfirmProcessDialog(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProcesar(false);
    }
  }
  async function handleMarcaChange(value: any) {
    try {
      const [modelos, colores, denoComerciais, destinosFrancia] =
        await Promise.all([
          loadModelos(value),
          loadCores(value),
          loadDenoComerciais(value),
          loadDestinosFrancias(value, model.destino),
        ]);
      setModel({
        ...model,
        marca: value,
        modelo: modelos[0] ?? null,
        color: colores[0] ?? null,
        denominacionComercial: denoComerciais[0] ?? null,
        destinoFrancia: destinosFrancia[0] ?? null,
      });
    } catch (error) {
      console.error(error);
    }
  }
  async function handleDestinoChange(value: any) {
    try {
      const [destinosFrancia] = await Promise.all([
        loadDestinosFrancias(model.marca, value),
      ]);
      setModel({
        ...model,
        destino: value,
        destinoFrancia: destinosFrancia[0] ?? null,
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="modificacion-vin">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      />
      <Panel
        header={<LanguageProvider id={"25195"} alt="Modificación Datos VIN" />}
      >
        <Panel>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"901"} alt="Ingrese el VIN" />
                </label>
                <InputText
                  value={filter.vin}
                  onChange={(e) => {
                    setFilter({ ...filter, vin: e.target.value });
                  }}
                  onKeyDown={handleVinInputKeyDown}
                />
              </div>
            </div>
          </div>
        </Panel>
        <Panel
          header={
            <LanguageProvider id={"906"} alt="Acesse os Dados a Modificar" />
          }
        >
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"23888"} alt="Marca" />
                </label>
                <Dropdown
                  value={model?.marca}
                  options={marcas}
                  onChange={(e) => handleMarcaChange(e.value)}
                  optionLabel="descripcionFull"
                  itemTemplate={inactivoColor}
                  disabled={!fieldsEnabled}
                  loading={loadingMarcas}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                </label>
                <Dropdown
                  value={model?.modelo}
                  options={modelos}
                  onChange={(e) => setModel({ ...model, modelo: e.value })}
                  optionLabel="descripcionFull"
                  itemTemplate={inactivoColor}
                  disabled={!fieldsEnabled || !model?.marca}
                  loading={loadingModelos}
                  filter
                />
              </div>
              <div className="sipco-option">
                <label>Codigo Modelo</label>
                <InputText
                  value={model?.modelo?.id || ""}
                  readOnly={true}
                  disabled={!fieldsEnabled}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13338"} alt="Modelo Largo" />
                </label>
                <InputText
                  value={model?.modeloLargo.charAt(0)}
                  disabled={!fieldsEnabled}
                />
              </div>
              <div className="sipco-option">
                <label>Codigo Modelo Largo</label>
                <InputText
                  value={model?.modeloLargo.slice(1)}
                  disabled={!fieldsEnabled}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2140"} alt="Color" />
                </label>
                <Dropdown
                  value={model?.color}
                  options={cores}
                  onChange={(e) => setModel({ ...model, color: e.value })}
                  optionLabel="descripcionFull"
                  itemTemplate={inactivoColor}
                  disabled={!fieldsEnabled || !model?.marca}
                  loading={loadingCores}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"18202"} alt="Denominación Comercial" />
                </label>
                <Dropdown
                  value={model?.denominacionComercial}
                  options={denoComerciais}
                  onChange={(e) =>
                    setModel({ ...model, denominacionComercial: e.value })
                  }
                  optionLabel="descripcionFull"
                  disabled={!fieldsEnabled || !model?.marca}
                  loading={loadingDenoComerciais}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13335"} alt="Motor" />
                </label>
                <InputText
                  value={model?.numeroMotor}
                  onChange={(e) =>
                    setModel({ ...model, numeroMotor: e.target.value })
                  }
                  disabled={!fieldsEnabled}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4724"} alt="Patente" />
                </label>
                <InputText
                  value={model?.patente}
                  onChange={(e) =>
                    setModel({ ...model, patente: e.target.value })
                  }
                  maxLength={8}
                  disabled={!fieldsEnabled}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                </label>
                <Dropdown
                  value={model?.mercado}
                  options={mercados}
                  onChange={(e) => setModel({ ...model, mercado: e.value })}
                  optionLabel="descripcion"
                  disabled={!fieldsEnabled}
                  loading={loadingMercados}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3410"} alt="Destino" />
                </label>
                <Dropdown
                  value={model?.destino}
                  options={destinos}
                  onChange={(e) => {
                    handleDestinoChange(e.value);
                  }}
                  optionLabel="descripcionFull"
                  disabled={!fieldsEnabled}
                  loading={loadingDestinos}
                  filter
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16274"} alt="Destino Francia" />
                </label>
                <Dropdown
                  value={model?.destinoFrancia}
                  options={destinosFrancias}
                  onChange={(e) => {
                    setModel({ ...model, destinoFrancia: e.value });
                  }}
                  optionLabel="descripcion"
                  disabled={!fieldsEnabled}
                  loading={loadingDestinosFrancias}
                  filter
                  itemTemplate={inactivoColor}
                />
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"12103"} alt="Fecha Fabricación" />
                </label>
                <Calendar
                  value={new Date(model?.fechaSalidaFabrica?.timestamp)}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      fechaSalidaFabrica: {
                        timestamp: e.value.toISOString(),
                        date: e.value.toISOString(),
                      },
                    })
                  }
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={!fieldsEnabled}
                />
              </div>
              <div className="sipco-option">
                <label style={{ display: "block", marginBottom: "5px" }}>
                  <LanguageProvider id={"22209"} alt="Cód. Plataforma" />
                </label>
                <InputText
                  value={model?.codigoPlataforma}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      codigoPlataforma: e.target.value,
                    })
                  }
                  maxLength={13}
                  disabled={!fieldsEnabled}
                />
              </div>
            </div>
          </div>
        </Panel>
        <Panel>
          <Button
            label={LanguageProvider({ id: "16283", alt: "Procesar" })}
            icon="pi pi-print"
            onClick={() => setProcesarDialog(true)}
            disabled={!fieldsEnabled}
          />
        </Panel>
      </Panel>
      <Dialog
        header={<LanguageProvider id="16239" alt="Atención" />}
        visible={procesarDialog}
        style={{ width: "30vw", textAlign: "center" }}
        footer={
          <div style={{ textAlign: "center", marginTop: "1rem" }}>
            <Button
              label={getLabel("10043", "Si")}
              icon="pi pi-check"
              onClick={() => handleProcesar(true)}
              autoFocus
              disabled={loadingProcesar}
            />
            <Button
              label={getLabel("10044", "No")}
              icon="pi pi-times"
              onClick={() => handleProcesar(false)}
              className="p-button-text"
              disabled={loadingProcesar}
            />
          </div>
        }
        onHide={() => setProcesarDialog(false)}
      >
        <p style={{ padding: "1rem 0", margin: "0" }}>
          <LanguageProvider
            id="13275"
            alt="¿Desea procesar los cambios realizados?"
          />
        </p>
        {loadingProcesar && (
          <ProgressSpinner style={{ width: "50px", height: "50px" }} />
        )}
      </Dialog>
      <Dialog
        header={<LanguageProvider id="16239" alt="Atención" />}
        visible={confirmProcessDialog}
        style={{ width: "30vw", textAlign: "center" }}
        footer={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
              label={getLabel("5599", "OK")}
              icon="pi pi-check"
              onClick={handleConfirmationClose}
              autoFocus
            />
          </div>
        }
        onHide={handleConfirmationClose}
      >
        <p style={{ padding: "1rem 0", margin: "0" }}>
          <LanguageProvider
            id="36"
            alt="Su transacción se realizó con éxito."
          />
        </p>
      </Dialog>
    </div>
  );
}
export default ModificacionVin;

const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
