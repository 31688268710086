import "./AdministracionRemitosAConcesionarios.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dropdown } from "primereact/dropdown";

//TODO - parte visual da tela iniciada, aguardando back para finalizar report  e botões de Administración de Remitos - Lucas Silvestre - 23/07/2024
export function AdministracionRemitosAConcesionarios() {
  const SCREEN_CODE = "fun0043_administracion_remitos_a_concesionarios";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [ingreseElVin, setIngreseElVin] = useState([]);
  const [mercados, setMercados] = useState([]);
  const [remitos, setRemitos] = useState([]);

  const [concesionario, setConcesionario] = useState([]);
  const [concesionarioFooter, setConcesionarioFooter] = useState([]);
  const [marca, setMarca] = useState([]);
  const [bloqueoCalidad, setBloqueoCalidad] = useState([]);
  const [direccion, setDireccion] = useState([]);
  const [modelo, setModelo] = useState([]);
  const [transportista, setTransportista] = useState([]);
  const [diasCalculados, setDiasCalculados] = useState([]);
  const [direccionFooter, setDireccionFooter] = useState([]);
  const [filter, setFilter] = useState({
    fecha: null,
    capitalyHBA: false,
    tipoRemito: null,
    letra: null,
    puntoVenta: null,
    numero: null,
    estados: "TODOS",
    concesionario: "TODOS",
    concesionarioFooter: null,
    marca: "TODOS",
    mercados: null,
    marcas: null,
    modelos: null,
    transportista: null,
    colores: null,
    diasCalculados: null,
    direccionFooter: null,  
    familias: null,
    vin: null,
    bloqueoCalidad: "TODOS",
    direccion: null,
    modelo: null,
  });

  const data = convertDateObjects(reporte?.filas);

  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const filterNames = [];
  const filterColumnsId = [
    16757, 16757, 3344, 2941, 932, 1491, 14370, 1492, 396, 2139, 2140, 849, 850,
    1, 4216, 1, 3069, 3591, 8299, 159, 3186, 13338, 16264, 10502, 367, 1, 16758,
    10906, 12103, 1719, 1, 2368, 22446, 4197,
  ];

  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  async function searchRemitos(event: any) {
    await loadRemitos(event.query);
  }
  async function loadRemitos(query = "") {
    try {
      if (
        securityFilters.securityValues.country.id &&
        securityFilters.securityValues.country.id !== null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/tipo-remito",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          if (query) {
            setRemitos(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setRemitos(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Pais Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function searchMercados(event: any) {
    await loadMercados(event.query);
  }
  async function loadMercados(query = "") {
    try {
      if (
        securityFilters.securityValues.country.id &&
        securityFilters.securityValues.country.id !== null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/mercado",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          if (query) {
            setMercados(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setMercados(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Pais Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [estados, setEstados] = useState([]);

  // End-Poin ok
  async function searchEstado(event: any) {
    await loadEstado(event.query);
  }
  async function loadEstado(query = "") {
    try {
      if (
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/estado-remito",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          console.log("data", data);
          if (query) {
            setEstados(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setEstados(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País e Cliente Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }
  // End-Poin ok
  async function searchConcesionario(event: any) {
    await loadConcesionario(event.query);
  }
  async function loadConcesionario(query = "") {
    try {
      if (
        securityFilters?.securityValues?.client &&
        securityFilters?.securityValues?.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/concesionario",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          if (query) {
            setConcesionario(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setConcesionario(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País e Cliente Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  // End-Poin ok
  async function searchMarca(event: any) {
    await loadMarca(event.query);
  }
  async function loadMarca(query = "") {
    try {
      if (
        securityFilters.securityValues.country &&
        securityFilters.securityValues.client
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/marca",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
          }
        );
        if (status === 200) {
          if (query) {
            setMarca(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setMarca(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País e Cliente Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  // End-Poin ok
  async function searchBloqueoCalidad(event: any) {
    await loadBloqueoCalidad(event.query);
  }
  async function loadBloqueoCalidad(query = "") {
    try {
      if (
        securityFilters.securityValues.country.id &&
        securityFilters.securityValues.country.id !== null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/bloqueo-calidad",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          if (query) {
            setBloqueoCalidad(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setBloqueoCalidad(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  // End-Poin ok
  async function searchDireccion(event: any) {
    await loadDireccion(event.query);
  }
  async function loadDireccion(query = "") {
    try {
      if (filter.concesionario === null) {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Concesionario Obrigatorio",
          life: 3000,
        });
        return;
      }
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/direccion-entrega",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            concesionario: filter.concesionario,
          }
        );
        if (status === 200) {
          if (query) {
            setDireccion(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setDireccion(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País e Cliente Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  // End-Poin ok
  async function searchModelo(event: any) {
    await loadModelo(event.query);
  }
  async function loadModelo(query = "") {
    if (filter.marca === null) {
      toast.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Concesionario Obrigatorio",
        life: 3000,
      });
      return;
    }
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/modelo",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            marca: filter.marca,
          }
        );
        if (status === 200) {
          if (query) {
            setModelo(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setModelo(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País e Cliente Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  // End-Poin ok
  async function searchIngreseElVin(event: any) {
    await loadIngreseElVin(event.query);
  }

  async function loadIngreseElVin(query = "") {
    try {
      if (
        securityFilters.securityValues.client &&
        securityFilters.securityValues.account &&
        securityFilters.securityValues.country
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/administracion-remitos-concesionario/vin",
          {
            pais: securityFilters.securityValues.country,
            cliente: securityFilters.securityValues.client,
            cuenta: securityFilters.securityValues.account,
            vin: filter.vin,
          }
        );
        if (status === 200) {
          if (query) {
            setIngreseElVin(
              data.filter((x: any) =>
                x.label.toLowerCase().includes(query.toLowerCase())
              )
            );
          } else {
            setIngreseElVin(data);
          }
        }
      } else {
        toast.current?.show({
          severity: "error",
          summary: "Error",
          detail: "País, Clientes e Cuentas Obrigatorio",
          life: 3000,
        });
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadReportData() {
    setLoading(true);
    try {
      let transacciones = [];
      if (filter.capitalyHBA) {
        transacciones.push("GFC06");
      }

      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-concesionario/vin",
        {
          pais: securityFilters?.securityValues?.country || null,
          cliente: securityFilters?.securityValues?.client || "",
          cuenta: securityFilters?.securityValues?.account || null,
          subcuenta: securityFilters?.securityValues?.subaccount || null,
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  // End-Poin ok
  async function loadDiasCalculados() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-concesionario/dias-calculo-fabricacion",
        {
          pais: securityFilters.securityValues.country,
        }
      );
      if (status === 200) {
        const response = data.map((x: any) => ({
          label: x.id,
        }));

        setTransportista(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadTransportista() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-concesionario/proveedor",
        {
          pais: securityFilters.securityValues.country,
        }
      );
      if (status === 200) {
        const response = data.map((x: any) => ({
          label: x.descripcion,
        }));

        setDiasCalculados(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function loadConcesionarioFooter() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-concesionario/concesionario",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
        }
      );
      if (status === 200) {
        const response = data.map((x: any) => ({
          label: x.descripcion,
        }));



        setDiasCalculados(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  }


  async function loadDireccionFooter() {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/administracion-remitos-concesionario/direccion-entrega",
        {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
        }
      );

      if (status === 200) {
        const response = data.map((x: any) => ({
          label: x.descripcion,
        }));

        setDireccionFooter(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    }
  }

  

  useEffect(() => {
    const init = async () => {
      const dias = await loadDiasCalculados();
      const transportista = await loadTransportista();
      const concesionarioFooter = await loadConcesionarioFooter();
      const direccionFooter = await loadDireccionFooter();

      setFilter({
        ...filter,
        diasCalculados: dias[0],
        transportista: transportista[0],
        concesionarioFooter: concesionarioFooter[0],
        direccionFooter: direccionFooter[0],
      });
    };
    init();
  }, [securityFilters.securityValues]);

  return (
    <div className="administracion-remitos-a-concesionarios">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"16656"} alt="Tipo de venta" />}
        >
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"848"} alt="Tipo de Remito" />
              </label>
              <AutoComplete
                value={filter.tipoRemito}
                suggestions={remitos}
                completeMethod={searchRemitos}
                onChange={(e) => setFilter({ ...filter, tipoRemito: e.value })}
                field="descripcion"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"10774"} alt="Mercados" />
              </label>
              <AutoComplete
                value={filter.mercados}
                suggestions={mercados}
                completeMethod={searchMercados}
                onChange={(e) => setFilter({ ...filter, mercados: e.value })}
                field="descripcion"
                dropdown
              />
            </div>
            <div className="RadioButtonFilter">
              <Checkbox
                checked={filter.capitalyHBA}
                onChange={(e) => {
                  setFilter({ ...filter, capitalyHBA: e.checked });
                }}
                style={{ justifyContent: "center", position: "relative" }}
              />
              <label>
                <LanguageProvider id={"8588"} alt="Capital y GBA" />
              </label>
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Ingrese el Vin" />
              </label>
              <AutoComplete
                value={filter.vin}
                suggestions={ingreseElVin}
                completeMethod={searchIngreseElVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value })}
                field="id"
                dropdown
              />
            </div>
          </div>
        </AccordionTab>

        <AccordionTab
          header={<LanguageProvider id={"7795"} alt="Filtrar Por:" />}
        >
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"6781"} alt="Letra" />
              </label>
              <AutoComplete
                value={filter.letra}
                // suggestions={playas}
                // completeMethod={searchPlayas}
                onChange={(e) => setFilter({ ...filter, letra: e.value })}
                field="label"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"13358"} alt="Punto Venta" />
              </label>
              <AutoComplete
                value={filter.puntoVenta}
                // suggestions={mercados}
                // // completeMethod={searchMercados}
                onChange={(e) => setFilter({ ...filter, puntoVenta: e.value })}
                field="label"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"851"} alt="Número" />
              </label>
              <AutoComplete
                value={filter.mercados}
                // suggestions={mercados}
                // // completeMethod={searchMercados}
                onChange={(e) => setFilter({ ...filter, mercados: e.value })}
                field="label"
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"1151"} alt="Estados" />
              </label>
              <AutoComplete
                value={filter.estados}
                suggestions={estados}
                completeMethod={searchEstado}
                onChange={(e) => setFilter({ ...filter, estados: e.value })}
                field="descripcion"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"2941"} alt="Concesionario" />
              </label>
              <AutoComplete
                value={filter.concesionario}
                suggestions={concesionario}
                completeMethod={searchConcesionario}
                onChange={(e) =>
                  setFilter({ ...filter, concesionario: e.value })
                }
                field="descripcion"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"396"} alt="Marca" />
              </label>
              <AutoComplete
                value={filter.marca}
                suggestions={marca}
                completeMethod={searchMarca}
                onChange={(e) => setFilter({ ...filter, marca: e.value })}
                field="descripcion"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"1595"} alt="Bloqueo Calidad" />
              </label>
              <AutoComplete
                value={filter.bloqueoCalidad}
                suggestions={bloqueoCalidad}
                completeMethod={searchBloqueoCalidad}
                onChange={(e) =>
                  setFilter({ ...filter, bloqueoCalidad: e.value })
                }
                field="descripcion"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"TODO"} alt="Dirección" />
              </label>
              <AutoComplete
                value={filter.direccion}
                suggestions={direccion}
                completeMethod={searchDireccion}
                onChange={(e) => setFilter({ ...filter, direccion: e.value })}
                field="descripcion"
                dropdown
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"10746"} alt="Modelo" />
              </label>
              <AutoComplete
                value={filter.modelo}
                suggestions={modelo}
                completeMethod={searchModelo}
                onChange={(e) => setFilter({ ...filter, modelo: e.value })}
                field="descripcion"
                dropdown
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Administración de Remitos
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              <div className="tags">
                <Tag value="Vin en taller" style={{ background: "#ff0000" }} />
                <Tag
                  value="Vin Indisponible"
                  style={{ background: "#0000db" }}
                />
                <Tag
                  value="Vehivulos Pendiente Taller"
                  style={{ background: "#c0c000" }}
                />
                <Tag
                  value="VIN No Nacionalizado"
                  style={{ background: "#ff8000" }}
                />
                <Tag
                  value="Vin con 150 dias desde el Ecom"
                  style={{ background: "#808000" }}
                />
                <Tag
                  value="Operaciones bloqueantes y no bloqueantes"
                  style={{ background: "#800040" }}
                />
                <Tag
                  value="Liberacion Remito Bloqueada"
                  style={{ background: "#00aa00" }}
                />
              </div>
              <div className="form ">
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"754"} alt="Transportista" />
                    </label>
                    <AutoComplete
                      value={filter.transportista}
                      suggestions={transportista}
                      field="label"
                      dropdown
                      disabled={true}
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"2941"} alt="Concesionario" />
                    </label>
                    <AutoComplete
                      value={filter.concesionarioFooter}
                      suggestions={concesionarioFooter}
                      onChange={(e) =>
                        setFilter({ ...filter, concesionarioFooter: e.value })
                      }
                      field="label"
                      dropdown
                      disabled={true}
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"864"}
                        alt="Registros Seleccionados"
                      />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // suggestions={playas}
                      // completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"3625"} alt="Observaciones" />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // suggestions={playas}
                      // completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                      className="search-action"
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"3069"} alt="Dirección" />
                    </label>
                    <AutoComplete
                      value={filter.direccionFooter}
                      suggestions={direccionFooter}
                      field="label"
                      dropdown
                      disabled={true}
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"2587"}
                        alt="Parametro para fecha Nulo"
                      />
                    </label>
                    <AutoComplete
                      value={filter.diasCalculados}
                      suggestions={diasCalculados}
                      field="label"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="RadioButtonFilter">
                    <Checkbox
                      checked={filter.capitalyHBA}
                      onChange={(e) => {
                        setFilter({ ...filter, capitalyHBA: e.checked });
                      }}
                      style={{ justifyContent: "center", position: "relative" }}
                    />
                    <label>
                      <LanguageProvider id={"25071"} alt="Liberar PSA" />
                    </label>
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider id={"3625"} alt="Observaciones" />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // suggestions={playas}
                      // completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                    />
                  </div>
                  <div className={"sipco-option"}>
                    <label>
                      <LanguageProvider
                        id={"3116"}
                        alt="Cantidad de Registros"
                      />
                    </label>
                    <AutoComplete
                      // value={filter.letra}
                      // suggestions={playas}
                      // completeMethod={searchPlayas}
                      // onChange={(e) => setFilter({ ...filter, letra: e.value })}
                      field="label"
                    />
                  </div>
                </div>
              </div>

              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "1522",
                    alt: "Retener el/los remitos",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "1523",
                    alt: "Liberar el/los remitos",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "25066",
                    alt: "Anulacion de Liberacion",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "27", alt: "Modificar" })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "187",
                    alt: "Imprimir o generar archivo Excel",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columnsNames.map((colum, index) => (
            <Column
              key={index}
              field={colum.id}
              header={colum.label}
              sortable
              filter
            />
          ))}
        </DataTable>
      </Panel>
    </div>
  );
}
export default AdministracionRemitosAConcesionarios;
