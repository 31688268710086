import "./RepararVines.scss";

import React, { useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Calendar } from "primereact/calendar";
import useFormattedDate from "../../../hooks/useFormattedDate";

export function RepararVines() {
  const SCREEN_CODE = "fun0104_reparar_vines";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [selectedReportData, setSelectedReportData] = useState(null);
  const { formatDateTime } = useFormattedDate();

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const selectAll = () => {
    const allRegisters = dt.current.props.value;
    setSelectedReportData(allRegisters);
  };

  const deselectAll = () => {
    setSelectedReportData([]);
  };

  async function validaImpresionRemitos() {
    try {
      if (selectedReportData <= 0) {
        showError("753", "Debe seleccionar al menos un registro de la Grilla.");
        return;
      } else {
        validate();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const [filter, setFilter] = useState({
    vin: null,
  });

  const [model, setModel] = useState({
    fechaReparacion: new Date(),
    preparar: true,
  });

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/reparar-dano/vin",
          {
            pais: { id: securityFilters.securityValues.country.id },
            cliente: { id: securityFilters.securityValues.client.id },
            cuenta: { id: securityFilters.securityValues.account.id },
            vin: { id: vin },
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError("1480", "EL VIN no existe");
          }
          setVin(data);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const { status, data } = await sipcoAxiosService.post(
        "/reparar-dano/historico-danos",
        {
          pais: { id: securityFilters.securityValues.country.id },
          cliente: { id: securityFilters.securityValues.client.id },
          cuenta: { id: securityFilters.securityValues.account.id },
          vin: { id: filter?.vin?.id ?? null },
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  const [isRepararVisible, setIsRepararVisible] = useState(true);
  const [isButtonsVisible, setIsButtonsVisible] = useState(false);

  const handleRepararClick = () => {
    setIsRepararVisible(false);
    setIsButtonsVisible(true);
  };

  async function validate() {
    try {
      if (selectedReportData != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/reparar-dano/validar-reparar",
          {
            daniosReparar: selectedReportData,
          }
        );

        if (status === 200) {
          procesar();
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }

  async function procesar() {
    try {
      console.log("processando");
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/reparar-dano/reparar-danos",
          {
            daniosReparar: selectedReportData,
          }
        );

        if (status === 200) {
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
    return [];
  }
  return (
    <div className="reparar-vines">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          account: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="filter-options">
            <div className={"sipco-options-line"}>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"662"} alt="Ingrese el VIN " />
                </label>
                <AutoComplete
                  value={filter?.vin}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  dropdown
                  maxLength={17}
                  forceSelection
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider
                  id={"12258"}
                  alt="Reparar Vines"
                ></LanguageProvider>
              </span>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          // rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="multiple"
          onSelectionChange={(e) => setSelectedReportData(e.value)}
          selection={selectedReportData}
        >
          <Column
            field="tipoDeDanio.id"
            header={<LanguageProvider id="10540" alt="Codigo Daño" />}
            sortable
            filter
          />
          <Column
            field="tipoDeDanio.descripcion"
            header={<LanguageProvider id="10541" alt="Descripción Daño" />}
            sortable
            filter
          />
          <Column
            field="partesDanios.id"
            header={<LanguageProvider id="2490" alt="Parte" />}
            sortable
            filter
          />
          <Column
            field="partesDanios.descripcion"
            header={<LanguageProvider id="16243" alt="Parte dañada" />}
            sortable
            filter
          />
          <Column
            field="gravedadDeDanio.id"
            header={<LanguageProvider id="16575" alt="Cod. Gravedad" />}
            sortable
            filter
          />
          <Column
            field="gravedadDeDanio.descripcion"
            header={<LanguageProvider id="17007" alt="Gravedad del Daño" />}
            sortable
            filter
          />
          <Column
            field="observacion"
            header={<LanguageProvider id="17007" alt="Observación del Daño" />}
            sortable
            filter
          />
          <Column
            field="fechaMovimientoDanio.date"
            header={<LanguageProvider id="17009" alt="Fecha Movimiento Daño" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaMovimientoDanio?.date
                ? formatDateTime(rowData.fechaMovimientoDanio.date)
                : rowData.fechaMovimientoDanio || ""
            }
          />
          <Column
            field="fechaDanio.date"
            header={<LanguageProvider id="16541" alt="Fecha de carga" />}
            sortable
            filter
            body={(rowData) =>
              rowData.fechaDanio?.date
                ? formatDateTime(rowData.fechaDanio.date)
                : rowData.fechaDanio || ""
            }
          />
          <Column
            field="checkpoint.id"
            header={<LanguageProvider id="21740" alt="Checkpoint" />}
            sortable
            filter
          />
          <Column
            field="checkpoint.descripcion"
            header={<LanguageProvider id="21740" alt="Checkpoint" />}
            sortable
            filter
          />
        </DataTable>
      </Panel>

      <div className="filter-options">
        <div className={"sipco-options-line"}>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"12262"} alt="Fecha Reparación" />
            </label>
            <Calendar
              value={model.fechaReparacion}
              onChange={(e) => {
                setModel({
                  ...model,
                  fechaReparacion: e.value,
                });
              }}
              showButtonBar
              showIcon
              dateFormat="dd/mm/yy"
              disabled={!isButtonsVisible}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        {isRepararVisible && (
          <Button
            label={LanguageProvider({
              id: "12265",
              alt: "Reparar Daños",
            })}
            icon="pi pi-check-circle"
            onClick={handleRepararClick}
          />
        )}
        {isButtonsVisible && (
          <>
            <Button
              label={LanguageProvider({ id: "748", alt: "Seleccionar todo" })}
              icon="pi pi-plus"
              onClick={selectAll}
            />
            <Button
              label={LanguageProvider({ id: "749", alt: "Deseleccionar todo" })}
              icon="pi pi-minus"
              onClick={deselectAll}
            />
            <Button
              label={LanguageProvider({ id: "78", alt: "Deshechar Cambios" })}
              icon="pi pi-times"
              onClick={deselectAll}
            />
            <Button
              label={LanguageProvider({
                id: "34",
                alt: "Procesar o guardar cambios",
              })}
              icon="pi pi-save"
              onClick={validaImpresionRemitos}
            />
          </>
        )}
      </div>
    </div>
  );
}
