import React, { useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import useFilterColumnsIds from "../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Divider } from "primereact/divider";

const Lectora: React.FC<any> = () => {
  const SCREEN_CODE = "fun0202_registracion_operacion_por_ot";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    numeroOt: null,
    fechaRealizacion: new Date(),
    vin: null,
    linea: "",
    resultado: "",
    operacion: '',
    descriptionOperacion: null,
    proveedor: '',
  });

  const filterColumnsIdLectora = [14369, 1491, 1569, 5788, 8380];

  const columNames = useFilterColumnsIds(filterColumnsIdLectora);

  const [loadingVin, setLoadingVin] = useState(false);

  const [numeroOt, setNumeroOt] = useState([]);
  const [loadingNumeroOt, setLoadingNumeroOt] = useState(false);
  const seachNumeroOt = async (event: any) => {
    return await loadNumeroOt(event.query);
  };

  const loadNumeroOt = async (query: any) => {
    try {
      setLoadingNumeroOt(true);
      const { status, data } = await sipcoAxiosService.post(
        "/registracion-operacion-ot/find-ot",
        {
          codPais: securityFilters.securityValues.country.id,
          codCliente: securityFilters.securityValues.client.id,
          codCuenta: securityFilters.securityValues.account.id,
          codOrdenTrabalho: query,
        }
      );

      if (status === 200) {
        const response = data.map((x: any) => {
          return {
            label: `${x?.vin?.id}`,
            linea: x?.lineaOperacion?.descripcion,
            resultado: x?.valor,
          };
        });

        if (response.length > 0) {
          setFilter({
            ...filter,
            vin: response[0].label,
            linea: response[0].linea,
            resultado: response[0].resultado,
          });
        }

        setNumeroOt(response);
        setLoadingVin(true);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNumeroOt(false);
    }
  };

  const [operacion, setOperacion] = useState(null);
  const [loadingOperacion, setLoadingOperacion] = useState(false);

  async function loadOperacion(idOperacion: any) {
    setLoadingOperacion(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-ot/find-op",
          {
            operacion: {
              pais: securityFilters.securityValues.country,
              id: idOperacion,
            },
          }
        );
        if (status === 200) {
          setOperacion(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperacion(false);
    }
  }

  const [proveedor, setProveedor] = useState(null);
  const [loadingProveedor, setLoadingProveedor] = useState(false);
  // Função para carregar as operações baseadas na consulta

  const loadProveedor = async (query: any) => {
    try {
      setLoadingProveedor(true);
      const { status, data } = await sipcoAxiosService.post(
        "/registracion-operacion-ot/find-proveedor",
        {
          proveedor: {
            id: query,
          },
        }
      );

      if (status === 200) {
        setProveedor(data);

      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
  };

  const [linea, setLinea] = useState(null);
  const [loadingLinea, setLoadingLinea] = useState(false);

  const seachLinea = async (event: any) => {
    return await loadProveedor(event.query);
  };

  const loadLinea = async (query: any) => {
    try {
      setLoadingLinea(true);
      const { status, data } = await sipcoAxiosService.post(
        "/registracion-operacion-ot/find-proveedor",
        {
          provedor: query,
        }
      );

      if (status === 200) {
        setLinea(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingLinea(false);
    }
  };

  const resetFilter = () => {
    setFilter({
      ...filter,
      numeroOt: null,
      fechaRealizacion: new Date(),
      vin: null,
      linea: "",
      resultado: "",
      operacion: '',
      descriptionOperacion: null,
      proveedor: '',
    });
  };


  return (
    <div className="registration-operacion-por-ot">
      <div className="search-action mb-3">
        <Button
          label={LanguageProvider({ id: "9665", alt: "Buscar" })}
          onClick={null}
        />
      </div>
      <div className="form mb-5">
        <div className="form-row">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"14369"} alt="Número OT" />
            </label>
            <AutoComplete
              value={filter.numeroOt}
              suggestions={numeroOt}
              completeMethod={seachNumeroOt}
              onChange={(e) =>
                setFilter({ ...filter, numeroOt: e.value || null })
              }
              field="label"
              dropdown
              forceSelection
            />
          </div>
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2781"} alt="Fecha de Realización" />
            </label>
            <Calendar
              value={filter.fechaRealizacion}
              onChange={(e) =>
                setFilter({ ...filter, fechaRealizacion: e.value })
              }
              showButtonBar
              showIcon
              showTime
            />
          </div>
        </div>
        <div className="form-row">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"1491"} alt="VIN" />
            </label>
            <InputText value={filter?.vin} disabled={true} />
          </div>
        </div>
        <div className="form-row align-items-end">
          <div className="sipco-option">
            <label>
              <LanguageProvider id={"1569"} alt="Operación" />
            </label>
            <InputText
              value={filter?.operacion}
              onChange={(e) =>
                setFilter({ ...filter, operacion: e.target.value })
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  loadOperacion(filter.operacion);
                }
              }}
            />
          </div>
          <div className="sipco-option">
            <InputText value={operacion?.descripcion} disabled={true} />
          </div>
        </div>
        <div className="form-row align-items-end">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"2521"} alt="Proveedor" />
            </label>
            <InputText
              value={filter?.proveedor}
              onChange={(e) =>
                setFilter({ ...filter, proveedor: e.target.value })
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  loadOperacion(filter.proveedor);
                }
              }}
            />
          </div>
          <div className={"sipco-option"}>
            <InputText value={proveedor?.descripcion} disabled={true} />
          </div>
        </div>
        <div className="form-row ">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"10828"} alt="Linea" />
            </label>
            <InputText value={filter.linea || ""} disabled={true} />
          </div>
        </div>
        <div className="form-row">
          <div className={"sipco-option"}>
            <label>
              <LanguageProvider id={"8380"} alt="Resultado" />
            </label>
            <InputText value={filter.resultado || ""} disabled={true} />
          </div>
        </div>
      </div>
      <DataTable
        size={"small"}
        showGridlines
        stripedRows
        value={data}
        paginator
        scrollHeight="flex"
        filterDisplay="menu"
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        style={{ maxWidth: "100%" }}
        loading={loading}
      >
        {columNames.map((colum, index) => (
          <Column
            key={index}
            field={colum.id}
            header={colum.label}
            sortable
            filter
          />
        ))}
      </DataTable>

      <Divider />

      <div className="form">
        <div className="form-row">
          <div className={"sipco-option"}>
            <Button
              label={LanguageProvider({
                id: "3155",
                alt: "Cancelar",
              })}
              style={{width: "150px"}}
              text
              onClick={resetFilter}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Lectora;
