import "./IngresoMovManuales.scss";

import React, { useEffect, useRef, useState } from "react";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import useRowStyles from "../../../hooks/useRowStyles";
import useFormattedDate from "../../../hooks/useFormattedDate";

export function IngresoMovManuales() {
  const SCREEN_CODE = "fun0222_ingreso_mov_manuales";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const [selectedReportData, setSelectedReportData] = useState(null);
  const rowStyles = useRowStyles([selectedReportData]);
  const [validationDatosDialog, setValidationDatosDialog] = useState(false);

  const [filter, setFilter] = useState({
    movimientoFacturar: null,
  });

  async function procesarClick() {
    try {
      if (selectedReportData <= 0) {
        setValidationDatosDialog(true);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleValidationClose = () => {
    setValidationDatosDialog(false);
  };

  const [movimientoFacturar, setMovimientoFacturar] = useState([]);
  const [loadingMovFacturar, setLoadingMovFacturar] = useState(false);
  async function loadMovimientoFacturar() {
    setLoadingMovFacturar(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-mov-manuales/movimientos-facturar",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
          }
        );

        if (status === 200) {
          setMovimientoFacturar(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMovFacturar(false);
    }
    setMovimientoFacturar([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const movimientosFacturar = await loadMovimientoFacturar();
          if (movimientosFacturar.length > 0) {
            setFilter((prevFilter) => ({
              ...prevFilter,
              movimientoFacturar:
                movimientosFacturar?.length > 0 ? movimientosFacturar[0] : null,
            }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const fileInfo = { name: `file-${Math.floor(Math.random() * 1000)}.csv` };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-mov-manuales/parse",
        {
          pais: {
            id: securityFilters?.securityValues?.country?.id || null,
          },
          cliente: {
            id: securityFilters?.securityValues?.client?.id || null,
          },
          cuenta: {
            id: securityFilters?.securityValues?.account?.id || null,
          },
          subcuenta: {
            id: securityFilters?.securityValues?.subaccount?.id || null,
          },
          movimiento: {
            id: null,
          },
          files: [fileInfo],
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  return (
    <div className="ingreso-mov-manuales">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>
      <Fieldset
        legend={
          <LanguageProvider id={"22173"} alt="Ingreso movimientos manuales" />
        }
      >
        <div className="form">
          <div className={"form-row"}>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"22098"} alt="Movimiento a Facturar" />
              </label>
              <Dropdown
                value={filter.movimientoFacturar}
                options={movimientoFacturar}
                onChange={(e) =>
                  setFilter({ ...filter, movimientoFacturar: e.value })
                }
                optionLabel="descripcionFull"
                loading={loadingMovFacturar}
                filter
              />
            </div>
            <div className={"sipco-option-btn"}>
              <Button
                label={LanguageProvider({
                  id: "2366",
                  alt: "Importar Archivo",
                })}
                icon="pi pi-upload"
              />
            </div>
          </div>
        </div>
      </Fieldset>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                {
                  <LanguageProvider
                    id="22173"
                    alt="Ingreso movimientos manuales"
                  ></LanguageProvider>
                }
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={null}
                  screenName={"Ingreso movimientos manuales"}
                  pdf={false}
                  xls={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          // rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          <Column
            field=""
            header={<LanguageProvider id="1491" alt="VIN"></LanguageProvider>}
            sortable
            filter
          />
          <Column
            field=""
            header={
              <LanguageProvider
                id="4589"
                alt="Fecha Movimiento"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field=""
            header={
              <LanguageProvider
                id="10871"
                alt="Cod. Concesionario"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field=""
            header={
              <LanguageProvider
                id="2941"
                alt="Concesionario"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field=""
            header={
              <LanguageProvider id="2639" alt="Movimiento"></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field=""
            header={<LanguageProvider id="13" alt="Error"></LanguageProvider>}
            sortable
            filter
          />
        </DataTable>
      </Panel>

      <div className="tags">
        <Tag
          value={
            <LanguageProvider
              id="6056"
              alt="Registros Erroneos"
            ></LanguageProvider>
          }
          className="tag-registros-erroneos"
        />
        <Tag
          value={
            <LanguageProvider
              id="10636"
              alt="Registros existentes"
            ></LanguageProvider>
          }
          className="tag-registros-existentes"
        />
      </div>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "16283",
            alt: "Procesar",
          })}
          icon="pi pi-save"
          onClick={procesarClick}
        />
        <Button
          label={LanguageProvider({
            id: "3155",
            alt: "Cancelar",
          })}
          icon="pi pi-times-circle"
        />

        {/* Completar datos Dialog */}
        <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={validationDatosDialog}
          style={{ width: "10vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("5599", "OK")}
                icon="pi pi-check"
                onClick={handleValidationClose}
                autoFocus
              />
            </div>
          }
          onHide={handleValidationClose}
        >
          <p>
            <LanguageProvider
              id="22178"
              alt="No existe en la grilla ningún movimiento para procesar"
            />
          </p>
        </Dialog>

        {/* Procesar Dialog */}
        {/* <Dialog
          header={<LanguageProvider id="16239" alt="Atención" />}
          visible={procesarDialog}
          style={{ width: "30vw", textAlign: "center" }}
          footer={
            <div style={{ textAlign: "center", marginTop: "1rem" }}>
              <Button
                label={getLabel("10043", "Si")}
                icon="pi pi-check"
                onClick={() => handleProcesar(true)}
                autoFocus
                disabled={loadingProcesar}
              />
              <Button
                label={getLabel("10044", "No")}
                icon="pi pi-times"
                onClick={() => handleProcesar(false)}
                className="p-button-text"
                disabled={loadingProcesar}
              />
            </div>
          }
          onHide={() => setProcesarDialog(false)}
        >
          <p style={{ padding: "1rem 0", margin: "0" }}>
            <LanguageProvider
              id="13275"
              alt="¿Desea procesar los cambios realizados?"
            />
          </p>
          {loadingProcesar && (
            <ProgressSpinner style={{ width: "50px", height: "50px" }} />
          )}
        </Dialog> */}
      </div>
    </div>
  );
}
const getLabel = (id: string, alt: string): string =>
  LanguageProvider({ id, alt }) as unknown as string;
