import "./CargaManualVin.scss";
import "primeicons/primeicons.css";

import React, { useEffect, useRef, useState } from "react";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Calendar } from "primereact/calendar";

export function CargaManualVin() {
  const SCREEN_CODE = "fun0068_carga_manual_vin";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();

  const showError = (id: string, alt: string) => {
    try {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: <LanguageProvider id={id} alt={alt} />,
        life: 3000,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [filter, setFilter] = useState({
    vin: null,
    copiaVin: false,
    cliente: null,
  });

  const [model, setModel] = useState({
    mercado: null,
    origen: null,
    marca: null,
    modelo: null,
    color: null,
    destino: null,
    destinoOrigen: null,
    genero: null,
    denominacionComercial: null,
    codPlataforma: "",

    silueta: null,
    acabado: null,
    motorizacion: null,
    caja: null,
    baseConcepcion: null,
    clienteConcesionario: null,
    anoFabricacion: null,
    modificacion: null,
    fechaFabricacion: null,

    tapizado: null,
    opcional: null,
    patente: "",
    motor: "",
    modeloLargo: "",
    ordenFabricacion: "",
    actualizoUsuario: "",
    actualizoConFecha: null,
    ncm: "",
  });

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country && vin !== null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/find-vin",
          {
            pais: { id: securityFilters.securityValues.country.id || null },
            cliente: { id: securityFilters.securityValues.client.id || null },
            cuenta: { id: securityFilters.securityValues.account.id || null },
            vin: vin || null,
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError("654", "No existe el VIN");
          }
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVin([]);
    return [];
  }

  const [loadingMercado, setLoadingMercado] = useState(false);
  const [mercado, setMercado] = useState([]);
  async function loadMercado() {
    setLoadingMercado(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/mercado",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          const mercadoData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setMercado(mercadoData);
          return mercadoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercado(false);
    }
    setMercado([]);
    return [];
  }

  const [loadingOrigen, setLoadingOrigen] = useState(false);
  const [origen, setOrigen] = useState([]);
  async function loadOrigen() {
    setLoadingOrigen(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/origen",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          const origenData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setOrigen(origenData);
          return origenData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
    setOrigen([]);
    return [];
  }

  const [loadingMarca, setLoadingMarca] = useState(false);
  const [marca, setMarca] = useState([]);
  async function loadMarca() {
    setLoadingMarca(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/marca",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
          }
        );

        if (status === 200) {
          const marcaData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setMarca(marcaData);
          return marcaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarca(false);
    }
    setMarca([]);
    return [];
  }

  const [loadingModelo, setLoadingModelo] = useState(false);
  const [modelo, setModelo] = useState([]);
  async function loadModelo(marca) {
    setLoadingModelo(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/modelo",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const modeloData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setModelo(modeloData);
          return modeloData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelo(false);
    }
    setModelo([]);
    return [];
  }

  const [loadingColor, setLoadingColor] = useState(false);
  const [color, setColor] = useState([]);
  async function loadColor(marca) {
    setLoadingColor(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/color",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const colorData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setColor(colorData);
          return colorData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColor(false);
    }
    setColor([]);
    return [];
  }

  const [loadingDestino, setLoadingDestino] = useState(false);
  const [destino, setDestino] = useState([]);
  async function loadDestino() {
    setLoadingDestino(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/destino",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          const destinoData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setDestino(destinoData);
          return destinoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestino(false);
    }
    setDestino([]);
    return [];
  }

  const [loadingDestinoOrig, setLoadingDestinoOrig] = useState(false);
  const [destinoOrig, setDestinoOrig] = useState([]);
  async function loadDestinoOrig() {
    setLoadingDestinoOrig(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post("/", {
          pais: securityFilters.securityValues.country || null,
        });

        if (status === 200) {
          const destinoOrigData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setDestinoOrig(destinoOrigData);
          return destinoOrigData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinoOrig(false);
    }
    setDestinoOrig([]);
    return [];
  }

  const [loadingGenero, setLoadingGenero] = useState(false);
  const [genero, setGenero] = useState([]);
  async function loadGenero(marca) {
    setLoadingGenero(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/genero",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const generoData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setGenero(generoData);
          return generoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGenero(false);
    }
    setGenero([]);
    return [];
  }

  const [loadingDenoComercial, setLoadingDenoComercial] = useState(false);
  const [denoComercial, setDenoComercial] = useState([]);
  async function loadDenominacionComercial() {
    setLoadingDenoComercial(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/denominacion-comercial",
          {
            pais: securityFilters.securityValues.country || null,
          }
        );

        if (status === 200) {
          const denoComercialData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setDenoComercial(denoComercialData);
          return denoComercialData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDenoComercial(false);
    }
    setDenoComercial([]);
    return [];
  }

  const [loadingSilueta, setLoadingSilueta] = useState(false);
  const [silueta, setSilueta] = useState([]);
  async function loadSilueta(marca) {
    setLoadingSilueta(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/silueta",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const siluetaData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setSilueta(siluetaData);
          return siluetaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSilueta(false);
    }
    setSilueta([]);
    return [];
  }

  const [loadingAcabado, setLoadingAcabado] = useState(false);
  const [acabado, setAcabado] = useState([]);
  async function loadAcabado(marca) {
    setLoadingAcabado(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/acabado",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const acabadoData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setAcabado(acabadoData);
          return acabadoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAcabado(false);
    }
    setAcabado([]);
    return [];
  }

  const [loadingMotorizacion, setLoadingMotorizacion] = useState(false);
  const [motorizacion, setMotorizacion] = useState([]);
  async function loadMotorizacion(marca) {
    setLoadingMotorizacion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/motorizacion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const motorizacionData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setMotorizacion(motorizacionData);
          return motorizacionData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMotorizacion(false);
    }
    setMotorizacion([]);
    return [];
  }

  const [loadingCaja, setLoadingCaja] = useState(false);
  const [caja, setCaja] = useState([]);
  async function loadCaja(marca) {
    setLoadingCaja(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/caja",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const cajaData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setCaja(cajaData);
          return cajaData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCaja(false);
    }
    setCaja([]);
    return [];
  }

  const [loadingBaseConcepcion, setLoadingBaseConcepcion] = useState(false);
  const [baseConcepcion, setBaseConcepcion] = useState([]);
  async function loadBaseConcepcion(marca) {
    setLoadingBaseConcepcion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/base-concepcion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const baseConcepcionData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setBaseConcepcion(baseConcepcionData);
          return baseConcepcionData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBaseConcepcion(false);
    }
    setBaseConcepcion([]);
    return [];
  }

  const [loadingClienteConc, setLoadingClienteConc] = useState(false);
  const [clienteConc, setClienteConc] = useState([]);
  async function loadClienteConcepcion(marca) {
    setLoadingClienteConc(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/cliente-concepcion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const clienteConcData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setClienteConc(clienteConcData);
          return clienteConcData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClienteConc(false);
    }
    setClienteConc([]);
    return [];
  }

  const [loadingAnoFabricacion, setLoadingAnoFabricacion] = useState(false);
  const [anoFabricacion, setAnoFabricacion] = useState([]);
  async function loadAnioFabricacion(marca) {
    setLoadingAnoFabricacion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/anio-fabricacion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const anoFabricacionData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setAnoFabricacion(anoFabricacionData);
          return anoFabricacionData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAnoFabricacion(false);
    }
    setAnoFabricacion([]);
    return [];
  }

  const [loadingModificacion, setLoadingModificacion] = useState(false);
  const [modificacion, setModificacion] = useState([]);
  async function loadModificacion(marca) {
    setLoadingModificacion(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/modificacion",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );
        if (status === 200) {
          const modificacionData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setModificacion(modificacionData);
          return modificacionData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModificacion(false);
    }
    setModificacion([]);
    return [];
  }

  const [loadingTapizado, setLoadingTapizado] = useState(false);
  const [tapizado, setTapizado] = useState([]);
  async function loadTapizado(marca) {
    setLoadingTapizado(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/tapizado",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const tapizadoData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setTapizado(tapizadoData);
          return tapizadoData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTapizado(false);
    }
    setTapizado([]);
    return [];
  }

  const [loadingOpcional, setLoadingOpcional] = useState(false);
  const [opcional, setOpcional] = useState([]);
  async function loadCabeceraOpcional(marca) {
    setLoadingOpcional(true);
    try {
      if (marca && securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/carga-manual-vin/cabecera-opcional",
          {
            pais: securityFilters.securityValues.country || null,
            cliente: securityFilters.securityValues.client || null,
            marca: marca || null,
          }
        );

        if (status === 200) {
          const opcionalData = data.map((x: any) => {
            return { ...x, id: x.id, label: `${x.descripcion} ` };
          });

          setOpcional(opcionalData);
          return opcionalData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOpcional(false);
    }
    setOpcional([]);
    return [];
  }

  return (
    <div className="carga-manual-vin">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          screenCode: SCREEN_CODE,
        }}
      ></Filter>

      <Fieldset
        legend={<LanguageProvider id={"1465"} alt="Carga Manual del VIN" />}
      >
        <div className="form" style={{ flexDirection: "column" }}>
          <div className="form-row">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"901"} alt="Ingrese el VIN" />
              </label>
              <AutoComplete
                value={filter.vin}
                suggestions={vin}
                completeMethod={searchVin}
                onChange={(e) => setFilter({ ...filter, vin: e.value || null })}
                field="label"
                dropdown
                forceSelection
              />
            </div>
            <label>
              <LanguageProvider
                id={"10196"}
                alt="Copiar el Vin a Otro Cliente"
              />
            </label>
            <Checkbox
              checked={filter.copiaVin}
              onChange={(e) => setFilter({ ...filter, copiaVin: e.checked })}
              disabled={true}
            />
            {filter.copiaVin && (
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"24084"} alt="Cliente" />
                </label>
                <Dropdown
                  value={filter.cliente}
                  //options={cliente}
                  onChange={(e) =>
                    setFilter({ ...filter, cliente: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                />
              </div>
            )}
          </div>
        </div>
      </Fieldset>

      <Fieldset>
        <div className="form">
          <div className="column ">
            <div className="sipco-option">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"367"} alt="Mercado" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.mercado}
                  options={mercado}
                  onChange={(e) =>
                    setModel({ ...model, mercado: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2496"} alt="Origen" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.origen}
                  options={origen}
                  onChange={(e) =>
                    setModel({ ...model, origen: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"396"} alt="Marca" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.marca}
                  options={marca}
                  onChange={(e) =>
                    setModel({ ...model, marca: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
                {/* <Button
                    icon="pi pi-arrow-right"
                    onClick={() =>
                      window.open("/abm0010_mantener_marcas", "_blank")  //NAO USARA POR HORA!
                    }
                  /> */}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2139"} alt="Modelo" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.modelo}
                  options={modelo}
                  onChange={(e) =>
                    setModel({ ...model, modelo: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
                {/* <Button
                    icon="pi pi-arrow-right"
                    onClick={
                      () => window.open("/abm0027_mantener_modelos", "_blank") //NAO USARA POR HORA!
                    }
                  /> */}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"2140"} alt="Color" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.color}
                  options={color}
                  onChange={(e) =>
                    setModel({ ...model, color: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
                {/* <Button
                    icon="pi pi-arrow-right"
                    onClick={
                      () => window.open("/abm0012_mantener_colores", "_blank") //NAO USARA POR HORA!
                    }
                  /> */}
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4102"} alt="Destino" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.destino}
                  options={destino}
                  onChange={(e) =>
                    setModel({ ...model, destino: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16421"} alt="Destino de origen" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.destinoOrigen}
                  options={destinoOrig}
                  onChange={(e) =>
                    setModel({ ...model, destinoOrigen: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16422"} alt="Género" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.genero}
                  options={genero}
                  onChange={(e) =>
                    setModel({ ...model, genero: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"18202"} alt="Denominación Comercial" />
                </label>
                <Dropdown
                  value={model.denominacionComercial}
                  options={denoComercial}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      denominacionComercial: e.value || null,
                    })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"22209"} alt="Cód. Plataforma" />
                </label>
                <InputText disabled={true} />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="sipco-option">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16423"} alt="Silueta" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.silueta}
                  options={silueta}
                  onChange={(e) =>
                    setModel({ ...model, silueta: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16424"} alt="Acabados" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.acabado}
                  options={acabado}
                  onChange={(e) =>
                    setModel({ ...model, acabado: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16425"} alt="Motorización" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.motorizacion}
                  options={motorizacion}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      motorizacion: e.value || null,
                    })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16426"} alt="Cajas" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.caja}
                  options={caja}
                  onChange={(e) =>
                    setModel({ ...model, caja: e.value || null })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16427"} alt="Base concepción" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.baseConcepcion}
                  options={baseConcepcion}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      baseConcepcion: e.value || null,
                    })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16307"} alt="Cliente concesionario" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.clienteConcesionario}
                  options={clienteConc}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      clienteConcesionario: e.value || null,
                    })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16429"} alt="Año de fabricación" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.anoFabricacion}
                  options={anoFabricacion}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      anoFabricacion: e.value || null,
                    })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13502"} alt="Modificación" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.modificacion}
                  options={modificacion}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      modificacion: e.value || null,
                    })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"12103"} alt="Fecha Fabricación" />
                </label>
                <Calendar
                  value={model.fechaFabricacion}
                  onChange={(e) => {
                    setModel({ ...model, fechaFabricacion: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={true}
                />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="sipco-option">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16430"} alt="Tapizado" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.tapizado}
                  options={tapizado}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      tapizado: e.value || null,
                    })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"16431"} alt="Opcional" />
                  <span className="required">*</span>
                </label>
                <Dropdown
                  value={model.opcional}
                  options={opcional}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      opcional: e.value || null,
                    })
                  }
                  optionLabel="descripcion"
                  filter
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"4724"} alt="Patente" />
                </label>
                <InputText value={model?.patente || null} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13335"} alt="Motor" />
                  <span className="required">*</span>
                </label>
                <InputText value={model?.motor || null} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13338"} alt="Modelo Largo" />
                  <span className="required">*</span>
                </label>
                <InputText value={model?.modeloLargo || null} disabled={true} />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"10784"} alt="Orden de Fabricación" />
                </label>
                <InputText
                  value={model?.ordenFabricacion || null}
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13489"} alt="Actualizó Usuario" />
                </label>
                <InputText
                  value={model?.actualizoUsuario || null}
                  disabled={true}
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"13490"} alt="Actualizó con Fecha" />
                </label>
                <Calendar
                  value={model.actualizoConFecha}
                  onChange={(e) => {
                    setModel({ ...model, actualizoConFecha: e.value });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                  disabled={true}
                />
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"3505"} alt="NCM" />
                </label>
                <InputText disabled={true} value={model?.ncm || null} />
              </div>
            </div>
          </div>
        </div>
      </Fieldset>

      <div className="flex flex-wrap gap-2 mt-5">
        <Button
          label={LanguageProvider({
            id: "16283",
            alt: "Procesar",
          })}
          icon="pi pi-save"
          //onClick={procesarClick}
          disabled={true}
        />
        <Button
          label={LanguageProvider({ id: "2016", alt: "Borrar" })}
          icon="pi pi-times"
          //onClick={cancelarClick}
          disabled={true}
        />
        <Button
          label={LanguageProvider({
            id: "2366",
            alt: "Importar Archivo",
          })}
          icon="pi pi-upload"
        />
      </div>
    </div>
  );
}
export default CargaManualVin;
