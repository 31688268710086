import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import logoBase64 from "../../../assets/excel-templates/imageBase64";
import { LanguageProviderString } from "../language-provider-string";
import JSZip from "jszip";


export interface HojaRutaResponse {
  title: string;
  subtitle: string | null;
  template: string;
  items: string[][];
  objectItems: any | null;
  headers: any | null;
  types: any | null;
  fileName: string | null;
  listaMails: string[] | null;
  exportedFilePath: string | null;
  mailSubject: string | null;
  mailBody: string | null;
  filters: any | null;
  customFilename: string | null;
  isXlsx: boolean;
  transportista: string;
  chofer: string;
  patenteTractor: string;
  patenteBatea: string | null;
  fechaDespacho: string;
  tipoBatea: string | null;
  tipoTractor: string;
  equipoBatea: string | null;
  equipoTractor: string;
  subcuenta: string;
  direccion: string;
  codigoPstal: string | null;
  provincia: string;
  pais: string;
  telefono: string | null;
  fax: string | null;
  km: number | null;
  administrador: string;
  nroHojaRuta: string;
  idRefecrencia: string;
  coeficiente: string;
  limiteInferior: number | null;
  limiteSuperior: number | null;
}
interface ExportPropsType {
  data: HojaRutaResponse[];
  user: any;

}


const languageLabels = (user) => {
  return {
    transportista: LanguageProviderString({
      user,
      id: "754",
      alt: "Transportista",
    }),
    chofer: LanguageProviderString({
      user,
      id: "4655",
      alt: "Chofer",
    }),
    tractor: LanguageProviderString({
      user,
      id: "4285",
      alt: "Tractor",
    }),
    bateaTransportin: LanguageProviderString({
      user,
      id: "4284",
      alt: "Batea / Transportín",
    }),
    adm: LanguageProviderString({
      user,
      id: "10948",
      alt: "Administrador",
    }),
    nroHojaDeRuta: "Nro Hoja de Ruta",
    fechaSalida: LanguageProviderString({
      user,
      id: "1730",
      alt: "Fecha Salida",
    }),
    hr: "HR"
  }
}

const LanguageFooter = (user) => {
  return {
    km: `${LanguageProviderString({
      user,
      id: "3150",
      alt: "Total KM:",
    })}`,
    coeficiente: `${LanguageProviderString({
      user,
      id: "16288",
      alt: "Coeficiente",
    })}:`,
    limiteInferior: `${LanguageProviderString({
      user,
      id: "24991",
      alt: "Limite Inferior",
    })}:`,
    limiteSuperior: `${LanguageProviderString({
      user,
      id: "24992",
      alt: "Limite Superior",
    })}:`,
    lengthItems: `${LanguageProviderString({
      user,
      id: "3116",
      alt: "Quantidade de Registros",
    })}:`,
    firmaTransportista: `${LanguageProviderString({
      user,
      id: "3585",
      alt: "Firma Transportista ....................................................................",
    })}:`,
    aclaracionFirma: `${LanguageProviderString({
      user,
      id: "3586",
      alt: "Aclaración Firma / DNI ................................................................",
    })}:`,
  }

}



const languageColumns = (user) => {
  //Destinos,	VIN,	Cliente,	Modelo,	Color,	Posición Batea,	Remito,	Ubicaciones
  const columnsids = [369, 1491, 54, 2139, 2140, 5522, 3184, 921]
  const columnsLabel = []

  for (const element of columnsids) {
    columnsLabel.push({
      id: element,
      label: LanguageProviderString({
        user,
        id: `${element}`,
        alt: "Error Columns Labels",
      }),
    });
  }
  return columnsLabel
}





const ExportExcelHojaRuta = async ({ user, data }: ExportPropsType): Promise<boolean> => {
  const screenName = `${LanguageProviderString({
    user,
    id: "713",
    alt: "Reimpresión de Hoja de Ruta",
  })}`;
  const infosHrLabels = languageLabels(user);
  const columnsLabelDataValues = languageColumns(user);
  const footerLabels = LanguageFooter(user)


  // Array para armazenar os buffers de arquivos gerados
  const fileBuffers: { buffer: ArrayBuffer; fileName: string }[] = [];

  for (const hojaRuta of data) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(hojaRuta.nroHojaRuta);

    // Adicionar imagem
    const imageId = workbook.addImage({
      base64: logoBase64,
      extension: "png",
    });
    worksheet.addImage(imageId, {
      tl: { col: 0, row: 0 },
      ext: { width: 1115, height: 40 },
    });

    // Informações básicas na parte superior
    worksheet.mergeCells("A3:H3");
    worksheet.getCell("A3").value = screenName;
    worksheet.getCell("A3").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("A3").font = {
      bold: true,
    };

    // Adiciona destino
    worksheet.mergeCells("A4:H4");
    const destino = `${hojaRuta.subcuenta} - ${hojaRuta.direccion} - ${hojaRuta.provincia} - ${hojaRuta.pais}`;
    worksheet.getCell("A4").value = destino;
    worksheet.getCell("A4").alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell("A4").font = {
      bold: true,
    };

    // Linha vazia de A a H com cor FFFFFF
    worksheet.mergeCells("A5:H5");
    worksheet.getCell("A5").font = { bold: true };
    worksheet.getCell("A5").fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '003366' }
    };

    // Adiciona informações da Hoja de Ruta
    worksheet.getCell("A6").value = infosHrLabels.transportista;
    worksheet.mergeCells("B6:D6");
    worksheet.getCell("B6").value = hojaRuta.transportista;

    worksheet.getCell("A7").value = infosHrLabels.chofer;
    worksheet.mergeCells("B7:D7");
    worksheet.getCell("B7").value = hojaRuta.chofer;

    worksheet.getCell("A8").value = infosHrLabels.tractor;
    worksheet.mergeCells("B8:D8");
    worksheet.getCell("B8").value = `${hojaRuta.equipoTractor} - ${hojaRuta.patenteTractor} - ${hojaRuta.tipoTractor}`;

    worksheet.getCell("E6").value = infosHrLabels.nroHojaDeRuta;
    worksheet.mergeCells("F6:G6");
    worksheet.getCell("F6").value = `${hojaRuta.nroHojaRuta}`;

    worksheet.getCell("E7").value = infosHrLabels.fechaSalida;
    worksheet.mergeCells("F7:G7");
    worksheet.getCell("F7").value = `${hojaRuta.fechaDespacho}`;

    worksheet.mergeCells("E8:E9");
    worksheet.getCell("E8").value = infosHrLabels.hr;
    worksheet.getCell("E8").font = {
      bold: true,
      size: 22,
    };

    worksheet.getCell("E8").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.mergeCells("F8:H9");
    worksheet.getCell("F8").value = `*${hojaRuta.nroHojaRuta}*`;
    worksheet.getCell("F8").font = {
      name: "Libre Barcode 39",
      size: 25,
    };

    worksheet.getCell("F8").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // Cabeçalho
    const header = columnsLabelDataValues.map((column) => column.label);
    worksheet.addRow(header);

    // Estilizar cabeçalho
    const headerRow = worksheet.getRow(10);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "003366" },
      };
      cell.font = { bold: true, color: { argb: "FFFFFF" } };
      cell.alignment = { vertical: "middle", horizontal: "center" };
    });

    // Ajustar largura das colunas
    worksheet.columns.forEach((column, index) => {
      const labelLength = columnsLabelDataValues[index]?.label?.length || 0;
      column.width = Math.max(labelLength + 5, 20);
    });

    hojaRuta.items.forEach((row) => {
      worksheet.addRow(row.map((cellValue) => cellValue || ""));
    });



    //Adiciona informações finais
    worksheet.addRow([]);
    worksheet.addRow([footerLabels.km, hojaRuta.km]).alignment = {
      horizontal: "left",
    };
    worksheet.addRow([footerLabels.coeficiente, hojaRuta.coeficiente]).alignment = {
      horizontal: "left",
    };;
    worksheet.addRow([footerLabels.limiteInferior, hojaRuta.limiteInferior]).alignment = {
      horizontal: "left",
    };;
    worksheet.addRow([footerLabels.limiteSuperior, hojaRuta.limiteSuperior]).alignment = {
      horizontal: "left",
    };;
    worksheet.addRow([footerLabels.lengthItems, hojaRuta.items.length]).alignment = {
      horizontal: "left",
    };;
    worksheet.addRow([]);

    // Armazena a última linha adicionada
    const lastRow = worksheet.lastRow;

    //Adiciona Campos de assinaturas
    worksheet.mergeCells(`D${lastRow.number}:H${lastRow.number}`);
    worksheet.getCell(`D${lastRow.number}`).value = footerLabels.firmaTransportista;
    worksheet.mergeCells(`D${lastRow.number + 2}:H${lastRow.number + 2}`);
    worksheet.getCell(`D${lastRow.number + 2}`).value = footerLabels.firmaTransportista;

    // Gerar o buffer do arquivo Excel
    try {
      const buffer = await workbook.xlsx.writeBuffer();
      fileBuffers.push({
        buffer,
        fileName: `hojaRuta-${hojaRuta.nroHojaRuta}.xlsx`,
      });
    } catch (error) {
      console.error("Erro ao gerar arquivo Excel:", error);
      return;
    }
  }

  try {
    // Verificar a quantidade de arquivos gerados
    if (fileBuffers.length === 1) {
      // Apenas um arquivo: exporta diretamente
      const { buffer, fileName } = fileBuffers[0];
      saveAs(new Blob([buffer]), fileName);
      return true
    } else if (fileBuffers.length > 1) {
      // Mais de um arquivo: cria e exporta um ZIP
      const zip = new JSZip();

      fileBuffers.forEach(({ buffer, fileName }) => {
        zip.file(fileName, buffer);
      });
      try {
        const zipContent = await zip.generateAsync({ type: "blob" });
        saveAs(zipContent, "hojasRuta.zip");
        return true
      } catch (error) {
        console.error("Erro ao gerar arquivo ZIP:", error);
        return false
      }
    }
  } catch (error) {
    console.error("Erro ao gerar arquivo Excel:", error);
    return false
  }

};



export default ExportExcelHojaRuta;
