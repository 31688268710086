import "./ModalVerComprasTaller.scss";

import React, { useEffect, useRef, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../../protected-routes";
import usePageViews from "../../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { useLocation } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

interface ModalComprasTaller {
  visible: boolean;
  onClose: () => void;
  model?: any;
  onConfirm?: (updatedList: any[]) => void;
}

const ModalVerComprasTaller: React.FC<ModalComprasTaller> = ({
  visible,
  onClose,
  model,
  onConfirm,
}) => {
  const location = useLocation();
  const { pais, cuenta, tallerFilter, subcuenta, nroOrden } =
    location.state || {};

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const columnsNames = [];

  const filterColumnsId = [
    17014, 5701, 15739, 17088, 396, 2139, 17015, 4791, 327, 16493, 16492, 3231,
    2807, 3344, 17012, 13489, 16958, 3568,
  ];

  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    checkVinTaller: false,
    taller: "",
    estados: null,
    vin: null,
    checkFueraTaller: false,
    desde: new Date(),
    hasta: new Date(),
  });

  const [vin, setVin] = useState([]);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/vin",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codCliente: securityFilters.securityValues.client.id,
              codCuenta: securityFilters.securityValues.account.id,
              vin: query,
              descripcionCliente: "",
            },
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }

  const [taller, setTaller] = useState([]);
  const [loadingTaller, setLoadingTaller] = useState(false);

  async function loadTaller() {
    setLoadingTaller(true);
    try {
      if (securityFilters?.securityValues?.country != null) {
        const { status, data } = await sipcoAxiosService.get(
          "/orden-reparacion/taller",
          {
            params: {
              codPais: securityFilters.securityValues.country.id,
              codSubcuenta: securityFilters.securityValues.subaccount.id,
              codCuenta: securityFilters.securityValues.account.id,
            },
          }
        );
        if (status === 200) {
          const response = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} - (${x.id})` };
          });
          setTaller(response);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTaller(false);
    }
  }

  const [estados, setEstados] = useState(null);
  const [loadingEstados, setLoadingEstados] = useState(false);

  async function loadEstados() {
    setLoadingEstados(true);
    try {
      if (
        securityFilters?.securityValues?.country == null ||
        securityFilters?.securityValues?.client == null
      )
        return;
      const { status, data } = await sipcoAxiosService.get(
        "/orden-reparacion/estados",

        {
          params: {
            codPais: securityFilters.securityValues.country.id,
          },
        }
      );
      if (status === 200) {
        data.unshift({ id: 0, descripcionFull: "TODOS", descripcion: "TODOS" });

        const response = data.map((x: any) => {
          return { ...x, label: `${x.descripcion} - (${x.id})` };
        });

        setEstados(response);
        return response;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }

  useEffect(() => {
    const init = async () => {
      try {
        const taller = await loadTaller();
        const estados = await loadEstados();

        setFilter({ ...filter, taller: taller[0], estados: estados[0] });
      } catch (error) {
        console.error(error);
      }
    };

    init();
  }, [securityFilters.securityValues]);

  const data = convertDateObjects(reporte?.filas);

  return (
    <div>
      <Dialog
        className="modalComprasTaller"
        header="Detalhes"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={onClose}
        modal
        footer={() => {
          return (
            <div style={{display: "flex", justifyContent: "center"}}>
              <Button
                label={LanguageProvider({ id: "15447", alt: "Confirmar" })}
                icon="pi pi-check"
                onClick={onClose}
                autoFocus
              />
            </div>
          );
        }}
      >
    

        <div className="modal-container">
          <div className="form-modal">
            <div className="form-row-modal">
              <label>
                <LanguageProvider id={"1253"} alt="Cantidad" />
              </label>
              <Dropdown className="input-modal" disabled={true} />

              <label>
                <LanguageProvider id={"2496"} alt="Origen" />
              </label>
              <InputText
                className="input-modal"
                value={pais.descripcion}
                disabled={true}
              />
            </div>
          </div>

          <div className="form-modal">
            <div className="form-row-modal">
              <label>
                <LanguageProvider id={"2265"} alt="Precio x Un." />
              </label>
              <InputText
                className="input-modal"
                value={pais.descripcion}
                disabled={true}
              />
              <label>
                <LanguageProvider id={"4137"} alt="Prestación" />
              </label>
              <Dropdown className="input-modal" disabled={true}/>
            </div>
          </div>

          <div className="form-modal">
            <div className="form-row-modal">
              <label>
                <LanguageProvider id={"2263"} alt="Descuento (%)" />
              </label>
              <Dropdown className="input-modal" disabled={true}/>
              <label>
                <LanguageProvider id={"4587"} alt="Subprestación" />
              </label>
              <Dropdown className="input-modal" disabled={true}/>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
export default ModalVerComprasTaller;
