import "./ingreso-ecom.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

const IngresoEcom = () => {
  const SCREEN_CODE = "fun0068_ingreso_ecom";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const toast = useRef<Toast>(null);

  const [vinTxt, setVinTxt] = useState("");
  const [ordenFabricacionTxt, setOrdenFabricacionTxt] = useState("");
  const [vinExiste, setVinExiste] = useState(false);

  const [modelForm, setModelForm] = useState(new IngresoEmcomModel());
  const [isDisabled, setIsDisabled] = useState(true);

  const [loadingVin, setLoadingVin] = useState(false);

  const vinInputRef = useRef(null);
  const manufactureOrderInputRef = useRef(null);

  const [calles, setCalles] = useState([]);
  const [loadingCalle, setLoadingCalle] = useState(false);
  async function loadCalle() {
    setLoadingCalle(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        subcuenta: securityFilters.securityValues.subaccount,
        cliente: securityFilters.securityValues.client,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/calle",
        params
      );
      if (status === 200) {
        setCalles(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCalle(false);
    }
  }

  const [columnas, setColumnas] = useState([]);
  const [loadingColumnas, setLoadingColumnas] = useState(false);
  async function loadColuna(idRua: any) {
    setLoadingColumnas(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        subcuenta: securityFilters.securityValues.subaccount,
        cliente: securityFilters.securityValues.client,
        calle: idRua,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/columna",
        params
      );
      if (status === 200) {
        setColumnas(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColumnas(false);
    }
  }
  const [niveles, setNiveles] = useState([]);
  const [loadingNiveles, setLoadingNiveles] = useState(false);
  async function loadNivel(idCalle: any, idColuna: any) {
    setLoadingNiveles(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        subcuenta: securityFilters.securityValues.subaccount,
        cliente: securityFilters.securityValues.client,
        calle: idCalle,
        columna: idColuna,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/nivel",
        params
      );
      if (status === 200) {
        setNiveles(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingNiveles(false);
    }
  }
  async function existe(vin: string) {
    setLoadingVin(true);
    try {
      if (vin.length === 17 || vin.length === 19) {
        if (vin.length === 19) {
          //Remove o primeiro e ultimo digito para quando o vin e inserido por um leitor
          vin = vin.slice(1, -1);
        }
        const params = {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          vin: {
            id: vin,
          },
        };
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-ecom/vin-existe",
          params
        );
        if (status === 200) {
          if (data) {
            toast.current?.show({
              severity: "warn",
              detail: (
                <LanguageProvider id="10787" alt="O Vin já passou por ECOM" />
              ),
              life: 3000,
            });
            setVinExiste(true);
            vinInputRef.current.focus();
          } else {
            setVinExiste(false);
            manufactureOrderInputRef.current.focus();
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }
  async function findByEcom(ordenFabricacion: string) {
    try {
      if (ordenFabricacion.length === 8) {
        const params = {
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          vin: {
            id: vinTxt,
          },
          ordenDeFabricacion: ordenFabricacion,
        };
        const { status, data } = await sipcoAxiosService.post(
          "/ingreso-ecom/findByEcom",
          params
        );
        if (status === 200) {
          await fillVin(data);
        }
        if (!securityFilters.subAccountDetails.permiteEcomManual) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function fillVin(result: any[]) {
    try {
      const isManualEcomAllowed =
        securityFilters.subAccountDetails.permiteEcomManual;

      if (!isManualEcomAllowed && result.length === 0) {
        toast.current?.show({
          severity: "warn",
          detail: (
            <LanguageProvider
              id="10798"
              alt="No se recibió del cliente, información complementaria del Vin. No se puede hacer el ingreso en ECOM."
            />
          ),
          life: 5000,
        });
        return;
      }

      let vinObj;
      if (result.length > 0) {
        const resultVin = result[0].vin ?? {};
        vinObj = {
          ...resultVin,
          id: vinTxt,
          ordenFabricacion: ordenFabricacionTxt,
          origenDeFabricacion: resultVin.origenDeFabricacion ?? { id: "G875" },
          fechaSalidaFabrica: resultVin.fechaSalidaFabrica ?? {
            timestamp: new Date().toISOString(),
          },
        };
      } else {
        vinObj = new IngresoEmcomModel();
        vinObj = {
          ...vinObj,
          id: vinTxt,
          ordenFabricacion: ordenFabricacionTxt,
          origenDeFabricacion: { id: "G875" },
          fechaSalidaFabrica: { timestamp: new Date().toISOString() },
        };
      }

      const [mercados, origenes, marcas, destinos, colores] = await Promise.all(
        [loadMercado(), loadOrigen(), loadMarca(), loadDestino(), loadColor()]
      );

      vinObj.mercado =
        mercados.find((x) => x.id === vinObj.mercado?.id) ?? mercados[0];
      vinObj.origen =
        origenes.find((x) => x.id === vinObj.origen?.id) ?? origenes[0];
      vinObj.destino =
        destinos.find((x) => x.id === vinObj.destino?.id) ?? destinos[0];
      vinObj.origenDeFabricacion =
        destinos.find((x) => x.id === vinObj.origenDeFabricacion?.id) ??
        destinos[0];
      vinObj.color =
        colores.find((x) => x.id === vinObj.color?.id) ?? colores[0];
      vinObj.marca = marcas.find((x) => x.id === vinObj.marca?.id) ?? marcas[0];

      if (vinObj.marca !== null) {
        const [
          modelos,
          generos,
          siluetas,
          acabados,
          motorizaciones,
          cajas,
          basesConcepcion,
          clientesConcepcion,
          aniosFabricacion,
          modificaciones,
          tapizados,
          cabecerasOpcionales,
        ] = await Promise.all([
          loadModelo(vinObj.marca),
          loadGenero(vinObj.marca),
          loadSilueta(vinObj.marca),
          loadAcabado(vinObj.marca),
          loadMotorizacion(vinObj.marca),
          loadCajas(vinObj.marca),
          loadBaseConcepcion(vinObj.marca),
          loadClienteConcepcion(vinObj.marca),
          loadAnoFabricacion(vinObj.marca),
          loadModificacion(vinObj.marca),
          loadTapizado(vinObj.marca),
          loadCabeceraOpcional(vinObj.marca),
        ]);

        vinObj.modelo =
          modelos.find((x) => x.id === vinObj.modelo?.id) ?? modelos[0];
        vinObj.genero =
          generos.find((x) => x.id === vinObj.genero?.id) ?? generos[0];
        vinObj.silueta =
          siluetas.find((x) => x.id === vinObj.silueta?.id) ?? siluetas[0];
        vinObj.motorizacion =
          motorizaciones.find((x) => x.id === vinObj.motorizacion?.id) ??
          motorizaciones[0];
        vinObj.caja = cajas.find((x) => x.id === vinObj.caja?.id) ?? cajas[0];
        vinObj.baseConcepcion =
          basesConcepcion.find((x) => x.id === vinObj.baseConcepcion?.id) ??
          basesConcepcion[0];
        vinObj.clienteConcepcion =
          clientesConcepcion.find(
            (x) => x.id === vinObj.clienteConcepcion?.id
          ) ?? clientesConcepcion[0];
        vinObj.anioFabricacion =
          aniosFabricacion.find((x) => x.id === vinObj.anioFabricacion?.id) ??
          aniosFabricacion[0];
        vinObj.modificacion =
          modificaciones.find((x) => x.id === vinObj.modificacion?.id) ??
          modificaciones[0];
        vinObj.tapizado =
          tapizados.find((x) => x.id === vinObj.tapizado?.id) ?? tapizados[0];
        vinObj.acabado =
          acabados.find((x) => x.id === vinObj.acabado?.id) ?? acabados[0];
        vinObj.cabeceraOpcional =
          cabecerasOpcionales.find(
            (x) => x.id === vinObj.cabeceraOpcional?.id
          ) ?? cabecerasOpcionales[0];
      }
      setModelForm({ ...vinObj });
    } catch (error) {
      console.error(error);
    }
  }

  const [mercados, setMercados] = useState([]);
  const [loadingMercados, setLoadingMercados] = useState(false);
  async function loadMercado() {
    setLoadingMercados(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/mercado",
        params
      );
      if (status === 200) {
        setMercados(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMercados(false);
    }
  }
  const [origenes, setOrigenes] = useState([]);
  const [loadingOrigenes, setLoadingOrigenes] = useState(false);
  async function loadOrigen() {
    setLoadingOrigenes(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/origen",
        params
      );
      if (status === 200) {
        setOrigenes(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigenes(false);
    }
  }
  const [marcas, setMarcas] = useState([]);
  const [loadingMarcas, setLoadingMarcas] = useState(false);
  async function loadMarca() {
    setLoadingMarcas(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/marca",
        params
      );
      if (status === 200) {
        setMarcas(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMarcas(false);
    }
  }
  const [destinos, setDestinos] = useState([]);
  const [loadingDestinos, setLoadingDestinos] = useState(false);
  async function loadDestino() {
    setLoadingDestinos(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/destino",
        params
      );
      if (status === 200) {
        setDestinos(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDestinos(false);
    }
  }
  const [modelos, setModelos] = useState([]);
  const [loadingModelos, setLoadingModelos] = useState(false);
  async function loadModelo(marca: any) {
    setLoadingModelos(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };

      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/modelo",
        params
      );
      if (status === 200) {
        setModelos(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModelos(false);
    }
  }
  const [colores, setColores] = useState([]);
  const [loadingColores, setLoadingColores] = useState(false);
  async function loadColor() {
    setLoadingColores(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/color",
        params
      );
      if (status === 200) {
        setColores(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingColores(false);
    }
  }
  const [generos, setGeneros] = useState([]);
  const [loadingGeneros, setLoadingGeneros] = useState(false);
  async function loadGenero(marca: any) {
    setLoadingGeneros(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/genero",
        params
      );
      if (status === 200) {
        setGeneros(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGeneros(false);
    }
  }
  const [siluetas, setSiluetas] = useState([]);
  const [loadingSiluetas, setLoadingSiluetas] = useState(false);
  async function loadSilueta(marca: any) {
    setLoadingSiluetas(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/silueta",
        params
      );
      if (status === 200) {
        setSiluetas(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSiluetas(false);
    }
  }
  const [acabados, setAcabados] = useState([]);
  const [loadingAcabados, setLoadingAcabados] = useState(false);
  async function loadAcabado(marca: any) {
    setLoadingAcabados(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/acabado",
        params
      );
      if (status === 200) {
        setAcabados(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAcabados(false);
    }
  }
  const [motorizaciones, setMotorizaciones] = useState([]);
  const [loadingMotorizaciones, setLoadingMotorizaciones] = useState(false);
  async function loadMotorizacion(marca: any) {
    setLoadingMotorizaciones(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/motorizacion",
        params
      );
      if (status === 200) {
        setMotorizaciones(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingMotorizaciones(false);
    }
  }
  const [cajas, setCajas] = useState([]);
  const [loadingCajas, setLoadingCajas] = useState(false);
  async function loadCajas(marca: any) {
    setLoadingCajas(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/caja",
        params
      );
      if (status === 200) {
        setCajas(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCajas(false);
    }
  }
  const [basesConcepcion, setBasesConcepcion] = useState([]);
  const [loadingBasesConcepcion, setLoadingBasesConcepcion] = useState(false);
  async function loadBaseConcepcion(marca: any) {
    setLoadingBasesConcepcion(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/base-concepcion",
        params
      );
      if (status === 200) {
        setBasesConcepcion(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingBasesConcepcion(false);
    }
  }
  const [clientesConcecion, setClientesConcecion] = useState([]);
  const [loadingClientesConcecion, setLoadingClientesConcecion] =
    useState(false);
  async function loadClienteConcepcion(marca: any) {
    setLoadingClientesConcecion(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/cliente-concepcion",
        params
      );
      if (status === 200) {
        setClientesConcecion(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingClientesConcecion(false);
    }
  }
  const [aniosFabricacion, setAniosFabricacion] = useState([]);
  const [loadingAniosFabricacion, setLoadingAniosFabricacion] = useState(false);
  async function loadAnoFabricacion(marca: any) {
    setLoadingAniosFabricacion(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/ano-fabricacion",
        params
      );
      if (status === 200) {
        setAniosFabricacion(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAniosFabricacion(false);
    }
  }
  const [modificationsSuggestions, setModificationsSuggestions] = useState([]);
  const [loadingModificaciones, setLoadingModificaciones] = useState(false);
  async function loadModificacion(marca: any) {
    setLoadingModificaciones(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/modificacion",
        params
      );
      if (status === 200) {
        setModificationsSuggestions(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingModificaciones(false);
    }
  }
  const [tapizados, setTapizados] = useState([]);
  const [loadingTapizados, setLoadingTapizados] = useState(false);
  async function loadTapizado(marca: any) {
    setLoadingTapizados(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/tapizado",
        params
      );
      if (status === 200) {
        setTapizados(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTapizados(false);
    }
  }
  const [cabecerasOpcionales, setCabecerasOpcionales] = useState([]);
  const [loadingCabecerasOpcionales, setLoadingCabecerasOpcionales] =
    useState(false);
  async function loadCabeceraOpcional(marca: any) {
    setLoadingCabecerasOpcionales(true);
    try {
      const params = {
        pais: securityFilters.securityValues.country,
        cliente: securityFilters.securityValues.client,
        marca: marca,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/ingreso-ecom/cabecera-opcional",
        params
      );
      if (status === 200) {
        setCabecerasOpcionales(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingCabecerasOpcionales(false);
    }
  }

  function clearForm() {
    setIsDisabled(true);
    setModelForm(new IngresoEmcomModel());
    setVinTxt("");
    setOrdenFabricacionTxt("");
  }
  function validateSecurityFilters(): boolean {
    return !!(
      securityFilters.securityValues.country?.id &&
      securityFilters.securityValues.client?.id &&
      securityFilters.securityValues.account?.id &&
      securityFilters.securityValues.subaccount?.id
    );
  }

  async function handleCalleChange(calle: any) {
    try {
      setModelForm((prevForm) => ({ ...prevForm, street: calle }));
      const colunas = await loadColuna(calle);
      if (colunas && colunas.length > 0) {
        handleColumnaChange(calle, colunas[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function handleColumnaChange(calle: any, columna: any) {
    try {
      setModelForm((prevForm) => ({ ...prevForm, collumn: columna }));
      const niveles = await loadNivel(calle, columna);
      if (niveles && niveles.length > 0) {
        handleNivelChange(niveles[0]);
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function handleNivelChange(nivel: any) {
    try {
      setModelForm((prevForm) => ({ ...prevForm, level: nivel }));
    } catch (error) {
      console.error(error);
    }
  }
  async function handleModeloChange(modelo: any) {
    try {
      setModelForm((prevForm) => ({
        ...prevForm,
        modelo: modelo,
        modeloLargo: "",
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async function findVinByPk() {
    try {
      let vin = modelForm;
      vin.cliente = securityFilters.securityValues.client;
      vin.pais = securityFilters.securityValues.country;
      vin.cuenta = securityFilters.securityValues.account;
      vin.subcuenta = securityFilters.securityValues.subaccount;
      vin.subcuenta.subcuentaDetalle = securityFilters.subAccountDetails;
      const { status, data } = await sipcoAxiosService.post(
        "ingreso-ecom/vin-find",
        vin
      );
      if (status === 200) {
        if (data != null && data != "") {
          await flujoUyEcom(vin);
        } else {
          await ingresar(vin);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function ingresar(vin: any) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "ingreso-ecom/vin-ingresar",
        {
          vin: vin,
          calle: modelForm.street,
          columna: modelForm.collumn,
          nivel: modelForm.level,
          pais: securityFilters.securityValues.country,
          cliente: securityFilters.securityValues.client,
          cuenta: securityFilters.securityValues.account,
          subcuenta: securityFilters.securityValues.subaccount,
        }
      );
      if (status === 200) {
        toast.current?.show({
          severity: "success",
          detail: (
            <LanguageProvider
              id="36"
              alt="Sua transação se realizou com sucesso."
            />
          ),
          life: 3000,
        });
        //TODO print zebra
        clearForm();
      } else {
        const errorParts = data.split(":");
        const errorMessage = errorParts[errorParts.length - 1].trim();
        toast.current?.show({
          severity: "error",
          detail: errorMessage,
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function flujoUyEcom(vin: any) {
    try {
      const { status, data } = await sipcoAxiosService.post(
        "ingreso-ecom/vin-flujo",
        vin
      );
      if (status === 200) {
        if (data) {
          toast.current?.show({
            severity: "success",
            detail: <LanguageProvider id="1467" alt="O VIN já existe" />,
            life: 10000,
          });
        } else {
          await ingresar(vin);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function loadInitialRua() {
      try {
        setLoadingCalle(true);
        const rua = await loadCalle();
        handleCalleChange(rua[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingCalle(false);
      }
    }
    if (validateSecurityFilters()) {
      loadInitialRua();
    }
  }, [securityFilters.securityValues]);

  return (
    <div className="ingreso-ecom">
      <Toast ref={toast} position="bottom-left" />
      <Filter
        onSearch={null}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"10836"} alt="Ingreso Ecom" />}
        >
          <div className="filter-options">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"662"} alt="Ingrese el VIN" />
              </label>
              <InputText
                ref={vinInputRef}
                value={vinTxt}
                onChange={(e) => {
                  setVinTxt(e.target.value);
                  if (
                    e.target.value.length === 17 ||
                    e.target.value.length === 19
                  ) {
                    existe(e.target.value);
                  }
                }}
                maxLength={19}
                disabled={!isDisabled || loadingVin}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"10784"} alt="Orden de Fabricación" />
              </label>
              <InputText
                ref={manufactureOrderInputRef}
                value={ordenFabricacionTxt}
                onChange={(e) => {
                  setOrdenFabricacionTxt(e.target.value);
                  if (e.target.value.length === 8) {
                    findByEcom(e.target.value);
                  }
                }}
                onKeyDown={(e) => {}}
                keyfilter="int"
                maxLength={8}
                disabled={!isDisabled}
              />
            </div>
            <div className={"sipco-option-small"}>
              <label>
                <LanguageProvider id={"990"} alt="Calle" />
              </label>
              <Dropdown
                value={modelForm.street}
                onChange={(e) => {
                  handleCalleChange(e.value);
                }}
                options={calles}
                filter
                loading={loadingCalle}
                disabled={loadingCalle}
              />
            </div>
            <div className={"sipco-option-small"}>
              <label>
                <LanguageProvider id={"991"} alt="Columna" />
              </label>
              <Dropdown
                value={modelForm.collumn}
                options={columnas}
                onChange={(e) => handleColumnaChange(modelForm.street, e.value)}
                loading={loadingColumnas}
                disabled={loadingColumnas}
                filter
              />
            </div>
            <div className={"sipco-option-small"}>
              <label>
                <LanguageProvider id={"992"} alt="Nivel" />
              </label>
              <Dropdown
                value={modelForm.level}
                options={niveles}
                onChange={(e) => handleNivelChange(e.value)}
                filter
                loading={loadingNiveles}
                disabled={loadingNiveles}
              />
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <div className="panel-content">
        <div className="form">
          <div className="form-row">
            <div className="input-container">
              <label>
                <LanguageProvider id="367" alt="Mercado" />
              </label>
              <Dropdown
                value={modelForm.mercado}
                options={mercados}
                onChange={(e) =>
                  setModelForm({ ...modelForm, mercado: e.value })
                }
                disabled={isDisabled || loadingMercados}
                loading={loadingMercados}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="16423" alt="Silueta" />
              </label>
              <Dropdown
                value={modelForm.silueta}
                options={siluetas}
                onChange={(e) =>
                  setModelForm({ ...modelForm, silueta: e.value })
                }
                disabled={isDisabled || loadingSiluetas}
                loading={loadingSiluetas}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="16430" alt="Tapizado" />
              </label>
              <Dropdown
                value={modelForm.tapizado}
                options={tapizados}
                onChange={(e) =>
                  setModelForm({ ...modelForm, tapizado: e.value })
                }
                disabled={isDisabled || loadingTapizados}
                loading={loadingTapizados}
                optionLabel="descripcion"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <label>
                <LanguageProvider id="2496" alt="Origen" />
              </label>
              <Dropdown
                value={modelForm.origen}
                options={origenes}
                onChange={(e) =>
                  setModelForm({ ...modelForm, origen: e.value })
                }
                disabled={isDisabled || loadingOrigenes}
                loading={loadingOrigenes}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="16424" alt="Acabados" />
              </label>
              <Dropdown
                value={modelForm.acabado}
                options={acabados}
                onChange={(e) =>
                  setModelForm({ ...modelForm, acabado: e.value })
                }
                disabled={isDisabled || loadingAcabados}
                loading={loadingAcabados}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="16431" alt="Opcional" />
              </label>
              <Dropdown
                value={modelForm.cabeceraOpcional}
                options={cabecerasOpcionales}
                onChange={(e) =>
                  setModelForm({ ...modelForm, cabeceraOpcional: e.value })
                }
                disabled={isDisabled || loadingCabecerasOpcionales}
                loading={loadingCabecerasOpcionales}
                optionLabel="descripcion"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <label>
                <LanguageProvider id="396" alt="Marca" />
              </label>
              <Dropdown
                value={modelForm.marca}
                options={marcas}
                onChange={(e) => setModelForm({ ...modelForm, marca: e.value })}
                disabled={isDisabled || loadingMarcas}
                loading={loadingMarcas}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="16425" alt="Motorización" />
              </label>
              <Dropdown
                value={modelForm.motorizacion}
                options={motorizaciones}
                onChange={(e) =>
                  setModelForm({ ...modelForm, motorizacion: e.value })
                }
                disabled={isDisabled || loadingMotorizaciones}
                loading={loadingMotorizaciones}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="4724" alt="Patente" />
              </label>
              <InputText
                value={modelForm.patente}
                //  disabled={isDisabled}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <label>
                <LanguageProvider id="2139" alt="Modelo" />
              </label>
              <Dropdown
                value={modelForm.modelo}
                options={modelos}
                onChange={(e) => handleModeloChange(e.value)}
                disabled={isDisabled || loadingModelos}
                loading={loadingModelos}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="16426" alt="Cajas" />
              </label>
              <Dropdown
                value={modelForm.caja}
                options={cajas}
                onChange={(e) => setModelForm({ ...modelForm, caja: e.value })}
                disabled={isDisabled || loadingCajas}
                loading={loadingCajas}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="13335" alt="Motor" />
              </label>
              <InputText
                value={modelForm.numeroMotor}
                onChange={(e) => {
                  setModelForm({ ...modelForm, numeroMotor: e.target.value });
                }}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <label>
                <LanguageProvider id="2140" alt="Color" />
              </label>
              <Dropdown
                value={modelForm.color}
                options={colores}
                onChange={(e) => setModelForm({ ...modelForm, color: e.value })}
                disabled={isDisabled || loadingColores}
                loading={loadingColores}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="16427" alt="Base concepcíon" />
              </label>
              <Dropdown
                value={modelForm.baseConcepcion}
                options={basesConcepcion}
                onChange={(e) =>
                  setModelForm({ ...modelForm, baseConcepcion: e.value })
                }
                disabled={isDisabled || loadingBasesConcepcion}
                loading={loadingBasesConcepcion}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="13338" alt="Modelo Largo" />
              </label>
              <InputText
                value={modelForm.modeloLargo}
                onChange={(e) => {
                  setModelForm({ ...modelForm, modeloLargo: e.target.value });
                }}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <label>
                <LanguageProvider id="14535" alt="Destino" />
              </label>
              <Dropdown
                value={modelForm.destino}
                options={destinos}
                onChange={(e) =>
                  setModelForm({ ...modelForm, destino: e.value })
                }
                disabled={isDisabled || loadingDestinos}
                loading={loadingDestinos}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="16307" alt="Cliente concesionario" />
              </label>
              <Dropdown
                value={modelForm.clienteConcepcion}
                options={clientesConcecion}
                onChange={(e) =>
                  setModelForm({ ...modelForm, clienteConcepcion: e.value })
                }
                disabled={isDisabled || loadingClientesConcecion}
                loading={loadingClientesConcecion}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="16448" alt="CAF" />
              </label>
              <InputText value={modelForm.caf} disabled={isDisabled} />
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <label>
                <LanguageProvider id="16421" alt="Destino de Origen" />
              </label>
              <Dropdown
                value={modelForm.origenDeFabricacion}
                options={destinos}
                onChange={(e) =>
                  setModelForm({ ...modelForm, origenDeFabricacion: e.value })
                }
                disabled={isDisabled || loadingOrigenes}
                loading={loadingOrigenes}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="16429" alt="Año de Fabricacion" />
              </label>
              <Dropdown
                value={modelForm.anioFabricacion}
                options={aniosFabricacion}
                onChange={(e) =>
                  setModelForm({ ...modelForm, aniosFabricacion: e.value })
                }
                disabled={isDisabled || loadingAniosFabricacion}
                loading={loadingAniosFabricacion}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="13489" alt="Actualizó con Usuario" />
              </label>
              <InputText
                value={modelForm?.usuarioUltimaModificacion?.id}
                disabled={isDisabled}
                readOnly
              />
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <label>
                <LanguageProvider id="TODO" alt="Género" />
              </label>
              <Dropdown
                value={modelForm.genero}
                options={generos}
                onChange={(e) =>
                  setModelForm({ ...modelForm, genero: e.value })
                }
                disabled={isDisabled || loadingGeneros}
                loading={loadingGeneros}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="13502" alt="Modificacion" />
              </label>
              <Dropdown
                value={modelForm.modificacion}
                options={modificationsSuggestions}
                onChange={(e) =>
                  setModelForm({ ...modelForm, modificacion: e.value })
                }
                disabled={isDisabled || loadingModificaciones}
                loading={loadingModificaciones}
                optionLabel="descripcion"
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="13490" alt="Actualizó con Fecha" />
              </label>
              <Calendar
                value={new Date(modelForm.fechaUltimaModificacion.timestamp)}
                dateFormat="dd/mm/yy"
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <label>
                <LanguageProvider id="12103" alt="Fecha Fabricacion" />
              </label>
              <Calendar
                value={new Date(modelForm.fechaSalidaFabrica.timestamp)}
                dateFormat="dd/mm/yy"
                hourFormat="24"
                showTime
                disabled={isDisabled}
              />
            </div>
            <div className="input-container">
              <label>
                <LanguageProvider id="3505" alt="NCM" />
              </label>
              <InputText value={modelForm.ncm} disabled={isDisabled} />
            </div>
          </div>
        </div>
        <div className="form-actions">
          <Button label={"Procesar"} onClick={findVinByPk} />
          <Button label={"Cancelar"} onClick={clearForm} />
        </div>
      </div>
    </div>
  );
};

export default IngresoEcom;

class IngresoEmcomModel {
  usuarioUltimaModificacion: any;
  fechaUltimaModificacion: any;
  activo: boolean;
  id: string;
  pais: any;
  cliente: any;
  cuenta: any;
  subcuenta: any;
  origen: any;
  mercado: any;
  marca: any;
  color: any;
  modelo: any;
  modeloLargo: string;
  genero: any;
  silueta: any;
  acabado: any;
  motorizacion: any;
  caja: any;
  baseConcepcion: any;
  clienteConcepcion: any;
  anioFabricacion: any;
  modificacion: any;
  tapizado: any;
  cabeceraOpcional: any;
  numeroMotor: any;
  ordenFabricacion: string;
  destino: any;
  origenDeFabricacion: any;
  fechaUltimoIngresoPlaya: any;
  fechaUltimaSalidaPLaya: any;
  fechaSalidaFabrica: any;
  fechaBloqueoCalidad: any;
  fechaIndisponible: any;
  fechaPedidoReparacion: any;
  completo: any;
  controlDaniosEcom: any;
  pasoPuestoEcom: any;
  patente: any;
  envioGrabacionCristales: any;
  noExportable: any;
  fechaPendienteIngresoTaller: any;
  descripcionIngresoTaller: any;
  numeroFactura: any;
  fechaFactura: any;
  numeroLicencia: any;
  fechaPedidoLicencia: any;
  fechaConfirmacionLicencia: any;
  ncm: string;
  clienteOriginal: any;
  caf: string;
  fechaPeritaje: any;
  fechaBloqueaLiberacionRemito: any;
  descripcionBloqueaLiberacionRemito: any;
  destinoFrancia: any;
  destinoFranciaFinal: any;
  fechaPreparacion: any;
  fechaAsignacion: any;
  fechaSolicitud: any;
  fechaPreparar: any;
  fechaDespachar: any;
  opcionales: any;
  puntero: any;
  remito: any;
  stockPlaya: any;
  observaciones: any;
  estadoVehiculo: any;
  tieneOperacionesPendientes: any;
  denominacionComercial: any;
  fechaBloqueoPreparacion: any;
  fechaM100: any;
  fechaG500: any;
  codigoPlataforma: any;
  tieneOperacionPendientesBloquea: any;
  noActualizaInterfaz: any;
  fechaEntrega: any;
  fechaBloqueoEcom: any;
  procesarEn: any;

  manufactureOrder: any;
  street: string;
  collumn: string;
  level: string;
  aniosFabricacion: any;

  constructor() {
    this.usuarioUltimaModificacion = null;
    this.fechaUltimaModificacion = {
      timestamp: new Date().toISOString(),
    };
    this.activo = false;
    this.id = "";
    this.pais = null;
    this.cliente = null;
    this.cuenta = null;
    this.subcuenta = null;
    this.origen = "";
    this.mercado = "";
    this.marca = "";
    this.color = "";
    this.modelo = "";
    this.modeloLargo = "";
    this.genero = "";
    this.silueta = "";
    this.acabado = "";
    this.motorizacion = "";
    this.caja = "";
    this.baseConcepcion = "";
    this.clienteConcepcion = "";
    this.anioFabricacion = null;
    this.modificacion = "";
    this.tapizado = "";
    this.cabeceraOpcional = "";
    this.numeroMotor = "";
    this.ordenFabricacion = "";
    this.destino = "";
    this.origenDeFabricacion = "";
    this.fechaUltimoIngresoPlaya = null;
    this.fechaUltimaSalidaPLaya = null;
    this.fechaSalidaFabrica = {
      timestamp: new Date().toISOString(),
    };
    this.fechaBloqueoCalidad = null;
    this.fechaIndisponible = null;
    this.fechaPedidoReparacion = null;
    this.completo = null;
    this.controlDaniosEcom = null;
    this.pasoPuestoEcom = null;
    this.patente = "";
    this.envioGrabacionCristales = null;
    this.noExportable = null;
    this.fechaPendienteIngresoTaller = null;
    this.descripcionIngresoTaller = null;
    this.numeroFactura = null;
    this.fechaFactura = null;
    this.numeroLicencia = null;
    this.fechaPedidoLicencia = null;
    this.fechaConfirmacionLicencia = null;
    this.ncm = "";
    this.clienteOriginal = null;
    this.caf = "";
    this.fechaPeritaje = null;
    this.fechaBloqueaLiberacionRemito = null;
    this.descripcionBloqueaLiberacionRemito = null;
    this.destinoFrancia = null;
    this.destinoFranciaFinal = null;
    this.fechaPreparacion = null;
    this.fechaAsignacion = null;
    this.fechaSolicitud = null;
    this.fechaPreparar = null;
    this.fechaDespachar = null;
    this.opcionales = null;
    this.puntero = null;
    this.remito = null;
    this.stockPlaya = null;
    this.observaciones = null;
    this.estadoVehiculo = null;
    this.tieneOperacionesPendientes = null;
    this.denominacionComercial = null;
    this.fechaBloqueoPreparacion = null;
    this.fechaM100 = null;
    this.fechaG500 = null;
    this.codigoPlataforma = null;
    this.tieneOperacionPendientesBloquea = null;
    this.noActualizaInterfaz = null;
    this.fechaEntrega = null;
    this.fechaBloqueoEcom = null;
    this.procesarEn = null;

    this.manufactureOrder = null;
    this.street = "";
    this.collumn = "";
    this.level = "";
    this.aniosFabricacion = null;
  }
}
