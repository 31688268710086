import "./DocumentacionTransportes.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Tag } from "primereact/tag";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function DocumentacionTransportes() {
  const SCREEN_CODE = "con0047_documentacion_de_transportes";

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);

  const [reporte2, setReporte2] = useState(null);
  const data2 = convertDateObjects(reporte2?.filas);



  const columnsLabelDatatable2 = [4022, 1144, 17044, 17045, 31, 1492, 3716, 13706, 5271]
  const columnsLabelDatatable2String = [];
  for (const element of columnsLabelDatatable2) {
    columnsLabelDatatable2String.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }


  const columnsLabelCH = [10948, 4655, 10643, 3627, 15739, 6197]
  const columnsLabelCHString = [];
  for (const element of columnsLabelCH) {
    columnsLabelCHString.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
// CH - Administrador, Chofer, Tipo Documento, Nro Documento, Fecha Alta, Fecha Baja
// RH - Administrador, Chofer, Tipo Documento, Nro Documento, Fecha Alta, Fecha Baja

  const columnsLabelP = [2521, 11178, 6780, 8137, 72]
  const columnsLabelPString = [];
  for (const element of columnsLabelP) {
    columnsLabelPString.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
      // P - Proveedor, CUIT, Tipo Doc, Nro Doc, Activo
  const columnsLabelT = [10948, 421, 4724, 396, 16782, 14610, 16783, 13858, 72]
  const columnsLabelTString = [];
  for (const element of columnsLabelT) {
    columnsLabelTString.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
      // T - Administrador, Transporte, Patente, Marca, Tipo de Vehiculo, Tara, Volumen real, KM Recorridos
  const columnsNames = [];





  const [origen, setOrigen] = useState([]);
  const [loadingOrigen, setLoadingOrigen] = useState(false);

  const [proveedor, setProveedor] = useState([]);
  const [loadingProveedor, setLoadingProveedor] = useState(false);

  const [chofer, setChofer] = useState([]);
  const [loadingChofer, setLoadingChofer] = useState(false);

  const [selectedItem, setSelectedItem] = useState([]);

  const [transporte, setTransporte] = useState([]);
  const [loadingTransporte, setLoadingTransporte] = useState(false);

  const [loading, setLoading] = useState(false);

  const [filter, setFilter] = useState({
    proveedor: null,
    origen: null,
    empleado: null,
    entity: null,
  });

  async function loadOrigenes() {
    setLoadingOrigen(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/documentacion-transporte/combo/origen",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          setOrigen(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigen(false);
    }
  }
  async function loadProveedores() {
    setLoadingProveedor(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/documentacion-transporte/combo/proveedor",
          {
            pais: {
              id: securityFilters.securityValues.country.id,
            },
          }
        );
        if (status === 200) {
          data.unshift({ id: null, descripcion: "TODOS" });
          setProveedor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
  }
  async function loadTransporte(proveedor: any) {
    setLoadingTransporte(true);
    try {
      if (securityFilters.securityValues.country && proveedor != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/documentacion-transporte/combo/transporte",
          {
            proveedor: proveedor,
          }
        );
        if (status === 200) {
          data.unshift({ id: null, descripcion: "TODOS" });
          setTransporte(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTransporte(false);
    }
  }
  async function loadChofer(origen: any) {
    setLoadingChofer(true);
    try {
      if (origen != null) {
        const { status, data } = await sipcoAxiosService.post(
          "/documentacion-transporte/combo/chofer",
          {
            tipoOrigen: origen,
          }
        );
        if (status === 200) {
          data.unshift({ nombre: "TODOS", apellido: "", id: null });
          setChofer(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingChofer(false);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const origenes = await loadOrigenes();
        const proveedores = await loadProveedores();
        const transportes = await loadTransporte(proveedores[0]);
        setFilter({
          ...filter,
          origen: origenes[origenes.length - 1],
          proveedor: proveedores[0],
          entity: transportes[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues.country]);

  async function loadReportData() {
    try {
      setLoading(true);
      const { status, data } = await sipcoAxiosService.post(
        "/documentacion-transporte/report",
        {
          pais: securityFilters.securityValues.country,
          tipoOrigen: filter.origen,
          proveedor: filter.proveedor,
          entity: filter.entity.id || null,
        }
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  async function loadReportDocumentacion(item: any) {
    try {
      setLoading(true);
      console.log(item);
      const { status, data } = await sipcoAxiosService.post(
        "/documentacion-transporte/report-documentacion",
        {
          tipoOrigen: filter.origen,
          origenDocumentacion: item,
        }
      );
      if (status === 200) {
        setReporte2(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  async function handleOrigenChange(value: any) {
    try {
      filter.origen = null;
      if (value.id === "CH" || value.id === "RH") {
        const choferes = await loadChofer(value);
        setFilter({
          ...filter,
          origen: value,
          entity: choferes[0],
        });
      } else if (value.id === "T") {
        const transportes = await loadTransporte(filter.proveedor);
        setFilter({
          ...filter,
          origen: value,
          entity: transportes[0],
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function handleProvedorChange(value: any) {
    try {
      if (filter?.origen?.id === "T") {
        const transportes = await loadTransporte(value);
        setFilter({ ...filter, proveedor: value, entity: transportes[0] });
      } else if(filter?.origen?.id === "P") {
        setFilter({...filter, entity: filter.proveedor})
      }else {
        setFilter({ ...filter, proveedor: value });
      }
    } catch (error) {
      console.error(error);
    }
  }

  console.log(filter);

  return (
    <div className="documentacion-transportes">
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: false,
          client: false,
          country: true,
          subaccount: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"5583"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>Origen</label>
                <Dropdown
                  loading={loadingOrigen}
                  options={origen}
                  value={filter.origen}
                  optionLabel="descripcionFull"
                  filter
                  onChange={(e) => handleOrigenChange(e.value)}
                />
              </div>
              <div className={"sipco-option"}>
                <label>Proveedor</label>
                <Dropdown
                  loading={loadingProveedor}
                  options={proveedor}
                  filter
                  value={filter.proveedor}
                  optionLabel="descripcion"
                  onChange={(e) => handleProvedorChange(e.value)}
                />
              </div>
              {filter?.origen &&
                (filter?.origen?.id === "CH" ||
                  filter?.origen?.id === "RH") && (
                  <div className={"sipco-option"}>
                    <label>Chofer</label>
                    <Dropdown
                      loading={loadingChofer}
                      options={chofer}
                      value={filter.entity}
                      filter
                      optionLabel="nombre"
                      itemTemplate={(option) =>
                        `${option.nombre} ${option.apellido} (${option.id})`
                      }
                      onChange={(e) =>
                        setFilter({ ...filter, entity: e.value })
                      }
                      virtualScrollerOptions={{ itemSize: 25 }}
                    />
                  </div>
                )}
              {filter?.origen?.id === "T" && (
                <div className={"sipco-option"}>
                  <label>Transporte</label>
                  <Dropdown
                    loading={loadingTransporte}
                    options={transporte}
                    value={filter.entity}
                    filter
                    optionLabel="descripcion"
                    onChange={(e) => setFilter({ ...filter, entity: e.value })}
                    virtualScrollerOptions={{ itemSize: 25 }}
                  />
                </div>
              )}
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Documentacion de Transportes
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Documentacion de Transportes"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
        style={{ margin: "0" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <Tag
            style={{ backgroundColor: "#fbbf24", color: "black" }}
            value={
              <LanguageProvider id={"10584"} alt="Documentación vencida" />
            }
          />
        </div>
        {filter?.origen?.id === "T" && (
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          header={"Transportes"}
          value={data}
          paginator
          scrollHeight="flex"
          rows={25}
          selectionMode="single"
          selection={selectedItem}
          onSelectionChange={(e) => loadReportDocumentacion(e.data)}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
              <Column field="1" header={columnsLabelTString[0].label} sortable filter />
              <Column field="3" header={columnsLabelTString[1].label} sortable filter />
              <Column field="6" header={columnsLabelTString[2].label} sortable filter />
              <Column field="7" header={columnsLabelTString[3].label} sortable filter />
              <Column field="8" header={columnsLabelTString[4].label} sortable filter />
              <Column field="9" header={columnsLabelTString[5].label} sortable filter />
              <Column field="10" header={columnsLabelTString[6].label} sortable filter />
              <Column field="11" header={columnsLabelTString[7].label} sortable filter />
              <Column field="12" header={columnsLabelTString[8].label} sortable filter />

        </DataTable>
        )}
        {filter?.origen?.id === "P" && (
            <DataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                header={"Sistemas"}
                value={data}
                paginator
                scrollHeight="flex"
                rows={25}
                selectionMode="single"
                selection={selectedItem}
                onSelectionChange={(e) => loadReportDocumentacion(e.data)}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                filterDisplay="menu"
            >
              <Column field="1" header={columnsLabelPString[0].label} sortable filter />
              <Column field="2" header={columnsLabelPString[1].label} sortable filter />
              <Column field="3" header={columnsLabelPString[2].label} sortable filter />
              <Column field="4" header={columnsLabelPString[3].label} sortable filter />
              <Column field="5" header={columnsLabelPString[4].label} sortable filter />
            </DataTable>
        )}
        {filter?.origen?.id === "CH" || filter?.origen?.id === "RH" && (
            <DataTable
                size={"small"}
                showGridlines
                stripedRows
                ref={dt}
                header={"Choferes"}
                value={data}
                paginator
                scrollHeight="flex"
                rows={25}
                selectionMode="single"
                selection={selectedItem}
                onSelectionChange={(e) => loadReportDocumentacion(e.data)}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                resizableColumns
                removableSort
                filterDisplay="menu"
            >
              <Column field="2" header={columnsLabelCHString[0].label} sortable filter />
              <Column field="5" header={columnsLabelCHString[1].label} sortable filter />
              <Column field="6" header={columnsLabelCHString[2].label} sortable filter />
              <Column field="7" header={columnsLabelCHString[3].label} sortable filter />
              <Column field="8" header={columnsLabelCHString[4].label} sortable filter />
              <Column field="9" header={columnsLabelCHString[5].label} sortable filter />
            </DataTable>
        )}
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          header={"Documentacion"}
          value={data2}
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
        >
          <Column field="0" header={columnsLabelDatatable2String[0].label} sortable filter />
          <Column field="1" header={columnsLabelDatatable2String[1].label} sortable filter />
          <Column field="2" header={columnsLabelDatatable2String[2].label} sortable filter />
          <Column field="3" header={columnsLabelDatatable2String[3].label} sortable filter />
          <Column field="4" header={columnsLabelDatatable2String[4].label} sortable filter />
          <Column field="5" header={columnsLabelDatatable2String[5].label} sortable filter />
          <Column field="6" header={columnsLabelDatatable2String[6].label} sortable filter />
          <Column field="7" header={columnsLabelDatatable2String[7].label} sortable filter />
          <Column field="8" header={columnsLabelDatatable2String[8].label} sortable filter />

        </DataTable>
      </Panel>
    </div>
  );
}
export default DocumentacionTransportes;
