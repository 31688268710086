import "./AnularRemitos.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Button } from "primereact/button";
import {convertDateObjects} from "@shared/components/utils/convertDateObjects";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";

export function AnularRemitos() {
  const SCREEN_CODE = "fun0047_anular_remitos";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  usePageViews();

  const dt = useRef(null);

  const filterColumnsId = [
    2131, 54, 266, 1491, 9428, 9620, 14731, 14722, 2375, 13534, 14735, 932,
    2892, 3344, 4216, 8299, 10774, 2496, 396, 2139, 2140, 1492, 1285, 10540,
    16243, 16505, 292, 295, 16926, 1617, 16757, 13338, 16288, 2892, 1617, 159,
    164, 3186, 10502, 13594, 1144, 1284, 16868, 16869, 5720, 8642, 2015, 818,
    1847, 10495, 2015, 1847, 754, 4589, 17759, 18099,
  ];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    letraDesde: "A",
    letraHasta: "Z",
    numeroDesde: 0,
    numeroHasta: 99999999,
    puntoVentaDesde: 0,
    puntoVentaHasta: 9999,
    fechaEmisionDesde: new Date(),
    fechaEmisionHasta: new Date(),
    tipoRemito: null,
    nota: "",

  });

  const [tiposRemito, setTiposRemito] = useState([]);
  const [loadingTiposRemito, setLoadingTiposRemito] = useState(false);
  const toast = useRef<Toast>(null);

  const [loadingReport, setLoadingReport] = useState(false);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const [selectedRows, setSelectedRows] = useState([]);
  const selectAllRows = () => {
    setSelectedRows(reporte);
  };
  const deselectAllRows = () => {
    setSelectedRows([]);
  };
  async function loadTiposRemito() {
    setLoadingTiposRemito(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
          "/anular-remitos/codigo-playa",
          {
            pais: securityFilters.securityValues.country
          }
      );
      if (status === 200) {
        setTiposRemito(data);
        return data;

      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingTiposRemito(false);
    }
  }
  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const tiposRemito = await loadTiposRemito();
          setFilter({
            ...filter,
            tipoRemito: tiposRemito[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  async function loadReportData() {
    try {
      setLoadingReport(true);
      const fechaEmisionDesde = new Date(filter.fechaEmisionDesde);
      const fechaEmisionHasta = new Date(filter.fechaEmisionHasta);
      const filters = {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          cliente : securityFilters.securityValues.client,
          tipoRemito: filter.tipoRemito,
          letraDesde: filter.letraDesde,
          letraHasta: filter.letraHasta,
          numeroRemitoDesde: filter.numeroDesde,
          numeroRemitoHasta: filter.numeroHasta,
          puntoVentaDesde: filter.puntoVentaDesde,
          puntoVentaHasta: filter.puntoVentaHasta,
          fechaDesde: {
            date: fechaEmisionDesde.toISOString()
          },
          fechaHasta: {
            date: fechaEmisionHasta.toISOString()
          }
      };
      const { status, data } = await sipcoAxiosService.post(
        "/anular-remitos/remito",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  }
  async function anularRemitos() {
    try {
      setLoadingReport(true);

      const filters = {
        pais: securityFilters.securityValues.country,
        cuenta: securityFilters.securityValues.account,
        cliente : securityFilters.securityValues.client,
        tipoRemito: filter.tipoRemito,
        remitos: selectedRows,
        nota: filter.nota,
      };
      const { status } = await sipcoAxiosService.post(
          "/anular-remitos/anular-remito",
          filters
      );
      if (status === 200) {
        loadReportData();
        toast.current?.show({
          severity: "success",
          summary: "Sucesso",
          detail: "processado",
          life: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  }
  function anularConfirModal() {
      confirmDialog({
        header: <LanguageProvider id={"16239"} alt="Atención" />,
        message: (
            <LanguageProvider
                id={"13275"}
                alt="¿Desea procesar los cambios realizados?"
            />
        ),
        icon: "pi pi-exclamation-triangle",
        defaultFocus: "reject",
        accept: () => anularRemitos(),
        reject: null,
      });

  }
  return (
    <div className="anular-remitos">
      <Toast ref={toast} position="bottom-left" />
      <ConfirmDialog />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: false,
          allClientsOptions: false,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"22550"} alt="Filtrar Por" />}>
          <div className="form">

            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"849"} alt="Letra" />
                </label>
                <div className="form-row">
                  <InputText
                    value={filter.letraDesde}
                    onChange={(e) => {
                      setFilter({ ...filter, letraDesde: e.target.value });
                    }}
                  />
                  <InputText
                    value={filter.letraHasta}
                    onChange={(e) => {
                      setFilter({ ...filter, letraHasta: e.target.value });
                    }}
                  />
                </div>
              </div>
            </div>


            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"850"} alt="Pto.Venta" />
                </label>
                <div className="form-row">
                  <InputNumber
                    value={filter.puntoVentaDesde}
                    onChange={(e) => {
                      setFilter({ ...filter, puntoVentaDesde: e.value });
                    }}
                    min={0}
                  />
                  <InputNumber
                    value={filter.puntoVentaHasta}
                    onChange={(e) => {
                      setFilter({ ...filter, puntoVentaHasta: e.value });
                    }}
                    min={0}
                  />
                </div>
              </div>
            </div>



            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"851"} alt="Número" />
                </label>
                <div className="form-row">
                  <InputNumber
                    value={filter.numeroDesde}
                    onChange={(e) => {
                      setFilter({ ...filter, numeroDesde: e.value });
                    }}
                    min={0}
                  />
                  <InputNumber
                    value={filter.numeroHasta}
                    onChange={(e) => {
                      setFilter({ ...filter, numeroHasta: e.value });
                    }}
                    min={0}
                  />
                </div>
              </div>
            </div>




            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"21422"} alt="Fecha Emision" />
                </label>
                <div className="form-row">
                  <Calendar
                    value={filter.fechaEmisionDesde}
                    onChange={(e) => {
                      setFilter({ ...filter, fechaEmisionDesde: e.value });
                    }}
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showIcon
                  />
                  <Calendar
                    value={filter.fechaEmisionHasta}
                    onChange={(e) => {
                      setFilter({ ...filter, fechaEmisionHasta: e.value });
                    }}
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showIcon
                  />
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1617"} alt="Tipo remito" />
                </label>
                <Dropdown
                    value={filter.tipoRemito}
                    options={tiposRemito}
                    onChange={(e) => setFilter({...filter, tipoRemito: e.value})}
                    loading={loadingTiposRemito}
                    optionLabel="descripcion"
                    filter
                    showClear
                />
              </div>
            </div>

          </div>
        </AccordionTab>
      </Filter>
      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="275" alt="Remitos" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={LanguageProvider({ id: "275", alt: "Remitos" })}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}

        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({ id: "748", alt: "Seleccionar todo" })}
                  text
                  onClick={selectAllRows}
                />
                <Button
                  label={LanguageProvider({ id: "749", alt: "Deseleccionar todo" })}
                  text
                  onClick={deselectAllRows}
                />
                <Button
                  label={LanguageProvider({ id: "34", alt: "Procesar o guardar cambios" })}
                  text
                  onClick={anularConfirModal}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReport}
          filterDisplay="menu"
          selectionMode={'checkbox'}
          selection={selectedRows}
          onSelectionChange={(e) => setSelectedRows(e.value)}
        >
          <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
          <Column field="letra" header={"Letra"} sortable filter />
          <Column field="puntoDeVenta" header={"Pto.Venta"} sortable filter />
          <Column field="id" header={"Código Remito"} sortable filter />
          <Column field="vin.id" header={"VIN"} sortable filter />
          <Column
              field="fechaEmision.date"
              header={columnsNames[9].label}
              body={(rowData) => {
                if (rowData?.fechaEmision?.date) {
                  return new Date(rowData.fechaEmision.date).toLocaleString('pt-BR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false
                  });
                } else {
                  return '';
                }
              }}
          ></Column>

           <Column field="vin.marca.descripcion" header={"Marca"} sortable filter />
          <Column field="vin.modelo.descripcionFull" header={"Modelo"} sortable filter />
          <Column field="vin.color.descripcion" header={"Color"} sortable filter />
          <Column field="estadoRemito.descripcion" header={"Estado"} sortable filter />
          <Column field="idPedidoCliente" header={"Cód.Pedido Cliente"} sortable filter />
          <Column field="concesionario.descripcion" header={"Concesionario"} sortable filter />
          <Column field="direccionEntrega.descripcion" header={"Dirección"} sortable filter />
          <Column field="razonSocialImpresion" header={"Razón Social"} sortable filter />
          <Column field="domicilioImpresion" header={"Domicilio"} sortable filter />
          <Column field="provinciaImpresion" header={"Provincia"} sortable filter />
          <Column field="localidadImpresion" header={"Localidad"} sortable filter />
        </DataTable>
        <InputText
            value={filter.nota}
            onChange={(e) => setFilter({ ...filter, nota: e.target.value })}
            style={{ width: '100%' }}
            type="search"
            placeholder="Nota"
        />      </Panel>
    </div>
  );
}
export default AnularRemitos;
