import "./RegistracionOperacionPorOt.scss";

import React, { useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import Lectora from "./components/Lectora";
import Manual from "./components/Manual";
import Masiva from "./components/Masiva";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function RegistracionOperacionPorOt() {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const SCREEN_CODE = "fun0202_registracion_operacion_por_ot";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const [reporte, setReporte] = useState(null);
  const toast = useRef<Toast>(null);

  const data = convertDateObjects(reporte?.filas);

  return (
    <div className="registration-operacion-por-ot">
      <Toast ref={toast} position="top-center" />
      <Filter
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      />
      <Fieldset
        legend={
          <LanguageProvider
            id={"18312"}
            alt="Registración de operaciones por OT"
          />
        }
      >
        <TabView>
          <TabPanel header={<LanguageProvider id={"TODO"} alt="Lectora" />}>
            <Lectora />
          </TabPanel>
          <TabPanel header={<LanguageProvider id={"6850"} alt="Manual" />}>
            <Manual />
          </TabPanel>
          <TabPanel header={<LanguageProvider id={"TODO"} alt="Masiva" />}>
            <Masiva />
          </TabPanel>
        </TabView>


      </Fieldset>
    </div>
  );
}
export default RegistracionOperacionPorOt;
