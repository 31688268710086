import "./ConsultaOperacionesPendientes.scss";

import { MultiSelect } from "primereact/multiselect";
import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { inactivoColorT } from "../../../hooks/useInactivoColor";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import useRowStyles from "../../../hooks/useRowStyles";
import { Dropdown } from "primereact/dropdown";

export function ConsultaOperacionesPendientes() {
  const SCREEN_CODE = " ";
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const toast = useRef<Toast>(null);
  ScreenCodeValue(SCREEN_CODE);

  usePageViews();
  const dt = useRef(null);

  const showError = (id: string, alt: string) => {
    toast.current.show({
      severity: "error",
      summary: "Erro",
      detail: <LanguageProvider id={id} alt={alt} />,
      life: 3000,
    });
  };

  const [loadingReportData, setLoadingReportData] = useState(false);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const [selectedReportData, setSelectedReportData] = useState(null);
  const rowStyles = useRowStyles([selectedReportData]);

  const [filter, setFilter] = useState({
    origenSolicitud: [],
    grupoOperacion: null,
    operacion: [],
    vin: null,
    remitoDespacho: false,
    archivoRegistracionWeb: false,
  });

  const [origenSolicitud, setOrigenSolicitud] = useState([]);
  const [loadingOrigenSolicitud, setLoadingOrigenSolicitud] = useState(false);
  async function loadOrigenSolicitud() {
    setLoadingOrigenSolicitud(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/report/codigo-playa",
          {
            codPais: securityFilters.securityValues.country.id || null,
          }
        );

        if (status === 200) {
          setOrigenSolicitud(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOrigenSolicitud(false);
    }
    setOrigenSolicitud([]);
    return [];
  }

  const [grupoOperacion, setGrupoOperacion] = useState([]);
  const [loadingGrupoOperacion, setLoadingGrupoOperacion] = useState(false);
  async function loadGrupoOperacion() {
    setLoadingGrupoOperacion(true);
    try {
      if (securityFilters?.securityValues?.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/report/grupo-operacion",
          {
            codPais: securityFilters.securityValues.country.id || null,
          }
        );

        if (status === 200) {
          const grupoOpData = data.map((x: any) => {
            return { ...x, label: `${x.descripcion} (${x.id})` };
          });
          grupoOpData.unshift({
            id: 0,
            label: "TODOS",
            descripcion: "TODOS",
          });
          setGrupoOperacion(grupoOpData);
          return grupoOpData;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGrupoOperacion(false);
    }
    setGrupoOperacion([]);
    return [];
  }

  const [operacion, setOperacion] = useState([]);
  const [loadingOperacion, setLoadingOperacion] = useState(false);
  async function loadOperacion(grupoOperacionId) {
    setLoadingOperacion(true);
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/report/operacion",
          {
            codPais: securityFilters.securityValues.country.id || null,
            grupoOperacion: grupoOperacionId || null,
          }
        );
        if (status === 200) {
          setOperacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperacion(false);
    }
    setOperacion([]);
    return [];
  }

  const [vin, setVin] = useState([]);
  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(vin: any) {
    try {
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/report/vin-op-pendiente",
          {
            codPais: securityFilters.securityValues.country.id || null,
            codCliente: securityFilters.securityValues.client.id || null,
            codCuenta: securityFilters.securityValues.account.id || null,
            vin: vin || null,
          }
        );

        if (status === 200) {
          if (data.length === 0) {
            return showError(
              "900",
              "No existe VIN para el Cliente, Cuenta y Subcuenta seleccionado"
            );
          }
          setVin(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setVin([]);
    return [];
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country) {
        try {
          const origenSolicitudes = await loadOrigenSolicitud();
          const grupoOperaciones = await loadGrupoOperacion();

          setFilter((prevFilter) => ({
            ...prevFilter,
            origenSolicitud:
              origenSolicitudes?.length > 0 ? origenSolicitudes : null,
            grupoOperacion:
              grupoOperaciones?.length > 0 ? grupoOperaciones[0] : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters?.securityValues]);

  useEffect(() => {
    async function updateOperacion() {
      if (filter?.grupoOperacion) {
        try {
          const operaciones = await loadOperacion(filter.grupoOperacion.id);
          setFilter((prevFilter) => ({
            ...prevFilter,
            operacion: operaciones?.length > 0 ? operaciones : null,
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    updateOperacion();
  }, [filter?.grupoOperacion]);

  async function loadReportData() {
    try {
      setLoadingReportData(true);
      const filters = {
        codPais: securityFilters?.securityValues?.country?.id || null,
        codCliente: securityFilters?.securityValues?.client?.id || "",
        clienteDescripcion:
          securityFilters?.securityValues?.client?.descripcion || "",
        codCuenta: securityFilters?.securityValues?.account?.id || null,
        codSubcuenta: securityFilters?.securityValues?.subaccount?.id || null,
        origenSolicitud: filter?.origenSolicitud?.map((x) => x.id) || [],
        grupoOperacion: filter?.grupoOperacion?.id || 0,
        codOperacion: filter?.operacion?.map((x) => x.id) || [],
        vin: filter?.vin?.id || "",
        remitoDespacho: filter.remitoDespacho,
        archivoRegistracionWeb: filter.archivoRegistracionWeb,
      };
      const { status, data } = await sipcoAxiosService.post(
        "/report/operaciones-pendientes",
        filters
      );
      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReportData(false);
    }
  }

  return (
    <div className="consulta-operaciones-pendientes">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          country: true,
          client: true,
          account: true,
          subaccount: true,
          allClientsOptions: true,
          allSubaccountsOptions: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row ">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="18308" alt="Origen Solicitud" />
                </label>
                <MultiSelect
                  value={filter.origenSolicitud}
                  options={origenSolicitud}
                  onChange={(e) => {
                    setFilter({ ...filter, origenSolicitud: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingOrigenSolicitud}
                  showClear
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="18309" alt="Grupo Operación)" />
                </label>
                <Dropdown
                  value={filter.grupoOperacion}
                  options={grupoOperacion}
                  onChange={(e) =>
                    setFilter({ ...filter, grupoOperacion: e.value })
                  }
                  optionLabel="label"
                  loading={loadingGrupoOperacion}
                  itemTemplate={inactivoColorT}
                  filter
                />
              </div>
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="18637" alt="Operación" />
                </label>
                <MultiSelect
                  value={filter.operacion}
                  options={operacion}
                  onChange={(e) => {
                    setFilter({ ...filter, operacion: e.value });
                  }}
                  display="chip"
                  filter
                  optionLabel="label"
                  loading={loadingOperacion}
                  showClear
                />
              </div>
            </div>

            <div className="form-row ">
              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id="662" alt="Ingrese el VIN)" />
                </label>
                <AutoComplete
                  value={filter?.vin ?? ""}
                  suggestions={vin}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="id"
                  dropdown
                  forceSelection
                  maxLength={17}
                />
              </div>

              <div className="checkbox-filter">
                <label>
                  <LanguageProvider id="321" alt="Remito de Despacho" />
                </label>
                <Checkbox
                  checked={filter.remitoDespacho}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      remitoDespacho: !filter.remitoDespacho,
                    });
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
                <label>
                  <LanguageProvider id="19178" alt="Archivo registración WEB" />
                </label>
                <Checkbox
                  checked={filter.archivoRegistracionWeb}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      archivoRegistracionWeb: !filter.archivoRegistracionWeb,
                    });
                  }}
                  style={{ justifyContent: "center", position: "relative" }}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="6697" alt="Listado"></LanguageProvider>
                {` (${Array.isArray(data) ? data.length : "0"})`}
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={null}
                  screenName={"Listado Operaciones Pendientes"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={data}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          rowClassName={rowStyles}
          filterDisplay="menu"
          loading={loadingReportData}
          selectionMode="single"
          selection={selectedReportData}
          onSelectionChange={(e) => setSelectedReportData(e.value)}
        >
          <Column
            field="0"
            header={<LanguageProvider id="54" alt="Cliente"></LanguageProvider>}
            sortable
            filter
          />
          <Column
            field="1"
            header={<LanguageProvider id="2892" alt="Playa"></LanguageProvider>}
            sortable
            filter
          />
          <Column
            field="2"
            header={<LanguageProvider id="1491" alt="VIN"></LanguageProvider>}
            sortable
            filter
          />
          <Column
            field="3"
            header={
              <LanguageProvider id="1569" alt="Operación"></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="4"
            header={<LanguageProvider id="990" alt="Calle"></LanguageProvider>}
            sortable
            filter
          />
          <Column
            field="5"
            header={
              <LanguageProvider id="991" alt="Columna"></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="6"
            header={<LanguageProvider id="396" alt="Marca"></LanguageProvider>}
            sortable
            filter
          />
          <Column
            field="7"
            header={
              <LanguageProvider
                id="13514"
                alt="Código Modelo"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="8"
            header={
              <LanguageProvider id="23864" alt="Modelo"></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="9"
            header={
              <LanguageProvider
                id="18305"
                alt="Código Color"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="10"
            header={<LanguageProvider id="2140" alt="Color"></LanguageProvider>}
            sortable
            filter
          />
          <Column
            field="11"
            header={
              <LanguageProvider id="10034" alt="Familia"></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="12"
            header={
              <LanguageProvider id="367" alt="Mercado"></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="13"
            header={
              <LanguageProvider
                id="12251"
                alt="Código Destino"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="14"
            header={
              <LanguageProvider id="3410" alt="Destino"></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="15"
            header={
              <LanguageProvider
                id="18202"
                alt="Denominación Comercial"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="16"
            header={
              <LanguageProvider
                id="11507"
                alt="Fecha Pendiente"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="17"
            header={
              <LanguageProvider id="3184" alt="Remito"></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="18"
            header={
              <LanguageProvider
                id="1492"
                alt="Fecha Emisión"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="19"
            header={
              <LanguageProvider id="3344" alt="Estado"></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="20"
            header={
              <LanguageProvider
                id="10871"
                alt="Cod. Concesionario"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="21"
            header={
              <LanguageProvider
                id="2941"
                alt="Concesionario"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="22"
            header={
              <LanguageProvider
                id="10868"
                alt="Cod. Dir. Entrega"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="23"
            header={
              <LanguageProvider
                id="10904"
                alt="Dir. Entrega"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="24"
            header={
              <LanguageProvider
                id="18306"
                alt="Fecha Solicitud WEB"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="25"
            header={
              <LanguageProvider
                id="18307"
                alt="Usuario Solicitud WEB"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="26"
            header={
              <LanguageProvider id="15739" alt="Fecha Alta"></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="27"
            header={
              <LanguageProvider
                id="19690"
                alt="Fecha Priorización"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="28"
            header={
              <LanguageProvider
                id="5366"
                alt="Orden de Trabajo"
              ></LanguageProvider>
            }
            sortable
            filter
          />
          <Column
            field="29"
            header={
              <LanguageProvider
                id="14370"
                alt="Fecha generación OT"
              ></LanguageProvider>
            }
            sortable
            filter
          />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ConsultaOperacionesPendientes;
