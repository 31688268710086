import "./RegistracionOperacionAutomatica.scss";

import { Accordion, AccordionTab } from "primereact/accordion";
import React, { useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";

import { Calendar } from "primereact/calendar";
import { Fieldset } from "primereact/fieldset";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import Lectora from "./components/Lectora";
import Manual from "./components/Manual";
import Masiva from "./components/Masiva";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function RegistracionOperacionAutomatica() {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const SCREEN_CODE = "fun0205_registracion_operacion_automatica";
  ScreenCodeValue(SCREEN_CODE);
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);
  const [filter, setFilter] = useState({
    vin: "",
    fechaRealizacion: new Date(),
  });

  async function consultaStock() {
    setLoading(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        filter.vin != ""
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-automatica/vin-stock",
          {
            pais: securityFilters?.securityValues?.country || null,
            cliente: securityFilters?.securityValues?.client || null,
            cuenta: securityFilters?.securityValues?.account || null,
            vin: {
              id: filter.vin,
            },
          }
        );

        if (status === 200) {
          if (data.length > 0) {
            await loadReportManual();
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Vin fora do Stock",
            life: 3000,
          });
        }
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error al consultar stock",
        life: 3000,
      });
    } finally {
      setLoading(false);
    }
  }
  async function  handleCalendarChange(event) {
    const value = event.target.value;
    setFilter({ ...filter, fechaRealizacion: value });
  };
  async function loadReportManual() {
    setLoading(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        securityFilters?.securityValues?.client != null &&
        securityFilters?.securityValues?.account != null &&
        filter.vin != ""
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-automatica/manual/operacion-pendiente",
          {
            pais: securityFilters?.securityValues?.country || null,
            cliente: securityFilters?.securityValues?.client || null,
            cuenta: securityFilters?.securityValues?.account || null,
            vin: {
              id: filter.vin,
            },
          }
        );

        if (status === 200) {
          setReporte(data);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="registracion-operacion-automatica">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={consultaStock}
        securityOptions={{
          account: true,
          client: true,
          country: true,
          subaccount: true,
          screenCode: SCREEN_CODE,
        }}
      />
      <Accordion>
        <AccordionTab header={<LanguageProvider id={"1491"} alt="VIN" />}>
          <div className="filter-options">
            <div className="sipco-options-line">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"1491"} alt="VIN" />
                </label>
                <InputText
                  value={filter.vin}
                  onChange={(e) =>
                    setFilter({ ...filter, vin: e.target.value })
                  }
                />{" "}
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={"2781"} alt="Fecha de Realización" />
                </label>
                <Calendar showIcon
                          showTime
                          showButtonBar
                          value={filter.fechaRealizacion}
                          onChange={handleCalendarChange}
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
      <Fieldset
        legend={
          <LanguageProvider
            id={"18330"}
            alt="Registración de Operaciones Automaticas"
          />
        }
      >
        <TabView>
          <TabPanel header={<LanguageProvider id={"TODO"} alt="Lectora" />}>
            <Lectora />
          </TabPanel>
          <TabPanel header={<LanguageProvider id={"6850"} alt="Manual" />}>
            <Manual reporte={reporte} vin={filter.vin} fechaRealizacion={filter.fechaRealizacion}/>
          </TabPanel>
          <TabPanel header={<LanguageProvider id={"TODO"} alt="Masiva" />}>
            <Masiva />
          </TabPanel>
        </TabView>
      </Fieldset>
    </div>
  );
}
export default RegistracionOperacionAutomatica;
