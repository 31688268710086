import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import React, {useEffect, useState} from "react";
import {useSipcoAxiosService} from "@services/axios/sipco-axios-service";
import {useFilterContext} from "../../../../protected-routes";
import {Dropdown} from "primereact/dropdown";

const ReparacionMasivaDanos: React.FC<{ vinData: any }> = ({ vinData }) => {

  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();

  const filterColumnsId = [1491, 8385, 3625];
  const columnsNames = [];
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const [filter, setFilter] = useState({
    parteDanada:null,
    tipoDano:null,
    gravedadDano: null,
    vin: null,
  });
  const [gravedadDanos, setGravedadDanos] = useState([]);
  const [loadingGravedadDanos, setLoadingGravedadDanos] = useState(false);

  const [parteDanadas, setParteDanadas] = useState([]);
  const [loadingParteDanadas, setLoadingParteDanadas] = useState(false);

  const [tipoDano, setTipoDano] = useState([]);
  const [loadingTipoDano, setLoadingTipoDano] = useState(false);

  const [vin, setVin] = useState(null);
  const [loadingVin, setLoadingVin] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }

  async function loadVin(query: any) {
    setLoadingVin(true);
    try {
      if (securityFilters?.securityValues?.country != null &&
          securityFilters?.securityValues?.client != null &&
          securityFilters?.securityValues?.account != null
      ) {

        const { status, data } = await sipcoAxiosService.post(
            "/carga-masiva-danos/vin-reparado",
            {
              pais: securityFilters?.securityValues?.country,
              cliente: securityFilters?.securityValues?.client,
              cuenta: securityFilters?.securityValues?.account,
              vin: { id: query},
            }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVin(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingVin(false);
    }
  }
  async function loadGravedadDanos() {
    if (securityFilters.securityValues.country != null &&
        securityFilters.securityValues.client != null) {
      setLoadingGravedadDanos(true);
      try {
        const {status, data} = await sipcoAxiosService.post(
            "/carga-masiva-danos/gravedad-dano",
            {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,

            }
        );
        if (status === 200) {
          setGravedadDanos(data);
          return data;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingGravedadDanos(false);
      }
    }
  }
  async function loadTipoDanos() {
    if ( securityFilters.securityValues.country != null &&
        securityFilters.securityValues.client != null) {
      setLoadingTipoDano(true);
      try {
        const {status, data} = await sipcoAxiosService.post(
            "/carga-masiva-danos/tipo-de-dano",
            {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,
            }
        );
        if (status === 200) {
          setTipoDano(data);
          return data;

        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingTipoDano(false);
      }
    }
  }
  async function loadParteDanadas() {
    if (securityFilters.securityValues.country != null &&
        securityFilters.securityValues.client != null) {
      setLoadingParteDanadas(true);
      try {
        const {status, data} = await sipcoAxiosService.post(
            "/carga-masiva-danos/partes-dano",
            {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,
            }
        );
        if (status === 200) {
          setParteDanadas(data);
          return data;

        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingParteDanadas(false);
      }
    }
  }

  useEffect(() => {
    async function initialize() {
      if (securityFilters?.securityValues?.country != null) {
        try {
          const parteDanadass = await loadParteDanadas();
          const tiposDanos = await loadTipoDanos();
          const gravedadDanoss = await loadGravedadDanos();
          setFilter({
            ...filter,
            parteDanada: parteDanadass[0],
            tipoDano: tiposDanos[0],
            gravedadDano: gravedadDanoss[0],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
    initialize();
  }, [securityFilters.securityValues]);

  return (
    <div className="vin-view">
      <Panel>
        <div className="form-row">
          <div className={"form-field"}>
            <div className="sipco-option">
              <label>Descripción Daño</label>
              <Dropdown
                  value={filter.tipoDano}
                  options={tipoDano}
                  onChange={(e) => setFilter({...filter, tipoDano: e.value})}
                  loading={loadingTipoDano}
                  optionLabel="descripcion"
                  filter
                  showClear
              />            </div>
            <div className="sipco-option">
              <label>Fecha Reparado</label>
              <Calendar
                showButtonBar
                showIcon
                dateFormat="dd/mm/yy"
                value={new Date()}
              />
            </div>
          </div>
          <div className={"form-field"}>
            <div className="sipco-option">
              <label>Parte dañada</label>
              <Dropdown
                  value={filter.parteDanada}
                  options={parteDanadas}
                  onChange={(e) => setFilter({...filter, parteDanada: e.value})}
                  loading={loadingParteDanadas}
                  optionLabel="descripcion"
                  filter
                  showClear
              />
            </div>
          </div>
          <div className={"form-field"}>
            <div className="sipco-option">
              <label>Gravedad del Daño</label>
              <Dropdown
                  value={filter.gravedadDano}
                  options={gravedadDanos}
                  onChange={(e) => setFilter({...filter, gravedadDano: e.value})}
                  loading={loadingGravedadDanos}
                  optionLabel="descripcion"
                  filter
                  showClear
              />
            </div>
          </div>
        </div>
      </Panel>
      <Fieldset
        legend={
          <label>
            Datos - Cantidad de Registros a Procesar.: 0 - Registros con
            errores.: 0
          </label>
        }
      >
        <div>
          <label style={{ marginRight: "10px" }}>
            <LanguageProvider id={1491} alt="VIN:" />
          </label>
          <AutoComplete
              value={filter.vin}
              suggestions={vin}
              completeMethod={searchVin}
              onChange={(e) => setFilter({...filter, vin: e.value || null})}
              field="label"
              dropdown
              forceSelection
          />
        </div>
        <Divider />
        <DataTable
          size="small"
          showGridlines
          stripedRows
          paginator
          scrollHeight="flex"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          filterDisplay="menu"
          selectionMode="single"
        >
          <Column field="1" header={columnsNames[0].label} sortable filter />
          <Column field="2" header={columnsNames[1].label} sortable filter />
          <Column field="3" header={columnsNames[2].label} sortable filter />
        </DataTable>

        <div>
          <Button label={LanguageProvider({ id: "23824", alt: "Alta" })} text />
        </div>
      </Fieldset>
    </div>
  );
};

export default ReparacionMasivaDanos;
