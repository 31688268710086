import "./ContabilizacionAnulacion.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { Toast } from "primereact/toast";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { Checkbox } from "primereact/checkbox";

export function ContabilizacionAnulacion() {
  const SCREEN_CODE = "fun0086_contabilizacion_anulacion_de_operaciones";
  ScreenCodeValue(SCREEN_CODE);
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  usePageViews();
  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useRef<Toast>(null);

  const [taller, setTaller] = useState([]);

  const [compraTaller, setCompraTaller] = useState([]);

  const [filter, setFilter] = useState({
    marca: null,
    fechaOperacion: false,
    fechaOperacionDesde: new Date(),
    fechaOperacionHasta: new Date(),
    fechaContabilizacion: false,
    fechaContabilizacionDesde: new Date(),
    fechaContabilizacionHasta: new Date(),
    estados: null,
    operacion: null,
  });
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [
    848, 55555, 55555, 55555, 55555, 55555, 449, 10774, 396, 497, 498, 55555,
    8588,
  ];

  const columnsNames = [];
  const filterColumnsId = [
    16949, 2518, 11833, 2519, 16483, 16480, 8627, 3756, 16968, 3756, 16481,
    16482, 3756, 13706, 23848,
  ];
  for (let i = 0; i < filterColumnsId.length; i++) {
    columnsNames.push({
      id: filterColumnsId[i],
      label: LanguageProvider({
        id: filterColumnsId[i],
        alt: "Error Columns Labels",
      }),
    });
  }


  const [estados, setEstados] = useState([]);
  const [loadingEstados, setLoadingEstados] = useState(false);
  async function loadEstados() {
    try {
      setLoadingEstados(true);
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/contabilizacio-anulacion-de-operaciones/estado-operacion",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setEstados(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingEstados(false);
    }
  }

  const [operacion, setOperacion] = useState([]);
  const [loadingOperacion, setLoadingOperacion] = useState(false);

  async function loadOperacion() {
    try {
      setLoadingOperacion(true);
      if (securityFilters.securityValues.country) {
        const { status, data } = await sipcoAxiosService.post(
          "/contabilizacio-anulacion-de-operaciones/operacion",
          {
            pais: securityFilters.securityValues.country,
          }
        );
        if (status === 200) {
          setOperacion(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOperacion(false);
    }
  }

  async function loadReportData() {
    try {
      setLoading(true);
      const reportResult = await sipcoAxiosService.post(
        "/compras-taller/find-compra-taller",
        {
          pais: securityFilters.securityValues?.country,
          cuenta: securityFilters.securityValues?.account,
          subcuenta: securityFilters.securityValues?.subaccount,
          taller: {
            id: filter.marca,
          },
        }
      );

      if (reportResult) {
        const { status, data } = reportResult;
        if (status === 200 && data) {
          const response = data.map((x) => {
            return {
              ...x,
              codCompra: x?.id,
              tipoTaller: x?.taller?.id,
              descripcion: x?.tipoCompra.descripcion,
              fechaCompra: x?.fechaUltimaModificacion.date,
              codEstado: x?.estado.descripcion,
              nroPedidoPSA: x?.nroPedidPsa,
              codProveedor: x?.proveedor.id,
              descripcionProveedor: x?.proveedor.descripcion,
              codCliente: x?.cliente.id,
              descripcionCliente: x?.cliente.descripcion,
              codRemito: x?.remito,
              codMoneda: x?.moneda.id,
              descripcionMoneda: x?.moneda.descripcion,
            };
          });

          setCompraTaller(response);
          setReporte(data);
          return response;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const columnsNamesTable = [
    { field: "codCompra", header: "Nro. Operación" },
    { field: "tipoTaller", header: "Descripcion" },
    { field: "descripcion", header: "Vin" },
    { field: "fechaCompra", header: "Fecha realizada" },
    { field: "codEstado", header: "Estado" },
    { field: "codEstado", header: "Fec. Contabilizada" },
    { field: "codEstado", header: "Fec. Anulada" },
    { field: "codEstado", header: "Operac. Prov." },
    { field: "codEstado", header: "Operac. Prov." },
    { field: "codEstado", header: "Stock Proveedor" },
    { field: "codEstado", header: "Stock Proveedor" },
    { field: "codEstado", header: "Actualizó Usuario" },
    { field: "codEstado", header: "Actualizó Usuario" },
  ];



  useEffect(() => {
    const init = () => {
      try {
        const estados = loadEstados();
        const operacion = loadOperacion();


        setFilter({
          ...filter,
          estados: estados[0],
          operacion: operacion[0],
        });

      } catch (error) {
        console.error(error);
      }
    }

    init();
  }, [securityFilters.securityValues])

  return (
    <div className="contabilizacion-anulacion">
      <Toast ref={toast} position="top-center" />
      <Filter
        onSearch={loadReportData}
        securityOptions={{
          account: true,
          country: true,
          client: true,
          screenCode: SCREEN_CODE,
        }}
      >
        <AccordionTab
          header={<LanguageProvider id={"22550"} alt="Filtrar Por" />}
        >
          <div className="form">
            <div className={"form-row"}>

              <div className="checkBox-spico">
                <div className="checkbox">
                  <label>
                    <LanguageProvider id={"2786"} alt="Fecha de Operacion: " />
                  </label>
                  <Checkbox checked={filter.fechaOperacion} onChange={(e) => setFilter({ ...filter, fechaOperacion: e.checked })} />
                </div>
                <div className="checkbox">
                  <label>
                    <LanguageProvider id={"2787"} alt="Fecha de Contabilización: " />
                  </label>
                  <Checkbox checked={filter.fechaContabilizacion} onChange={(e) => setFilter({ ...filter, fechaContabilizacion: e.checked })} />
                </div>
              </div>
              <div className={"sipco-calendar"}>
                <label>
                  <LanguageProvider id={"16921"} alt="Desde: " />
                </label>
                {!filter.fechaOperacion ?
                  (<>
                    <Calendar value={filter.fechaOperacionDesde} onChange={(e) => setFilter({ ...filter, fechaOperacion: e.value })} showTime showIcon disabled />
                  </>)
                  :
                  (<>
                    <Calendar value={filter.fechaOperacionDesde} onChange={(e) => setFilter({ ...filter, fechaOperacion: e.value })} showTime showIcon />
                  </>)
                }

                <label>
                  <LanguageProvider id={"16921"} alt="Desde: " />
                </label>
                {!filter.fechaContabilizacion ?
                  (<>
                    <Calendar value={filter.fechaContabilizacionDesde} onChange={(e) => setFilter({ ...filter, fechaContabilizacionDesde: e.value })} showTime showIcon disabled />
                  </>)
                  :
                  (<>
                    <Calendar value={filter.fechaContabilizacionDesde} onChange={(e) => setFilter({ ...filter, fechaContabilizacionDesde: e.value })} showTime showIcon />
                  </>)
                }
              </div>

              <div className={"sipco-calendar"}>
                <label>
                  <LanguageProvider id={"2622"} alt="Hasta: " />
                </label>
                {!filter.fechaOperacion ?
                  (<>
                    <Calendar value={filter.fechaOperacionHasta} onChange={(e) => setFilter({ ...filter, fechaOperacionHasta: e.value })} showTime showIcon disabled />
                  </>)
                  :
                  (<>
                    <Calendar value={filter.fechaOperacionHasta} onChange={(e) => setFilter({ ...filter, fechaOperacionHasta: e.value })} showTime showIcon />
                  </>)
                }

                <label>
                  <LanguageProvider id={"2622"} alt="Hasta: " />
                </label>
                {!filter.fechaContabilizacion ?
                  (<>
                    <Calendar value={filter.fechaContabilizacionHasta} onChange={(e) => setFilter({ ...filter, fechaContabilizacionHasta: e.value })} showTime showIcon disabled />
                  </>)
                  :
                  (<>
                    <Calendar value={filter.fechaContabilizacionHasta} onChange={(e) => setFilter({ ...filter, fechaContabilizacionHasta: e.value })} showTime showIcon />
                  </>)
                }
              </div>

              <div className={"sipco-option"}>
                <label>
                  <LanguageProvider id={"1151"} alt="Estado: " />
                </label>
                <Dropdown
                  value={filter.estados}
                  loading={loadingEstados}
                  options={estados}
                  onChange={(e) => {
                    setFilter({ ...filter, estados: e.value });
                  }}
                  optionLabel="descripcion"
                />
                <label>
                  <LanguageProvider id={"1569"} alt="Operación: " />
                </label>
                <Dropdown
                  value={filter.operacion}
                  loading={loadingOperacion}
                  options={operacion}
                  onChange={(e) => {
                    setFilter({ ...filter, operacion: e.value });
                  }}
                  optionLabel="descripcion"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>
      <Panel
        className="sipco-AdmRemitosConcesionarios"
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                Contab. y Anul. de Operaciones
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"AdministracionRemitos"}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  label={LanguageProvider({
                    id: "748",
                    alt: "Seleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "749",
                    alt: "Deseleccionar todo",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "25206", alt: "[id:1309] Not Found" })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "187",
                    alt: "Imprimir o generar archivo Excel",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "3155",
                    alt: "Cancelar",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "3155",
                    alt: "Cancelar",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({
                    id: "2813",
                    alt: "Modificar Proveedores",
                  })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={compraTaller}
          paginator
          scrollHeight="flex"
          filterDisplay="menu"
          rows={25}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          style={{ maxWidth: "100%" }}
          loading={loading}
        >
          {columnsNamesTable.map((colum, index) => {
            if (colum.header === "Fecha Compra") {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  body={(e) => colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            } else {
              return (
                <Column
                  key={index}
                  field={colum.field}
                  header={colum.header}
                  sortable
                  filter
                />
              );
            }
          })}
        </DataTable>
        <Divider />

        <div className="form">
          <div className={"form-row"}>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"841"} alt="Total de Registros: " />
              </label>
            </div>
            <div className="sipco-option">
              <label>
                <LanguageProvider id={"864"} alt="Registros Seleccionados: " />
              </label>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
}
