import "./ContabilizarViajesTerceros.scss";

import React, { useEffect, useRef, useState } from "react";

import { AccordionTab } from "primereact/accordion";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import ExportButtons from "@shared/components/Export";
import { Filter } from "@shared/components/filter/filter";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import { Panel } from "primereact/panel";
import { ScreenCodeValue } from "@shared/ScrennCode";
import { convertDateObjects } from "@shared/components/utils/convertDateObjects";
import { useFilterContext } from "../../../protected-routes";
import usePageViews from "../../../hooks/usePageViews";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";

export function ContabilizarViajesTerceros() {
  const SCREEN_CODE = "fun0226_contabilizar_viajes_terceros";
  usePageViews();
  ScreenCodeValue(SCREEN_CODE);

  const dt = useRef(null);
  const [reporte, setReporte] = useState(null);
  const data = convertDateObjects(reporte?.filas);
  const columns = [];
  const filterLabelId = [14370, 467, 14368, 14369, 901, 18308, 18309, 1569];
  const filterNames = [];
  const filterColumnsId = [16641, 3750, 6256, 11720];
  const columnsNames = [];
  for (const element of filterLabelId) {
    filterNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Filter Labels",
      }),
    });
  }
  for (const element of filterColumnsId) {
    columnsNames.push({
      id: element,
      label: LanguageProvider({
        id: element,
        alt: "Error Columns Labels",
      }),
    });
  }

  const mensagens = [];
  const ids = [
    32, 54, 65, 448, 467, 468, 653, 901, 1182, 1184, 1491, 1569, 1669, 2502,
    4791, 5044, 5583, 1480, 11035, 11833, 11986, 12642, 13324, 14368, 14369,
    14370, 14562, 18308, 18309, 396, 13514, 2139, 18305, 2140, 367, 2892, 6367,
    16580, 3410, 18308, 18307, 749, 748,
  ];
  for (const element of ids) {
    mensagens.push({
      id: element,
      label: LanguageProvider({ id: element, alt: "Error Columns Labels" }),
    });
  }

  const { values: securityFilters } = useFilterContext();
  const sipcoAxiosService = useSipcoAxiosService();

  // Campos da requisição
  const [filter, setFilter] = useState({
    cliente: [],
    vin: "",
    numeroOfertaServicio: "",
    numeroHojaRuta: "",
    codigoReferencia: "",
    fechaDesde: new Date(),
    fechaHasta: new Date(),
  });

  const [vinOptions, setVinOptions] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [loadingClientes, setLoadingClientes] = useState(false);

  const [loadingReport, setLoadingReport] = useState(false);

  async function searchVin(event: any) {
    await loadVin(event.query);
  }
  async function loadVin(query: any) {
    try {
      if (
        securityFilters?.securityValues?.country &&
        securityFilters?.securityValues?.account
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/contabilizar-viajes-terceros/vin",
          {
              pais: securityFilters.securityValues.country,
              cuenta: securityFilters.securityValues.account,
              vin: {
                id: query
              }
          }
        );
        if (status === 200) {
          const vin = data.map((x: any) => {
            return { ...x, label: `${x.id}` };
          });
          setVinOptions(vin);
          return vin;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  async function loadClientes() {

    try {
      setLoadingClientes(true);
      const { status, data } = await sipcoAxiosService.post(
          "/contabilizar-viajes-terceros/cliente",
          {
            pais: securityFilters?.securityValues?.country
          }
      );
      if (status === 200) {
        data.unshift({ id: "", descripcion: "TODOS", descripcionFull: " - TODOS" });

        setClientes(data);
        return data;
      }
    } catch (error) {
      console.error(error);
    }finally{
      setLoadingClientes(false);
    }
  }
  async function loadReport() {
    setLoadingReport(true);
    try {
      const { status, data } = await sipcoAxiosService.post(
        "/contabilizar-viajes-terceros/detalle",
        {
          pais: securityFilters.securityValues.country,
          cuenta: securityFilters.securityValues.account,
          cliente: filter.cliente,
          vin: filter.vin,
          ordenServicio: filter.numeroOfertaServicio,
          hojaRuta: filter.numeroHojaRuta,
          codReferencia: filter.codigoReferencia,
          fechaDesde:{
            date: filter.fechaDesde
          },
          fechaHasta: {
            date:filter.fechaHasta
          }
        }
      );

      if (status === 200) {
        setReporte(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingReport(false);
    }
  }


  useEffect(() => {
    async function initialize() {
      try {
        const clientess = await loadClientes();
        setFilter({
          ...filter,
          cliente: clientess[0],
        });
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [securityFilters.securityValues.country]);
  return (
    <div className="consulta-ot">
      <Filter
        onSearch={loadReport}
        securityOptions={{
          country: true,
          account: true,
          screenCode: SCREEN_CODE,
          allClientsOptions: true,
        }}
      >
        <AccordionTab header={<LanguageProvider id={"743"} alt="Filtros" />}>
          <div className="form">
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider
                    id={filterNames[0].label}
                    alt="Nro. Oferta Servicio"
                  />
                </label>
                <InputText
                  value={filter.numeroOfertaServicio}
                  onChange={(e) =>
                    setFilter({ ...filter, numeroOfertaServicio: e.target.value })
                  }
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider
                    id={filterNames[0].label}
                    alt="Nro. Hoja de Ruta"
                  />
                </label>
                <InputText
                  value={filter.numeroHojaRuta}
                  onChange={(e) =>
                    setFilter({ ...filter, numeroHojaRuta: e.target.value })
                  }
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider
                    id={filterNames[4].label}
                    alt="Ingrese el VIN"
                  />
                </label>
                <AutoComplete
                  value={filter.vin}
                  suggestions={vinOptions}
                  completeMethod={searchVin}
                  onChange={(e) => setFilter({ ...filter, vin: e.value })}
                  field="label"
                  dropdown
                  forceSelection
                />
              </div>
            </div>

            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[4].label} alt="Cliente" />
                </label>
                <Dropdown
                  value={filter.cliente}
                  options={clientes}
                  onChange={(e) => setFilter({ ...filter, cliente: e.value })}
                  optionLabel="descripcionFull"
                  filter
                  loading={loadingClientes}
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider
                    id={filterNames[0].label}
                    alt="Código referencia"
                  />
                </label>
                <InputText
                  value={filter.codigoReferencia}
                  onChange={(e) =>
                    setFilter({ ...filter, codigoReferencia: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="form-row">
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[3].label} alt="Desde" />
                </label>
                <Calendar
                  value={filter.fechaDesde}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaDesde: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
              <div className="sipco-option">
                <label>
                  <LanguageProvider id={filterNames[3].label} alt="Hasta" />
                </label>
                <Calendar
                  value={filter.fechaHasta}
                  onChange={(e) => {
                    setFilter({
                      ...filter,
                      fechaHasta: e.value,
                    });
                  }}
                  showButtonBar
                  showIcon
                  dateFormat="dd/mm/yy"
                />
              </div>
            </div>
          </div>
        </AccordionTab>
      </Filter>

      <Panel
        headerTemplate={(props) => {
          return (
            <div className={props.className} onClick={props.onTogglerClick}>
              <span className={props.titleClassName}>
                <LanguageProvider id="14380" alt="Ordenes de Trabajo" />
              </span>
              <div className="action">
                <ExportButtons
                  dt={dt}
                  data={data}
                  columns={columnsNames}
                  screenName={"Consulta Orden Trabajo"}
                  pdf={false}
                />
              </div>
            </div>
          );
        }}
        footerTemplate={(props) => {
          return (
            <div className={props.className}>
              {/* butons */}
              <div>
                <Button
                  // className="buttons"
                  label={LanguageProvider({
                    id: "23531",
                    alt: "Compras/Ventas",
                  })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "1309", alt: "Contabilizar" })}
                  text
                />
                <Button
                  label={LanguageProvider({ id: "2784", alt: "Anular" })}
                  text
                />
              </div>
            </div>
          );
        }}
      >
        <DataTable
          size={"small"}
          showGridlines
          stripedRows
          ref={dt}
          value={reporte}
          paginator
          scrollHeight="flex"
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          resizableColumns
          removableSort
          loading={loadingReport}
          filterDisplay="menu"
        >
          <Column field="caratula.id" header={columnsNames[0].label} sortable filter />
          <Column field="cliente.descripcion" header={columnsNames[1].label} sortable filter />
          <Column field="fechaCotizacion.date" header={columnsNames[2].label} sortable filter
                  body={(rowData) => {
                    if (rowData?.fechaCotizacion?.date) {
                      return new Date(rowData?.fechaCotizacion?.date).toLocaleString('pt-BR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false
                      });
                    } else {
                      return '';
                    }
                  }}/>
          <Column field="cantidad" header={columnsNames[3].label} sortable filter />
        </DataTable>
      </Panel>
    </div>
  );
}
export default ContabilizarViajesTerceros;
