import en from "../../language/en-US.json";
import esAR from "../../language/es-AR.json";
import esCL from "../../language/es-CL.json";
import esMX from "../../language/es-MX.json";
import fr from "../../language/fr-FR.json";
import pt from "../../language/pt-BR.json";
/**
 * LanguageProviderString é uma versão do LanguageProvider projetada para resolver o erro:
 * "Invalid hook call. Hooks can only be called inside of the body of a function component."
 * Este componente não utiliza hooks e, em vez disso, depende de props para determinar o idioma
 * e retornar a string apropriada com base no código de idioma e id fornecidos.
 *
 * @param {Object} props - O objeto de propriedades.
 * @param {Object} props.user - O objeto do usuário contendo informações sobre o idioma. que deve vir de "const { user } = useAuth();"
 * @param {string} props.id - O identificador para a string a ser traduzida.
 * @param {string} [props.alt] - Uma string alternativa a ser retornada caso a tradução não seja encontrada.
 * @returns {string} A string traduzida com base no idioma do usuário ou a string alternativa, se fornecida.
 * * @example
 * // Exemplo de uso:
 * const { user } = useAuth(); 
 * 
 * const resultado = LanguageProviderString({
 *   user: user,
 *   id: "9369", // ID da string que será traduzida
 *   alt: "Usuário:", // Texto alternativo caso a tradução não seja encontrada
 * });
 * 
 * console.log(resultado); // Retorna a tradução com base no idioma do usuário ou "Usuário:" caso não haja tradução
 */
 
export const LanguageProviderString = (props) => {
  let label = "";

  if (!props.user) {
    return props.alt || "Error Labels";
  }

  switch (props.user?.idioma.codigoIso) {
    case "en-us":
      label = en[props.id];
      break;
    case "fr-fr":
      label = fr[props.id];
      break;
    case "es-mx":
      label = esMX[props.id];
      break;
    case "es-ar":
      label = esAR[props.id];
      break;
    case "es-cl":
      label = esCL[props.id];
      break;
    case "pt-br":
      label = pt[props.id];
      break;
    default:
      label = en[props.id]; // Default to English if no match
  }

  return label || props.alt || "Error Labels";
};