import React, {useEffect, useRef, useState} from "react";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { LanguageProvider } from "@shared/components/language-provider";
import useFilterColumnsIds from "../hooks/useFilterColumnsIds";
import { useFilterContext } from "../../../../protected-routes";
import { useSipcoAxiosService } from "@services/axios/sipco-axios-service";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";

const Manual: React.FC<{ reporte: any, vin: any , fechaRealizacion: any}> = ({ reporte, vin, fechaRealizacion }) => {
  const sipcoAxiosService = useSipcoAxiosService();
  const { values: securityFilters } = useFilterContext();
  const [tableData, setTableData] = useState(reporte ? [...reporte] : []);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const toast = useRef<Toast>(null);
  const filterColumnsIdManual = [1491, 1569, 5788, 8380, 2521, 1669];
  const columNames = useFilterColumnsIds(filterColumnsIdManual);
  const [resultado, setResultado] = useState([]);
  const [loadingResultado, setLoadingResultado] = useState(false);
  const [proveedor, setProveedor] = useState([]);
  const [loadingProveedor, setLoadingProveedor] = useState(false);


  const [filter, setFilter] = useState({
    resultado: null,
    proveedor: null,
    observacion: null,
  });

  useEffect(() => {
    async function initialize() {
      try {
        if (reporte) {
          setTableData([...reporte]);
        }
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, [reporte]);

  async function registrarOperacion() {
    try {
      let updatedTableData = tableData;
      const newRow = { ...selectedRow };
      newRow.lineaOperacionRegistrada.valor = filter.resultado.id;
      newRow.lineaOperacionRegistrada.operacionRegistrada.proveedorOperacion =
        filter.proveedor;
      newRow.lineaOperacionRegistrada.operacionRegistrada.observaciones =
        filter.observacion;
      newRow.tieneResultado = true;
      const updatedRowIndex = tableData.indexOf(selectedRow);
      updatedTableData[updatedRowIndex] = newRow;

      setTableData(updatedTableData);
      setSelectedRow(newRow);
    } catch (error) {
      console.error(error);
    }
  }
  async function cancelarRegistros() {
    try {
      setFilter({
        ...filter,
        resultado: null,
        proveedor: null,
        observacion: "",
      });
      setTableData([])
    } catch (error) {
      console.error(error);
    }
  }
  async function loadResultado(operacion: any, lineaOperacion: any) {
    setLoadingResultado(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        operacion &&
        lineaOperacion
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-automatica/manual/resultado-operacion",
          {
            pais: securityFilters.securityValues.country,
            operacion: operacion,
            lineaOperacion: lineaOperacion,
          }
        );
        if (status === 200) {
          setResultado(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingResultado(false);
    }
    return [];
  }
  const longValue = fechaRealizacion.getTime();
  async function validarRegistros() {
    setLoading(true);
    tableData.forEach((item) => {
      if (item.tieneResultado === null) {
        item.tieneResultado = false;
      }
    });

    try {
      if (securityFilters.securityValues.country != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/registracion-operacion-automatica/manual/validar-operacion-registrada",
            {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,
              cuenta: securityFilters.securityValues.account,
              subcuenta: securityFilters.securityValues.subaccount,
              vin:{
                id: vin,
              },
              fechaRealizacion: longValue,
              lineasOperacion: tableData,
            }
        );
        if (status === 200) {
          confirmRegistrar();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    return [];
  }
  async function processarRegistros() {
    setLoading(true);
    tableData.forEach((item) => {
      if (item.tieneResultado === null) {
        item.tieneResultado = false;
      }
    });
    try {
      if (securityFilters.securityValues.country != null) {
        const { status, data } = await sipcoAxiosService.post(
            "/registracion-operacion-automatica/manual/operacion-registrada",
            {
              pais: securityFilters.securityValues.country,
              cliente: securityFilters.securityValues.client,
              cuenta: securityFilters.securityValues.account,
              subcuenta: securityFilters.securityValues.subaccount,
              fechaRealizacion: longValue,
              lineasOperacion: tableData,
            }
        );
        if (status === 200) {
          toast.current.show({
            severity: "success",
            summary: "Operación registrada",
            detail: "Operación registrada correctamente",
            life: 3000,
          })
          cancelarRegistros();
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    return [];
  }
  async function loadProveedor(operacion: any) {
    setLoadingProveedor(true);
    try {
      if (
        securityFilters?.securityValues?.country != null &&
        operacion &&
        securityFilters?.securityValues?.account != null
      ) {
        const { status, data } = await sipcoAxiosService.post(
          "/registracion-operacion-automatica/manual/proveedor",
          {
            pais: securityFilters.securityValues.country,
            cuenta: securityFilters.securityValues.account,
            operacion: operacion,
          }
        );
        if (status === 200) {
          setProveedor(data);
          return data;
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingProveedor(false);
    }
    return [];
  }
  async function handleRowClick(rowData) {
    setSelectedRow(rowData.data);
    await Promise.all([
      loadResultado(
        rowData?.data?.operacion,
        rowData?.data?.lineaOperacionRegistrada?.lineaOperacion
      ),
      loadProveedor(rowData?.data?.operacion),
    ]);
    setFilter({
      ...filter,
      resultado: null,
      proveedor: null,
      observacion: null,
    });
  }
  const confirmRegistrar = () => {
    confirmDialog({
      message: (
          <LanguageProvider
              id={"13275"}
              alt="Deseja processar as alterações realizadas?"
          />
      ),
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept: () => processarRegistros(),
      reject: null,
    });
  };
  return (
      <div>
        <div>
          <ConfirmDialog/>
        </div>
        <Toast ref={toast} position="bottom-left" />
        <DataTable
            size={"small"}
            showGridlines
            stripedRows
            value={tableData}
            paginator
            scrollHeight="flex"
            filterDisplay="menu"
            rows={10}
            rowsPerPageOptions={[5, 10, 25, 50]}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            style={{maxWidth: "100%"}}
            onRowClick={handleRowClick}
            loading={loading}
            selectionMode="single"
            selection={selectedRow}
        >
          <Column field="vin.id" header={columNames[0].label}/>
          <Column field="operacion.descripcion" header={columNames[1].label}/>
          <Column
              field="lineaOperacionRegistrada.lineaOperacion.descripcion"
              header={columNames[2].label}
          />
          <Column
              header={columNames[3].label}
              body={(rowData) => {
                return rowData?.lineaOperacionRegistrada?.valor;
              }}
          />
          <Column
              header={columNames[4].label}
              body={(rowData) => {
                return rowData?.lineaOperacionRegistrada?.operacionRegistrada
                    ?.proveedorOperacion?.descripcion;
              }}
          />
          <Column
              field="lineaOperacionRegistrada.operacionRegistrada.observaciones"
              header={columNames[5].label}
          />
        </DataTable>
        <div className="filter-options">
          <div className="sipco-options-line">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"2785"} alt="Operación"/>
              </label>
              <InputText
                  value={selectedRow?.operacion?.descripcion}
                  disabled={true}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"10828"} alt="Linea"/>
              </label>
              <InputText
                  value={
                    selectedRow?.lineaOperacionRegistrada?.lineaOperacion
                        ?.descripcion
                  }
                  disabled={true}
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"8380"} alt="Resultado"/>
              </label>
              <Dropdown
                  value={filter.resultado}
                  options={resultado.map((item) => ({
                    ...item,
                    optionLabel: `${item.descripcion} (${item.id})`,
                  }))}
                  onChange={(e) => setFilter({...filter, resultado: e.value})}
                  loading={loadingResultado}
                  optionLabel="optionLabel"
                  filter
                  showClear
              />
            </div>
          </div>
          <div className="sipco-options-line">
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"2521"} alt="Proveedor"/>
              </label>
              <Dropdown
                  value={filter.proveedor}
                  options={proveedor.map((item) => ({
                    ...item,
                    optionLabel: `${item.descripcion} (${item.id})`,
                  }))}
                  onChange={(e) => setFilter({...filter, proveedor: e.value})}
                  loading={loadingProveedor}
                  optionLabel="optionLabel"
                  filter
                  showClear
              />
            </div>
            <div className={"sipco-option"}>
              <label>
                <LanguageProvider id={"1669"} alt="Observación"/>
              </label>
              <InputText
                  value={filter?.observacion}
                  onChange={(e) =>
                      setFilter({...filter, observacion: e.target.value})
                  }
              />
            </div>
            <div className={"sipco-option"}>
              <Button
                  className="button"
                  onClick={registrarOperacion}
                  icon="pi pi-check"
                  iconPos="right"
                  severity="success"
                  label={LanguageProvider({
                    id: "14394",
                    alt: "Registrar Operación",
                  })}
              />
            </div>
          </div>
        </div>
        {/* buttons */}
        <div>
          <Button
              label={LanguageProvider({
                id: "14391",
                alt: "Realizar Operación",
              })}
              onClick={validarRegistros}
              text
          />
          <Button
              label={LanguageProvider({
                id: "3155",
                alt: "Cancelar",
              })}
              onClick={cancelarRegistros}
              text
          />
        </div>
      </div>
  );
};
export default Manual;
